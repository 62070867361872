import React, { FC, useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getSearch, setSearch } from "config/util";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { get_items, get_items_id, delete_items_id } from "stores/Items/actions";
import { get_collections_id } from "stores/Collections/actions";
import { ChainLink } from "components/ChainLink";
import { put_collections_id_favorite } from "stores/Collections/actions";
import { put_collections_id_join, delete_collections_id } from "stores/Collections/actions";
import { get_activities } from "stores/Activities/actions";

import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import socketIOClient from "socket.io-client";
import env from "../../stores/env";
import { view_collection } from "../../stores/Collections/view";

let socket = null;

const App = ({ branch, children }) => {
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();

  const dispatch = useDispatch();

  const maps = useSelector((state) => state.reduce_get_maps);

  const history = useHistory();

  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection, maps);

  // const location_search = useLocation().search;
  // const propsSearch = getSearch(location_search) ?? {};
  const count = useSelector((state) => state.reduce_get_items.count);
  const loading = useSelector((state) => state.reduce_get_items.isLoading);

  const [type, set_type] = React.useState(branch.type);

  const { collection_id } = useParams();

  const dispatch_type = (name) => {
    if (name == "items") {
      history.push(`/collections/${collection_id}/items`);
    }

    if (name == "activities") {
      history.push(`/collections/${collection_id}/activities`);
    }
  };

  const dispatch_page = (name, collection_id) => {
    if (name == "items") {
      set_get_items({
        ...propsItems.search,
        collection_id,
        // collection_id: null,
        // type: "collected",
      });
    }

    if (name == "activities") {
      set_get_activities({ ...propsActivities.search, collection_id });
    }
  };

  useEffect(() => {
    if (collection_id) {
      // set_get_users_my_id(collection_id);
      // set_propsItems({ search: { ...propsItems.search, collection_id } });
    }

    dispatch_page(branch.type, collection_id);
    set_type(branch.type);
  }, [branch.type, collection_id]);

  const [propsItems, set_propsItems] = useState({
    search: {
      active_flg: true,
      collection_id,
      page: 1,
      limit: 12,
      sale_types: [],
      // sort: [["updated_at", "DESC"]],
      sort_type: "item_id",
      // sort_type: "price_lower",
      // sort_type: "price_higher",
      ...branch?.search,
      // ...propsSearch?.search,
    },
  });

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if (!message) return;
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_get_collections_id = (data, next, prev) => {
    dispatch(
      get_collections_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_put_collections_id_favorite = (data, next, prev) => {
    dispatch(
      put_collections_id_favorite({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_put_collections_id_join = (data, next, prev) => {
    dispatch(
      put_collections_id_join({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_delete_collections_id = (data, next, prev) => {
    dispatch(
      delete_collections_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_get_items = (data, next, prev) => {
    dispatch(
      get_items({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_items = (search, page = 1) => {
    dispatch_get_items({
      search: { ...search, page },
    });
    set_propsItems({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    if (collection_id) {
      dispatch_get_collections_id(
        {
          id: collection_id,
        },
        (data) => {}
      );
    }

    set_get_items(propsItems.search);
  }, [collection_id]);

  const [propsActivities, set_propsActivities] = useState({
    search: {
      active_flg: true,
      type: [0, 21, 22, 30, 31, 32, 34, 35],
      page: 1,
      limit: 10,
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_activities = (data, next, prev) => {
    dispatch(
      get_activities({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_activities = (search, page = 1) => {
    dispatch_get_activities({
      search: { ...search, page },
    });
    set_propsActivities({
      search: { ...search, page },
    });
  };

  const newchildren = React.cloneElement(children, {
    Items: {
      propsItems,
      set_propsItems,
      dispatch_gets: set_get_items,
      dispatch_put_collections_id_favorite,
      dispatch_put_collections_id_join,
      dispatch_delete_collections_id,
      count,
      loading,
      list: "user",
      branch,
    },
    Activities: {
      propsActivities,
      set_propsActivities,
      dispatch_gets: set_get_activities,
      branch,
      list: "user",
      dispatch_type,
      type,
    },
    Page: { type, dispatch_type, t, v_collection, history },
  });

  if (loading) return <></>;

  return (
    <>
      <Helmet>
        <title>tomonity</title>
      </Helmet>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      {newchildren}
    </>
  );
};

export default App;
