import Header from "../../views_components/Component/Header";
import _sidebar from "./_sidebar";
import _mainheader from "./_mainheader";
import Footer from "../../views_components/Component/Footer";
import _mainstandard from "./_mainstandard";
import _mainunapproved from "./_mainunapproved";
import _modaljoin from "./_modaljoin";

export default function Contents({ Page, Items, Activities }) {
  const { type, dispatch_type, v_collection } = Page;

  const collabo_flg = v_collection.collection_collabo_flg;
  const active_flg = v_collection.active_flg;

  const コラボ承認済 = collabo_flg && active_flg;
  const コラボ未承認 = collabo_flg && !active_flg;

  return (
    <>
      <div>
        <Header />
        {コラボ未承認 && (
          <div className="bg-danger text-light mb-2">
            <div className="container py-3 py-md-3 overflow-hidden">
              すべてのメンバーが参加していません
            </div>
          </div>
        )}
        <div className="container container-main">
          {_sidebar(Items)}
          <main className="main">
            {!コラボ未承認 && _mainstandard({ Page, Items, Activities })}
            {コラボ未承認 && _mainunapproved({ Page, Items, Activities })}
            {_modaljoin({ Page, Items })}
          </main>
        </div>
        <Footer />
      </div>
    </>
  );
}
