import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { view_item } from "../../stores/Items/view";

import ItemOffersPager from "../../views_components/PagerItemOffers/ItemOffersPager";
import ItemOffersList from "../../views_components/PagerItemOffers/ItemOffersList";

export default function Contents(ItemOffers) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const item = useSelector((state) => state.reduce_get_items.item);

  return (
    <>
      <div className="accordion mb-3" id="offerssPanels">
        <div className="accordion-item">
          <h3 className="accordion-header" id="offerss-heading">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#offerss-collapse"
              aria-expanded="true"
              aria-controls="offerss-collapse"
            >
              {t("オファー履歴")}
            </button>
          </h3>
          <div
            id="offerss-collapse"
            className="accordion-collapse collapse show"
            aria-labelledby="offerss-heading"
          >
            <div className="accordion-body py-0">
              <div className="activitys">
                <ItemOffersPager ItemOffers={ItemOffers} />
                <ItemOffersList ItemOffers={ItemOffers} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
