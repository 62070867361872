import { createAction, createReducer } from 'redux-act';

export const get_users_my = createAction("GET_USERS_MY");
export const get_users_my_data = createAction("GET_USERS_MY_DATA");
export const get_users_my_error = createAction("GET_USERS_MY_ERROR");
export const get_users_my_id = createAction("GET_USERS_MY_ID");
export const get_users_my_id_data = createAction("GET_USERS_MY_ID_DATA");
export const get_users_my_id_error = createAction("GET_USERS_MY_ID_ERROR");
export const post_users_my = createAction("POST_USERS_MY");
export const post_users_my_data = createAction("POST_USERS_MY_DATA");
export const post_users_my_error = createAction("POST_USERS_MY_ERROR");
export const put_users_my_id = createAction("PUT_USERS_MY_ID");
export const put_users_my_id_data = createAction("PUT_USERS_MY_ID_DATA");
export const put_users_my_id_error = createAction("PUT_USERS_MY_ID_ERROR");
export const delete_users_my_id = createAction("DELETE_USERS_MY_ID");
export const delete_users_my_id_data = createAction("DELETE_USERS_MY_ID_DATA");
export const delete_users_my_id_error = createAction("DELETE_USERS_MY_ID_ERROR");
