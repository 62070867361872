import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
// import Sidebar from "./Sidebar";
import { view_collection } from "../../stores/Collections/view";
import { view_user } from "../../stores/Users/view";
import _sidebar from "./_sidebar";
import _modalfollow from "./_modalfollow";

export default function Contents(Page) {
  const { type, dispatch_type } = Page;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_users_my = useSelector((state) => state.reduce_get_users_my);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const collection = useSelector((state) => state.reduce_get_items.collection);

  const user = reduce_get_users_my.user;
  const v_user = view_user(user);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  const collection = useSelector((state) => state.reduce_get_items.collection);
  const v_collection = view_collection(collection);

  return (
    <>
      <div className="main-header main-header-hidden mb-0">
        <ul className="nav canopy">
          <li className="nav-item canopy-item">
            <a
              className={`nav-link ${type == "items" ? "active" : ""}`}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                dispatch_type("items");
              }}
            >
              <span className="canopy-text">
                <span className="canopy-num">{v_user.count_item_collected}</span>
                {t("アイテム")}
              </span>
            </a>
          </li>
          {!v_user.collabo_flg && (
            <li className="nav-item canopy-item">
              <a
                className={`nav-link ${type == "collections" ? "active" : ""}`}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch_type("collections");
                }}
              >
                <span className="canopy-text">
                  <span className="canopy-num">{v_user.count_collection_collected}</span>
                  {t("コレクション")}
                </span>
              </a>
            </li>
          )}

          <li className="nav-item canopy-item">
            <a
              className={`nav-link ${type == "created" ? "active" : ""}`}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                dispatch_type("created");
              }}
              aria-current="page"
            >
              <span className="canopy-text">
                <span className="canopy-num">{v_user.count_collection_created}</span>
                {t("クリエイト")}
              </span>
            </a>
          </li>
          {!v_user.collabo_flg && (
            <li className="nav-item canopy-item">
              {/* <li className="nav-item"> */}
              <a
                className={`nav-link ${type == "favoritecollections" ? "active" : ""}`}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch_type("favoritecollections");
                }}
              >
                <span className="canopy-text">
                  <span className="canopy-num">&nbsp;</span>
                  {t("ウォッチリスト")}
                </span>
              </a>
            </li>
          )}

          {/* canopy-item */}
          {/* &nbsp; */}
          {!v_user.collabo_flg && (
            <li className="nav-item canopy-item">
              <a
                className={`nav-link ${type == "favoriteitems" ? "active" : ""}`}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch_type("favoriteitems");
                }}
              >
                <span className="canopy-text">
                  <span className="canopy-num">&nbsp;</span>
                  {t("お気に入り")}
                </span>
              </a>
            </li>
          )}

          <li className="nav-item canopy-item">
            <a
              className={`nav-link ${type == "itemoffers" ? "active" : ""}`}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                dispatch_type("itemoffers");
              }}
            >
              <span className="canopy-text">
                <span className="canopy-num">&nbsp;</span>
                {t("オファー")}
              </span>
            </a>
          </li>
          <li className="nav-item canopy-item">
            <a
              className={`nav-link ${type == "activities" ? "active" : ""}`}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                dispatch_type("activities");
              }}
            >
              <span className="canopy-text">
                <span className="canopy-num">&nbsp;</span>
                {t("アクティビティ")}
              </span>
            </a>
          </li>
        </ul>
      </div>
      {/* <div className="d-flex justify-content-between align-items-center pt-3">
        <h2 className="h4 m-0 text-truncate"></h2>
        {v_user.myself && (
          <a
            className="btn btn-outline-primary rounded-pill px-sm-3 text-nowrap"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/collections/create`);
            }}
          >
            <i className="fa-solid fa-plus fa-fw" />
            <span className="d-none d-md-inline-block"></span>
            <span className="ms-1 ms-md-0">コレクションを作成</span>
          </a>
        )}
      </div> */}
    </>
  );
}
