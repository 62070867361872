import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";

import { view_withdraw } from "../../stores/Withdraws/view";

export default function Dashboard({ Withdraws }) {
  const { propsWithdraws, set_propsWithdraws } = Withdraws;

  const [t, i18n] = useTranslation();

  const history = useHistory();
  const maps = useSelector((state) => state.reduce_get_maps);
  const datalist = useSelector((state) => state.reduce_get_withdraws.withdraws);

  const Head = () => {
    return (
      <>
        <div className="row g-2 border-bottom d-none d-sm-flex">
          <div className="col-sm-4 col-xl-3 py-sm-3">
            <span className="fw-bold">{t("ラベル")}</span>
          </div>
          <div className="col py-sm-3">
            <span className="fw-bold">{t("アドレス")}</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Head />
      {datalist.map((prop, idx) => {
        const v_withdraw = view_withdraw(prop, maps, t);

        return (
          <div className="row g-3 mb-3 pb-4 mb-sm-0 pb-sm-0 border-bottom position-relative">
            <div className="col-12 col-sm-4 col-xl-3 py-sm-3">
              <small className="mb-2 text-muted fw-bold d-block d-sm-none">{t("ラベル")}</small>
              {v_withdraw.label}
            </div>
            <div className="col py-sm-3 text-truncate overflow-hidden">
              <small className="mb-2 text-muted fw-bold d-block d-sm-none">{t("アドレス")}</small>
              <a
                className="stretched-link text-body"
                href="javascript:void(0)"
                data-bs-toggle="modal"
                data-bs-target="#withdrawalsModal"
                onClick={(e) => {
                  e.preventDefault();
                  set_propsWithdraws({
                    ...propsWithdraws,
                    address: v_withdraw.address,
                    label: v_withdraw.label,
                    wallet_id: v_withdraw.wallet_id,
                    amount:""
                  });
                }}
              >
                {v_withdraw.address}
              </a>
            </div>
            <div className="col-1 py-sm-3 text-end">
              <i className="fa-solid fa-angle-right" />
            </div>
          </div>
        );
      })}
    </>
  );
}

// <GridContainer>
//   <GridItem xs={12} sm={12} md={12} padding={true} key={prop.activity_id}>
//   </GridItem>
//   </GridContainer>
