import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import Footer from "../../views_components/Component/Footer";

import _sidebar from "./_sidebar";

import ActivitiesPager from "../../views_components/PagerActivities/ActivitiesPager";
import ActivitiesList from "../../views_components/PagerActivities/ActivitiesList";

export default function Contents({ Withdraws, Activities }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_wallets = useSelector((state) => state.reduce_get_wallets);
  const maps = useSelector((state) => state.reduce_get_maps);

  const coin_decimals = maps.coin_decimals;
  const coins = maps.coins;

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  return (
    <>
      <div>
        <Header />
        <div className="container container-main">
          {_sidebar(Withdraws, t("ウォレット"))}
          <main className="main">
            <div className="main-header pt-3 pb-2 ps-lg-3 d-none d-sm-block">
              <h2 className="h4 m-0 text-truncate">
                {/* <i className="fa-solid fa-wallet fa-fe me-3" /> */}
                {t("ウォレット")}
              </h2>
            </div>
            <div className="main-toolbar align-items-center pt-4 pt-md-0 pb-2 ps-lg-3">
              <h3 className="h5">{t("アクティビティ")}</h3>
            </div>
            <div className="ps-lg-3">
              {/* <div className="p-5 bg-light text-center rounded-3">
                <p className="m-5">チャート本体</p>
              </div> */}
              <ActivitiesPager Activities={Activities} />
              <ActivitiesList Activities={Activities} />
            </div>
          </main>
        </div>
        <Footer />
      </div>
    </>
  );
}
