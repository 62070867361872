import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";
import CustomPhone from "components/CustomPhone/CustomPhone.js";
import CustomCircle from "components/CustomCircle/CustomCircle.js";

import { hideModal, showModal } from "config/util";

export default function Contents({ Account }) {
  const {
    propsAccount,
    set_propsAccount,
    dispatch_put_accounts_security,
    dispatch_type,
    branch,
  } = Account;
  const method = branch.method;
  const [finish, set_finish] = useState(false);

  const props = propsAccount;
  const set_props = set_propsAccount;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts.user);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const mail_address_current = reduce_get_accounts.mail_address;
  const phone_number_current = reduce_get_accounts.phone_number;

  const two_factor_type = reduce_get_accounts.two_factor_type;
  const two_factor_flg = reduce_get_accounts.two_factor_flg;
  const loading = useSelector((state) => state.reduce_put_accounts_security.isLoading);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const maps = useSelector((state) => state.reduce_get_maps);

  const maps_two_factor_types = { ...maps.two_factor_types };
  delete maps_two_factor_types[0];
  delete maps_two_factor_types[1];

  return (
    <>
      <div>
        <Header />
        <div className="container container-main">
          <main className="main">
            <div action="created-collection-detail.html" entype="multpart/form-data">
              <div className="main-header pt-3 pb-2">
                <h2 className="h4 m-0 text-truncate">{t("セキュリティ設定の変更")}</h2>

                <div className="btn-group">
                  <a
                    className="btn btn-outline-secondary text-dark rounded-pill px-3 me-2 text-nowrap"
                    href="javascript:history.back()"
                  >
                    <i className="fa-solid fa-xmark" />
                    <span className="d-none d-sm-inline-block">{t(" キャンセル")}</span>
                  </a>
                  <button
                    type="button"
                    name="confirm"
                    className="btn btn-primary rounded-pill px-3 text-nowrap"
                    role="button"
                    // data-bs-toggle="modal"
                    // data-bs-target="#processDialog"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch_put_accounts_security(
                        {
                          two_factor_flg: props.two_factor_flg ? 1 : 0,
                          two_factor_type: props.two_factor_type,
                        },
                        () => {
                          dispatch_type();
                        },
                        () => {}
                      );
                    }}
                  >
                    <CustomCircle title={t("変更")} loading={loading} size={16} />
                  </button>
                </div>
              </div>
              <div className="row g-3 p-xl-3">
                <div className="col-md-6 col-xl-8">
                  <h3 className="h5">{t("通知先")}</h3>
                  <div className="row row-cols-1 row-cols-xl-2 g-3">
                    <div className="col">
                      <div className="mb-4">
                        <div className="form-floating mb-2">
                          <select
                            onChange={(e) => {
                              const val = e.target.value;
                              set_props({
                                ...props,
                                two_factor_type: val,
                              });
                            }}
                            className="form-select"
                            id="collectionCategory"
                            aria-label="\u30AB\u30C6\u30B4\u30EA"
                            value={props.two_factor_type}
                          >
                            {Object.keys(maps_two_factor_types).map((k, idx) => {
                              return <option value={k}>{t(maps_two_factor_types[k])}</option>;
                            })}
                          </select>
                          <label htmlFor="collectionCategory">{t("通知先")}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="h5">{t("２ファクター認証")}</h3>
                  <div className="row row-cols-1 row-cols-xl-2 g-3">
                    <div className="col">
                      <div className="form-check-reverse form-switch mb-3">
                        <label className="form-check-label" htmlFor="tradingOnly">
                          {t("認証する")}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="tradingOnly"
                          onChange={() => {
                            set_props({
                              ...props,
                              two_factor_flg: !props.two_factor_flg,
                            });
                          }}
                          defaultChecked={props.two_factor_flg}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 
              {finish && (
                <div className="row g-3 p-xl-3">
                  <div className="col-md-6 col-xl-8">
                    <h3 className="h5">{t("セキュリティ設定が変更されました")}</h3>
                  </div>
                </div>
              )} */}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
