import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";
import { getMessage } from "config/util";

import { view_top_seller } from "../../stores/Top/view";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

export default function Dashboard(TopCollections) {
  // const {
  //   propsCollections,
  //   set_propsCollections,
  //   dispatch_gets,
  //   dispatch_type,
  //   type,
  //   mypage,
  //   list,
  //   user_id,
  // } = Collections;

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const maps = useSelector((state) => state.reduce_get_maps);

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const datalist = useSelector((state) => state.reduce_get_top_sellers.sellers);
  const loading = useSelector((state) => state.reduce_get_top_sellers.isLoading);
  // const loadingId = useSelector((state) => state.reduce_get_sellers.isLoadingId);

  if (loading)
    return (
      <>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-2 g-xl-5 mb-3">
          <CustomLinearProgress color="primary" />
        </div>
      </>
    );
  if (!datalist) return <></>;

  if (datalist.length == 0) return <></>;

  const chunk = (ary, number) => {
    const length = Math.ceil(ary.length / number);
    return new Array(length).fill().map((_, i) => ary.slice(i * number, (i + 1) * number));
  };

  const datalist_ary = chunk(datalist, 15);

  const datalist_ary_0 = datalist_ary[0] ?? [];
  const datalist_ary_1 = datalist_ary[1] ?? [];
  const datalist_ary_2 = datalist_ary[2] ?? [];

  // "collection_name": "...AND PEGGY",
  // "collection_id": "b63a4315-3c3a-4b9d-9e82-ad1880496ec4",
  // "sum_amount": "4.000000000000",
  // "sum_price": "1.200000000000",
  // "distinct_price": 2,
  // "percent": "-76.92"
  const List = ({ v_top_seller, idx }) => {
    return (
      <>
        <div className="col">
          <div className="d-flex align-items-center position-relative">
            <div className="me-3 me-sm-2 me-xl-3"> {idx}</div>
            <div className="media overflow-hidden">
              <div className="media-object">
                {/* <div className="icon icon-pill verified"> */}
                <div className="icon icon-pill">
                  <img
                    src={v_top_seller.user_icon_uri}
                    className="icon-item"
                    width="100%"
                    height="auto"
                    alt="tomonity official"
                  />
                </div>
              </div>
              <div className="media-body overflow-hidden">
                <div className="d-flex justify-content-between">
                  <div className="text-truncate overflow-hidden me-3">
                    <a
                      className="stretched-link text-dark"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/users/${v_top_seller.user_id}`);
                      }}
                    >
                      {/* <span className="d-flex verified-text"> */}
                      <span className="text-truncate">{v_top_seller.user_name}</span>
                      {/* </span> */}
                    </a>
                    <small className="d-block mt-2 text-muted text-truncate">
                      {/* @{v_top_seller.user_id} */}
                    </small>
                  </div>
                  <div className="text-nowrap text-end">
                    {v_top_seller.percent_sign ? (
                      <p className="mb-0 text-success">
                        <sub>+</sub>
                        {v_top_seller.percent_label}%
                      </p>
                    ) : (
                      <p className="mb-0 text-danger">
                        <sub>−</sub>
                        {v_top_seller.percent_label}%
                      </p>
                    )}

                    <small className="d-block mt-2 text-muted">
                      <sub>$</sub>
                      {v_top_seller.sum_price_usd_label}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-xl-4 mb-3">
        {datalist_ary_0.map((c, idx) => {
          const v_top_seller = view_top_seller(c, maps, t);
          return <List v_top_seller={v_top_seller} idx={idx + 1} />;
        })}
        {/* 
        <div className="col">
          {datalist_ary_0.map((c, idx) => {
            const v_top_seller = view_top_seller(c, maps, t);
            return <List v_top_seller={v_top_seller} idx={idx + 1} />;
          })}
        </div>
        <div className="col">
          {datalist_ary_1.map((c, idx) => {
            const v_top_seller = view_top_seller(c, maps, t);
            return <List v_top_seller={v_top_seller} idx={idx + 5} />;
          })}
        </div>
        <div className="col d-none d-md-block">
          {datalist_ary_2.map((c, idx) => {
            const v_top_seller = view_top_seller(c, maps, t);
            return <List v_top_seller={v_top_seller} idx={idx + 10} />;
          })}
        </div> */}
      </div>
    </>
  );
}
