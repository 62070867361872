import React, { createContext, useEffect, useRef, useState } from "react";

import AppRouter from "./AppRouter";

// import { createMuiTheme } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

import { MuiThemeProvider } from "@material-ui/core/styles";
// import { theme } from "./materialui/theme";
// "#ef8157"
// "#ef8157"
import SimpleReactLightbox from "simple-react-lightbox";

import jaLocale from "date-fns/locale/ja";
import "date-fns";

import format from "date-fns/format";
import DateFnsUtils from "@date-io/date-fns";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { create } from "jss";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";

import { ChainLinkProvider } from "../components/ChainLink";

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, "yyyy MMM", { locale: this.locale });
  }
  getDatePickerHeaderText(date) {
    return format(date, "MMMd日", { locale: this.locale });
  }
}

// const theme = createMuiTheme({
//   overrides: {
//     // Style sheet name ⚛️
//     MuiButton: {
//       // Name of the rule
//       text: {
//         // Some CSS
//         color: 'white',
//       },
//     },
//   },
// });

// 6200EE
// 3700B3

// const theme = createMuiTheme({
const theme = createTheme({
  palette: {
    primary: {
      // light: "#757ce8",
      // light: "#6200EE",
      // main: "#3f50b5",
      // main: "#2ba9cd",
      // main: "#00bcd4",
      // main: "#2196f3",
      // main: "#6200EE", // chaintier

      main: "#ce33ff", // zept

      // dark: "#002884",
      dark: "#ce33ff",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      // main: "#f44336",
      // main: "#e91e63",
      main: "#dc004e",
      // main: "#ef8157",
      dark: "#ba000d",
      contrastText: "#fff",
    },
  },
});

const Material = () => {
  const jss = create({
    ...jssPreset(),
    // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
    // insertionPoint: document.head.firstChild,
    insertionPoint: document.body.firstChild,
  });

  // const jss = create({
  //   ...jssPreset(),
  //   // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  //   // insertionPoint: document.getElementById("jss-insertion-point"),
  //   // insertionPoint: document.head.lastChild,
  //   // insertionPoint: document.getElementsByClassName("data-emotion"),
  //   // insertionPoint: 'jss-insertion-point',
  // });

  // <StylesProvider injectFirst>

  return (
    // <StylesProvider injectFirst>
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
          <ChainLinkProvider>
            <SimpleReactLightbox>
              <AppRouter />
            </SimpleReactLightbox>
          </ChainLinkProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default Material;
