import { combineReducers } from "redux";
import { createAction, createReducer } from "redux-act";

import {
  get_users_my,
  get_users_my_data,
  get_users_my_error,
  get_users_my_id,
  get_users_my_id_data,
  get_users_my_id_error,
  post_users_my,
  post_users_my_data,
  post_users_my_error,
  put_users_my_id,
  put_users_my_id_data,
  put_users_my_id_error,
  delete_users_my_id,
  delete_users_my_id_data,
  delete_users_my_id_error,
} from "./actions";

const forupdate = (array, unit, id) => {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element[id] === unit[id]) {
      array[index] = unit;
    }
  }
  return array;
};

export const reduce_get_users_my = createReducer(
  {
    [get_users_my]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      if (!payload.data.search) {
        newState.users_mylist = [];
      } else if (payload.data.search.page == 1) {
        newState.users_mylist = [];
        // newState.same = false;
        // newState.page = 1;
      } else {
        // newState.same = newState.page == payload.data.search.page;
        // newState.page = payload.data.search.page;
      }
      newState.isLoading = true;
      return newState;
    },
    [get_users_my_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      // if (!newState.same) {
      newState.users_mylist = newState.users_mylist.concat(payload.data.users_my);
      // }

      newState.isLoading = false;
      return newState;
    },
    [get_users_my_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [get_users_my_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoadingId = true;
      return newState;
    },
    [get_users_my_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      const user = payload.data.user;
      const users = state.users;
      const userslist = state.userslist;

      if (users) newState.users = forupdate(users, user, "user_id");
      if (userslist) newState.userslist = forupdate(userslist, user, "user_id");
      newState.isLoadingId = false;
      return newState;
    },
    [get_users_my_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoadingId = false;
      return newState;
    },
  },
  {
    isLoading: false,
    isLoadingId: false,
    user: {},
    users: [],
    userslist: [],
    count: 0,
  }
);

export const reduce_post_users_my = createReducer(
  {
    [post_users_my]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_users_my_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_users_my_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_users_my_id = createReducer(
  {
    [put_users_my_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_users_my_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_users_my_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_users_my_id = createReducer(
  {
    [delete_users_my_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_users_my_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [delete_users_my_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export default {
  reduce_get_users_my,
  reduce_post_users_my,
  reduce_put_users_my_id,
  reduce_delete_users_my_id,
};
