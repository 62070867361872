import React, { Fragment, useEffect, useState } from "react";
import Material from "./Material";
import { useDispatch, useSelector } from "react-redux";

// import {
//   GoogleReCaptchaProvider,
//   withGoogleReCaptcha,
// } from "react-google-recaptcha-v3";

// const YourReCaptchaComponent = withGoogleReCaptcha(Material);

const ReCaptcha = () => {
  return (
    // <GoogleReCaptchaProvider
    //   reCaptchaKey="6LdrJnIaAAAAAFLYBBZ538_dLJH4Zz51u3mCfERj"
    // //   scriptProps={{ async: true, defer: true, appendTo: 'body' }}
    // >
      <Material />
    // </GoogleReCaptchaProvider>
  );
};

export default ReCaptcha;
