import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@mui/material/Box";

import styles from "assets/jss/chaintier/components/customLinearProgressStyle.js";

const useStyles = makeStyles(styles);

export default function CustomLinearProgress(props) {
  const classes = useStyles();
  const { color, ...rest } = props;
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress
        {...rest}
        classes={{
          root: classes.root + " " + classes[color + "Background"],
          bar: classes.bar + " " + classes[color],
        }}
      />
    </Box>
  );
}

CustomLinearProgress.defaultProps = {
  color: "gray",
};

CustomLinearProgress.propTypes = {
  color: PropTypes.oneOf(["primary", "warning", "danger", "success", "info", "rose", "gray"]),
};
