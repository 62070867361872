import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  zeroPadding,
} from "config/util";
import { toNumber, createBr } from "config/util";

export const view_user = (prop, maps, t) => {
  if (!prop) return {};

  const res = {
    ...prop,
    user_icon_uri: prop.user_icon_uri ? prop.user_icon_uri : "/dist/tmty/assets/default/icon.webp",
    user_icon_mime: prop.user_icon_mime ? prop.user_icon_mime : "",
    user_name: prop.user_name,
    user_message: prop.user_message,
    user_message_br: createBr(prop.user_message),
    user_message_short: prop.user_message_short,
    user_message_short_br: createBr(prop.user_message_short),
    user_message2: prop.user_message2,
    user_message2_br: createBr(prop.user_message2),
    user_favorited_my: prop.user_favorited_my,
    link_twitter: prop.user_data?.link_twitter,
    link_facebook: prop.user_data?.link_facebook,
    link_instagram: prop.user_data?.link_instagram,
    link_discord: prop.user_data?.link_discord,
    // user_id:prop.username,,
    user_id: prop.user_id,
  };

  if (prop.collection_members) {
    for (const collection_member of prop.collection_members) {
      if (!collection_member.user_icon_uri) {
        collection_member.user_icon_uri = "/dist/tmty/assets/default/icon.webp";
      }
    }
  }

  if (res.collection_owner_user) {
    if (!res.collection_owner_user.user_icon_uri) {
      res.collection_owner_user.user_icon_uri = "/dist/tmty/assets/default/icon.webp";
    }
  }

  return res;
};

// import { view_user } from "../../stores/Users/view";
// const user = reduce_get_accounts.user;
// const v_user = view_user(user);
