import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import { QRCodeSVG } from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Header from "../../views_components/Component/Header";

import ActivitiesPager from "../../views_components/PagerActivities/ActivitiesPager";
import ActivitiesList from "../../views_components/PagerActivities/ActivitiesList";
import WithdrawsPager from "../../views_components/PagerWithdraws/WithdrawsPager";
import WithdrawsList from "../../views_components/PagerWithdraws/WithdrawsList";

import { hideModal, showModal } from "config/util";
import { view_wallets } from "../../stores/Wallets/view";

import _modal_withdrawals from "./_modal_withdrawals";
import _modal_walletsCreate from "./_modal_walletsCreate";

export default function Contents(Withdraws) {
  const { propsWithdraws, set_propsWithdraws } = Withdraws;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const { coin } = useParams();

  const [active_tab, set_active_tab] = useState("預入");

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_wallets = useSelector((state) => state.reduce_get_wallets);
  const reduce_get_withdraws = useSelector((state) => state.reduce_get_withdraws);

  const maps = useSelector((state) => state.reduce_get_maps);

  const coin_decimals = maps.coin_decimals;
  const coins = maps.coins;

  useEffect(() => {}, []);
  const coin_fullname = coins[coin];

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const wallets = reduce_get_wallets?.wallets;
  const v_wallets = view_wallets(wallets, maps, t, coin);

  const withdraws = reduce_get_withdraws?.withdraws;
  // const v_withdraws = view_withdraws(withdraws,  maps,t, coin);

  const mindeposit = {
    BTC: 0.0002,
    ETH: 0.0025,
    IOST: 0,
    USDT: 0,
  };
  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  return (
    <>
      <div
        className={`tab-pane fade pt-2 m-md-3 show ${active_tab === "送付" && "active"}`}
        id="withdrawals"
        role="tabpanel"
        aria-labelledby="withdrawals-tab"
      >
        <p className="lead">{t("送付先の外部アドレスを選択してください。")}</p>
        <div className="card card-body bg-light mb-3 p-2 p-sm-3 px-sm-3 px-lg-2 px-xl-5">
          <WithdrawsList Withdraws={Withdraws} />
          <div className="card-footer border-0 text-center pt-4">
            <button
              type="button"
              className="btn btn-outline-primary rounded-pill px-3"
              data-bs-toggle="modal"
              data-bs-target="#walletsCreateModal"
              onClick={(e) => {
                e.preventDefault();
               
                // alert("dispatch_post_wallets");
                // showModal("walletsCreateModal");
              }}
            >
              {t("アドレスを登録")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
