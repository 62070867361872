import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import LiLanguage from "components/Li/LiLanguage";
import LiAccount from "components/Li/LiAccount";
import { getMessage } from "config/util";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";

import { view_collection } from "../../stores/Collections/view";
import CustomImage from "./CustomImage";

import { hideModal, showModal } from "config/util";

import _modalSuccessfully from "./_modalSuccessfully";
import _processDialog from "./_processDialog";

export default function Contents(Items) {
  const { propsItems, set_propsItems, dispatch_post_nfts } = Items;

  const props = propsItems;
  const set_props = set_propsItems;

  const { collection_id } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection);
  const maps = useSelector((state) => state.reduce_get_maps);

  return (
    <>
      <div className="row g-3 p-xl-3">
        <div className="col">
          <h3 className="h5">{t("基本設定")}</h3>
          <div className="media mb-2 bg-light p-2">
            <div className="media-object">
              <div className="icon icon-square">
                <img
                  src={v_collection.collection_icon_uri}
                  className="icon-item"
                  width="100%"
                  height="auto"
                  alt=""
                />
              </div>
            </div>
            <div className="media-body overflow-hidden">
              <p className="m-0 text-truncate">{v_collection.collection_name}</p>
              <span className="d-block">@{v_collection.collection_nmid}</span>
            </div>
          </div>
          {/* <div className="form-floating mb-2">
                    <select
                      className="form-select"
                      id="collectionCategory"
                      aria-label="\u4F5C\u6210\u5148\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3"
                    >
                      <option>–</option>
                      <option>'Disney' Collection</option>
                      <option>'WANWAN' Collection</option>
                    </select>
                    <label htmlFor="collectionCategory">
                      作成先コレクション
                    </label>
                  </div> */}
          {/* <div className="text-end mb-4">
                    <a href="#">コレクションを作成</a>
                  </div> */}
          <CustomImage
            id="collectionLogo"
            title={t("アイテムのメディア")}
            image_path={props.main_image_path}
            image={props.main_image}
            mime_type={props.main_mime_type}
            set_image_path={(base64, file) => {
              if (
                ![
                  "image/jpeg",
                  "image/png",
                  "image/svg+xml",
                  "image/gif",
                  "image/webp",
                  // "video/mp4",
                ].includes(file.type)
              ) {
                alert(t("対応するメディアではありません"));
                return;
              }

              set_props({
                ...props,
                main_image: file,
                main_mime_type: file.type,
                main_image_path: base64,
              });
            }}
          />
          {/* <div className="form-check-reverse form-switch mb-4">
                    <label className="form-check-label" htmlFor="sensitive">
                      センシティブなメディア
                      <span
                        className="ms-1"
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        title=""
                      >
                        <i className="fa-solid fa-circle-question" />
                      </span>
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="sensitive"
                    />
                  </div> */}
        </div>
        <div className="col-md-6 col-xl-8">
          <div className="row row-cols-1 row-cols-xl-2 g-3">
            <div className="mb-4">
              <div className="form-floating mb-2">
                <input
                  type="text"
                  className="form-control"
                  id="itemName"
                  placeholder="\u30A2\u30A4\u30C6\u30E0\u540D"
                  maxLength={40}
                  onChange={(e) => {
                    const val = e.target.value;
                    set_props({
                      ...props,
                      token_name: val,
                    });
                  }}
                  value={props.token_name}
                />
                <label htmlFor="itemName">{t("アイテム名")}</label>
              </div>
              <div className="form-floating mb-2">
                <textarea
                  type="text"
                  className="form-control"
                  id="collectionDisc"
                  placeholder="\u30A2\u30A4\u30C6\u30E0\u306E\u8AAC\u660E"
                  style={{
                    height: "200px",
                  }}
                  maxLength={300}
                  defaultValue={""}
                  onChange={(e) => {
                    const val = e.target.value;
                    set_props({
                      ...props,
                      description: val,
                    });
                  }}
                  value={props.description}
                />
                <label htmlFor="collectionDisc">{t("アイテムの説明")}</label>
              </div>
              {/* <div className="form-floating mb-2">
                <input
                  type="text"
                  className="form-control"
                  id="itemURL"
                  placeholder="\u30A2\u30A4\u30C6\u30E0\u306E\u7D39\u4ECBURL"
                  onChange={(e) => {
                    const val = e.target.value;
                    set_props({
                      ...props,
                      referral_url: val,
                    });
                  }}
                  value={props.referral_url}
                />
                <label htmlFor="itemURL">アイテムの紹介URL</label>
              </div> */}
              <div className="form-floating mb-2">
                <select
                  onChange={(e) => {
                    const val = e.target.value;
                    set_props({
                      ...props,
                      symbol: val,
                    });
                  }}
                  className="form-select"
                  id="collectionCategory"
                  aria-label="\u30AB\u30C6\u30B4\u30EA"
                  value={props.symbol}
                >
                  {Object.keys(maps.nfts).map((k, idx) => {
                    return <option value={k}>{maps.nfts[k]}</option>;
                  })}
                </select>
                <label htmlFor="collectionCategory">{t("ブロックチェーン")}</label>
              </div>
              {props.symbol == "NRC1155" && (
                <div className="form-floating mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="itemAmount"
                    placeholder="\u30A2\u30A4\u30C6\u30E0\u540D"
                    onChange={(e) => {
                      const val = e.target.value;
                      set_props({
                        ...props,
                        amount: val,
                      });
                    }}
                    value={props.amount}
                  />
                  <label htmlFor="itemAmount">{t("数量")}</label>
                </div>
              )}
            </div>
            {/* <div className="col mb-4">
                      <div className="d-flex align-items-center mb-2">
                        <h3 className="h5 m-0">プロパティ</h3>
                        <button
                          type="button"
                          className="btn btn-link btn-sm ms-auto"
                        >
                          プロパティを作成
                        </button>
                      </div>
                      <div className="row g-1 mb-2">
                        <div className="col-4">
                          <select
                            className="selectpicker form-control"
                            id="filter-category"
                            data-style="btn-white border"
                            data-live-search="true"
                            data-header=" \u30D7\u30ED\u30D1\u30C6\u30A3\u540D"
                            aria-label="\u30D7\u30ED\u30D1\u30C6\u30A3\u540D"
                            title=""
                          >
                            <option>色</option>
                            <option>コンセプト</option>
                          </select>
                          {}
                        </div>
                        <div className="col">
                          <select
                            className="selectpicker form-control"
                            id="filter-category"
                            multiple
                            data-style="btn-white border"
                            data-live-search="true"
                            data-header=" \u30D7\u30ED\u30D1\u30C6\u30A3\u60C5\u5831"
                            aria-label="\u30D7\u30ED\u30D1\u30C6\u30A3\u60C5\u5831"
                            title=""
                          >
                            <option>ピンク</option>
                            <option>パープル</option>
                            <option>ブルー</option>
                            <option>ホワイト</option>
                            <option>グレー</option>
                            <option>ブラック</option>
                          </select>
                          {}
                        </div>
                      </div>
                      <div className="text-end mt-3">
                        <button
                          type="button"
                          className="btn btn-outline-primary rounded-pill ms-auto"
                        >
                          <i className="fa-solid fa-plus" />
                          <span className="ps-1">追加</span>
                        </button>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-1 g-3 g-sm-4 mb-4">
                        <div className="col">
                          <h3 className="h5 mb-3">公開制限</h3>
                          <div className="form-check-reverse form-switch mb-3">
                            <label
                              className="form-check-label"
                              htmlFor="regSee-1"
                            >
                              ウォッチリスト登録者
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="regSee-1"
                            />
                          </div>
                          <div className="form-check-reverse form-switch mb-3">
                            <label
                              className="form-check-label"
                              htmlFor="regSee-2"
                            >
                              フォロワー
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="regSee-2"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <h3 className="h5 mb-3">購入者制限</h3>
                          <div className="form-check-reverse form-switch mb-3">
                            <label
                              className="form-check-label"
                              htmlFor="regBuyer-1"
                            >
                              ウォッチリスト登録者
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="regBuyer-1"
                            />
                          </div>
                          <div className="form-check-reverse form-switch mb-3">
                            <label
                              className="form-check-label"
                              htmlFor="regBuyer-2"
                            >
                              フォロワー
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="regBuyer-2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-4">
                        <h3 className="h5">予約公開</h3>
                        <p className="text-muted">
                          予約公開の日時が未設定の場合すぐに公開されます。
                          <br />
                          ・コレクションの公開予約日時以前の設定はコレクション公開日時を優先に公開されます。
                        </p>
                        <div
                          className="input-daterange hstack gap-2"
                          id="datepicker"
                        >
                          <div className="form-floating w-100">
                            <input
                              type="text"
                              className="form-control"
                              id="itemDateStart"
                              name="start"
                              placeholder="\u516C\u958B\u65E5\u6642"
                            />
                            <label htmlFor="itemDateStart">公開日時</label>
                          </div>
                        </div>
                      </div>
                    </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
