import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import MyItemEditView from "./MyItemEditView";
import { initReactI18next, useTranslation } from "react-i18next";

import { ChainLink } from "../../components/ChainLink";

export default function Contents({ branch, children }) {
  const [t, i18n] = useTranslation();

  const defaultProps = {};

  const [propsMyItemEdit, set_propsMyItemEdit] = useState(defaultProps);

  const MyItemEdit = {
    propsMyItemEdit,
    set_propsMyItemEdit,
    branch,
  };

  const newchildren = React.cloneElement(children, {
    MyItemEdit,
  });

  return (
    <>
      <ChainLink
        hrefs={[
          // "/dist/tmty/dist/js/vendor/mobile-side-navigation-zeynepjs/zeynep.min.css",
          // "/dist/tmty/dist/css/base.css",
          // "/dist/tmty/dist/css/ja-jp.css",
        ]}
      >
        <Helmet>
          <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <title>tomonity</title>
          {/* <script src="dist/material/js/particle.js"></script> */}
        </Helmet>
        {newchildren}
      </ChainLink>
    </>
  );
}
