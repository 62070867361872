import React from "react";
import { useTranslation } from "react-i18next";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import styles from "assets/jss/chaintier/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    value,
    set_value,
    maps,
    color,
    options,
    multiple,
    nonBlank,
    type,
    disabled,
    readOnly,
    multiline,
    justWidth,
    autoComplete,
    endAdornment,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
  } = props;

  let classesMenuItem = {};
  let inputPropsMultiple = {};

  classesMenuItem.root =
    color === "secondary" ? classes.selectMenuItem2 : classes.selectMenuItem;

  if (multiple) {
    classesMenuItem.selected =
      color === "secondary"
        ? classes.selectMenuItemSelectedMultiple2
        : classes.selectMenuItemSelectedMultiple;

    inputPropsMultiple = {
      name: "multipleSelect",
      id: "multiple-select",
    };
  } else {
    classesMenuItem.selected =
      color === "secondary"
        ? classes.selectMenuItemSelected2
        : classes.selectMenuItemSelected;
    inputPropsMultiple = {
      name: "simpleSelect",
      id: "simple-select",
    };
  }

  const [t] = useTranslation();

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  var formControlClasses;

  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });

  if (maps === undefined) return <></>;

  return (
    <FormControl fullWidth={!justWidth}>
      {labelText !== undefined ? (
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          {labelText}
        </InputLabel>
      ) : null}

      {readOnly ? (
        <Input
          className={readOnly ? "MuiReadOnly" : ""}
          classes={{
            input: inputClasses,
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses,
          }}
          value={t(maps[value])}
          onChange={(e) => {
            set_value(e.target.value);
          }}
          id={id}
          type={type}
          disabled={disabled}
          readOnly={readOnly}
          multiline={multiline || readOnly}
          autoComplete={autoComplete}
          endAdornment={
            endAdornment ? (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ) : null
          }
          {...inputProps}
        />
      ) : (
        <Select
          color={color ? color : "primary"}
          multiple={multiple}
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={value}
          onChange={(event) => {
            if (readOnly) return;
            set_value(event.target.value);
          }}
          inputProps={inputPropsMultiple}
        >
          {!nonBlank && (
            <MenuItem classes={classesMenuItem} value={null}>
              {"　"}
            </MenuItem>
          )}

          {Object.keys(maps).map((k, idx) => {
            return (
              <MenuItem classes={classesMenuItem} value={`${k}`} key={idx}>
                {t(maps[k])}
              </MenuItem>
            );
          })}
        </Select>
      )}

      {helperText !== undefined ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
};
