import React, { FC, useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  zeroPadding,
} from "config/util";
import { getSearch, setSearch } from "config/util";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { get_items, get_items_id, put_items_id, delete_items_id } from "stores/Items/actions";

import { ChainLink } from "components/ChainLink";

import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import socketIOClient from "socket.io-client";
import env from "../../stores/env";
import moment from "moment/moment";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

let socket = null;

const App = ({ branch, children }) => {
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();

  const dispatch = useDispatch();

  const maps = useSelector((state) => state.reduce_get_maps);
  const loadingId = useSelector((state) => state.reduce_get_items.isLoadingId);
  const loading_put_items_id = useSelector((state) => state.reduce_put_items_id.isLoading);

  const location_search = useLocation().search;
  const propsSearch = getSearch(location_search) ?? {};

  const { item_id } = useParams();

  const [propsItems, set_propsItems] = useState({
    search: {
      active_flg: true,
      item_id,
      page: 1,
      limit: 10,
      sort: [],
      ...branch?.search,
      ...propsSearch?.search,
    },
  });

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_get_items_id = (data, next, prev) => {
    dispatch(
      get_items_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_items_id = (id, idx, callback) => {
    dispatch_get_items_id(
      {
        id,
      },
      (data) => {

        const item = data.item;

        set_propsItems({
          ...item,
          // price: item.item_detail.price,
          opened_at: item.item_sale_opened_at ?? moment().format(),
          // opened_at: moment().format(),
          // closed_at: null,
          closed_at: item.item_sale_closed_at ?? null,
          coin: item.item_sale_coin ?? "BTC",
          sale_flg: item.item_sale_flg ?? false,
          price: item.item_sale_price ?? 0,
          sale_type: item.item_sale_type ?? 1,
          item_detail_id: item.item_detail_id,
          search: propsItems.search,
          id: id,
          idx: idx,
        });
        !callback || callback();
      }
    );
  };

  useEffect(() => {
    if (item_id) {
      set_get_items_id(item_id);
    }
  }, []);

  const dispatch_put_items_id = (data, next, prev) => {
    dispatch(
      put_items_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const newchildren = React.cloneElement(children, {
    Items: {
      propsItems,
      set_propsItems,
      set_get_items_id,
      dispatch_get_id: set_get_items_id,
      dispatch_put_items_id,
      loadingId,
      branch,
    },
  });

  return (
    <>
      <Helmet>
        <title>tomonity</title>
      </Helmet>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }}
        open={loading_put_items_id}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      {newchildren}
    </>
  );
};

export default App;
