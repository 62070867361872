import { createReducer } from "redux-act";

import {
  get_accounts_kyc,
  get_accounts_kyc_data,
  get_accounts_kyc_error,
  get_accounts_totp,
  get_accounts_totp_data,
  get_accounts_totp_error,
  get_accounts,
  get_accounts_data,
  get_accounts_error,
  put_accounts,
  put_accounts_data,
  put_accounts_error,
  put_accounts_extra,
  put_accounts_extra_data,
  put_accounts_extra_error,
  delete_accounts,
  delete_accounts_data,
  delete_accounts_error,
  delete_accounts_delete,
  delete_accounts_delete_data,
  delete_accounts_delete_error,
  post_accounts_verify,
  post_accounts_verify_data,
  post_accounts_verify_error,
  post_accounts_auth,
  post_accounts_auth_data,
  post_accounts_auth_error,
  post_accounts_auth_code,
  post_accounts_auth_code_data,
  post_accounts_auth_code_error,
  put_accounts_security,
  put_accounts_security_data,
  put_accounts_security_error,
  post_accounts_images,
  post_accounts_images_data,
  post_accounts_images_error,
  
  delete_accounts_images_id,
  delete_accounts_images_id_data,
  delete_accounts_images_id_error,

  get_notification,
  get_notification_data,
  get_notification_error,
  post_notification,
  post_notification_data,
  post_notification_error,
  set_notification,
  // get_users,
  // get_users_data,
  // get_users_error,
  // get_users_id,
  // get_users_id_data,
  // get_users_id_error,
} from "./actions";



export const reduce_get_accounts_kyc = createReducer(
  {
    [get_accounts_kyc]: (state, payload) => {
      const newState = Object.assign({}, state, {});

      newState.isLoading = true;
      return newState;
    },
    [get_accounts_kyc_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [get_accounts_kyc_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);



export const reduce_get_accounts_totp = createReducer(
  {
    [get_accounts_totp]: (state, payload) => {
      const newState = Object.assign({}, state, {});

      newState.isLoading = true;
      return newState;
    },
    [get_accounts_totp_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [get_accounts_totp_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);




export const reduce_get_accounts = createReducer(
  {
    [get_accounts]: (state, payload) => {
      const newState = Object.assign({}, state, {});

      newState.isLoading = true;
      return newState;
    },
    [get_accounts_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [get_accounts_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);

export const reduce_put_accounts = createReducer(
  {
    [put_accounts]: (state, payload) => {
      const newState = Object.assign({}, state, {});

      newState.isLoading = true;
      return newState;
    },
    [put_accounts_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [put_accounts_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_accounts_extra = createReducer(
  {
    [put_accounts_extra]: (state, payload) => {
      const newState = Object.assign({}, state, {});

      newState.isLoading = true;
      return newState;
    },
    [put_accounts_extra_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [put_accounts_extra_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_accounts = createReducer(
  {
    [delete_accounts]: (state, payload) => {
      const newState = Object.assign({}, state, {});

      newState.isLoading = true;
      return newState;
    },
    [delete_accounts_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.hash = payload.data.hash;
      newState.isLoading = false;
      return newState;
    },
    [delete_accounts_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_accounts_delete = createReducer(
  {
    [delete_accounts_delete]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_accounts_delete_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [delete_accounts_delete_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);


export const reduce_post_accounts_verify = createReducer(
  {
    [post_accounts_verify]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_accounts_verify_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.hash = payload.data.hash;
      newState.isLoading = false;
      return newState;
    },
    [post_accounts_verify_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_accounts_auth = createReducer(
  {
    [post_accounts_auth]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_accounts_auth_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.hash = payload.data.hash;
      newState.isLoading = false;
      return newState;
    },
    [post_accounts_auth_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_accounts_auth_code = createReducer(
  {
    [post_accounts_auth_code]: (state, payload) => {
      const newState = Object.assign({}, state, {});

      newState.isLoading = true;
      return newState;
    },
    [post_accounts_auth_code_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [post_accounts_auth_code_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_accounts_security = createReducer(
  {
    [put_accounts_security]: (state, payload) => {
      const newState = Object.assign({}, state, {});

      newState.isLoading = true;
      return newState;
    },
    [put_accounts_security_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [put_accounts_security_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_accounts_images = createReducer(
  {
    [post_accounts_images]: (state, payload) => {
      const newState = Object.assign({}, state, {});

      newState.isLoading = true;
      return newState;
    },
    [post_accounts_images_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [post_accounts_images_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_accounts_images_id = createReducer(
  {
    [delete_accounts_images_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});

      newState.isLoading = true;
      return newState;
    },
    [delete_accounts_images_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [delete_accounts_images_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);




export const reduce_get_notification = createReducer(
  {
    [get_notification]: (state, payload) => {
      const newState = Object.assign({}, state, {});

      newState.isLoading = true;
      return newState;
    },
    [get_notification_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [get_notification_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);

export const reduce_post_notification = createReducer(
  {
    [post_notification]: (state, payload) => {
      const newState = Object.assign({}, state, {});

      newState.isLoading = true;
      return newState;
    },
    [post_notification_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [post_notification_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [set_notification]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.token = payload.data.token;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);

// export const reduce_get_users = createReducer(
//   {
//     [get_users]: (state, payload) => {
//       const newState = Object.assign({}, state, {});

//       newState.isLoading = true;
//       return newState;
//     },
//     [get_users_data]: (state, payload) => {
//       const newState = Object.assign({}, state, { ...payload.data });
//       newState.isLoading = false;
//       return newState;
//     },
//     [get_users_error]: (state, payload) => {
//       const newState = Object.assign({}, state, {});
//       newState.isLoading = false;
//       return newState;
//     },
//   },
//   {
//     isLoading: true,
//   }
// );

// export const reduce_get_users_id = createReducer(
//   {
//     [get_users_id]: (state, payload) => {
//       const newState = Object.assign({}, state, {});

//       newState.isLoading = true;
//       return newState;
//     },
//     [get_users_id_data]: (state, payload) => {
//       const newState = Object.assign({}, state, { ...payload.data });
//       newState.isLoading = false;
//       return newState;
//     },
//     [get_users_id_error]: (state, payload) => {
//       const newState = Object.assign({}, state, {});
//       newState.isLoading = false;
//       return newState;
//     },
//   },
//   {
//     isLoading: true,
//   }
// );

export default {
  reduce_get_accounts_kyc,
  reduce_get_accounts_totp,
  reduce_get_accounts,
  reduce_put_accounts,
  reduce_put_accounts_extra,
  reduce_delete_accounts,
  reduce_delete_accounts_delete,
  reduce_post_accounts_verify,
  reduce_post_accounts_auth,
  reduce_post_accounts_auth_code,
  reduce_put_accounts_security,
  reduce_post_accounts_images,
  reduce_delete_accounts_images_id,
  reduce_get_notification,
  reduce_post_notification,
  // reduce_get_users,
  // reduce_get_users_id,
};
