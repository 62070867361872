import { combineReducers } from "redux";
import { createAction, createReducer } from "redux-act";

import {
  get_collections,
  get_collections_data,
  get_collections_error,
  get_collections_id,
  get_collections_id_data,
  get_collections_id_error,
  post_collections,
  post_collections_data,
  post_collections_error,
  put_collections_id,
  put_collections_id_data,
  put_collections_id_error,
  delete_collections_id,
  delete_collections_id_data,
  delete_collections_id_error,
  put_collections_id_favorite,
  put_collections_id_favorite_data,
  put_collections_id_favorite_error,
  put_collections_id_join,
  put_collections_id_join_data,
  put_collections_id_join_error,
} from "./actions";

const forupdate = (array, unit, id) => {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element[id] === unit[id]) {
      array[index] = unit;
    }
  }
  return array;
};

export const reduce_get_collections = createReducer(
  {
    [get_collections]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_collections_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_collections_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [get_collections_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoadingId = true;
      return newState;
    },
    [get_collections_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      const collection = payload.data.collection;
      const collections = state.collections;

      if (collections) newState.collections = forupdate(collections, collection, "collection_id");
      newState.isLoadingId = false;
      return newState;
    },
    [get_collections_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoadingId = false;
      return newState;
    },
  },
  {
    isLoading: false,
    isLoadingId: false,
    collection: {},
    collections: [],
    count: 0,
  }
);

export const reduce_post_collections = createReducer(
  {
    [post_collections]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_collections_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_collections_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_collections_id = createReducer(
  {
    [put_collections_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_collections_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_collections_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_collections_id = createReducer(
  {
    [delete_collections_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_collections_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [delete_collections_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_collections_id_favorite = createReducer(
  {
    [put_collections_id_favorite]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_collections_id_favorite_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_collections_id_favorite_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_collections_id_join = createReducer(
  {
    [put_collections_id_join]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_collections_id_join_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_collections_id_join_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export default {
  reduce_get_collections,
  reduce_post_collections,
  reduce_put_collections_id,
  reduce_delete_collections_id,
  reduce_put_collections_id_favorite,
  reduce_put_collections_id_join,
};
