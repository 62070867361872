import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// import _options_buyer from "./_options_buyer";
// import _options_owner from "./_options_owner";

export const HeaderUser = (Items) => {
  const { propsItems, set_propsItems, dispatch_gets, user_id } = Items;
  return <></>;
};

export const RowUser = (Items) => {
  const { v_item, history, t, propsItems, dispatch_put_items_id_favorite } = Items;

  return (
    <>
      <div className="col">
        <div className="card product product-item">
          <div className="product-body position-relative">
            <div className="product-media object-item">
              <img
                src={v_item.item_icon_uri}
                width="100%"
                height="100%"
                className="card-img img-fluid"
                alt=""
              />
              <div className="product-action">
                <button
                  type="button"
                  className={`btn rounded-pill tmty-btn-love ${
                    v_item.item_favorited_my ? "active" : ""
                  }`}
                  role="button"
                  data-bs-toggle="button"
                  autoComplete="off"
                  aria-pressed="false"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch_put_items_id_favorite({
                      id: v_item.item_id,
                      search: propsItems.search,
                    });
                  }}
                >
                  {v_item.item_favorited_count}
                </button>
              </div>
              {v_item.販売中 && (
                <div className="product-auction">
                  <span className="product-auction-item bg-animation-brand">
                    <i className="fa-solid fa-stopwatch fa-fw small" />
                    {v_item.item_sale_closed_at_countdown_label}
                  </span>
                </div>
              )}
            </div>
            <h3 className="product-title">
              <a
                className="stretched-link text-dark"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/items/${v_item.item_id}`);
                }}
              >
                {v_item.item_name}
              </a>
            </h3>
            <div className="d-md-flex">
              <ul
                className="list-unstyled hstack gap-2 mb-0 icon-color"
                style={{
                  zIndex: 2,
                }}
              >
                <li>
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={v_item.coin_platform_label}
                  >
                    {/* <i className="icomoon-brand-eth" /> */}
                    {v_item.coin_platform_tag}
                  </span>
                </li>
                {v_item.collection?.collection_collabo_flg && (
                  <li>
                    <span data-bs-toggle="tooltip" data-bs-placement="top" title="コラボレート作品">
                      <i className="icomoon-collab" />
                    </span>
                  </li>
                )}

                {/* <li>
                  <span data-bs-toggle="tooltip" data-bs-placement="top" title="">
                    <i className="icomoon-brand-eth" />
                  </span>
                </li> */}
                {v_item.オークション販売中 && (
                  <li>
                    <span data-bs-toggle="tooltip" data-bs-placement="top" title="オークション">
                      <i className="fa-solid fa-gavel"></i>
                    </span>
                  </li>
                )}

                {/* <li>
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="GIF\u30A2\u30CB\u30E1\u30FC\u30B7\u30E7\u30F3"
                  >
                    <i className="icomoon-file-gif" />
                  </span>
                </li> */}
                {/* <li>
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                  >
                    <i className="icomoon-handshake" />
                  </span>
                </li> */}
              </ul>
              <div className="ms-auto">
                <div className="d-flex flex-column align-items-center align-items-md-end">
                  {v_item.販売中 && (
                    <button
                      type="button"
                      className="btn btn-dark rounded-pill text-nowrap py-1"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                    >
                      <sub className="me-1">{v_item.item_sale_coin_tag}</sub>
                      {v_item.item_sale_price}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="product-footer position-relative overflow-hidden">
            <div className="media media-xs overflow-hidden">
              <div className="media-object">
                {/* <div className="icon icon-square verified verified-xs"> */}
                <div className="icon icon-square">
                  <img
                    src={v_item.collection_icon_uri}
                    className="icon-item"
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </div>
              </div>
              <div className="media-body text-truncate overflow-hidden">
                <a
                  className="stretched-link text-dark"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`/collections/${v_item.collection_id}/items`);
                  }}
                >
                  {/* <div className="d-flex verified-text"> */}
                  <span className="text-truncate">{v_item.collection_name}</span>
                  {/* </div> */}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// <div className="col">
// <div className="card product product-item position-relative">
//   <div className="product-body position-relative pb-0">
//     <div className="product-media object-item">
//       <img
//         src={v_item.item_icon_uri}
//         width="100%"
//         height="100%"
//         className="card-img img-fluid"
//         alt="Let It Go Dog GIF"
//       />
//       <div className="product-action">
//         <button
//           type="button"
//           className={`btn rounded-pill tmty-btn-love ${
//             v_item.item_favorited_my ? "active" : ""
//           }`}
//           role="button"
//           // data-bs-toggle="button"
//           autoComplete="off"
//           aria-pressed="false"
//           onClick={(e) => {
//             e.preventDefault();
//             dispatch_put_items_id_favorite({
//               id: v_item.item_id,
//               search: propsItems.search,
//             });
//           }}
//         >
//           {v_item.item_favorited_count}
//         </button>
//       </div>
//     </div>
//     <h3 className="product-title">
//       <a
//         className="stretched-link text-dark"
//         href="#"
//         onClick={(e) => {
//           e.preventDefault();
//           history.push(`/items/${v_item.item_id}`);
//         }}
//       >
//         {v_item.item_name}
//       </a>
//     </h3>

//     {v_item.item_owner && (
//       <div className="d-md-flex">
//         <ul className="list-unstyled hstack gap-2 mb-0">
//           <li>
//             <span
//               data-bs-toggle="tooltip"
//               data-bs-placement="top"
//               title="GIF\u30A2\u30CB\u30E1\u30FC\u30B7\u30E7\u30F3"
//             >
//               <img
//                 src="/dist/tmty/assets/icons/gif.svg"
//                 width={18}
//                 height={18}
//                 alt="GIF\u30A2\u30CB\u30E1\u30FC\u30B7\u30E7\u30F3"
//               />
//             </span>
//           </li>
//           <li>
//             <span
//               data-bs-toggle="tooltip"
//               data-bs-placement="top"
//               title=""
//             >
//               <i className="fa-solid fa-handshake-angle fa-fw text-secondary" />
//             </span>
//           </li>
//         </ul>
//         <div className="ms-auto">
//           <div className="d-flex flex-column align-items-center align-items-md-end">
//             {v_item.item_sale_flg && (
//               <button
//                 type="button"
//                 className="btn btn-dark rounded-pill text-nowrap py-1"
//                 data-bs-toggle="tooltip"
//                 data-bs-placement="top"
//                 title
//                 data-bs-original-title=""
//               >
//                 <i className="fa-brands fa-ethereum fa-fw small" />
//                 {v_item.item_sale_price}
//               </button>
//             )}
//           </div>
//         </div>
//       </div>
//     )}
//     {!v_item.item_owner && (
//       <div className="d-md-flex">
//         <ul className="list-unstyled hstack gap-2 mb-0">
//           <li>
//             <span
//               className="icon-polygon text-secondary"
//               data-bs-toggle="tooltip"
//               data-bs-placement="top"
//               title="Polygon"
//             />
//           </li>
//           <li>
//             <span
//               className="icon-blockchain text-secondary"
//               data-bs-toggle="tooltip"
//               data-bs-placement="top"
//               title="Blockchain"
//             />
//           </li>
//           <li>
//             <span
//               className="icon-loked text-secondary"
//               data-bs-toggle="tooltip"
//               data-bs-placement="top"
//               title=""
//             />
//           </li>
//         </ul>
//       </div>
//     )}
//   </div>
//   <div className="d-flex align-items-center p-2 position-relative">
//     <div className="d-flex align-items-center overflow-hidden mx-auto">
//       <div className="flex-shrink-0">
//         <div className="icon-sm icon-square">
//           <div className="media-object">
//             <img
//               src={v_item.collection_icon_uri}
//               className="icon-item"
//               width={22}
//               height={22}
//               alt="'WANWAN' Collection"
//             />
//           </div>
//         </div>
//       </div>
//       <div className="flex-grow-1 ms-2 text-truncate overflow-hidden">
//         <a className="stretched-link text-dark" href="#">
//           <div className="d-flex">
//             <span className="text-truncate">{v_item.collection_name}</span>
//           </div>
//         </a>
//       </div>
//     </div>
//   </div>
//   <div className="card-footer card-tools d-flex align-items-center ps-2">
//     {v_item.process_flg && <span className="badge bg-dark text-white">処理中</span>}
//     {v_item.item_sale_flg && <span className="badge bg-dark text-white">出品中</span>}
{
  /* {!v_item.item_owner && <>{_options_buyer(Items)}</>} */
}
//     {/* {v_item.item_owner && <>{_options_owner(Items)}</>} */}
//   </div>
//   {/* <span className="position-absolute top-0 end-0 translate-middle-y badge rounded-pill bg-danger">
//     99+
//     <span className="visually-hidden">オファー通知</span>
//   </span> */}
// </div>
// {}
// </div>
