import { combineReducers } from "redux";
import { createAction, createReducer } from "redux-act";

import {
  get_categories,
  get_categories_data,
  get_categories_error,
  get_categories_id,
  get_categories_id_data,
  get_categories_id_error,
  post_categories,
  post_categories_data,
  post_categories_error,
  put_categories_id,
  put_categories_id_data,
  put_categories_id_error,
  delete_categories_id,
  delete_categories_id_data,
  delete_categories_id_error,
} from "./actions";

const forupdate = (array, unit, id) => {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element[id] === unit[id]) {
      array[index] = unit;
    }
  }
  return array;
};

export const reduce_get_categories = createReducer(
  {
    [get_categories]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      if (!payload.data.search) {
        newState.categorieslist = [];
      } else if (payload.data.search.page == 1) {
        newState.categorieslist = [];
        // newState.same = false;
        // newState.page = 1;
      } else {
        // newState.same = newState.page == payload.data.search.page;
        // newState.page = payload.data.search.page;
      }
      newState.isLoading = true;
      return newState;
    },
    [get_categories_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      // if (!newState.same) {
      newState.categorieslist = newState.categorieslist.concat(
        payload.data.categories
      );
      // }

      newState.isLoading = false;
      return newState;
    },
    [get_categories_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [get_categories_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_categories_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      const category = payload.data.category;
      const categories = state.categories;
      const categorieslist = state.categorieslist;

      if (categories)
        newState.categories = forupdate(categories, category, "category_id");
      if (categorieslist)
        newState.categorieslist = forupdate(
          categorieslist,
          category,
          "category_id"
        );
      newState.isLoading = false;

      return newState;
    },
    [get_categories_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
 isLoading: false,
    category: {},
    categories: [],
    categorieslist: [],
    count: 0,
  }
);

export const reduce_post_categories = createReducer(
  {
    [post_categories]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_categories_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_categories_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_categories_id = createReducer(
  {
    [put_categories_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_categories_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_categories_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_categories_id = createReducer(
  {
    [delete_categories_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_categories_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [delete_categories_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export default {
  reduce_get_categories,
  reduce_post_categories,
  reduce_put_categories_id,
  reduce_delete_categories_id,
};
