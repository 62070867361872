import { fork, take, put, join, select, takeLatest, takeEvery, call } from "redux-saga/effects";

import { post_authout } from "../Auth/actions";

import {
  api_get_users_my,
  api_get_users_my_id,
  api_post_users_my,
  api_put_users_my_id,
  api_delete_users_my_id,
} from "./api";

import {
  get_users_my,
  get_users_my_data,
  get_users_my_error,
  get_users_my_id,
  get_users_my_id_data,
  get_users_my_id_error,
  post_users_my,
  post_users_my_data,
  post_users_my_error,
  put_users_my_id,
  put_users_my_id_data,
  put_users_my_id_error,
  delete_users_my_id,
  delete_users_my_id_data,
  delete_users_my_id_error,
} from "./actions";

export function* run_get_users_my({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_users_my, payload.data, token);
  if (!error) {
    yield put(get_users_my_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_users_my_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_users_my_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_users_my_id, payload.data, token);
  if (!error) {
    yield put(get_users_my_id_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_users_my_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_users_my({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_users_my, payload.data, token);
  if (!error) {
    yield put(post_users_my_data({ data }));
    yield put(get_users_my(payload));
  } else {
    yield put(post_users_my_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_users_my_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_users_my_id, payload.data, token);
  if (!error) {
    yield put(put_users_my_id_data({ data }));
    yield put(get_users_my_id(payload));
  } else {
    yield put(put_users_my_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_users_my_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_delete_users_my_id, payload.data, token);
  if (!error) {
    yield put(delete_users_my_id_data({ data }));
    yield put(get_users_my(payload));
  } else {
    yield put(delete_users_my_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* flow_get_users_my() {
  yield takeLatest(get_users_my, run_get_users_my);
}
export function* flow_get_users_my_id() {
  yield takeLatest(get_users_my_id, run_get_users_my_id);
}
export function* flow_post_users_my() {
  yield takeLatest(post_users_my, run_post_users_my);
}
export function* flow_put_users_my_id() {
  yield takeLatest(put_users_my_id, run_put_users_my_id);
}
export function* flow_delete_users_my_id() {
  yield takeLatest(delete_users_my_id, run_delete_users_my_id);
}

export default function* rootSaga() {
  yield fork(flow_get_users_my);
  yield fork(flow_get_users_my_id);
  yield fork(flow_post_users_my);
  yield fork(flow_put_users_my_id);
  yield fork(flow_delete_users_my_id);
}
