import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import LiLanguage from "components/Li/LiLanguage";
import LiAccount from "components/Li/LiAccount";
import { getMessage } from "config/util";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";

import CollectionsPager from "../../views_components/PagerCollections/CollectionsPager";
import CollectionsList from "../../views_components/PagerCollections/CollectionsList";
import Footer from "../../views_components/Component/Footer";

export default function Contents({ Collections }) {
  const { propsCollections, set_propsCollections, dispatch_gets } = Collections;

  const props = propsCollections;
  const set_props = set_propsCollections;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const maps = useSelector((state) => state.reduce_get_maps);
  const nft_categories = { "": "すべて", ...maps.nft_categories };

  return (
    <>
      <div>
        <Header />
        <div className="main-header main-header-hidden mb-0">
          <div className="container">
            <ul className="nav canopy">
              {Object.keys(nft_categories).map((uuid, idx) => {
                const name = nft_categories[uuid];
                const category_id = props.search.category_id ? props.search.category_id : "";
                // const class_nav = "nav-link " + props.search.category_id == uuid ? "active" : "";
                // const class_nav = "nav-link " + "active";
                const class_nav = `nav-link ${category_id == uuid ? "active" : ""}`;

                return (
                  <>
                    <li className="nav-item">
                      {/* props.search.category_id==uuid?"active": */}
                      <a
                        href="#"
                        className={class_nav}
                        aria-current="page"
                        onClick={(e) => {
                          e.preventDefault();
                          // set_props({
                          //   search: {
                          //     ...props.search,
                          //     category_id: uuid,
                          //   },
                          // });
                          // dispatch_gets({
                          //   ...props.search,
                          //   category_id: uuid,
                          // });
                          if (uuid) {
                            history.push(`/categories/id/${uuid}`);
                          } else {
                            history.push(`/categories`);
                          }
                        }}
                      >
                        <span className="canopy-text">{t(name)}</span>
                      </a>
                    </li>
                  </>
                );
              })}
              {/* 
              <li className="nav-item">
                <a href="collections" className="nav-link active" aria-current="page">
                  <span className="canopy-text">アート</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="collections" className="nav-link">
                  <span className="canopy-text">動画</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="collections" className="nav-link">
                  <span className="canopy-text">ゲーム</span>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="container container-main">
          <main className="main">
            <CollectionsPager Collections={Collections} />
            <div className="ms-md-3 mt-0">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-2 g-md-3 mb-3">
                <CollectionsList Collections={Collections} />
              </div>
            </div>
            <CollectionsPager Collections={Collections} isFooter={true} />
          </main>
        </div>
        <Footer />
        {/* 
        <div
          className="modal fade"
          id="filSearch"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="filSearchLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="filSearchLabel">
                  検索を絞り込む
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="filter-status" className="form-label">
                    販売形式
                  </label>
                  <div className="row row-cols-2 g-1">
                    <div className="d-grid">
                      <input
                        type="checkbox"
                        className="btn-check"
                        id="status-1"
                        autoComplete="off"
                      />
                      <label className="btn btn-outline-secondary" htmlFor="status-1">
                        販売中
                      </label>
                    </div>
                    <div className="d-grid">
                      <input
                        type="checkbox"
                        className="btn-check"
                        id="status-2"
                        autoComplete="off"
                      />
                      <label className="btn btn-outline-secondary" htmlFor="status-2">
                        オークション中
                      </label>
                    </div>
                    <div className="d-grid">
                      <input
                        type="checkbox"
                        className="btn-check"
                        id="status-3"
                        autoComplete="off"
                      />
                      <label className="btn btn-outline-secondary" htmlFor="status-3">
                        新着
                      </label>
                    </div>
                    <div className="d-grid">
                      <input
                        type="checkbox"
                        className="btn-check"
                        id="status-4"
                        autoComplete="off"
                      />
                      <label className="btn btn-outline-secondary" htmlFor="status-4">
                        オファーあり
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="filter-price" className="form-label">
                    価格と通貨
                  </label>
                  <div className="row row-cols-1 row-cols-sm-2 g-1">
                    <div className="col">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={0}
                          aria-label="Username"
                        />
                        <span className="input-group-text">〜</span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={1000}
                          aria-label="Server"
                        />
                      </div>
                    </div>
                    <div className="col mb-2 mb-sm-0">
                      <select
                        className="selectpicker form-control"
                        id="filter-price"
                        data-style="btn-white border"
                        aria-label="\u901A\u8CA8\u306E\u7A2E\u985E"
                        title=""
                      >
                        <option>United States Dollar (USD)</option>
                        <option>Ether (ETH)</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="filter-category" className="form-label">
                    カテゴリ
                  </label>
                  <select
                    className="selectpicker form-control"
                    id="filter-category"
                    multiple
                    data-style="btn-white border"
                    data-live-search="true"
                    data-header=" \u30AB\u30C6\u30B4\u30EA"
                    aria-label="\u30AB\u30C6\u30B4\u30EA"
                    title=""
                  >
                    <option>絵画</option>
                    <option>音楽</option>
                    <option>ドメイン</option>
                    <option>仮想世界</option>
                    <option>トレーディングカード</option>
                    <option>ユーティリティ</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="filter-chain" className="form-label">
                    ブロックチェーン
                  </label>
                  <select
                    className="selectpicker form-control"
                    id="filter-chain"
                    multiple
                    data-style="btn-white border"
                    data-header=" \u30D6\u30ED\u30C3\u30AF\u30C1\u30A7\u30FC\u30F3"
                    aria-label="\u30D6\u30ED\u30C3\u30AF\u30C1\u30A7\u30FC\u30F3"
                    title=""
                  >
                    <option>Ethereum</option>
                    <option>Polygon</option>
                    <option>Klaytn</option>
                  </select>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                  キャンセル
                </button>
                <button type="button" className="btn btn-primary">
                  検索
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
