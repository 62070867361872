import { createAction, createReducer } from 'redux-act';

export const get_collabos = createAction("GET_COLLABOS");
export const get_collabos_data = createAction("GET_COLLABOS_DATA");
export const get_collabos_error = createAction("GET_COLLABOS_ERROR");
export const get_collabos_id = createAction("GET_COLLABOS_ID");
export const get_collabos_id_data = createAction("GET_COLLABOS_ID_DATA");
export const get_collabos_id_error = createAction("GET_COLLABOS_ID_ERROR");
export const post_collabos = createAction("POST_COLLABOS");
export const post_collabos_data = createAction("POST_COLLABOS_DATA");
export const post_collabos_error = createAction("POST_COLLABOS_ERROR");
export const put_collabos_id = createAction("PUT_COLLABOS_ID");
export const put_collabos_id_data = createAction("PUT_COLLABOS_ID_DATA");
export const put_collabos_id_error = createAction("PUT_COLLABOS_ID_ERROR");
export const delete_collabos_id = createAction("DELETE_COLLABOS_ID");
export const delete_collabos_id_data = createAction("DELETE_COLLABOS_ID_DATA");
export const delete_collabos_id_error = createAction("DELETE_COLLABOS_ID_ERROR");

export const put_collabos_id_favorite = createAction("PUT_COLLABOS_ID_FAVORITE");
export const put_collabos_id_favorite_data = createAction("PUT_COLLABOS_ID_FAVORITE_DATA");
export const put_collabos_id_favorite_error = createAction("PUT_COLLABOS_ID_FAVORITE_ERROR");





