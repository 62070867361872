import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import Menu from "./Menu";

import { getMessage } from "config/util";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { ChainLink } from "../../components/ChainLink";
import { post_authouthome } from "stores/Auth/actions";
import { hideModal, showModal } from "config/util";

import { home } from "../../Navigator/Router_material";

export default function Contents({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  useEffect(() => {}, []);

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_post_authouthome = (data, next, prev) => {
    dispatch(
      post_authouthome({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts?.user?.mail_address;
  return (
    <>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => set_snack(false)}
        close
      />
      <div
        className="modal fade modal-alert py-5"
        id="signOut"
        data-bs-keyboard="false"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content rounded-4 shadow">
            <div action="../index.html">
              <div className="modal-body p-4 text-center">
                <h5 className="mb-2">{t("ログアウト")}</h5>
                <p className="mb-0">{t("ログアウトしてもよろしいですか？")}</p>
              </div>
              <div className="modal-footer flex-nowrap p-0">
                <button
                  type="button"
                  className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0"
                  data-bs-dismiss="modal"
                >
                  {t("キャンセル")}
                </button>
                <button
                  type="button"
                  className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"
                  onClick={() => {
                    hideModal("signOut");
                    const accountsNav = document.getElementById("accountsNav");
                    const modal = window.bootstrap.Offcanvas.getInstance(accountsNav);
                    modal.hide();
                    dispatch_post_authouthome({}, () => {
                      history.push({
                        pathname: home,
                      });
                    });
                  }}
                >
                  <strong>{t("はい")}</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
