import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";

import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";

import { view_item_offer } from "../../stores/ItemOffers/view";

export default function Dashboard(ItemOffers) {
  const { propsItemOffers, set_propsItemOffers, dispatch_gets, user_id } = ItemOffers;
  const t = ItemOffers.t;

  return (
    <>
      <div className="btn-toolbar ms-auto" role="toolbar" aria-label="\u691C\u7D22\u6761\u4EF6">
        <div className="dropdown me-1" data-bs-toggle="tooltip" data-bs-placement="top" title="">
          <button
            type="button"
            className="btn btn-outline-secondary dropdown-toggle me-1"
            id="filSort"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-solid fa-filter" />
            {propsItemOffers.search.user_id && t("された")}
            {propsItemOffers.search.user_id_by && t("した")}
          </button>
          <ul className="dropdown-menu dropdown-menu-end shadow-sm" aria-labelledby="filSort">
            <li>
              <button
                type="button"
                className="dropdown-item"
                onClick={() => {
                  const search = {
                    ...propsItemOffers.search,
                    user_id: user_id,
                    user_id_by: null,
                  };
                  dispatch_gets(search, 1);
                }}
              >
                <i
                  className="fa-solid fa-down-long fa-fw text-muted me-3"
                  style={{
                    transform: "rotate(45deg)",
                  }}
                />
                {t("された")}
              </button>
            </li>
            <li>
              <button
                type="button"
                className="dropdown-item"
                onClick={() => {
                  const search = {
                    ...propsItemOffers.search,
                    user_id: null,
                    user_id_by: user_id,
                  };
                  dispatch_gets(search, 1);
                }}
              >
                <i
                  className="fa-solid fa-up-long fa-fw text-muted me-3"
                  style={{
                    transform: "rotate(45deg)",
                  }}
                />
                {t("した")}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
