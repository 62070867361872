import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import { QRCodeSVG } from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { hideModal, showModal } from "config/util";
import { view_wallets } from "../../stores/Wallets/view";

export default function Contents(Withdraws) {
  const {
    propsWithdraws,
    set_propsWithdraws,
    dispatch_delete_withdraws_id,
    dispatch_post_wallets_id_withdraws_id_withdraw,
  } = Withdraws;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const { coin } = useParams();

  const [active_tab, set_active_tab] = useState("預入");

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_wallets = useSelector((state) => state.reduce_get_wallets);
  const reduce_get_withdraws = useSelector((state) => state.reduce_get_withdraws);

  const maps = useSelector((state) => state.reduce_get_maps);

  const coin_decimals = maps.coin_decimals;
  const coins = maps.coins;

  useEffect(() => {}, []);
  const coin_fullname = coins[coin];

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const wallets = reduce_get_wallets?.wallets;
  const v_wallets = view_wallets(wallets, maps, t, coin);

  const withdraws = reduce_get_withdraws?.withdraws;
  // const v_withdraws = view_withdraws(withdraws,  maps,t, coin);

  const mindeposit = {
    BTC: 0.0002,
    ETH: 0.0025,
    IOST: 0,
    USDT: 0,
  };

  return (
    <>
      <div
        className="modal fade"
        id="withdrawalsModal"
        tabIndex={-1}
        aria-labelledby="withdrawalsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="withdrawalsModalLabel">
                {t("送付")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <dl className="row g-2">
                <dt className="col-sm-4">{propsWithdraws.label}</dt>
                <dd className="col-sm-8 text-truncate overflow-hidden">{propsWithdraws.address}</dd>
                <dt className="col-sm-4">{t("残高")}</dt>
                <dd className="col-sm-8">{v_wallets.coin_balance}</dd>
                <dt className="col-sm-4">{t("送付額")}</dt>
                <dd className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="0.01"
                    onChange={(e) => {
                      const val = e.target.value;
                      set_propsWithdraws({
                        ...propsWithdraws,
                        amount: val,
                      });
                    }}
                    value={propsWithdraws.amount}
                  />
                </dd>
              </dl>
              {/* <p>
                  預入後　「<b>預入を反映</b>
                  」ボタンを押して情報を更新してください
                </p> */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  dispatch_delete_withdraws_id(
                    {
                      id: propsWithdraws.wallet_id,
                      // symbol: propsWithdraws.symbol,
                      // symbol: coin,
                      search: propsWithdraws.search,
                    },
                    () => {
                      hideModal("withdrawalsModal");
                    },
                    () => {}
                  );
                }}
              >
                {t("送付先を削除")}
              </button>
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                {t("キャンセル")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  dispatch_post_wallets_id_withdraws_id_withdraw(
                    {
                      id: v_wallets.wallet_id,
                      withdraw_id: propsWithdraws.wallet_id,
                      amount: propsWithdraws.amount,
                      // symbol: coin,
                      search: propsWithdraws.search,
                    },
                    () => {
                      hideModal("withdrawalsModal");
                    },
                    () => {}
                  );
                }}
              >
                {t("申請")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
