import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import LiLanguage from "components/Li/LiLanguage";
import LiAccount from "components/Li/LiAccount";
import { getMessage } from "config/util";
import Recaptcha from "../../components/Recaptcha/Recaptchav2";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import Header from "../../views_components/Component/Header";

export default function Contents({ Password }) {
  const {
    propsPassword,
    set_propsPassword,
    dispatch_post_password,
    dispatch_message,
    branch,
  } = Password;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();
  const [recaptcha, set_recaptcha] = useState("");

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  const props = propsPassword;
  const set_props = set_propsPassword;

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  return (
    <>
      <div style={{ height: "100vh" }}>
        <main className="main sign bg-animation-brand">
          <div
            className="modal-content rounded-5 shadow"
            style={{
              maxWidth: "450px",
            }}
          >
            <div className="modal-header border-bottom-0">
              <a
                href="#"
                className="navbar-brand modal-title"
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: `/home`,
                  });
                }}
              >
                <h1 className="m-0">
                  <span className="tmty-brand">
                    <img
                      src="/dist/tmty/assets/brand/tomonity-typography.webp"
                      width={118}
                      height="auto"
                      alt="tomonity"
                    />
                  </span>
                </h1>
              </a>
              {}
            </div>
            <div className="modal-body pt-0 px-sm-5 pt-sm-0">
              <h2 className="h5 fw-bold mb-3">{t("アカウントを探す")}</h2>
              <div action="initialize.html">
                <p>{t("メールアドレスまたはアカウント名を入力してください")}</p>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control rounded-4"
                    id="floatingInput"
                    placeholder="name@example.com"
                    inputMode="email"
                    required
                    autoFocus
                    value={props.mail_address}
                    onChange={(e) => {
                      const val = e.target.value;
                      set_props({
                        ...props,
                        mail_address: val,
                        username: val,
                      });
                    }}
                  />
                  <label htmlFor="floatingInput">{t("メールアドレスまたはアカウント名")}</label>
                </div>
                <button
                  className="btn btn-primary rounded-pill btn-lg w-100 mb-4"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();

                    if (!props.mail_address) {
                      return;
                    }

                    if (props.mail_address.includes("@")) {
                      dispatch_post_password({ mail_address: props.mail_address, type: 2 }, () => {
                        history.push({
                          pathname: `/password-recovery-code`,
                        });
                        window.scroll(0, 0);
                      });
                    } else {
                      dispatch_post_password({ username: props.username, type: 1 }, () => {
                        history.push({
                          pathname: `/password-recovery-code`,
                        });
                      });
                    }
                  }}
                >
                  {t("次へ")}
                </button>
                <hr className="my-4" />
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `/signin`,
                    });
                  }}
                  className="btn btn-link w-100 rounded-pill"
                >
                  {t("ログインに戻る")}
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
