import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import { QRCodeSVG } from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Header from "../../views_components/Component/Header";
import Footer from "../../views_components/Component/Footer";
import _sidebar from "../MyWallet/_sidebar";

import { hideModal, showModal } from "config/util";
import { view_wallets } from "../../stores/Wallets/view";

import _modal_withdrawals from "./_modal_withdrawals";
import _modal_walletsCreate from "./_modal_walletsCreate";
import _tabDeposits from "./_tabDeposits";
import _tabWithdrawals from "./_tabWithdrawals";
import _tabActivity from "./_tabActivity";

export default function Contents({ Withdraws, Activities }) {
  const { propsWithdraws, set_propsWithdraws, dispatch_post_withdraws } = Withdraws;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const { coin } = useParams();

  const [active_tab, set_active_tab] = useState("預入");

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_wallets = useSelector((state) => state.reduce_get_wallets);
  const reduce_get_withdraws = useSelector((state) => state.reduce_get_withdraws);

  const maps = useSelector((state) => state.reduce_get_maps);

  const coin_decimals = maps.coin_decimals;
  const coins = maps.coins;

  useEffect(() => {}, []);
  const coin_fullname = coins[coin];

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const wallets = reduce_get_wallets?.wallets;
  const v_wallets = view_wallets(wallets, maps, t, coin);

  const withdraws = reduce_get_withdraws?.withdraws;
  // const v_withdraws = view_withdraws(withdraws,  maps,t, coin);

  const mindeposit = {
    BTC: 0.0002,
    ETH: 0.0025,
    IOST: 0,
    USDT: 0,
  };
  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  return (
    <>
      <div>
        <Header />
        <div className="container container-main">
          {_sidebar(Withdraws, `${coin} / ${coin_fullname}`)}
          <main className="main">
            <div className="main-header pt-3 pb-2 ps-lg-3 mb-0 d-none d-md-flex">
              <h3 className="h4 m-0 text-truncate">
                {/* <i className="fa-brands fa-ethereum fa-fw me-2 text-muted small" /> */}
                {/* <i className="fa-brands fa-bitcoin fa-fw me-2 text-muted small" /> */}
                <small className="text-muted">
                  {coin} / {coin_fullname}
                </small>
              </h3>
            </div>
            <div className="main-header main-header-hidden mb-0">
              <ul className="nav canopy nav-fill w-100" id="wallet-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    type="button"
                    className={`nav-link btn ${active_tab === "預入" && "active"}`}
                    id="deposits-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#deposits"
                    role="tab"
                    aria-controls="deposits"
                    aria-selected="true"
                    onClick={(e) => {
                      e.preventDefault();
                      set_active_tab("預入");
                    }}
                  >
                    <span className="canopy-text">{t("預入")}</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    type="button"
                    className={`nav-link btn ${active_tab === "送付" && "active"}`}
                    id="withdrawals-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#withdrawals"
                    role="tab"
                    aria-controls="withdrawals"
                    aria-selected="false"
                    onClick={(e) => {
                      e.preventDefault();
                      set_active_tab("送付");
                    }}
                  >
                    <span className="canopy-text">{t("送付")}</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link btn ${active_tab === "履歴" && "active"}`}
                    href="javascript:void(0)"
                    id="activity-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#activity"
                    role="tab"
                    aria-controls="activity"
                    aria-selected="false"
                    onClick={(e) => {
                      e.preventDefault();
                      set_active_tab("履歴");
                    }}
                  >
                    <span className="canopy-text">{t("履歴")}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="wallet-tabContent">
              {_tabDeposits()}
              {_tabWithdrawals(Withdraws)}
              {_tabActivity(Activities)}
            </div>
            {active_tab === "預入" && (
              <dl className="m-md-3">
                <dt className="mb-2">{t("ご注意")}</dt>
                <dd>
                  <ul>
                    {mindeposit[coin] != 0 && (
                      <span className="text-danger">
                        <li className="mb-2">
                          {t("最低預入金額は")} {mindeposit[coin]} {coin}
                          {t(
                            "です。最低預入金額以下で預入された場合は反映されませんので、合計金額が最低預入金額を上回るように再度お預けください。"
                          )}
                        </li>
                      </span>
                    )}

                    <li className="mb-2">
                      {t("預入後　「預入を反映」ボタン")} <i className="fa-solid fa-rotate-right" />{" "}
                      {t("を押して情報を更新してください。")}
                    </li>
                    <li className="mb-2">
                      {t(
                        "当社が取扱わない通貨、トークン等を誤ってお預入された場合、原則として返還対応いたしかねます。返還が可能な場合においても、それに係る手数料等をお支払いただくことがあります。"
                      )}
                    </li>
                    <li className="mb-2">{t("預入用アドレスは変わることがあります。")}</li>
                  </ul>
                </dd>
              </dl>
            )}
            {active_tab === "送付" && (
              <dl className="m-md-3">
                <dt className="mb-2">{t("ご注意")}</dt>
                <dd>
                  <ul>
                    <li className="mb-2">
                      {t(
                        "セキュリティーのため、ご登録いただいた「アドレス」以外の送付できません。"
                      )}
                    </li>
                    <li className="mb-2">
                      {t("当社は外部アドレスで起きたことに一切の責任を負いません。")}
                    </li>
                    <li className="mb-2">
                      {t("送付先の外部アドレスを間違えて送付した場合は絶対に取戻せません。")}
                    </li>
                    <li className="mb-2">
                      {t(
                        "当社で送付手続きの手数料はいただいておりませんが、ブロックチェーンで発生するガス代が送付金額から差し引かれます。"
                      )}
                    </li>
                    <li className="mb-2">
                      {t(
                        "平日 14 時 30 分より前に送付を申請された場合、原則として当日、または翌営業日中にお手続きいたします。14 時 30 分以降に申請された送付は原則として翌営業日、または翌々営業日中にお手続きいたします。当社またはブロックチェーンの処理状況等により遅れる可能性もありますのでご了承ください。"
                      )}
                    </li>
                    <li className="mb-2">
                      {t(
                        "土日祝日明けに申請された送付は、通常よりお時間をいただく場合がございます。送付のご依頼は時間に余裕をもって行っていただきますようお願いいたします。"
                      )}
                    </li>
                  </ul>
                </dd>
              </dl>
            )}
          </main>
        </div>
        {_modal_withdrawals(Withdraws)}
        {_modal_walletsCreate(Withdraws)}
      </div>
      <Footer />
    </>
  );
}
