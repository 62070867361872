import axios from "../axios";
import env from "../env";

export const api_get_top_collections = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`top/collections`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};
export const api_get_top_items = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`top/items`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};
export const api_get_top_buyers = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`top/buyers`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};
export const api_get_top_sellers = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`top/sellers`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_get_top_collabos = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`top/collabos`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_put_top_items_id_favorite = (data, token) => {
  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`items/${data.id}/favorite`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    data,
    formData: true,
  };
  return axios(options);
};

export const api_put_top_collections_id_favorite = (data, token) => {
  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`collections/${data.id}/favorite`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    data,
    formData: true,
  };
  return axios(options);
};