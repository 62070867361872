import React, { FC, useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { getSearch, setSearch } from "config/util";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import { ChainLink } from "components/ChainLink";
import { get_activities } from "stores/Activities/actions";

const App = ({ branch, children }) => {
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();

  const dispatch = useDispatch();

  const maps = useSelector((state) => state.reduce_get_maps);

  const [propsActivities, set_propsActivities] = useState({
    search: {
      page: 1,
      // type: [21, 22, 30, 31, 32],
      // type: [0, 10, 11, 21, 22, 23, 24, 30, 31, 32, 33, 34, 35],
      // type: [0, 10, 11, 21, 22, 30, 31, 32, 34, 35],
      // type: [0, 21, 22, 23, 30, 31, 32, 34],
      type: [0, 21, 22, 23, 31, 32, 34],
      platform: [0, 4, 10],
      // type: [0],
      limit: 10,
      sort: [],
      ...branch?.search,
    },
  });

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_get_activities = (data, next, prev) => {
    dispatch(
      get_activities({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_gets_activities = (search, page = 1) => {
    dispatch_get_activities({
      search: { ...search, page },
    });
    set_propsActivities({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    dispatch_gets_activities(propsActivities.search, 1);
  }, []);

  const newchildren = React.cloneElement(children, {
    Activities: {
      propsActivities,
      set_propsActivities,
      dispatch_gets: dispatch_gets_activities,
      list: "user",
      // dispatch_get_id: (id, idx, callback) => {
      //   dispatch_get_activities_id(
      //     {
      //       id,
      //     },
      //     (data) => {
      //       set_propsActivities({
      //         ...data.item,
      //         search: propsActivities.search,
      //         id: id,
      //         idx: idx,
      //       });
      //       !callback || callback();
      //     }
      //   );
      // },

      branch,
    },
  });

  return (
    <>
      <Helmet>
        <title>tomonity</title>
      </Helmet>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      {newchildren}
    </>
  );
};

export default App;
