import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";
import CustomPhone from "components/CustomPhone/CustomPhone.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomCircle from "components/CustomCircle/CustomCircle.js";

import { hideModal, showModal } from "config/util";

export default function Contents({ Account }) {
  const { propsAccount, set_propsAccount, dispatch_post_accounts_auth, branch } = Account;
  const method = branch.method;

  const props = propsAccount;
  const set_props = set_propsAccount;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts.user);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const username_current = reduce_get_accounts.username;
  const mail_address_current = reduce_get_accounts.mail_address;
  const phone_number_current = reduce_get_accounts.phone_number;

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const maps = useSelector((state) => state.reduce_get_maps);
  const loading = useSelector((state) => state.reduce_post_accounts_auth.isLoading);

  let title = "";
  if (method == "user") title = t("ユーザーネームの変更");
  if (method == "mail") title = t("メールアドレスの変更");
  if (method == "phone") title = t("携帯電話番号の変更");
  if (method == "password") title = t("パスワードの変更");

  return (
    <>
      <div>
        <Header />
        <div className="container container-main">
          <main className="main">
            <div action="created-collection-detail.html" entype="multpart/form-data">
              <div className="main-header pt-3 pb-2">
                <h2 className="h4 m-0 text-truncate">{title}</h2>
                <div className="btn-group">
                  <a
                    className="btn btn-outline-secondary text-dark rounded-pill px-3 me-2 text-nowrap"
                    href="javascript:history.back()"
                  >
                    <i className="fa-solid fa-xmark" />
                    <span className="d-none d-sm-inline-block"> {t("キャンセル")}</span>
                  </a>
                  <button
                    type="button"
                    name="confirm"
                    className="btn btn-primary rounded-pill px-3 text-nowrap"
                    role="button"
                    // data-bs-toggle="modal"
                    // data-bs-target="#processDialog"
                    onClick={(e) => {
                      e.preventDefault();

                      if (method == "user") {
                        dispatch_post_accounts_auth(
                          {
                            type: 1,
                            username: props.username,
                          },
                          () => {
                            history.push(`/account-user-code`);
                          },
                          () => {}
                        );
                      }

                      if (method == "mail") {
                        dispatch_post_accounts_auth(
                          {
                            type: 2,
                            mail_address: props.mail_address,
                          },
                          () => {
                            history.push(`/account-mail-code`);
                          },
                          () => {}
                        );
                      }
                      if (method == "phone") {
                        dispatch_post_accounts_auth(
                          {
                            type: 3,
                            phone_number: props.phone_number,
                          },
                          () => {
                            history.push(`/account-phone-code`);
                          },
                          () => {}
                        );
                      }

                      if (method == "password") {
                        dispatch_post_accounts_auth(
                          {
                            type: 4,
                            password: props.password,
                          },
                          () => {
                            history.push(`/account-password-code`);
                          },
                          () => {}
                        );
                      }
                    }}
                  >
                    <CustomCircle title={t("変更")} loading={loading} size={16} />
                  </button>
                </div>
              </div>
              {method == "user" && (
                <div className="row g-3 p-xl-3">
                  <div className="col-md-6 col-xl-8">
                    <h3 className="h5">{t("ユーザーネーム")}</h3>
                    <div className="row row-cols-1 row-cols-xl-2 g-3">
                      <div className="col">
                        <div className="mb-4">
                          <div className="form-floating mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="collectionId"
                              placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3ID"
                              inputMode="email"
                              onChange={(e) => {
                                const val = e.target.value;
                                set_props({
                                  ...props,
                                  username: val,
                                });
                              }}
                              value={props.username}
                            />
                            <label htmlFor="collectionId">{t("ユーザーネーム")}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {method == "mail" && (
                <div className="row g-3 p-xl-3">
                  <div className="col-md-6 col-xl-8">
                    <h3 className="h5">{t("現在のメールアドレス")}</h3>
                    <div className="row row-cols-1 row-cols-xl-2 g-3">
                      <div className="col">
                        <div className="media mb-2 bg-light p-2">
                          <div className="media-body overflow-hidden">
                            <span className="d-block">{mail_address_current}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 className="h5">{t("変更後のメールアドレス")}</h3>
                    <div className="row row-cols-1 row-cols-xl-2 g-3">
                      <div className="col">
                        <div className="mb-4">
                          <div className="form-floating mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="collectionId"
                              placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3ID"
                              inputMode="email"
                              onChange={(e) => {
                                const val = e.target.value;
                                set_props({
                                  ...props,
                                  mail_address: val,
                                });
                              }}
                              value={props.mail_address}
                            />
                            <label htmlFor="collectionId">{t("メールアドレス")}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {method == "phone" && (
                <div className="row g-3 p-xl-3">
                  <div className="col-md-6 col-xl-8">
                    <h3 className="h5">{t("現在の携帯電話番号")}</h3>
                    <div className="row row-cols-1 row-cols-xl-2 g-3">
                      <div className="col">
                        <div className="media mb-2 bg-light p-2">
                          <div className="media-body overflow-hidden">
                            <span className="d-block">{phone_number_current}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 className="h5">{t("変更後の携帯電話番号")}</h3>
                    <div className="row row-cols-1 row-cols-xl-2 g-3">
                      <div className="col">
                        <div className="mb-4">
                          <div className="form-floating mb-2">
                            <CustomPhone
                              shrink={true}
                              labelText={t("携帯電話番号")}
                              value={props.phone_number}
                              set_value={(val) => {
                                set_props({
                                  ...props,
                                  phone_number: val,
                                });
                              }}
                              country_code={props.country_code}
                              set_country_code={(val) => {
                                set_props({
                                  ...props,
                                  country_code: val,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {method == "password" && (
                <div className="row g-3 p-xl-3">
                  <div className="col-md-6 col-xl-8">
                    {/* <h3 className="h5">変更後のメールアドレス</h3> */}
                    <div className="row row-cols-1 row-cols-xl-2 g-3">
                      <div className="col">
                        <div className="mb-4">
                          <div className="form-floating mb-3">
                            <input
                              type="password"
                              className="form-control rounded-4"
                              id="floatingPassword"
                              placeholder="Password"
                              required
                              value={props.password}
                              onChange={(e) => {
                                const val = e.target.value;
                                set_props({
                                  ...props,
                                  password: val,
                                });
                              }}
                            />
                            <label htmlFor="floatingPassword">{t("パスワード")}</label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              type="password"
                              className="form-control rounded-4"
                              id="floatingPassword"
                              placeholder="Password"
                              required
                              value={props.password_confirm}
                              onChange={(e) => {
                                const val = e.target.value;
                                set_props({
                                  ...props,
                                  password_confirm: val,
                                });
                              }}
                            />
                            <label htmlFor="floatingPassword">{t("パスワード(確認)")}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
