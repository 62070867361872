import _sidebar from "./_sidebar";
import _mainheader from "./_mainheader";
import { hideModal, showModal } from "config/util";

export default function Contents({ Page, Items, Activities }) {
  const { type, dispatch_type, v_collection, history } = Page;

  const { dispatch_put_collections_id_join, dispatch_delete_collections_id } = Items;

  return (
    <>
      <div
        className="modal fade"
        id="teamJoin"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="teamJoinLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
          <div className="modal-content h-100">
            <div className="modal-header">
              <h5 className="modal-title text-truncate" id="teamJoinLabel">
                コラボに参加
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body p-0">
              <div className="p-3">
                <h6>参加するコラボ</h6>
                <div className="media position-relative w-100 mb-2">
                  <div className="media-object">
                    <div className="object-item icon icon-square position-relative">
                      <img
                        src={v_collection.collection_icon_uri}
                        className="icon-item"
                        width="100%"
                        height="100%"
                        alt=""
                      />

                      <div
                        className="position-absolute top-100 start-100 translate-middle badge rounded-pill bg-white text-body p-1 lh-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="\u30B3\u30E9\u30DC\u30EC\u30FC\u30C8"
                        style={{
                          zIndex: 2,
                        }}
                      >
                        <i className="icomoon-collab text-secondary" />
                      </div>
                    </div>
                  </div>
                  <div className="media-body">
                    <h3 className="media-title mb-0">
                      <a className="stretched-link" href="collaborate-hidden.html">
                        {v_collection.collection_name}
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="p-3 bg-light">
                <div className="media position-relative w-100">
                  <div className="media-object">
                    <div className="mask-hex">
                      <figure
                        className="object-item ratio ratio-1x1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="\u30CF\u30F3\u30C9\u30B7\u30A7\u30EB"
                      >
                        <img
                          src={v_collection.collection_user_icon_uri}
                          className="icon-item"
                          alt="\u30CF\u30F3\u30C9\u30B7\u30A7\u30EB"
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="media-body">
                    <h3 className="media-title mb-0">
                      {v_collection.collection_user_name}
                      <span>@{v_collection.collection_user_nmid}</span>
                    </h3>
                    {}
                    <div className="mt-3">
                      <span className="d-block mb-2 fw-bold">あなたへの報酬の分配</span>
                      <dl className="d-flex justify-content-between mb-1">
                        <dt className="m-0">
                          一次<span className="ms-1">(販売売上)</span>
                        </dt>
                        <dd className="m-0">{v_collection.collection_user_self?.reward_first}%</dd>
                      </dl>
                      <dl className="d-flex justify-content-between mb-1">
                        <dt className="m-0">
                          二次<span className="ms-1">(クリエイター報酬)</span>
                        </dt>
                        <dd className="m-0">{v_collection.collection_user_self?.reward_second}%</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <span className="me-auto">参加しますか？</span>
              <button type="button" className="btn btn-link link-dark" data-bs-dismiss="modal">
                キャンセル
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch_put_collections_id_join(
                    {
                      id: v_collection.collection_id,
                      // search: propsCollections.search,
                    },
                    () => {
                      hideModal("teamJoin");
                    },
                    () => {}
                  );
                }}
              >
                参加する
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="teamWithdraw"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="teamWithdrawLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
          <div className="modal-content h-100">
            <div className="modal-header bg-danger text-light">
              <h5 className="modal-title text-truncate" id="teamWithdrawLabel">
                コラボを削除
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body p-0">
              <div className="alert alert-warning rounded-0 px-3 mb-0" role="alert">
                <div className="d-flex align-items-center mb-2 ps-2">
                  <i className="fa-solid fa-triangle-exclamation me-2" />
                  <span className="d-block fw-bold">ご注意ください</span>
                </div>
                <p className="m-0">
                  コラボを削除すると、コラボチームとコラボコレクションが削除され元に戻せません。
                  <br />
                  それでも削除しますか？
                </p>
              </div>
              {/* <div className="p-3">
                <h6>削除するコラボ</h6>
                <div className="media position-relative w-100 mb-2">
                  <div className="media-object">
                    <div className="object-item icon icon-square position-relative">
                      <img
                        src="../example/collections/10-1-icon.webp"
                        className="icon-item"
                        width="100%"
                        height="100%"
                        alt="MOMIJI"
                      />
                      <div
                        className="position-absolute top-100 start-100 translate-middle badge rounded-pill bg-white text-body p-1 lh-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="\u30B3\u30E9\u30DC\u30EC\u30FC\u30C8"
                        style={{
                          zIndex: 2,
                        }}
                      >
                        <i className="icomoon-collab text-secondary" />
                      </div>
                    </div>
                  </div>
                  <div className="media-body">
                    <h3 className="media-title mb-0">
                      <a className="stretched-link" href="collaborate-hidden.html">
                        MOMIJI
                      </a>
                    </h3>
                    <small>
                      <i
                        className="fa-solid fa-clipboard-check fa-fw me-1"
                        title="\u30B3\u30E9\u30DCID"
                      />
                      COLLAB654321
                    </small>
                  </div>
                </div>
              </div> */}
              {/* <div className="p-3 bg-light">
                <div className="media position-relative w-100">
                  <div className="media-object">
                    <div className="mask-hex">
                      <figure
                        className="object-item ratio ratio-1x1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="TSOD+J"
                      >
                        <img
                          src="../example/teams/1-icon.webp"
                          className="icon-item"
                          alt="TSOD+J"
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="media-body">
                    <h3 className="media-title mb-0">
                      TSOD+J<span>@tsodj</span>
                    </h3>
                    <small>
                      <i
                        className="fa-solid fa-clipboard-check fa-fw me-1"
                        title="\u30B3\u30E9\u30DCID"
                      />
                      TEAM656565
                    </small>
                    <div className="mt-3">
                      <span className="d-block mb-2 fw-bold">あなたへの報酬の分配</span>
                      <dl className="d-flex justify-content-between mb-1">
                        <dt className="m-0">
                          一次<span className="ms-1">(販売売上)</span>
                        </dt>
                        <dd className="m-0">40%</dd>
                      </dl>
                      <dl className="d-flex justify-content-between mb-1">
                        <dt className="m-0">
                          二次<span className="ms-1">(クリエイター報酬)</span>
                        </dt>
                        <dd className="m-0">0%</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="modal-footer bg-danger text-light">
              <span className="me-auto">削除しますか？</span>
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal">
                キャンセル
              </button>
              <button
                type="button"
                className="btn btn-outline-light"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch_delete_collections_id(
                    {
                      id: v_collection.collection_id,
                      // search: propsCollections.search,
                    },
                    () => {
                      hideModal("teamWithdraw");
                    },
                    () => {}
                  );
                }}
              >
                削除する
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
