import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";

import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";

import { view_item } from "../../stores/Items/view";

import { put_items_id_favorite } from "stores/Items/actions";
// import { HeaderItem, RowItem } from "./_item";
import { HeaderUser, RowUser } from "./_user";

export default function Dashboard({ Items }) {
  const { propsItems, list, mypage } = Items;

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_put_items_id_favorite = (data, next, prev) => {
    dispatch(
      put_items_id_favorite({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const history = useHistory();

  const maps = useSelector((state) => state.reduce_get_maps);

  const loading = useSelector((state) => state.reduce_get_items.isLoading);
  const loadingId = useSelector((state) => state.reduce_get_items.isLoadingId);

  const datalist = useSelector((state) => state.reduce_get_items.items);
  Items.dispatch_put_items_id_favorite = dispatch_put_items_id_favorite;
  Items.history = history;
  Items.t = t;

  if (loading) return <></>;

  return (
    <>
      {Items.list == "user" && (
        <>
          {HeaderUser(Items)}
          {datalist.map((prop, idx) => {
            const v_item = view_item(prop, maps, t);
            Items.v_item = v_item;
            return <>{RowUser(Items)}</>;
          })}
        </>
      )}
      {/* {Items.list == "item" && (
        <>
          {HeaderItem(Items)}
          {datalist.map((prop, idx) => {
            const v_item = view_item(prop, maps, t);
            Items.v_item = v_item;
            return <>{RowItem(Items)}</>;
          })}
        </>
      )} */}
      {/* {list == 5 && (
        <div className="ms-md-3 mt-0">
          <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5 g-2 g-sm-3">
            {datalist.map((prop, idx) => {
              const v_item = view_item(prop);
              return <ColList v_item={v_item} />;
            })}
          </div>
        </div>
      )}
      {list == 4 && (
        <div className="ms-md-3 mt-0">
          <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-xxl-4 g-2 g-sm-3">
            {datalist.map((prop, idx) => {
              const v_item = view_item(prop);
              return <ColList v_item={v_item} />;
            })}
          </div>
        </div>
      )} */}
    </>
  );
}
