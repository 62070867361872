import { combineReducers } from "redux";
import { createAction, createReducer } from "redux-act";

import {
  get_items_id_offers,
  get_items_id_offers_data,
  get_items_id_offers_error,
  get_items_id_offers_id,
  get_items_id_offers_id_data,
  get_items_id_offers_id_error,
  post_items_id_offers,
  post_items_id_offers_data,
  post_items_id_offers_error,
  put_items_id_offers_id,
  put_items_id_offers_id_data,
  put_items_id_offers_id_error,
  delete_items_id_offers_id,
  delete_items_id_offers_id_data,
  delete_items_id_offers_id_error,
} from "./actions";

const forupdate = (array, unit, id) => {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element[id] === unit[id]) {
      array[index] = unit;
    }
  }
  return array;
};

export const reduce_get_items_id_offers = createReducer(
  {
    [get_items_id_offers]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_items_id_offers_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_items_id_offers_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [get_items_id_offers_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoadingId = true;
      return newState;
    },
    [get_items_id_offers_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      const item_offer = payload.data.item_offer;
      const item_offers = state.item_offers;
      if (item_offers) newState.item_offers = forupdate(item_offers, item_offer, "item_id");
      newState.isLoadingId = false;

      return newState;
    },
    [get_items_id_offers_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoadingId = false;
      return newState;
    },
  },
  {
    isLoading: false,
    isLoadingId: false,
    item_offer: {},
    item_offers: [],
    count: 0,
  }
);

export const reduce_post_items_id_offers = createReducer(
  {
    [post_items_id_offers]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_items_id_offers_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_items_id_offers_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_items_id_offers_id = createReducer(
  {
    [put_items_id_offers_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_items_id_offers_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_items_id_offers_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_items_id_offers_id = createReducer(
  {
    [delete_items_id_offers_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_items_id_offers_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [delete_items_id_offers_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export default {
  reduce_get_items_id_offers,
  reduce_post_items_id_offers,
  reduce_put_items_id_offers_id,
  reduce_delete_items_id_offers_id,
};
