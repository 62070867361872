import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";

import CustomImage from "./CustomImage";
import { hideModal, showModal } from "config/util";
import _collection from "./_collection";
import _collabo from "./_collabo";

export default function Contents({ Page, Collection }) {
  const { maps, t, history, v_collection } = Page;
  const { propsCollection, set_propsCollection, dispatch_put_collections_id } = Collection;

  const props = propsCollection;
  const set_props = set_propsCollection;

  const collabo_flg = props.collection_collabo_flg;
  const active_flg = props.active_flg;

  const スタンダード = !collabo_flg;
  const コラボ承認済 = collabo_flg && active_flg;
  const コラボ未承認 = collabo_flg && !active_flg;

  return (
    <>
      <div>
        <Header />
        <div className="container container-main">
          <main className="main">
            {!collabo_flg && _collection({ Page, Collection })}
            {collabo_flg && _collabo({ Page, Collection })}
            {/* <div
              className="modal fade modal-alert py-5"
              id="processDialog"
              data-bs-keyboard="false"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content rounded-4 shadow">
                  <div className="modal-body p-4 text-center">
                    <h5 className="mb-2">{t("確認")}</h5>
                    <p className="mb-0">{t("入力内容を保存してもいいですか？")}</p>
                  </div>
                  <div className="modal-footer flex-nowrap p-0">
                    <button
                      type="button"
                      className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0"
                      data-bs-dismiss="modal"
                    >
                      {t("キャンセル")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"
                      data-bs-target="#modalSuccessfully"
                      data-bs-toggle="modal"
                    >
                      <strong>{t("はい")}</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="modalSuccessfully"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-6 shadow">
                  <div className="modal-body p-md-5">
                    <h2 className="fw-bold mb-0 text-center">{t("コレクションを変更しました!")}</h2>
                    <div className="row mt-4 mb-3">
                      <div className="col-4 mx-auto">
                        <div
                          htmlFor="collectionLogo"
                          className="ratio ratio-1x1 object-item icon icon-square"
                        >
                          <img
                            src="../example/collections/2-1-icon.webp"
                            className="icon-item"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 text-center">
                      <h5>{t("シェアしよう!")}</h5>
                      <aside className="mb-3" role="link">
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-twitter text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-instagram text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-discord text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-linkedin-in text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fa-brands fa-telegram fa-fw" />
                        </a>
                      </aside>
                    </div>
                    <a className="btn btn-lg rounded-pill btn-primary w-100" href="#">
                      {t("今すぐアイテムを作成")}
                    </a>
                    <a className="btn btn-lg rounded-pill btn-outline-primary mt-2 w-100" href="#">
                      {t("コレクション詳細へ")}
                    </a>
                    <a className="btn btn-lg rounded-pill btn-link mt-2 w-100" href="#">
                      {t("続けてコレクションを作成")}
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </main>
        </div>
      </div>
    </>
  );
}
