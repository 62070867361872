import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import { view_collection } from "../../stores/Collections/view";
import { view_user } from "../../stores/Users/view";
import _section1 from "./_section1";
import _section2 from "./_section2";
import _section3 from "./_section3";
import Footer from "../../views_components/Component/Footer";

export default function Contents({ Contact }) {
  const { propsContact, set_propsContact, dispatch_post_contact } = Contact;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();
  const { user_id } = useParams();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_users = useSelector((state) => state.reduce_get_users);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const collection = useSelector((state) => state.reduce_get_items.collection);
  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection);

  const user = reduce_get_users.user;
  const v_user = view_user(user);

  return (
    <>
      <div>
        <Header />
        <div className="container-main">
          <main className="main pb-0">
            <div className="py-3 py-md-4 contactview">
              <div className="container">
                <h2 className="h5 m-0">
                  {t("tomonity サポートへの")}
                  <br />
                  {t("お問い合わせ")}
                </h2>
              </div>
            </div>
            {propsContact.type == 1 && _section1(Contact)}
            {propsContact.type == 2 && _section2(Contact)}
            {propsContact.type == 3 && _section3(Contact)}
          </main>
        </div>
        <Footer />

      </div>
    </>
  );
}
