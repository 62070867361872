import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import { toNumber } from "config/util";
import { view_wallets } from "../../stores/Wallets/view";

export default function Contents(Withdraws, name) {
  const {
    propsWithdraws,
    set_propsWithdraws,
    dispatch_gets,
    dispatch_post_wallets_id_deposit,
  } = Withdraws;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_wallets = useSelector((state) => state.reduce_get_wallets);

  const maps = useSelector((state) => state.reduce_get_maps);

  const coin_decimals = maps.coin_decimals;
  const coins = maps.coins;

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const wallets = reduce_get_wallets?.wallets;
  const v_wallets = view_wallets(wallets, maps, t);
  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  const LiCoin = ({ coin }) => {
    const v_wallets = view_wallets(wallets, maps, t, coin);

    const coin_icon_tag =
      coin == "BTC" ? (
        <i className="icomoon-brand-btc" />
      ) : coin == "ETH" ? (
        <i className="icomoon-brand-eth" />
      ) : coin == "IOST" ? (
        <i className="icomoon-brand-iost" />
      ) : coin == "USDT" ? (
        <i className="icomoon-brand-usdt" />
      ) : (
        <></>
      );

    return (
      <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <div className="me-auto flex-grow-1 d-flex align-items-center position-relative text-body">
          {/* <i
            className="icomoon-brand-btc me-3 lead border rounded-pill text-muted"
            style={{
              width: "32px",
              height: "32px",
              lineHeight: "32px",
            }}
          /> */}
          {/* {coin_icon_tag} */}
          <a
            className="stretched-link text-body"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/wallets/${coin}`);
              // dispatch_gets({ ...propsWithdraws.search, symbol: coin }, 1);
            }}
          >
            <span className="fw-bold">{coin}</span>
            <small className="d-block">{v_wallets.coin_fullname}</small>
          </a>
          <span className="ms-auto text-end">
            {v_wallets.coin_balance_label}
            <small className="d-block">(${v_wallets.usd_balance_label})</small>
          </span>
        </div>
        <div className="d-flex align-items-center flex-shrink-0 ms-3">
          <div data-bs-toggle="tooltip" data-bs-placement="top" title="預入を反映">
            <button
              type="button"
              className="btn btn-white rounded-pill btn-sm border"
              onClick={() => {
                dispatch_post_wallets_id_deposit(
                  {
                    id: v_wallets.wallet_id,
                    // amount: propsWithdraws.amount,
                    // symbol: coin,
                    coin,
                    search: propsWithdraws.search,
                  },
                  () => {
                    alert(t("入金処理を開始しました"));
                  },
                  () => {}
                );
              }}
            >
              <i className="fa-solid fa-rotate-right" />
            </button>
          </div>
        </div>
      </li>
    );
  };

  return (
    <>
      <div className="sidebar shadow-end pb-0">
        <div className="sidebar-sticky">
          {/* <div className="border-bottom d-flex align-items-center pt-1 pb-2 d-md-none"> */}
          <nav className="d-flex d-md-none align-items-center border-bottom pb-2">
            <h2 className="h4 m-0 text-truncate">
              {/* <i className="fa-solid fa-wallet fa-fe text-muted me-2" /> */}
              {name}
            </h2>
            <button
              type="button"
              className="btn btn-light ms-auto d-md-none mb-0"
              data-bs-toggle="collapse"
              data-bs-target="#navbarWallets"
              aria-controls="navbarWallets"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fa-solid fa-angle-down" />
            </button>
          </nav>

          {/* </div> */}
          <div className="navbar navbar-expand-md py-0 pt-md-3">
            <ul
              id="navbarWallets"
              className="collapse navbar-collapse list-group list-group-flush mb-0"
              aria-label="Wallet navigation"
            >
              <li className="list-group-item d-flex align-items-center position-relative w-100">
                <i
                  className="fa-solid fa-wallet fa-fw me-3 border rounded-pill text-muted"
                  style={{
                    width: "32px",
                    height: "32px",
                    lineHeight: "32px",
                  }}
                />
                <a
                  className="stretched-link text-body"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`/wallets`);
                  }}
                >
                  <span className="d-block mb-1">{t("ウォレット残高(総計)")}</span>
                  <span className="lead">${v_wallets.sum_usd_label}</span>
                  <small className="d-block">(¥{v_wallets.sum_jpy_label})</small>
                </a>
              </li>
              {Object.keys(coins).map((c) => {
                return <LiCoin coin={c} />;
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
