import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const CustomCircle = ({ size, loading, title }) => {
  return (
    <>
      {loading ? (
        <>
          {/* <Box sx={{ display: "flex" }}> */}
          <Box sx={{ display: "inline-block" }}>
            <CircularProgress color="inherit" style={{ width: size, height: size }} />
          </Box>
        </>
      ) : (
        <>{title}</>
      )}
    </>
  );
};

export default CustomCircle;
