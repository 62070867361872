import React, { FC, useEffect, useRef, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import { initReactI18next, useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomLoading from "components/CustomLoading/CustomLoading.js";
import { DisplayContext } from "../../Navigator/Router_material";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { getMessage, toMomentString } from "config/util";

import MainFixed from "../../components/CustomLoadingScreen/MainFixed";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import _search from "./_search";
import _modal from "./_modal";
// import LinearProgress from "./Line";

export default function Dashboard({ Items, isFooter }) {
  const { propsItems, set_propsItems, dispatch_gets } = Items;
  const history = useHistory();
  const dispatch = useDispatch();

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if (!message) return;
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };
  useEffect(() => {
    // dispatch_gets(propsItems.search, 1);
  }, []);
  const [t, i18n] = useTranslation();

  const theme = useTheme();
  const primary = theme.palette.primary.main;

  const count = useSelector((state) => state.reduce_get_items.count);
  const { windowHeight, tableHeight } = useContext(DisplayContext);

  const location = useLocation();
  const nonce = location.nonce;

  const [mount, set_mount] = React.useState(true);
  const [load, set_load] = useState(true);
  const ref_load = useRef(load);

  useEffect(() => {
    ref_load.current = load;
  }, [load]);

  const loading = useSelector((state) => state.reduce_get_items.isLoading);
  const datalist = useSelector((state) => state.reduce_get_items.items);

  useEffect(() => {
    if (ref_load.current) {
      set_load(loading);
    }
  }, [loading]);

  useEffect(() => {
    set_mount(false);
    set_load(true);
  }, [nonce]);

  useEffect(() => {
    set_mount(true);
  }, [mount]);

  if (!mount) {
    return <></>;
  }

  if (!datalist) {
    return <></>;
  }

  const height = tableHeight;
  Items.t = t;

  return (
    <>
      {/* <MainFixed loading={loading && ref_load.current} /> */}
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      <div className="main-toolbar">
        <div>
          <TablePagination
            // rowsPerPageOptions={[10, 100, 1000]}
            rowsPerPageOptions={[10]}
            labelRowsPerPage={t("")}
            // labelDisplayedRows={({ from, to, count }) =>
            //   t(`${count} 件中 ${from} ~ ${to} 件表示`, count, from, to)
            // }
            // labelDisplayedRows={({ from, to, count }) =>
            //   loading ? "" : `${from} ~ ${to} of ${count}`
            // }
            component="div"
            count={count}
            page={propsItems.search.page - 1}
            onPageChange={(event, newPage) => {
              dispatch_gets(propsItems.search, newPage + 1);
            }}
            onRowsPerPageChange={(event) => {
              const search = {
                ...propsItems.search,
                limit: parseInt(event.target.value, 10),
              };
              dispatch_gets(search, 1);
            }}
            rowsPerPage={propsItems.search.limit ? propsItems.search.limit : 0}
          />
        </div>
        {/* {Items.list == "user" && <>{_search(Items)}</>} */}
        {!isFooter && _modal(Items)}
        {!isFooter && _search(Items)}
      </div>
      {!isFooter && loading && (
        <div className="main-toolbar">
          <CustomLinearProgress color="primary" />
        </div>
      )}
    </>
  );
}

//  {/* <GridContainer justifyContent="flex-start"> */}
//  <GridContainer justifyContent="space-between">
//  <GridItem>
//    <TablePagination
//      // rowsPerPageOptions={[10, 100, 1000]}
//      rowsPerPageOptions={[10]}
//      labelRowsPerPage={t("")}
//      // labelDisplayedRows={({ from, to, count }) =>
//      //   t(`${count} 件中 ${from} ~ ${to} 件表示`, count, from, to)
//      // }
//      // labelDisplayedRows={({ from, to, count }) =>
//      //   loading ? "" : `${from} ~ ${to} of ${count}`
//      // }
//      component="div"
//      count={count}
//      page={propsItems.search.page - 1}
//      onPageChange={(event, newPage) => {
//        dispatch_gets(propsItems.search, newPage + 1);
//      }}
//      onRowsPerPageChange={(event) => {
//        const search = {
//          ...propsItems.search,
//          limit: parseInt(event.target.value, 10),
//        };
//        dispatch_gets(search, 1);
//      }}
//      rowsPerPage={propsItems.search.limit ? propsItems.search.limit : 0}
//    />
//  </GridItem>
//  <GridItem></GridItem>
// </GridContainer>
