import React, { useState, useEffect, Component } from "react";
import { initReactI18next, useTranslation } from "react-i18next";

export const Type = ({ type, name }) => {
  const [t, i18n] = useTranslation();

  if (name == "発行アイテム") {
    return (
      <>
        <i className="fa-solid fa-baby-carriage fa-fw me-1" />
        {t("発行")}
      </>
    );
  }
  if (name == "発行コレクション") {
    return (
      <>
        <i className="fa-solid fa-baby-carriage fa-fw me-1" />
        {t("発行")}
      </>
    );
  }

  if (name == "転送") {
    return (
      <>
        <i className="fa-solid fa-right-left fa-fw me-1" />
        {t("転送")}
      </>
    );
  }
  if (name == "販売") {
    return (
      <>
        <i className="fa-solid fa-tag fa-fw me-1" />
        {t("出品")}
      </>
    );
  }
  if (name == "購入") {
    return (
      <>
        <i className="fa-solid fa-cart-shopping fa-fw me-1" />
        {t("販売")}
      </>
    );
  }

  if (name == "オファー") {
    return (
      <>
        <i className="fa-solid fa-cart-shopping fa-fw me-1" />
        {t("オファー")}
      </>
    );
  }
  if (name == "承諾") {
    return (
      <>
        <i className="fa-solid fa-cart-shopping fa-fw me-1" />
        {t("承諾")}
      </>
    );
  }

  if (name == "出品") {
    return (
      <>
        <i className="fa-solid fa-tag fa-fw me-1" />
        {t("出品")}
      </>
    );
  }
  if (name == "入札") {
    return (
      <>
        <i className="fa-solid fa-gavel fa-fw me-1" />
        {t("入札")}
      </>
    );
  }
  if (name == "落札") {
    return (
      <>
        <i className="fa-solid fa-gavel fa-fw me-1" />
        {t("落札")}
      </>
    );
  }

  if (name == "交換") {
    return (
      <>
        <i className="fa-solid fa-down-left-and-up-right-to-center fa-fw text-muted me-1" />
        {t("交換")}
      </>
    );
  }
  if (name == "預入") {
    return (
      <>
        <i
          className="fa-solid fa-down-long fa-fw text-muted me-1"
          style={{
            transform: "rotate(45deg)",
          }}
        />
        {t("預入")}
      </>
    );
  }
  if (name == "送付") {
    return (
      <>
        <i
          className="fa-solid fa-up-long fa-fw text-muted me-1"
          style={{
            transform: "rotate(45deg)",
          }}
        />
        {t("送付")}
      </>
    );
  }
  if (name == "報酬") {
    return (
      <>
        <i
          className="fa-solid fa-down-long fa-fw text-muted me-1"
          style={{
            transform: "rotate(45deg)",
          }}
        />
        {t("報酬")}
      </>
    );
  }
  if (name == "支払") {
    return (
      <>
        <i
          className="fa-solid fa-up-long fa-fw text-muted me-1"
          style={{
            transform: "rotate(45deg)",
          }}
        />
        {t("支払")}
      </>
    );
  }
  if (name == "売上") {
    return (
      <>
        <i
          className="fa-solid fa-down-long fa-fw text-muted me-1"
          style={{
            transform: "rotate(45deg)",
          }}
        />
        {t("売上")}
      </>
    );
  }
  return <></>;
};
