import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import ExploreHeader from "./ExploreHeader";
import { view_collection } from "../../stores/Collections/view";
import { view_user } from "../../stores/Users/view";

import CollectionsPager from "../../views_components/PagerCollections/CollectionsPager";
import CollectionsList from "../../views_components/PagerCollections/CollectionsList";

import ItemsPager from "../../views_components/PagerItems/ItemsPager";
import ItemsList from "../../views_components/PagerItems/ItemsList";

import UsersPager from "../../views_components/PagerUsers/UsersPager";
import UsersList from "../../views_components/PagerUsers/UsersList";
import Footer from "../../views_components/Component/Footer";

export default function Contents({ Collections, Items, Users, Page }) {
  const { type } = Page;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();
  const { user_id } = useParams();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_users = useSelector((state) => state.reduce_get_users);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const collection = useSelector((state) => state.reduce_get_items.collection);
  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection);

  const user = reduce_get_users.user;
  const v_user = view_user(user);

  return (
    <>
      <div>
        {Header(Page)}
        <ExploreHeader Page={Page} />
        <div className="container container-main">
          <main className="main">
            {type == "collections" && (
              <>
                <CollectionsPager Collections={Collections} />
                <div className="ms-md-3 mt-0">
                  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-2 g-md-3 mb-3">
                    <CollectionsList Collections={Collections} />
                  </div>
                </div>
                <CollectionsPager Collections={Collections} isFooter={true} />
              </>
            )}
            {type == "items" && (
              <>
                <ItemsPager Items={Items} />
                <div className="ms-md-3 mt-0">
                  <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5 g-2 g-sm-3">
                    <ItemsList Items={Items} />
                  </div>
                </div>
                <ItemsPager Items={Items} isFooter={true} />
              </>
            )}

            {type == "users" && (
              <>
                <UsersPager Users={Users} />
                <div className="ms-md-3 mt-0">
                  <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-3">
                    {/* <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5 g-2 g-sm-3"> */}
                    <UsersList Users={Users} />
                  </div>
                </div>
                <UsersPager Users={Users} isFooter={true} />
              </>
            )}
          </main>
        </div>
        <Footer />
      </div>
    </>
  );
}
