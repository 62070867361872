import React, { FC, useEffect, useRef, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  zeroPadding,
} from "config/util";
import { getSearch, setSearch } from "config/util";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";

import CustomModal from "components/CustomModal/CustomModal.js";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Search from "@material-ui/icons/Search";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import { useDispatch, useSelector } from "react-redux";

import { getMessage } from "config/util";
import {
  get_collections,
  get_collections_id,
  delete_collections_id,
} from "stores/Collections/actions";

const App = ({ branch, children }) => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  const location_search = useLocation().search;
  const propsSearch = getSearch(location_search) ?? {};

  const { category_id } = useParams();

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const [propsCollections, set_propsCollections] = useState({
    search: {
      active_flg: true,
      category_id,
      page: 1,
      limit: 12,
      sort: [],
      myself: branch?.myself,
      ...branch?.search,
      ...propsSearch?.search,
    },
  });

  const dispatch_get_collections = (data, next, prev) => {
    dispatch(
      get_collections({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_collections = (search, page = 1) => {
    dispatch_get_collections({
      search: { ...search, page },
    });
    set_propsCollections({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    set_get_collections({ ...propsCollections.search, category_id });
  }, [category_id]);

  const maps = useSelector((state) => state.reduce_get_maps);

  const newchildren = React.cloneElement(children, {
    Collections: {
      propsCollections,
      set_propsCollections,
      dispatch_gets: set_get_collections,
      list: "explore",
      branch,
    },
  });

  return (
    <>
      <Helmet>
        <title>tomonity</title>
      </Helmet>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      {newchildren}
    </>
  );
};

export default App;
