import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";
import { getMessage } from "config/util";
import { HeaderHot, RowHot } from "../PagerCollections/_hot";

import { view_top_collection } from "../../stores/Top/view";
import { put_collections_id_favorite } from "stores/Collections/actions";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

export default function Dashboard(TopCollections) {
  const {
    propsTopCollections,
    set_propsTopCollections,
    dispatch_gets,
    dispatch_type,
    type,
    mypage,
    list,
    user_id,
  } = TopCollections;

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const maps = useSelector((state) => state.reduce_get_maps);

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_put_collections_id_favorite = (data, next, prev) => {
    dispatch(
      put_collections_id_favorite({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const datalist = useSelector((state) => state.reduce_get_top_collections.collections2);
  const loading_collections = useSelector((state) => state.reduce_get_collections.isLoading);
  const loadingId = useSelector((state) => state.reduce_get_collections.isLoadingId);

  let loading = loading_collections;

  if (loading)
    return (
      <>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-2 g-xl-5 mb-3">
          <CustomLinearProgress color="primary" />
        </div>
      </>
    );
  if (!datalist) return <></>;
  if (datalist.length == 0) return <></>;

  const chunk = (ary, number) => {
    const length = Math.ceil(ary.length / number);
    return new Array(length).fill().map((_, i) => ary.slice(i * number, (i + 1) * number));
  };

  const datalist_ary = chunk(datalist, 4);

  const datalist_ary_0 = datalist_ary[0] ?? [];

  TopCollections.t = t;
  TopCollections.history = history;

  // "collection_name": "...AND PEGGY",
  // "collection_id": "b63a4315-3c3a-4b9d-9e82-ad1880496ec4",
  // "sum_amount": "4.000000000000",
  // "sum_price": "1.200000000000",
  // "distinct_price": 2,
  // "percent": "-76.92"

  return (
    <>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-2 g-md-3 mb-3">
        {HeaderHot(TopCollections)}
        {datalist_ary_0.map((prop, idx) => {
          const v_collection = view_top_collection(prop, maps, t);
          TopCollections.v_collection = v_collection;
          return <>{RowHot(TopCollections)}</>;
        })}
      </div>
    </>
  );
  // return (
  //   <>
  //     <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-2 g-md-3 mb-3">
  //       {datalist.map((prop, idx) => {
  //         const v_collection = view_top_collection(prop, maps, t);
  //         return <List v_collection={v_collection} />;
  //       })}
  //     </div>
  //   </>
  // );
}
