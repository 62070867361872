import React, { FC, useEffect, useRef, useState, createContext } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ChainLink } from "../components/ChainLink";
import { useDispatch, useSelector } from "react-redux";

import { get_maps } from "stores/Auth/actions";
import { get_accounts } from "stores/Accounts/actions";
import { get_wallets } from "stores/Wallets/actions";

// import Home from "../viewsAuth_material/Home/Home";
import Home from "../views_page/Home/Home";
import HomeView from "../views_page/Home/HomeView";

import Explore from "../views_page/Explore/Explore";
import ExploreView from "../views_page/Explore/ExploreView";
// import Users from "../views_page/Users/Users";
// import UsersView from "../views_page/Users/UsersView";
// import Collections from "../views_page/Collections/Collections";
// import CollectionsView from "../views_page/Collections/CollectionsView";
// import Items from "../views_page/Items/Items";
// import ItemsView from "../views_page/Items/ItemsView";

import Categories from "../views_page/Categories/Categories";
import CategoriesView from "../views_page/Categories/CategoriesView";
import Collection from "../views_page/Collection/Collection";
import CollectionView from "../views_page/Collection/CollectionView";
import Item from "../views_page/Item/Item";
import ItemView from "../views_page/Item/ItemView";

import User from "../views_page/User/User";
import UserView from "../views_page/User/UserView";

import Activity from "../views_page/Activities/Activities";
import ActivityView from "../views_page/Activities/ActivitiesView";
import Rankings from "../views_page/Rankings/Rankings";
import RankingsView from "../views_page/Rankings/RankingsView";

import SignIn from "../views_auth/SignIn/SignIn";
import SignInView from "../views_auth/SignIn/SignInView";

import SignInCode from "../views_auth/SignInCode/SignInCode";
import SignInCodeView from "../views_auth/SignInCode/SignInCodeView";

import SignUp from "../views_auth/SignUp/SignUp";
import SignUpView from "../views_auth/SignUp/SignUpView";

import SignUpCode from "../views_auth/SignUpCode/SignUpCode";
import SignUpCodeView from "../views_auth/SignUpCode/SignUpCodeView";

import PasswordRecovery from "../views_auth/PasswordRecovery/PasswordRecovery";
import PasswordRecoveryView from "../views_auth/PasswordRecovery/PasswordRecoveryView";
import PasswordRecoveryCode from "../views_auth/PasswordRecoveryCode/PasswordRecoveryCode";
import PasswordRecoveryCodeView from "../views_auth/PasswordRecoveryCode/PasswordRecoveryCodeView";
import PasswordReset from "../views_auth/PasswordReset/PasswordReset";
import PasswordResetView from "../views_auth/PasswordReset/PasswordResetView";

import Contact from "../views_page/Contact/Contact";
import ContactView from "../views_page/Contact/ContactView";

// import MyHome from "../views_account_page/MyHome/MyHome";
// import MyHomeView from "../views_account_page/MyHome/MyHomeView";

// import MyPage from "../views_account_page/MyPage/MyPage";
// import MyPageView from "../views_account_page/MyPage/MyPageView";

// import MyCollection from "../views_account_page/MyCollection/MyCollection";
// import MyCollectionView from "../views_account_page/MyCollection/MyCollectionView";
import MyCollectionCreate from "../views_account_page/MyCollectionCreate/MyCollectionCreate";
import MyCollectionCreateView from "../views_account_page/MyCollectionCreate/MyCollectionCreateView";

import MyCollaborateCreate from "../views_account_page/MyCollaborateCreate/MyCollaborateCreate";
import MyCollaborateCreateView from "../views_account_page/MyCollaborateCreate/MyCollaborateCreateView";

import MyCollectionEdit from "../views_account_page/MyCollectionEdit/MyCollectionEdit";
import MyCollectionEditView from "../views_account_page/MyCollectionEdit/MyCollectionEditView";

// import MyItem from "../views_account_page/MyItem/MyItem";
// import MyItemView from "../views_account_page/MyItem/MyItemView";
import MyItemCreate from "../views_account_page/MyItemCreate/MyItemCreate";
import MyItemCreateView from "../views_account_page/MyItemCreate/MyItemCreateView";
import MyItemEdit from "../views_account_page/MyItemEdit/MyItemEdit";
import MyItemEditView from "../views_account_page/MyItemEdit/MyItemEditView";
import MyItemSell from "../views_account_page/MyItemSell/MyItemSell";
import MyItemSellView from "../views_account_page/MyItemSell/MyItemSellView";
import MyItemSend from "../views_account_page/MyItemSend/MyItemSend";
import MyItemSendView from "../views_account_page/MyItemSend/MyItemSendView";

// import MyFavorites from "../views_account_page/MyFavorites/MyFavorites";
// import MyFavoritesView from "../views_account_page/MyFavorites/MyFavoritesView";

import MyWallet from "../views_account_page/MyWallet/MyWallet";
import MyWalletView from "../views_account_page/MyWallet/MyWalletView";

import MyWalletDetail from "../views_account_page/MyWalletDetail/MyWalletDetail";
import MyWalletDetailView from "../views_account_page/MyWalletDetail/MyWalletDetailView";

import AccountsAuth from "../views_account/AccountsAuth/AccountsAuth";
import AccountsAuthView from "../views_account/AccountsAuth/AccountsAuthView";
import AccountsAuthCode from "../views_account/AccountsAuthCode/AccountsAuthCode";
import AccountsAuthCodeView from "../views_account/AccountsAuthCode/AccountsAuthCodeView";
import AccountsTotp from "../views_account/AccountsTotp/AccountsTotp";
import AccountsTotpView from "../views_account/AccountsTotp/AccountsTotpView";

import AccountsSecurity from "../views_account/AccountsSecurity/AccountsSecurity";
import AccountsSecurityView from "../views_account/AccountsSecurity/AccountsSecurityView";
import AccountsExtra from "../views_account/AccountsExtra/AccountsExtra";
import AccountsExtraView from "../views_account/AccountsExtra/AccountsExtraView";
import AccountsImage from "../views_account/AccountsImage/AccountsImage";
import AccountsImageView from "../views_account/AccountsImage/AccountsImageView";
import AccountsKyc from "../views_account/AccountsKyc/AccountsKyc";
import AccountsKycView from "../views_account/AccountsKyc/AccountsKycView";

// import AccountInfo from "./AccountInfo";

import { initReactI18next, useTranslation } from "react-i18next";

export const host = window.location.host;
export const home = "/home";
export const login = "/signin";
// export const dashboard = "/nft-tokens/:nft_token_id";
export const dashboard = "/home";
export const holderadmin = "/home";
export const holderuser = "/home";
export const DisplayContext = createContext();

export const backward = (token) => {
  if (!token) {
    const pathname = window.location.pathname;
    sessionStorage.setItem("pathname", pathname);
    window.location.href = window.location.origin + login;
  }
};

export const AppRouter = () => {
  const dispatch = useDispatch();

  const dispatch_get_maps = (data, next, prev) => {
    dispatch(
      get_maps({
        data,
        prev: (err) => {
          // toast(err);
          // !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_get_accounts = (data, next, prev) => {
    dispatch(
      get_accounts({
        data,
        prev: (err) => {
          // toast(err);
          // !prev || prev(err);
        },
        next,
      })
    );
  };
  const dispatch_get_wallets = (data, next, prev) => {
    dispatch(
      get_wallets({
        data,
        prev: (err) => {
          // toast(err);
          // !prev || prev(err);
        },
        next,
      })
    );
  };

  // reduce_post_authinが変更する行為を行うと、You cannot change <Router routes>

  // const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  // useEffect(() => {
  //   if (reduce_post_authin.token) {
  //     dispatch_get_accounts({}, () => {});
  //     dispatch_get_wallets({}, () => {});
  //   }
  //   // }, [reduce_post_authin.token]);
  // }, [reduce_post_authin.token]);

  useEffect(() => {
    dispatch_get_maps({}, () => {});
    dispatch_get_accounts({}, () => {});
    dispatch_get_wallets({}, () => {});

    const pathname = window.location.pathname;
    if (!sessionStorage.getItem("PATH_FIRST")) {
      sessionStorage.setItem("PATH_FIRST", pathname);
    }
  }, []);

  const reduce_get_maps = useSelector((state) => state.reduce_get_maps);

  if (reduce_get_maps.isLoading) {
    return <></>;
  }

  return <>{RouterList()}</>;
};

let innerHeight = window.innerHeight;
let outerHeight = window.outerHeight;
let windowHeight = innerHeight;
let tableHeight = 44 + 53 * 10 + 16;

const RouterList = () => {
  const hist = createBrowserHistory();
  return (
    <DisplayContext.Provider value={{ windowHeight, tableHeight }}>
      <Router history={hist}>
        <Switch>
          <Route
            exact
            path="/signin"
            render={(props) => (
              <SignIn {...props} branch={{}}>
                <SignInView />
              </SignIn>
            )}
          />
          <Route
            exact
            path="/signincode"
            render={(props) => (
              <SignInCode {...props} branch={{}}>
                <SignInCodeView />
              </SignInCode>
            )}
          />
          <Route
            exact
            path="/password-recovery"
            render={(props) => (
              <PasswordRecovery {...props} branch={{}}>
                <PasswordRecoveryView />
              </PasswordRecovery>
            )}
          />
          <Route
            exact
            path="/password-recovery-code"
            render={(props) => (
              <PasswordRecoveryCode {...props} branch={{}}>
                <PasswordRecoveryCodeView />
              </PasswordRecoveryCode>
            )}
          />
          <Route
            exact
            path="/password-reset"
            render={(props) => (
              <PasswordReset {...props} branch={{}}>
                <PasswordResetView />
              </PasswordReset>
            )}
          />
          <Route
            exact
            path="/contact"
            render={(props) => (
              <Contact {...props} branch={{}}>
                <ContactView />
              </Contact>
            )}
          />
          <Route exact path="/" component={PushHome} />
          <Route
            exact
            path="/home"
            render={(props) => (
              <Home {...props} branch={{}}>
                <HomeView />
              </Home>
            )}
          />
          <Route
            exact
            path="/index.html"
            render={(props) => (
              <Home {...props} branch={{}}>
                <HomeView />
              </Home>
            )}
          />
          <Route
            exact
            path="/signup"
            render={(props) => (
              <SignUp {...props} branch={{}}>
                <SignUpView />
              </SignUp>
            )}
          />
          <Route
            exact
            path="/signupcode"
            render={(props) => (
              <SignUpCode {...props} branch={{}}>
                <SignUpCodeView />
              </SignUpCode>
            )}
          />
          {/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */}
          {/* <Route
            exact
            path="/collections"
            render={(props) => (
              <Collections {...props} branch={{}}>
                <CollectionsView />
              </Collections>
            )}
          /> */}
          {/* <Route
            exact
            path="/collections/:collection_id/items"
            // exact path="/collection"
            render={(props) => (
              <Collection {...props} branch={{}}>
                <CollectionView />
              </Collection>
            )}
          /> */}
          <Route
            exact
            path="/collections/:collection_id/items"
            render={(props) => (
              <Collection {...props} branch={{ type: "items" }}>
                <CollectionView />
              </Collection>
            )}
          />
          <Route
            exact
            path="/collections/:collection_id/activities"
            render={(props) => (
              <Collection {...props} branch={{ type: "activities" }}>
                <CollectionView />
              </Collection>
            )}
          />
          <Route
            exact
            path="/collections/edit/:collection_id"
            render={(props) => (
              <MyCollectionEdit {...props} branch={{}}>
                <MyCollectionEditView />
              </MyCollectionEdit>
            )}
          />



          <Route
            exact
            path="/collections/create"
            render={(props) => (
              <MyCollectionCreate {...props} branch={{}}>
                <MyCollectionCreateView />
              </MyCollectionCreate>
            )}
          />
          <Route
            exact
            path="/collaborate/create"
            render={(props) => (
              <MyCollaborateCreate {...props} branch={{}}>
                <MyCollaborateCreateView />
              </MyCollaborateCreate>
            )}
          />
          
          {/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */}
          {/* <Route
            exact
            path="/items"
            render={(props) => (
              <Items {...props} branch={{}}>
                <ItemsView />
              </Items>
            )}
          /> */}
          <Route
            exact
            path="/items/:item_id"
            render={(props) => (
              <Item {...props} branch={{}}>
                <ItemView />
              </Item>
            )}
          />
          <Route
            exact
            path="/items/:collection_id/create"
            render={(props) => (
              <MyItemCreate {...props} branch={{}}>
                <MyItemCreateView />
              </MyItemCreate>
            )}
          />
          <Route
            exact
            path="/items/:item_id/edit"
            render={(props) => (
              <MyItemEdit {...props} branch={{}}>
                <MyItemEditView />
              </MyItemEdit>
            )}
          />
          <Route
            exact
            path="/items/:item_id/sell"
            render={(props) => (
              <MyItemSell {...props} branch={{}}>
                <MyItemSellView />
              </MyItemSell>
            )}
          />
          <Route
            exact
            path="/items/:item_id/send"
            render={(props) => (
              <MyItemSend {...props} branch={{}}>
                <MyItemSendView />
              </MyItemSend>
            )}
          />
          {/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */}
          <Route
            exact
            path="/explore"
            render={(props) => (
              <Explore {...props} branch={{}}>
                <ExploreView />
              </Explore>
            )}
          />
          {/* <Route
            exact
            path="/users"
            render={(props) => (
              <Users {...props} branch={{}}>
                <UsersView />
              </Users>
            )}
          /> */}
          <Route
            exact
            path="/users/:user_id"
            render={(props) => (
              <User {...props} branch={{ type: "items" }}>
                <UserView />
              </User>
            )}
          />
          <Route
            exact
            path="/users/:user_id/created"
            render={(props) => (
              <User {...props} branch={{ type: "created" }}>
                <UserView />
              </User>
            )}
          />
          <Route
            exact
            path="/users/:user_id/collections"
            render={(props) => (
              <User {...props} branch={{ type: "collections" }}>
                <UserView />
              </User>
            )}
          />
          <Route
            exact
            path="/users/:user_id/items"
            render={(props) => (
              <User {...props} branch={{ type: "items" }}>
                <UserView />
              </User>
            )}
          />
          <Route
            exact
            path="/users/:user_id/favoriteitems"
            render={(props) => (
              <User {...props} branch={{ type: "favoriteitems" }}>
                <UserView />
              </User>
            )}
          />
          <Route
            exact
            path="/users/:user_id/favoritecollections"
            render={(props) => (
              <User {...props} branch={{ type: "favoritecollections" }}>
                <UserView />
              </User>
            )}
          />
          <Route
            exact
            path="/users/:user_id/followers"
            render={(props) => (
              <User {...props} branch={{ type: "followers" }}>
                <UserView />
              </User>
            )}
          />
          <Route
            exact
            path="/users/:user_id/followings"
            render={(props) => (
              <User {...props} branch={{ type: "followings" }}>
                <UserView />
              </User>
            )}
          />
          <Route
            exact
            path="/users/:user_id/activities"
            render={(props) => (
              <User {...props} branch={{ type: "activities" }}>
                <UserView />
              </User>
            )}
          />
          <Route
            exact
            path="/users/:user_id/itemoffers"
            render={(props) => (
              <User {...props} branch={{ type: "itemoffers" }}>
                <UserView />
              </User>
            )}
          />
          {/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */}
          <Route
            exact
            path="/wallets"
            render={(props) => (
              <MyWallet {...props} branch={{}}>
                <MyWalletView />
              </MyWallet>
            )}
          />
          <Route
            exact
            path="/wallets/:coin"
            render={(props) => (
              <MyWalletDetail {...props} branch={{}}>
                <MyWalletDetailView />
              </MyWalletDetail>
            )}
          />
          {/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */}
          <Route
            exact
            path="/categories"
            render={(props) => (
              <Categories {...props} branch={{}}>
                <CategoriesView />
              </Categories>
            )}
          />
          <Route
            exact
            path="/categories/id/:category_id"
            // exact path="/collection"
            render={(props) => (
              <Categories {...props} branch={{}}>
                <CategoriesView />
              </Categories>
            )}
          />
          {/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */}
          <Route
            exact
            path="/activities"
            render={(props) => (
              <Activity {...props} branch={{}}>
                <ActivityView />
              </Activity>
            )}
          />
          <Route
            exact
            path="/rankings"
            render={(props) => (
              <Rankings {...props} branch={{}}>
                <RankingsView />
              </Rankings>
            )}
          />
          {/* ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */}
          <AccountRoute />
        </Switch>
      </Router>
    </DisplayContext.Provider>
  );
};

export const AccountRoute = () => {
  const [t, i18n] = useTranslation();

  return (
    <>
      {/* <AccountInfo /> */}
      <Account>
        <Route
          exact
          path="/account-user"
          render={(props) => (
            <AccountsAuth {...props} branch={{ method: "user" }}>
              <AccountsAuthView />
            </AccountsAuth>
          )}
        />
        <Route
          exact
          path="/account-user-code"
          render={(props) => (
            <AccountsAuthCode {...props} branch={{ method: "user" }}>
              <AccountsAuthCodeView />
            </AccountsAuthCode>
          )}
        />
        <Route
          exact
          path="/account-mail"
          render={(props) => (
            <AccountsAuth {...props} branch={{ method: "mail" }}>
              <AccountsAuthView />
            </AccountsAuth>
          )}
        />
        <Route
          exact
          path="/account-mail-code"
          render={(props) => (
            <AccountsAuthCode {...props} branch={{ method: "mail" }}>
              <AccountsAuthCodeView />
            </AccountsAuthCode>
          )}
        />
        <Route
          exact
          path="/account-phone"
          render={(props) => (
            <AccountsAuth {...props} branch={{ method: "phone" }}>
              <AccountsAuthView />
            </AccountsAuth>
          )}
        />
        <Route
          exact
          path="/account-phone-code"
          render={(props) => (
            <AccountsAuthCode {...props} branch={{ method: "phone" }}>
              <AccountsAuthCodeView />
            </AccountsAuthCode>
          )}
        />
        <Route
          exact
          path="/account-password"
          render={(props) => (
            <AccountsAuth {...props} branch={{ method: "password" }}>
              <AccountsAuthView />
            </AccountsAuth>
          )}
        />
        <Route
          exact
          path="/account-password-code"
          render={(props) => (
            <AccountsAuthCode {...props} branch={{ method: "password" }}>
              <AccountsAuthCodeView />
            </AccountsAuthCode>
          )}
        />
        <Route
          exact
          path="/account-totp"
          render={(props) => (
            <AccountsTotp {...props} branch={{}}>
              <AccountsTotpView />
            </AccountsTotp>
          )}
        />
        <Route
          exact
          path="/account-security"
          render={(props) => (
            <AccountsSecurity {...props} branch={{}}>
              <AccountsSecurityView />
            </AccountsSecurity>
          )}
        />
        <Route
          exact
          path="/account-extra"
          render={(props) => (
            <AccountsExtra {...props} branch={{}}>
              <AccountsExtraView />
            </AccountsExtra>
          )}
        />
        <Route
          exact
          path="/account-image"
          render={(props) => (
            <AccountsImage {...props} branch={{}}>
              <AccountsImageView />
            </AccountsImage>
          )}
        />
        <Route
          exact
          path="/account-kyc"
          render={(props) => (
            <AccountsKyc {...props} branch={{}}>
              <AccountsKycView />
            </AccountsKyc>
          )}
        />
      </Account>
    </>
  );
};

export const Account = (props) => {
  // || reduce_get_accounts.isLoading
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  if (reduce_post_authin.token) {
    return props.children;
  } else {
    // return <></>
    // const pathname = window.location.pathname;
    // if (!sessionStorage.getItem("pathname")) {
    //   sessionStorage.setItem("pathname", pathname);
    // }

    return <Redirect to={login} />;
  }
};

const PushHome = (prop) => {
  useEffect(() => {
    prop.history.push(home);
  }, []);

  return <></>;
};
