import { createAction, createReducer } from 'redux-act';

export const get_items_id_bids = createAction("GET_ITEMS_ID_BIDS");
export const get_items_id_bids_data = createAction("GET_ITEMS_ID_BIDS_DATA");
export const get_items_id_bids_error = createAction("GET_ITEMS_ID_BIDS_ERROR");
export const get_items_id_bids_id = createAction("GET_ITEMS_ID_BIDS_ID");
export const get_items_id_bids_id_data = createAction("GET_ITEMS_ID_BIDS_ID_DATA");
export const get_items_id_bids_id_error = createAction("GET_ITEMS_BIDS_ID_ERROR");

export const post_items_id_bids = createAction("POST_ITEMS_ID_BIDS");
export const post_items_id_bids_data = createAction("POST_ITEMS_ID_BIDS_DATA");
export const post_items_id_bids_error = createAction("POST_ITEMS_ID_BIDS_ERROR");

export const put_items_id_bids_id = createAction("PUT_ITEMS_ID_BIDS_ID");
export const put_items_id_bids_id_data = createAction("PUT_ITEMS_ID_BIDS_ID_DATA");
export const put_items_id_bids_id_error = createAction("PUT_ITEMS_ID_BIDS_ID_ERROR");

export const delete_items_id_bids_id = createAction("DELETE_ITEMS_ID_BIDS_ID");
export const delete_items_id_bids_id_data = createAction("DELETE_ITEMS_ID_BIDS_ID_DATA");
export const delete_items_id_bids_id_error = createAction("DELETE_ITEMS_ID_BIDS_ID_ERROR");