import React from "react";

function ImageUpload({ image_path, set_image_path, mime_type, title, id, minHeight }) {
  const height = minHeight ? minHeight : "190px";
  let type = null;

  switch (mime_type) {
    case "image/jpeg":
    case "image/png":
    case "image/svg+xml":
    case "image/gif":
    case "image/webp":
      type = "photo";
      break;
    case "audio/mpeg":
      type = "music";
      break;
    case "video/mp4":
      type = "video";
      break;
    default:
      break;
  }
  const fileInput = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        set_image_path(reader.result, file);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleClick = () => {
    fileInput.current.click();
  };

  switch (type) {
    case "photo":
      const imgdiv = (
        <div
          className="form-file border rounded-2 p-2 d-flex flex-column justify-content-center"
          style={{
            minHeight: height,
          }}
        >
          <div className="mb-2">
            <span className="text-muted">{title}</span>
          </div>
          {image_path && id == "collectionLogo" && (
            <div className="row justify-content-center my-auto">
              <div className="col-4 col-sm-5">
                <label htmlFor={id} className="ratio ratio-1x1 icon icon-square">
                  <img
                    src={image_path}
                    className="icon-item"
                    alt
                    style={{ height: "100%", width: "100%" }}
                  />
                </label>
                <input type="file" id={id} onChange={handleImageChange} ref={fileInput} />
              </div>
            </div>
          )}
          {image_path && id == "collaboLogo" && (
            <div className="row justify-content-center my-auto">
              <div className="col-4 col-lg-7 col-xl-6 col-xxl-5">
                {/* <label htmlFor={id} className="ratio ratio-1x1 icon icon-square"> */}
                <label htmlFor={id}>
                  <div class="mask-hex">
                    <figure
                      class="object-item ratio ratio-1x1"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      data-bs-original-title="ハンドシェル"
                    >
                      <img src={image_path} class="icon-item" alt="" />
                    </figure>
                  </div>
                  {/* <img
                    src={image_path}
                    className="icon-item"
                    alt
                    style={{ height: "100%", width: "100%" }}
                  /> */}
                </label>
                <input type="file" id={id} onChange={handleImageChange} ref={fileInput} />
              </div>
            </div>
          )}
          {image_path && id == "collectionCover" && (
            <div className="row justify-content-center my-auto">
              <div className="col-9 col-lg-12">
                <label htmlFor={id} className="ratio ratio-1x3 cover">
                  <img src={image_path} className="cover-item" alt />
                </label>
                <input type="file" id={id} onChange={handleImageChange} ref={fileInput} />
              </div>
            </div>
          )}
        </div>
      );
      return <>{imgdiv}</>;

    default:
      return (
        <>
          <div
            className="form-file border rounded-2 p-2 d-flex flex-column justify-content-center"
            style={{
              minHeight: height,
            }}
          >
            <div className="mb-2">
              <span className="text-muted">{title}</span>
            </div>

            <div className="d-flex justify-content-center my-auto">
              <label htmlFor={id} className="btn btn-link bg-light text-secondary py-4 px-5 m-0">
                <i className="fa-solid fa-file-arrow-up fa-2x" />
              </label>
              <input type="file" id={id} required onChange={handleImageChange} ref={fileInput} />
            </div>
          </div>
        </>
      );
  }
}

export default ImageUpload;
