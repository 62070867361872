import { fork, take, put, join, select, takeLatest, takeEvery, call } from "redux-saga/effects";

import { post_authout } from "../Auth/actions";

import {
  api_get_items_id_bids,
  api_get_items_id_bids_id,
  api_post_items_id_bids,
  api_put_items_id_bids_id,
  api_delete_items_id_bids_id,
} from "./api";

import {
  get_items_id_bids,
  get_items_id_bids_data,
  get_items_id_bids_error,
  get_items_id_bids_id,
  get_items_id_bids_id_data,
  get_items_id_bids_id_error,
  post_items_id_bids,
  post_items_id_bids_data,
  post_items_id_bids_error,
  put_items_id_bids_id,
  put_items_id_bids_id_data,
  put_items_id_bids_id_error,
  delete_items_id_bids_id,
  delete_items_id_bids_id_data,
  delete_items_id_bids_id_error,
} from "./actions";



export function* run_get_items_id_bids({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_items_id_bids, payload.data, token);
  if (!error) {
    yield put(get_items_id_bids_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_items_id_bids_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_items_id_bids_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_items_id_bids_id, payload.data, token);
  if (!error) {
    yield put(get_items_id_bids_id_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_items_id_bids_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_items_id_bids({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_items_id_bids, payload.data, token);
  if (!error) {
    yield put(post_items_id_bids_data({ data }));
    yield put(get_items_id_bids(payload));
  } else {
    yield put(post_items_id_bids_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_items_id_bids_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_items_id_bids_id, payload.data, token);
  if (!error) {
    yield put(put_items_id_bids_id_data({ data }));
    yield put(get_items_id_bids_id(payload));
  } else {
    yield put(put_items_id_bids_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_items_id_bids_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_delete_items_id_bids_id, payload.data, token);
  if (!error) {
    yield put(delete_items_id_bids_id_data({ data }));
    yield put(get_items_id_bids(payload));
  } else {
    yield put(delete_items_id_bids_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}


export function* flow_get_items_id_bids() {
  yield takeLatest(get_items_id_bids, run_get_items_id_bids);
}
export function* flow_get_items_id_bids_id() {
  yield takeLatest(get_items_id_bids_id, run_get_items_id_bids_id);
}
export function* flow_post_items_id_bids() {
  yield takeLatest(post_items_id_bids, run_post_items_id_bids);
}
export function* flow_put_items_id_bids_id() {
  yield takeLatest(put_items_id_bids_id, run_put_items_id_bids_id);
}
export function* flow_delete_items_id_bids_id() {
  yield takeLatest(delete_items_id_bids_id, run_delete_items_id_bids_id);
}
export default function* rootSaga() {
  yield fork(flow_get_items_id_bids);
  yield fork(flow_get_items_id_bids_id);
  yield fork(flow_post_items_id_bids);
  yield fork(flow_put_items_id_bids_id);
  yield fork(flow_delete_items_id_bids_id);
}
