import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import { QRCodeSVG } from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Header from "../../views_components/Component/Header";

import ActivitiesPager from "../../views_components/PagerActivities/ActivitiesPager";
import ActivitiesList from "../../views_components/PagerActivities/ActivitiesList";
import Footer from "../../views_components/Component/Footer";

export default function Contents({ Wallet, Activities }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const { coin } = useParams();

  const [active_tab, set_active_tab] = useState("預入");

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_wallets = useSelector((state) => state.reduce_get_wallets);
  const maps = useSelector((state) => state.reduce_get_maps);

  const coin_decimals = maps.coin_decimals;
  const coins = maps.coins;

  useEffect(() => {}, []);
  const coin_fullname = coins[coin];

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  return (
    <>
      <div>
        <Header />
        <div className="container-main">
          <main className="main mt-4 mb-3">
            <div className="container main-toolbar align-items-center pb-0 pb-md-4">
              <h2 className="mb-0 text-truncate"> {t("アクティビティ")}</h2>
            </div>
            <hr className="border-bottom border-dark border-2 mb-2 d-md-none" />
            <div className="container activitys">
              <ActivitiesPager Activities={Activities} />
              <ActivitiesList Activities={Activities} />
              <ActivitiesPager Activities={Activities} isFooter={true} />
            </div>
          </main>
        </div>
        <Footer />
        {/* <div action="collections.html">
          <div
            className="modal fade"
            id="searchField"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="searchFieldLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
              <div className="modal-content">
                <div className="modal-header">
                  {}
                  <button className="btn" type="button" data-bs-dismiss="modal" aria-label="Close">
                    <i className="fa-solid fa-arrow-left fa-fw" />
                  </button>
                  <input
                    type="search"
                    id="searchFieldInput"
                    className="form-control"
                    placeholder="\u30A2\u30A4\u30C6\u30E0\u3001\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3\u3001\u30E6\u30FC\u30B6\u3092\u691C\u7D22"
                    aria-label="Search"
                    autoFocus
                  />
                  {}
                </div>
                <div className="modal-body p-0 bg-light">
                  {}
                  <h6 className="px-3 mt-4 mb-2">保存した検索条件</h6>
                  <div className="list-group">
                    <a href="#" className="list-group-item list-group-item-action d-flex p-3">
                      ラプンツェル
                      <small className="badge bg-dark text-white ms-1">コレクション</small>
                      <i className="fa-solid fa-angle-right ms-auto" />
                    </a>
                    <a href="#" className="list-group-item list-group-item-action d-flex p-3">
                      プリンセス
                      <small className="badge bg-dark text-white ms-1">コレクション</small>
                      <i className="fa-solid fa-angle-right ms-auto" />
                    </a>
                  </div>
                  <h6 className="px-3 mt-3 mb-2">直近の検索履歴</h6>
                  <div className="list-group">
                    <a href="#" className="list-group-item list-group-item-action d-flex p-3">
                      ディズニー
                      <small className="badge bg-dark text-white ms-1">コレクション</small>
                      <i className="fa-solid fa-angle-right ms-auto" />
                    </a>
                    <a href="#" className="list-group-item list-group-item-action d-flex p-3">
                      ディズニー
                      <small className="badge bg-dark text-white ms-1">ユーザ</small>
                      <i className="fa-solid fa-angle-right ms-auto" />
                    </a>
                    <a href="#" className="list-group-item list-group-item-action d-flex p-3">
                      プリンセス
                      <i className="fa-solid fa-angle-right ms-auto" />
                    </a>
                    <a href="#" className="list-group-item list-group-item-action d-flex p-3">
                      ラプンツェル
                      <small className="badge bg-dark text-white ms-1">コレクション</small>
                      <i className="fa-solid fa-angle-right ms-auto" />
                    </a>
                    <a href="#" className="list-group-item list-group-item-action d-flex p-3">
                      スーパーブラシ
                      <i className="fa-solid fa-angle-right ms-auto" />
                    </a>
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  {}
                  <button type="submit" className="btn btn-primary rounded-pill px-4" role="button">
                    検索
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
