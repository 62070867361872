import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import Menu from "./Menu";
import Logout from "./Logout";
import { view_user } from "../../stores/Users/view";
import LiLanguage from "components/Li/LiLanguage";

export default function Contents() {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  const user = reduce_get_accounts.user;
  const v_user = view_user(user);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts?.user?.mail_address;
  const user_name = reduce_get_accounts?.user?.username;
  return (
    <>
      <footer className="border-top bg-light">
        <div className="container py-5">
          <div className="row g-3">
            <div className="col-12 col-lg-4 col-xl-3 mb-3">
              <div className="d-grid justify-content-center justify-content-md-start mb-4 mb-md-3">
                <h5>
                  <a
                    className="tmty-brand tmty-brand-lg"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/home`,
                      });
                    }}
                  >
                    <img
                      src="/dist/tmty/assets/brand/tomonity-typography.webp"
                      width="auto"
                      height="auto"
                      alt="tomonity"
                    />
                  </a>
                </h5>
              </div>
              <p>
                {t("仮装通貨ウォレット不要で利用できる。")}
                <br />
                {t("さらにガス代も不要で、すべてが新しいカタチのNFTマーケットプレイス。")}
                <br />
                {t("利便性向上のため複雑な操作はありません、初めての人も安心してご利用できます。")}
              </p>
            </div>
            <div className="col text-center d-none d-lg-block">
              <div className="vr h-100" />
            </div>
            <div className="col-6 col-sm-4 col-lg mb-3 ms-auto">
              <h6>{t("マーケットプレイス")}</h6>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <a
                    className="nav-link p-0 text-muted"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/home`,
                      });
                    }}
                  >
                    tmty.io
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className="nav-link p-0 text-muted"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/categories`,
                      });
                    }}
                  >
                    {t("カテゴリ")}
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className="nav-link p-0 text-muted"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/rankings`,
                      });
                    }}
                  >
                    {t("ランキング")}
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className="nav-link p-0 text-muted"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/activities`,
                      });
                    }}
                  >
                    {t("アクティビティ")}
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className="nav-link p-0 text-muted"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (token) {
                        history.push({
                          pathname: `/users/${v_user.user_id}/created`,
                        });
                      } else {
                        history.push({
                          pathname: `/signin`,
                        });
                      }
                    }}
                  >
                    {t("マイページ")}
                  </a>
                </li>
                {!token && (
                  <li className="nav-item mb-2">
                    <a
                      className="nav-link p-0 text-muted"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push({
                          pathname: `/signup`,
                        });
                      }}
                    >
                      {t("アカウント登録")}
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="col-6 col-sm-4 col-lg mb-3">
              <h6>{t("リソース")}</h6>
              <ul className="nav flex-column">
                {/* <li className="nav-item mb-2">
                  <a
                    className="nav-link p-0 text-muted"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`https://help.tmty.io/using/`, "_blank", "noreferrer");
                    }}
                  >
                    tomonityガイド
                  </a>
                </li> */}
                <li className="nav-item mb-2">
                  <a
                    className="nav-link p-0 text-muted"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`https://help.tmty.io`, "_blank", "noreferrer");
                    }}
                  >
                    {t("ヘルプセンター")}
                  </a>
                </li>
                {/* <li className="nav-item mb-2">
                  <a
                    className="nav-link p-0 text-muted"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/home`,
                      });
                    }}
                  >
                    FAQsフォーラム
                  </a>
                </li> */}
                <li className="nav-item mb-2">
                  <a
                    className="nav-link p-0 text-muted"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`https://help.tmty.io/blog/`, "_blank", "noreferrer");
                    }}
                  >
                    {t("公式ブログ")}
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className="nav-link p-0 text-muted"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`https://help.tmty.io/company/`, "_blank", "noreferrer");
                    }}
                  >
                    {t("運営会社")}
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className="nav-link p-0 text-muted"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/contact`,
                      });
                    }}
                  >
                    {t("お問い合わせ")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col col-sm-4 col-lg mb-3">
              <div className="row row-cols-2 row-cols-sm-1">
                <div className="col">
                  <h6>{t("言語")}</h6>
                  <ul className="nav flex-column mb-3">
                    <li className="nav-item mb-2">
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn btn-outline-secondary rounded-pill w-100 dropdown-toggle"
                          id="languageNavFooter"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        >
                          {i18n.language === "ja" && "日本語"}
                          {i18n.language === "en" && "English"}
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                          <ul className="list-unstyled row row-cols-2 g-2">
                            <li>
                              <button
                                type="button"
                                className={`dropdown-item ${
                                  i18n.language === "en" ? "active" : ""
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  i18n.changeLanguage("en");
                                  sessionStorage.setItem("AcceptLanguage", "en");
                                }}
                              >
                                English
                              </button>
                            </li>
                            {/* <li>
                              <button type="button" className="dropdown-item">
                                Español
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                Français
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                Português
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                Русский
                              </button>
                            </li> */}
                            <li>
                              <button
                                type="button"
                                className={`dropdown-item ${
                                  i18n.language === "ja" ? "active" : ""
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  i18n.changeLanguage("ja");
                                  sessionStorage.setItem("AcceptLanguage", "ja");
                                }}
                              >
                                日本語
                              </button>
                            </li>
                            {/* <li>
                              <button type="button" className="dropdown-item">
                                简体中文
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                繁体中文
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                한국어
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                Tiếng Việt
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                Indonesian
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                ภาษาไทย
                              </button>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <div className="col">
                  <h6>通貨</h6>
                  <ul className="nav flex-column mb-3">
                    <li className="nav-item mb-2">
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn btn-outline-secondary rounded-pill w-100 dropdown-toggle"
                          id="currencyNavFooter"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        >
                          USD : $
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                          <ul className="list-unstyled row row-cols-2 g-2">
                            <li>
                              <button type="button" className="dropdown-item active">
                                <small>
                                  USD <span className="text-muted">—</span>{" "}
                                </small>
                                $
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                <small>
                                  CNY <span className="text-muted">—</span>{" "}
                                </small>
                                £
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                <small>
                                  JPY <span className="text-muted">—</span>{" "}
                                </small>
                                ¥
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                <small>
                                  KRW <span className="text-muted">—</span>{" "}
                                </small>
                                ₩
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                <small>
                                  EUR <span className="text-muted">—</span>{" "}
                                </small>
                                €
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                <small>
                                  GBP <span className="text-muted">—</span>{" "}
                                </small>
                                £
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                <small>
                                  HKD <span className="text-muted">—</span>{" "}
                                </small>
                                $
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                <small>
                                  IDR <span className="text-muted">—</span>{" "}
                                </small>
                                Rp
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                <small>
                                  INR <span className="text-muted">—</span>{" "}
                                </small>
                                ₹
                              </button>
                            </li>
                            <li>
                              <button type="button" className="dropdown-item">
                                <small>
                                  RUB <span className="text-muted">—</span>{" "}
                                </small>
                                ₽
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center py-3 mt-4 border-top">
            <p className="small">© 2022 tomonity team.</p>
            <ul className="list-unstyled d-flex">
              <li>
                <a
                  className="btn btn-sm btn-white m-0"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`https://help.tmty.io/privacy/`, "_blank", "noreferrer");
                  }}
                >
                  {t("プライバシーポリシー")}
                </a>
              </li>
              <li>
                <a
                  className="btn btn-sm btn-white m-0"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`https://help.tmty.io/tos/`, "_blank", "noreferrer");
                  }}
                >
                  {t("利用規約")}
                </a>
              </li>
            </ul>
          </div>
          <aside role="link">
            <ul className="list-unstyled d-flex justify-content-center">
              <li>
                <a
                  className="btn btn-white m-0"
                  href="https://twitter.com/tomonityNFT"
                  title="Twitter@tomonityNFT"
                  target="_blank"
                >
                  <i className="fa-brands fa-twitter" />
                </a>
              </li>
              <li>
                <a
                  className="btn btn-white m-0"
                  href="https://discord.gg/xBXyDgbv5k"
                  title="Discord@tomonityNFT"
                  target="_blank"
                >
                  <i className="fa-brands fa-discord" />
                </a>
              </li>
              <li>
                <a className="btn btn-white m-0" href="#">
                  <i className="fa-brands fa-github" />
                </a>
              </li>
            </ul>
            <small className="d-block text-center text-muted">Version: 0.1</small>
          </aside>
        </div>
      </footer>
    </>
  );
}
