import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { put_collections_id, get_collections_id } from "stores/Collections/actions";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import { getMessage, toMomentString } from "config/util";

import { Helmet } from "react-helmet";
import { initReactI18next, useTranslation } from "react-i18next";

import { ChainLink } from "../../components/ChainLink";

export default function Contents({ branch, children }) {
  const [t, i18n] = useTranslation();

  const maps = useSelector((state) => state.reduce_get_maps);
  const { collection_id } = useParams();

  const defaultProps = {};

  if (maps.nft_categories) {
    defaultProps.category_id = Object.keys(maps.nft_categories)[0];
  }
  const history = useHistory();

  const [propsCollection, set_propsCollection] = useState(defaultProps);

  const dispatch = useDispatch();
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");
  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if (!message) return;
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_get_collections_id = (data, next, prev) => {
    dispatch(
      get_collections_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_put_collections_id = (data, next, prev) => {
    dispatch(
      put_collections_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_collections_id = (id, idx, callback) => {
    dispatch_get_collections_id(
      {
        id,
      },
      (data) => {
        const collection = data.collection;
        set_propsCollection({
          ...collection,
          link_twitter: collection.data.link_twitter,
          link_facebook: collection.data.link_facebook,
          link_discord: collection.data.link_discord,
          link_instagram: collection.data.link_instagram,
          link_other: collection.data.link_other,
          search: propsCollection.search,
          id: id,
          idx: idx,
        });
        !callback || callback();
      }
    );
  };

  useEffect(() => {
    if (collection_id) {
      set_get_collections_id(collection_id);
    }
  }, []);

  const newchildren = React.cloneElement(children, {
    Page: { maps, t, history },
    Collection: {
      propsCollection,
      set_propsCollection,
      set_get_collections_id,
      dispatch_put_collections_id,
      branch,
    },
  });

  return (
    <>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      <ChainLink
        hrefs={
          [
            // "/dist/tmty/dist/js/vendor/mobile-side-navigation-zeynepjs/zeynep.min.css",
            // "/dist/tmty/dist/css/base.css",
            // "/dist/tmty/dist/css/ja-jp.css",
          ]
        }
      >
        <Helmet>
          <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <title>tomonity</title>
          {/* <script src="dist/material/js/particle.js"></script> */}
        </Helmet>
        {newchildren}
      </ChainLink>
    </>
  );
}
