import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import CustomDate from "components/CustomDate/CustomDate.js";
import { hideModal, showModal } from "config/util";
import Header from "../../views_components/Component/Header";
// import { view_collection } from "../../stores/Collections/view";
import { view_item } from "../../stores/Items/view";
import moment from "moment/moment";

import _main_header from "./_main_header";

import _user_md from "./_user_md";
import _user_md_none from "./_user_md_none";

import _activity_lg from "./_activity_lg";
import _activity_lg_none from "./_activity_lg_none";

import _sale_lg from "./_sale_lg";
import _sale_md_lg from "./_sale_md_lg";
import _sale_md_none from "./_sale_md_none";
import _sale_md_none2 from "./_sale_md_none2";

import _price from "./_price";
import _nav_tab from "./_nav_tab";
import _collection from "./_collection";
import _offer from "./_offer";
import _bid from "./_bid";
import _listing from "./_listing";
import _item from "./_item";

import _item_flow from "./_item_flow";
import _modal_item_offer from "./_modal_item_offer";
import _modal_item_order from "./_modal_item_order";
import _modal_item_bid from "./_modal_item_bid";
import _modal_item_offer_allow from "./_modal_item_offer_allow";
import _modal_item_offer_decline from "./_modal_item_offer_decline";
import Footer from "../../views_components/Component/Footer";

export default function Contents({ Item, Activities, ItemOffers, ItemBids }) {
  const { propsItemOffers, set_propsItemOffers, dispatch_post_items_id_offers } = ItemOffers;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  const maps = useSelector((state) => state.reduce_get_maps);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const item = useSelector((state) => state.reduce_get_items.item);
  const v_item = view_item(item, maps, t);

  Item.maps = maps;
  Item.t = t;
  Item.v_item = v_item;

  ItemOffers.maps = maps;
  ItemOffers.t = t;
  ItemOffers.v_item = v_item;

  ItemBids.maps = maps;
  ItemBids.t = t;
  ItemBids.v_item = v_item;

  return (
    <>
      <div>
        <Header />
        {_main_header(Item)}
        {_sale_md_none2(Item, ItemOffers, ItemBids)}
        <div className="container container-main">
          <div
            className="sidebar pe-md-3 pe-xl-4"
            style={{
              flexBasis: "320px",
            }}
          >
            {_user_md_none()}
            {_item()}
            {_nav_tab(Item)}
            {_collection()}
          </div>
          <main className="d-lg-flex flex-grow-1">
            <div className="main-primary flex-grow-1">
              {_user_md()}
              {_sale_md_lg(Item, ItemOffers, ItemBids)}
              {/* {_price()} */}
              {_activity_lg(Activities)}
            </div>
            <div className="main-secondary sidebar ps-lg-3 ps-xl-4">
              {_sale_lg(Item, ItemOffers, ItemBids)}
              {/* {_listing()} */}
              {_bid(ItemBids)}
              {_offer(ItemOffers)}
            </div>
            {_sale_md_none(Item, ItemOffers, ItemBids)}
          </main>
        </div>
        <div className="container d-lg-none">{_activity_lg_none(Activities)}</div>
        {/* {_item_flow()} */}
        {_modal_item_order(Item)}
        {_modal_item_offer(ItemOffers)}
        {_modal_item_bid(ItemBids)}
        {_modal_item_offer_allow(ItemOffers)}
        {_modal_item_offer_decline(ItemOffers)}
        <Footer />
      </div>
    </>
  );
}
