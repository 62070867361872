import { createAction } from "redux-act";


export const get_markets_rates = createAction("GET_MARKETS_RATES");
export const get_markets_rates_data = createAction("GET_MARKETS_RATES_DATA");
export const get_markets_rates_error = createAction("GET_MARKETS_RATES_ERROR");



export const get_markets_ohlcs = createAction("GET_MARKETS_OHLCS");
export const get_markets_ohlcs_data = createAction("GET_MARKETS_OHLCS_DATA");
export const get_markets_ohlcs_error = createAction("GET_MARKETS_OHLCS_ERROR");

export const get_markets_ohlc = createAction("GET_MARKETS_OHLC");
export const get_markets_ohlc_data = createAction("GET_MARKETS_OHLC_DATA");
export const get_markets_ohlc_error = createAction("GET_MARKETS_OHLC_ERROR");

export const get_markets_orders = createAction("GET_MARKETS_ORDERS");
export const get_markets_orders_data = createAction("GET_MARKETS_ORDERS_DATA");
export const get_markets_orders_error = createAction("GET_MARKETS_ORDERS_ERROR");

export const get_wallets = createAction("GET_WALLETS");
export const get_wallets_data = createAction("GET_WALLETS_DATA");
export const get_wallets_error = createAction("GET_WALLETS_ERROR");

export const put_wallets = createAction("PUT_WALLETS");
export const put_wallets_data = createAction("PUT_WALLETS_DATA");
export const put_wallets_error = createAction("PUT_WALLETS_ERROR");

export const post_wallets = createAction("POST_WALLETS");
export const post_wallets_data = createAction("POST_WALLETS_DATA");
export const post_wallets_error = createAction("POST_WALLETS_ERROR");

export const put_wallets_id_deposit = createAction("PUT_WALLETS_ID_DEPOSIT");
export const put_wallets_id_deposit_data = createAction("PUT_WALLETS_ID_DEPOSIT_DATA");
export const put_wallets_id_deposit_error = createAction("PUT_WALLETS_ID_DEPOSIT_ERROR");


export const post_wallets_id_staking = createAction("POST_WALLETS_ID_STAKING");
export const post_wallets_id_staking_data = createAction("POST_WALLETS_ID_STAKING_DATA");
export const post_wallets_id_staking_error = createAction("POST_WALLETS_ID_STAKING_ERROR");

export const delete_wallets_id = createAction("DELETE_WALLETS_ID");
export const delete_wallets_id_data = createAction("DELETE_WALLETS_ID_DATA");
export const delete_wallets_id_error = createAction("DELETE_WALLETS_ID_ERROR");

export const post_markets_exchanges = createAction(
  "post_markets_exchanges"
);
export const post_markets_exchanges_data = createAction(
  "post_markets_exchanges_DATA"
);
export const post_markets_exchanges_error = createAction(
  "post_markets_exchanges_ERROR"
);



export const put_markets_exchanges_id_cancel = createAction(
  "PUT_MARKETS_EXCHANGES_ID_CANCEL"
);
export const put_markets_exchanges_id_cancel_data = createAction(
  "PUT_MARKETS_EXCHANGES_ID_CANCEL_DATA"
);
export const put_markets_exchanges_id_cancel_error = createAction(
  "PUT_MARKETS_EXCHANGES_ID_CANCEL_ERROR"
);

export const get_histories_orderings = createAction("GET_HISTORIES_ORDERRING");
export const get_histories_orderings_data = createAction(
  "GET_HISTORIES_ORDERRING_DATA"
);
export const get_histories_orderings_error = createAction(
  "GET_HISTORIES_ORDERRING_ERROR"
);

export const put_histories_orderings_id = createAction(
  "PUT_HISTORIES_ORDERRING_ID"
);
export const put_histories_orderings_id_data = createAction(
  "PUT_HISTORIES_ORDERRING_ID_DATA"
);
export const put_histories_orderings_id_error = createAction(
  "PUT_HISTORIES_ORDERRING_ID_ERROR"
);

export const get_histories_orders = createAction("GET_HISTORIES_ORDERS");
export const get_histories_orders_data = createAction(
  "GET_HISTORIES_ORDERS_DATA"
);
export const get_histories_orders_error = createAction(
  "GET_HISTORIES_ORDERS_ERROR"
);

export const get_histories_transactions = createAction(
  "GET_HISTORIES_TRANSACTIONS"
);
export const get_histories_transactions_data = createAction(
  "GET_HISTORIES_TRANSACTIONS_DATA"
);
export const get_histories_transactions_error = createAction(
  "GET_HISTORIES_TRANSACTIONS_ERROR"
);

export const get_histories_affiliates = createAction(
  "GET_HISTORIES_AFFILIATES"
);
export const get_histories_affiliates_data = createAction(
  "GET_HISTORIES_AFFILIATES_DATA"
);
export const get_histories_affiliates_error = createAction(
  "GET_HISTORIES_AFFILIATES_ERROR"
);

export const get_histories_bids = createAction("GET_HISTORIES_BIDS");
export const get_histories_bids_data = createAction(
  "GET_HISTORIES_BIDS_DATA"
);
export const get_histories_bids_error = createAction(
  "GET_HISTORIES_BIDS_ERROR"
);



export const post_wallets_id_withdraws_id_withdraw = createAction("post_wallets_id_withdraws_id_withdraw");
export const post_wallets_id_withdraws_id_withdraw_data = createAction("post_wallets_id_withdraws_id_withdraw_DATA");
export const post_wallets_id_withdraws_id_withdraw_error = createAction("post_wallets_id_withdraws_id_withdraw_ERROR");

export const post_wallets_id_deposit = createAction("POST_WALLETS_ID_DEPOSIT");
export const post_wallets_id_deposit_data = createAction("POST_WALLETS_ID_DEPOSIT_DATA");
export const post_wallets_id_deposit_error = createAction("POST_WALLETS_ID_DEPOSIT_ERROR");
