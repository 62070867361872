import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { post_collabos } from "stores/Collabos/actions";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import { getMessage, toMomentString } from "config/util";

import { Helmet } from "react-helmet";
import { initReactI18next, useTranslation } from "react-i18next";

import { ChainLink } from "../../components/ChainLink";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { view_user } from "../../stores/Accounts/view";
import { get_users } from "stores/Users/actions";

export default function Contents({ branch, children }) {
  const [t, i18n] = useTranslation();

  const maps = useSelector((state) => state.reduce_get_maps);
  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);

  const user = reduce_get_accounts.user;
  const v_user = view_user(user);

  const defaultProps = {
    owner: {
      user_icon: v_user.user_icon_uri,
      user_name: v_user.user_name,
      user_id: v_user.user_nmid,
      auth: true,
      reward_first: 30,
      reward_second: 30,
      disabled: true,
    },
    members: [],
  };

  if (maps.nft_categories) {
    defaultProps.category_id = Object.keys(maps.nft_categories)[0];
  }

  const loadingId = useSelector((state) => state.reduce_post_collections.isLoading);

  const [propsCollections, set_propsCollections] = useState(defaultProps);

  const dispatch = useDispatch();
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");
  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if (!message) return;
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_post_collabos = (data, next, prev) => {
    dispatch(
      post_collabos({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const [propsUsers, set_propsUsers] = useState({
    search: {
      active_flg: true,
      // followinger: 1,
      followings: 1,
      followers: 1,
      page: 1,
      limit: 12,
      user_id: v_user.user_id,
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_users = (data, next, prev) => {
    dispatch(
      get_users({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_users = (search, page = 1) => {
    dispatch_get_users({
      search: { ...search, page },
    });
    set_propsUsers({
      search: { ...search, page },
    });
  };

  const newchildren = React.cloneElement(children, {
    Collections: {
      propsCollections,
      set_propsCollections,
      dispatch_post_collabos,
      branch,
      loadingId,
    },
    Users: {
      propsUsers,
      set_propsUsers,
      dispatch_gets: set_get_users,
      branch,
      list: "collabo",
    },
  });

  useEffect(() => {
    if (v_user.user_id) {
      set_get_users({ ...propsUsers.search, user_id: v_user.user_id });
    }
  }, [v_user.user_id]);

  return (
    <>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }}
        open={loadingId}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ChainLink
        hrefs={
          [
            // "/dist/tmty/dist/js/vendor/mobile-side-navigation-zeynepjs/zeynep.min.css",
            // "/dist/tmty/dist/css/base.css",
            // "/dist/tmty/dist/css/ja-jp.css",
          ]
        }
      >
        <Helmet>
          <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <title>tomonity</title>
          {/* <script src="dist/material/js/particle.js"></script> */}
        </Helmet>
        {newchildren}
      </ChainLink>
    </>
  );
}
