import React, { FC, useEffect, useRef, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import { initReactI18next, useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomLoading from "components/CustomLoading/CustomLoading.js";
import { DisplayContext } from "../../Navigator/Router_material";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { getMessage, toMomentString } from "config/util";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

export default function Dashboard(Collections) {
  const { propsCollections, set_propsCollections, dispatch_gets, type } = Collections;

  return (
    <>
      {/* <div className="btn-toolbar ms-auto" role="toolbar" aria-label="\u691C\u7D22\u6761\u4EF6">
        <div className="filter-only-xs me-sm-1">
          <button
            className="btn btn-outline-secondary"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#searchFilter"
            aria-controls="searchFilter"
          >
            <i className="fa-solid fa-filter" />
          </button>
        </div>
        <div
          className="dropdown me-1"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title=""
        >
          <button
            type="button"
            className="btn btn-outline-secondary dropdown-toggle"
            id="filSort"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-solid fa-arrow-down-wide-short" />
          </button>
          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="filSort">
            <li>
              <button type="button" className="dropdown-item">
                出品順
              </button>
            </li>
            <li>
              <button type="button" className="dropdown-item">
                作成順
              </button>
            </li>
            <li>
              <button type="button" className="dropdown-item">
                出品順
              </button>
            </li>
            <li>
              <button type="button" className="dropdown-item">
                オファー順
              </button>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <button type="button" className="dropdown-item">
                まもなく終了
              </button>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <button type="button" className="dropdown-item">
                価格の安い順
              </button>
            </li>
            <li>
              <button type="button" className="dropdown-item">
                価格の高い順
              </button>
            </li>
            <li>
              <button type="button" className="dropdown-item">
                最高出品額順
              </button>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <button type="button" className="dropdown-item">
                閲覧数順
              </button>
            </li>
            <li>
              <button type="button" className="dropdown-item">
                人気順
              </button>
            </li>
          </ul>
        </div>
        <button
          type="button"
          className="btn btn-outline-secondary"
          role="button"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title=""
        >
          <i className="fa-solid fa-grip-lines" />
        </button>
        {}
      </div> */}
    </>
  );
}
