import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import CustomDate from "components/CustomDate/CustomDate.js";
import { hideModal, showModal } from "config/util";

// import { view_collection } from "../../stores/Collections/view";
import { view_item } from "../../stores/Items/view";
import moment from "moment/moment";

const _modal_item_offer = (ItemOffers) => {
  const {
    propsItemOffers,
    set_propsItemOffers,
    dispatch_delete_items_id_offers_id,
    maps,
    t,
    v_item,
  } = ItemOffers;

  return (
    <>
      <div
        className="modal fade"
        id="offerDeclineModal"
        tabIndex={-1}
        aria-labelledby="offerDeclineModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="offerDeclineModalLabel">
                {t("オファー解消")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <dl className="row g-2">
                {/* <dt className="col-sm-4">コイン</dt>
                <dd className="col-sm-8">
                  <select
                    className="form-select"
                    id="collectionCategory"
                    aria-label="\u30AB\u30C6\u30B4\u30EA"
                    value={propsItemOffers.coin}
                    onChange={(e) => {
                      const val = e.target.value;
                      set_propsItemOffers({
                        ...propsItemOffers,
                        coin: val,
                      });
                    }}
                  >
                    {Object.keys(maps.coins).map((k, idx) => {
                      return <option value={k}>{maps.coins[k]}</option>;
                    })}
                  </select>
                </dd>

                <dt className="col-sm-4">金額</dt>
                <dd className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) => {
                      const val = e.target.value;
                      set_propsItemOffers({
                        ...propsItemOffers,
                        price: val,
                      });
                    }}
                    value={propsItemOffers.price}
                  />
                </dd> */}
                {/* <dt className="col-sm-4">有効期限</dt>
                <dd className="col-sm-8">
                  <CustomDate
                    readOnly={false}
                    labelText={t("いつまで")}
                    type="text"
                    autoComplete="off"
                    value={propsItemOffers.closed_at}
                    set_value={(val) => {
                      set_propsItemOffers({
                        ...propsItemOffers,
                        closed_at: val,
                      });
                    }}
                  />
                </dd> */}
              </dl>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                {t("キャンセル")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  // hideModal("offerDeclineModal");
                  e.preventDefault();
                  dispatch_delete_items_id_offers_id(
                    {
                      item_id: v_item.item_id,
                      item_detail_id: v_item.item_detail_id,
                      item_offer_id: propsItemOffers.item_offer_id,
                      // coin: propsItemOffers.coin,
                      // price: propsItemOffers.price,
                      // closed_at: moment(propsItemOffers.closed_at).format(),
                      search: propsItemOffers.search,
                      // timezone: moment().format("Z"),
                    },
                    () => {
                      hideModal("offerDeclineModal");
                    },
                    () => {}
                  );
                }}
              >
                {t("解消")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default _modal_item_offer;
