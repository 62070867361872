import React, { FC, useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  zeroPadding,
} from "config/util";
import { getSearch, setSearch } from "config/util";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { get_items, get_items_id, delete_items_id } from "stores/Items/actions";

import { get_activities } from "stores/Activities/actions";
import { get_items_id_offers } from "stores/ItemOffers/actions";
import { get_items_id_bids, post_items_id_bids } from "stores/ItemBids/actions";

import DashboardIcon from "@material-ui/icons/Dashboard";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Search from "@material-ui/icons/Search";

import { ChainLink } from "components/ChainLink";

import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import socketIOClient from "socket.io-client";
import env from "../../stores/env";
import moment from "moment/moment";
import { put_items_id_favorite, post_orders } from "stores/Items/actions";
import {
  post_items_id_offers,
  put_items_id_offers_id,
  delete_items_id_offers_id,
} from "stores/ItemOffers/actions";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

let socket = null;

const App = ({ branch, children }) => {
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();

  const dispatch = useDispatch();

  const maps = useSelector((state) => state.reduce_get_maps);

  const location_search = useLocation().search;
  const propsSearch = getSearch(location_search) ?? {};

  const loading_get_items_id = useSelector((state) => state.reduce_get_items.isLoadingId);

  const loading_post_orders = useSelector((state) => state.reduce_post_orders.isLoading);
  const loading_post_items_id_offers = useSelector(
    (state) => state.reduce_post_items_id_offers.isLoading
  );
  const loading_put_items_id_offers_id = useSelector(
    (state) => state.reduce_put_items_id_offers_id.isLoading
  );
  const loading_delete_items_id_offers_id = useSelector(
    (state) => state.reduce_delete_items_id_offers_id.isLoading
  );

  const loading =
    loading_post_orders ||
    loading_post_items_id_offers ||
    loading_put_items_id_offers_id ||
    loading_delete_items_id_offers_id;

  const { item_id } = useParams();
  const [item_detail_id, set_item_detail_id] = useState();

  const [propsItem, set_propsItem] = useState({
    search: {
      active_flg: true,
      item_id,
      page: 1,
      limit: 10,
      sort: [],
      ...branch?.search,
    },
  });

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if (!message) return;
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_get_items_id = (data, next, prev) => {
    dispatch(
      get_items_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_delete_items_id = (data, next, prev) => {
    dispatch(
      delete_items_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_put_items_id_favorite = (data, next, prev) => {
    dispatch(
      put_items_id_favorite({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_post_orders = (data, next, prev) => {
    dispatch(
      post_orders({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_post_items_id_offers = (data, next, prev) => {
    dispatch(
      post_items_id_offers({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_put_items_id_offers_id = (data, next, prev) => {
    dispatch(
      put_items_id_offers_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_delete_items_id_offers_id = (data, next, prev) => {
    dispatch(
      delete_items_id_offers_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const [propsActivities, set_propsActivities] = useState({
    search: {
      active_flg: true,
      item_id,
      // type: [0, 10, 11, 21, 22, 23, 24, 30, 31, 32, 33, 34, 35],
      type: [0, 10, 11, 21, 22, 30, 31, 32, 34, 35],

      // type: [11],
      page: 1,
      limit: 5,
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_activities = (data, next, prev) => {
    dispatch(
      get_activities({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_activities = (search, page = 1) => {
    dispatch_get_activities({
      search: { ...search, page },
    });
    set_propsActivities({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    set_get_activities(propsActivities.search);
  }, []);

  const [propsItemOffers, set_propsItemOffers] = useState({
    search: {
      item_id,
      page: 1,
      limit: 3,
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_items_id_offers = (data, next, prev) => {
    dispatch(
      get_items_id_offers({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_items_id_offers = (search, page = 1) => {
    dispatch_get_items_id_offers({
      search: { ...search, page },
    });
    set_propsItemOffers({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    set_get_items_id_offers(propsItemOffers.search);
  }, []);

  const [propsItemBids, set_propsItemBids] = useState({
    search: {
      item_id,
      page: 1,
      limit: 3,
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_items_id_bids = (data, next, prev) => {
    dispatch(
      get_items_id_bids({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_post_items_id_bids = (data, next, prev) => {
    dispatch(
      post_items_id_bids({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_items_id_bids = (search, page = 1) => {
    dispatch_get_items_id_bids({
      search: { ...search, page },
    });
    set_propsItemBids({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    if (item_detail_id) {
      set_get_items_id_bids({ ...propsItemBids.search, item_detail_id });
    }
  }, [item_detail_id]);

  useEffect(() => {
    if (item_id) {
      dispatch_get_items_id(
        {
          id: item_id,
        },
        (data) => {
          set_item_detail_id(data.item.item_detail_id);
          set_propsItemBids({
            search: { ...propsItemBids.search, item_detail_id: data.item.item_detail_id },
            // price: data.item.item_sale_price,
            // price: 2,
          });
        }
      );
    }
  }, []);

  const set_get_items_id = () => {
    if (item_id) {
      dispatch_get_items_id(
        {
          id: item_id,
        },
        (data) => {}
      );
    }
  };

  const newchildren = React.cloneElement(children, {
    Item: {
      propsItem,
      set_propsItem,
      dispatch_put_items_id_favorite,
      dispatch_post_orders,
      dispatch_delete_items_id,
      // dispatch_gets,
      branch,
    },
    Activities: {
      propsActivities,
      set_propsActivities,
      dispatch_gets: set_get_activities,
      list: "item",
      branch,
    },
    ItemOffers: {
      propsItemOffers,
      set_propsItemOffers,
      dispatch_gets: set_get_items_id_offers,
      dispatch_post_items_id_offers,
      dispatch_put_items_id_offers_id,
      dispatch_delete_items_id_offers_id,
      list: "item",
      branch,
    },
    ItemBids: {
      propsItemBids,
      set_propsItemBids,
      dispatch_gets: set_get_items_id_bids,
      dispatch_post_items_id_bids,
      set_get_items_id,
      dispatch_activities: () => {
        set_get_activities(propsActivities.search);
      },
      propsActivities,
      list: "item",
      branch,
    },
  });

  if (loading_get_items_id) return <></>;

  return (
    <>
      <Helmet>
        <title>tomonity</title>
      </Helmet>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }}
        open={loading}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {newchildren}
    </>
  );
};

export default App;
