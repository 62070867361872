import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";

import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";

import { view_item_offer } from "../../stores/ItemOffers/view";
import { HeaderItem, RowItem } from "./_item";
import { HeaderUser, RowUser } from "./_user";

// import { put_items_id_favorite } from "stores/ItemOffers/actions";

export default function Dashboard({ ItemOffers }) {
  const { propsItemOffers, list, mypage } = ItemOffers;
  const [t, i18n] = useTranslation();
  const history = useHistory();

  ItemOffers.history = history;
  ItemOffers.t = t;

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const dispatch = useDispatch();

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const maps = useSelector((state) => state.reduce_get_maps);

  const loading = useSelector((state) => state.reduce_get_items_id_offers.isLoading);
  const loadingId = useSelector((state) => state.reduce_get_items_id_offers.isLoadingId);

  const datalist = useSelector((state) => state.reduce_get_items_id_offers.item_offers);

  if (loading) return <></>;

  return (
    <>
      {ItemOffers.list == "user" && (
        <>
          {HeaderUser(ItemOffers)}
          {datalist.map((prop, idx) => {
            const v_item_offer = view_item_offer(prop, maps, t);
            ItemOffers.v_item_offer = v_item_offer;
            return <>{RowUser(ItemOffers)}</>;
          })}
        </>
      )}
      {ItemOffers.list == "item" && (
        <>
          {HeaderItem(ItemOffers)}
          {datalist.map((prop, idx) => {
            const v_item_offer = view_item_offer(prop, maps, t);
            ItemOffers.v_item_offer = v_item_offer;
            return <>{RowItem(ItemOffers)}</>;
          })}
        </>
      )}
    </>
  );
}
