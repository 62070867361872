import { combineReducers } from "redux";
import { createAction, createReducer } from "redux-act";

import {
  get_accounts_cards,
  get_accounts_cards_data,
  get_accounts_cards_error,
  get_accounts_cards_id,
  get_accounts_cards_id_data,
  get_accounts_cards_id_error,
  post_accounts_cards,
  post_accounts_cards_data,
  post_accounts_cards_error,
  put_accounts_cards_id,
  put_accounts_cards_id_data,
  put_accounts_cards_id_error,
  delete_accounts_cards_id,
  delete_accounts_cards_id_data,
  delete_accounts_cards_id_error,
  post_cards_id_buy,
  post_cards_id_buy_data,
  post_cards_id_buy_error,
  post_cards_id_pay,
  post_cards_id_pay_data,
  post_cards_id_pay_error,
  put_cards_id_favorite,
  put_cards_id_favorite_data,
  put_cards_id_favorite_error,
} from "./actions";

const forupdate = (array, unit, id) => {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element[id] === unit[id]) {
      array[index] = unit;
    }
  }
  return array;
};

export const reduce_get_accounts_cards = createReducer(
  {
    [get_accounts_cards]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      if (!payload.data.search) {
        newState.cardslist = [];
      } else if (payload.data.search.page == 1) {
        newState.cardslist = [];
        // newState.same = false;
        // newState.page = 1;
      } else {
        // newState.same = newState.page == payload.data.search.page;
        // newState.page = payload.data.search.page;
      }
      newState.isLoading = true;
      return newState;
    },
    [get_accounts_cards_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      // if (!newState.same) {
      newState.cardslist = newState.cardslist.concat(
        payload.data.cards
      );
      // }

      newState.isLoading = false;
      return newState;
    },
    [get_accounts_cards_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [get_accounts_cards_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_accounts_cards_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      const card = payload.data.card;
      const cards = state.cards;
      const cardslist = state.cardslist;

      if (cards)
        newState.cards = forupdate(cards, card, "card_id");
      if (cardslist)
        newState.cardslist = forupdate(
          cardslist,
          card,
          "card_id"
        );
      newState.isLoading = false;

      return newState;
    },
    [get_accounts_cards_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
 isLoading: false,
    card: {},
    cards: [],
    cardslist: [],
    count: 0,
  }
);

export const reduce_post_accounts_cards = createReducer(
  {
    [post_accounts_cards]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_accounts_cards_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_accounts_cards_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_accounts_cards_id = createReducer(
  {
    [put_accounts_cards_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_accounts_cards_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_accounts_cards_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_accounts_cards_id = createReducer(
  {
    [delete_accounts_cards_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_accounts_cards_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [delete_accounts_cards_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_cards_id_buy = createReducer(
  {
    [post_cards_id_buy]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_cards_id_buy_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_cards_id_buy_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);


export const reduce_post_cards_id_pay = createReducer(
  {
    [post_cards_id_pay]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_cards_id_pay_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_cards_id_pay_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_cards_id_favorite = createReducer(
  {
    [put_cards_id_favorite]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_cards_id_favorite_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_cards_id_favorite_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export default {
  reduce_get_accounts_cards,
  reduce_post_accounts_cards,
  reduce_put_accounts_cards_id,
  reduce_delete_accounts_cards_id,
  reduce_post_cards_id_buy,
  reduce_post_cards_id_pay,
  reduce_put_cards_id_favorite,
};
