import { fork, take, put, join, select, takeLatest, takeEvery, call } from "redux-saga/effects";

import { post_authout } from "../Auth/actions";

import {
  api_get_items_id_offers,
  api_get_items_id_offers_id,
  api_post_items_id_offers,
  api_put_items_id_offers_id,
  api_delete_items_id_offers_id,
} from "./api";

import {
  get_items_id_offers,
  get_items_id_offers_data,
  get_items_id_offers_error,
  get_items_id_offers_id,
  get_items_id_offers_id_data,
  get_items_id_offers_id_error,
  post_items_id_offers,
  post_items_id_offers_data,
  post_items_id_offers_error,
  put_items_id_offers_id,
  put_items_id_offers_id_data,
  put_items_id_offers_id_error,
  delete_items_id_offers_id,
  delete_items_id_offers_id_data,
  delete_items_id_offers_id_error,
} from "./actions";



export function* run_get_items_id_offers({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_items_id_offers, payload.data, token);
  if (!error) {
    yield put(get_items_id_offers_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_items_id_offers_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_items_id_offers_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_items_id_offers_id, payload.data, token);
  if (!error) {
    yield put(get_items_id_offers_id_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_items_id_offers_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_items_id_offers({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_items_id_offers, payload.data, token);
  if (!error) {
    yield put(post_items_id_offers_data({ data }));
    yield put(get_items_id_offers(payload));
  } else {
    yield put(post_items_id_offers_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_items_id_offers_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_items_id_offers_id, payload.data, token);
  if (!error) {
    yield put(put_items_id_offers_id_data({ data }));
    yield put(get_items_id_offers(payload));
    // yield put(get_items_id_offers_id(payload));
  } else {
    yield put(put_items_id_offers_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_items_id_offers_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_delete_items_id_offers_id, payload.data, token);
  if (!error) {
    yield put(delete_items_id_offers_id_data({ data }));
    yield put(get_items_id_offers(payload));
  } else {
    yield put(delete_items_id_offers_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}


export function* flow_get_items_id_offers() {
  yield takeLatest(get_items_id_offers, run_get_items_id_offers);
}
export function* flow_get_items_id_offers_id() {
  yield takeLatest(get_items_id_offers_id, run_get_items_id_offers_id);
}
export function* flow_post_items_id_offers() {
  yield takeLatest(post_items_id_offers, run_post_items_id_offers);
}
export function* flow_put_items_id_offers_id() {
  yield takeLatest(put_items_id_offers_id, run_put_items_id_offers_id);
}
export function* flow_delete_items_id_offers_id() {
  yield takeLatest(delete_items_id_offers_id, run_delete_items_id_offers_id);
}
export default function* rootSaga() {
  yield fork(flow_get_items_id_offers);
  yield fork(flow_get_items_id_offers_id);
  yield fork(flow_post_items_id_offers);
  yield fork(flow_put_items_id_offers_id);
  yield fork(flow_delete_items_id_offers_id);
}
