import { createAction, createReducer } from 'redux-act';

export const get_categories = createAction("GET_CATEGORIES");
export const get_categories_data = createAction("GET_CATEGORIES_DATA");
export const get_categories_error = createAction("GET_CATEGORIES_ERROR");
export const get_categories_id = createAction("GET_CATEGORIES_ID");
export const get_categories_id_data = createAction("GET_CATEGORIES_ID_DATA");
export const get_categories_id_error = createAction("GET_CATEGORIES_ID_ERROR");
export const post_categories = createAction("POST_CATEGORIES");
export const post_categories_data = createAction("POST_CATEGORIES_DATA");
export const post_categories_error = createAction("POST_CATEGORIES_ERROR");
export const put_categories_id = createAction("PUT_CATEGORIES_ID");
export const put_categories_id_data = createAction("PUT_CATEGORIES_ID_DATA");
export const put_categories_id_error = createAction("PUT_CATEGORIES_ID_ERROR");
export const delete_categories_id = createAction("DELETE_CATEGORIES_ID");
export const delete_categories_id_data = createAction("DELETE_CATEGORIES_ID_DATA");
export const delete_categories_id_error = createAction("DELETE_CATEGORIES_ID_ERROR");
