import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import LiLanguage from "components/Li/LiLanguage";
import LiAccount from "components/Li/LiAccount";
import { getMessage } from "config/util";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";
import CustomCircle from "components/CustomCircle/CustomCircle.js";

import { view_collection } from "../../stores/Collections/view";
import CustomImage from "./CustomImage";

import { hideModal, showModal } from "config/util";

import _modal from "./_modalSuccessfully";

export default function Contents(Items) {
  const { propsItems, set_propsItems, dispatch_post_nfts, loading_post_nfts } = Items;

  const props = propsItems;
  const set_props = set_propsItems;

  const { collection_id } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection);
  const maps = useSelector((state) => state.reduce_get_maps);

  return (
    <>
      <div
        className="modal fade modal-alert py-5"
        id="processDialog"
        data-bs-keyboard="false"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content rounded-4 shadow">
            <div className="modal-body p-4 text-center">
              <h5 className="mb-2">{t("確認")}</h5>
              <p className="mb-0">{t("入力内容を保存してもいいですか？")}</p>
            </div>
            <div className="modal-footer flex-nowrap p-0">
              <button
                type="button"
                className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0"
                data-bs-dismiss="modal"
              >
                {t("キャンセル")}
              </button>
              <button
                type="button"
                className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"
                // data-bs-target="#modalSuccessfully"
                // data-bs-toggle="modal"
                onClick={(e) => {
                  e.preventDefault();

                  const metadata = {
                    name: props.token_name,
                    description: props.description,
                    referral_url: props.referral_url,
                  };

                  const metadataString = JSON.stringify(metadata, (k, v) => {
                    if (v === undefined) {
                      return "";
                    }
                    if (v === null) {
                      return "";
                    }
                    return v;
                  });
                  dispatch_post_nfts(
                    {
                      // ...props,
                      search: props.search,
                      symbol: props.symbol,
                      amount: props.amount,
                      name: props.token_name,
                      description: props.description,
                      collection_id,
                      type: "2",
                      coin_id: 2,
                      // price: 1,
                      // sale_type: 1,
                      // tax_rate: 0.1,
                      // opened_at: "2022-02-12T10:00:00.000Z",
                      // closed_at: "2023-02-12T10:00:00.000Z",
                      image: props.main_image,
                      metadata: metadataString,
                    },
                    () => {
                      hideModal("processDialog");
                      history.goBack();
                      // showModal("modalSuccessfully");
                    }
                  );
                }}
              >
                <strong>
                  <CustomCircle title={t("はい")} loading={loading_post_nfts} size={16} />
                </strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
