import { fork, take, put, join,takeLatest, select, call } from "redux-saga/effects";

import { post_authout } from "../Auth/actions";

import {
  api_get_withdraws,
  api_get_withdraws_id,
  api_post_withdraws,
  api_put_withdraws_id,
  api_delete_withdraws_id,

  api_put_withdraws_id_favorite,
} from "./api";

import {
  get_withdraws,
  get_withdraws_data,
  get_withdraws_error,
  get_withdraws_id,
  get_withdraws_id_data,
  get_withdraws_id_error,
  post_withdraws,
  post_withdraws_data,
  post_withdraws_error,
  put_withdraws_id,
  put_withdraws_id_data,
  put_withdraws_id_error,
  delete_withdraws_id,
  delete_withdraws_id_data,
  delete_withdraws_id_error,

  put_withdraws_id_favorite,
  put_withdraws_id_favorite_data,
  put_withdraws_id_favorite_error,
} from "./actions";

export function* run_get_withdraws({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_withdraws, payload.data, token);
  if (!error) {
    yield put(get_withdraws_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_withdraws_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_withdraws_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_withdraws_id, payload.data, token);
  if (!error) {
    yield put(get_withdraws_id_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_withdraws_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_withdraws({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_withdraws, payload.data, token);
  if (!error) {
    yield put(post_withdraws_data({ data }));
    yield put(get_withdraws(payload));
    
  } else {
    yield put(post_withdraws_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_withdraws_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_withdraws_id, payload.data, token);
  if (!error) {
    yield put(put_withdraws_id_data({ data }));
    yield put(get_withdraws_id(payload));
  } else {
    yield put(put_withdraws_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_withdraws_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(
    api_delete_withdraws_id,
    payload.data,
    token
  );
  if (!error) {
    yield put(delete_withdraws_id_data({ data }));
    yield put(get_withdraws(payload));
  } else {
    yield put(delete_withdraws_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_withdraws_id_favorite({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(
    api_put_withdraws_id_favorite,
    payload.data,
    token
  );
  if (!error) {
    yield put(put_withdraws_id_favorite_data({ data }));
    yield put(get_withdraws_id(payload));
  } else {
    yield put(put_withdraws_id_favorite_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* flow_get_withdraws() {
  yield takeLatest(get_withdraws, run_get_withdraws);
}
export function* flow_get_withdraws_id() {
  yield takeLatest(get_withdraws_id, run_get_withdraws_id);
}
export function* flow_post_withdraws() {
  yield takeLatest(post_withdraws, run_post_withdraws);
}
export function* flow_put_withdraws_id() {
  yield takeLatest(put_withdraws_id, run_put_withdraws_id);
}
export function* flow_delete_withdraws_id() {
  yield takeLatest(delete_withdraws_id, run_delete_withdraws_id);
}

export function* flow_put_withdraws_id_favorite() {
  yield takeLatest(put_withdraws_id_favorite, run_put_withdraws_id_favorite);
}

export default function* rootSaga() {
  yield fork(flow_get_withdraws);
  yield fork(flow_get_withdraws_id);
  yield fork(flow_post_withdraws);
  yield fork(flow_put_withdraws_id);
  yield fork(flow_delete_withdraws_id);

  yield fork(flow_put_withdraws_id_favorite);
}
