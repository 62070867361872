import React, { createContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment/moment";

import { KeyboardDateTimePicker ,KeyboardDatePicker} from "@material-ui/pickers";

import styles from "assets/jss/chaintier/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const {
    value,
    set_value,
    defaultvalue,
    justWidth,
    formControlProps,
    labelText,
    id,
    labelProps,
    error,
    success,
    readOnly,
  } = props;

  const [t] = useTranslation();
  const [focused, set_focused] = useState(false);
  const [raw_date, set_raw_date] = useState(null);

  useEffect(() => {
    if (value) {
      const mom = moment(value);
      // set_raw_date(Date(mom));
      set_raw_date(mom);
    } else {
      set_raw_date(null);
    }
  }, [value]);

  const classes = useStyles();

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  var formControlClasses;

  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }

  return (
    <>
      <FormControl
        {...formControlProps}
        fullWidth={!justWidth}
        className={formControlClasses}
        onFocus={() => {
          set_focused(true);
        }}
        onBlur={() => {
          set_focused(false);
        }}
      >
        {labelText !== undefined ? (
          <InputLabel
            className={`${
              classes.labelRoot
            } ${labelClasses} MuiFormLabel-root ${
              focused || raw_date ? "MuiInputLabel-shrink" : ""
            }`}
            htmlFor={id}
            {...labelProps}
          >
            {labelText}
          </InputLabel>
        ) : null}
        <KeyboardDatePicker
          style={{ marginTop: 0, marginBottom: 0 }}
          autoComplete="off"
          className="MyDatePicker"
          // className={readOnly ? "MyDatePicker MuiReadOnly" : "MyDatePicker"}
          fullWidth={true}
          margin="dense"
          id="date-picker-dialog"
          format="yyyy/MM/dd"
          value={raw_date}
          // disabled={readOnly}
          readOnly={readOnly}
          onChange={(d) => {
            if (readOnly) return;

            if (isNaN(d)) return;
            const m = moment(d);

            set_raw_date(d);

            const str = toMomentString(m);
            set_value(str);
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          inputVariant="standard"
          okLabel={t("決定")}
          cancelLabel={t("キャンセル")}
          error={false}
          helperText={""}
        />
      </FormControl>
    </>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
};

const toMomentString = (obj, formatStr) => {
  //   if (moment.isMoment(obj)) {
  //     return obj.format(formatStr);
  //   }
  if (obj) {
    const mom = moment(obj);
    if (mom.isValid()) {
      return mom.format(formatStr);
    }
  }

  return "";
};
