import Header from "../../views_components/Component/Header";
// import Sidebar from "./Sidebar";
import { view_collection } from "../../stores/Collections/view";
import { view_user } from "../../stores/Users/view";
import _sidebar from "./_sidebar";

export default function Contents(Page, Items) {
  const { type, dispatch_type, v_collection, t } = Page;

  return (
    <>
      <div className="main-header main-header-hidden mb-0">
        <ul className="nav canopy">
          <li className="nav-item canopy-item">
            <a
              href="#"
              className={`nav-link ${type == "items" ? "active" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                dispatch_type("items");
                // e.preventDefault();
                // history.push(`/collections/${v_collection.collection_id}/items`);
              }}
              aria-current="page"
            >
              <span className="canopy-text">
                <span className="canopy-num">{v_collection.item_count}</span>
                {t("アイテム")}
              </span>
            </a>
          </li>
          <li className="nav-item canopy-item">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                // history.push(`/collections/${v_collection.collection_id}/items`);
              }}
              className="nav-link"
              aria-current="page"
            >
              <span className="canopy-text">
                <span className="canopy-num">{v_collection.owner_count}</span>
                {t("オーナー")}
              </span>
            </a>
          </li>
          <li className="nav-item canopy-item">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                // history.push(`/collections/${v_collection.collection_id}/items`);
              }}
              className="nav-link"
              aria-current="page"
            >
              <span className="canopy-text">
                <span className="canopy-num">
                  <sub>$</sub>
                  {v_collection.min_price_usd_label2 ? (
                    v_collection.min_price_usd_label2
                  ) : (
                    <sub>-</sub>
                  )}
                </span>
                {t("最低価格")}
              </span>
            </a>
          </li>
          <li className="nav-item canopy-item">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                // history.push(`/collections/${v_collection.collection_id}/items`);
              }}
              className="nav-link"
              aria-current="page"
            >
              <span className="canopy-text">
                <span className="canopy-num">
                  <sub>$</sub>
                  {v_collection.sum_price_usd_label2 ? (
                    v_collection.sum_price_usd_label2
                  ) : (
                    <sub>-</sub>
                  )}
                </span>
                {t("取引量")}
              </span>
            </a>
          </li>
          <li className="nav-item canopy-item">
            <a
              className={`nav-link ${type == "activities" ? "active" : ""}`}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                dispatch_type("activities");
              }}
            >
              <span className="canopy-text">
                <span className="canopy-num">&nbsp;</span>
                {t("アクティビティ")}
              </span>
            </a>
          </li>
        </ul>
      </div>
      {/* <div className="d-flex justify-content-between align-items-center pt-3">
        <h2 className="h4 m-0 text-truncate"></h2>
        {v_user.myself && (
          <a
            className="btn btn-outline-primary rounded-pill px-sm-3 text-nowrap"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/collections/create`);
            }}
          >
            <i className="fa-solid fa-plus fa-fw" />
            <span className="d-none d-md-inline-block"></span>
            <span className="ms-1 ms-md-0">コレクションを作成</span>
          </a>
        )}
      </div> */}
    </>
  );
}
