import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";

import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";

import { view_item_offer } from "../../stores/ItemOffers/view";

export const HeaderItem = (ItemOffers) => {
  const t = ItemOffers.t;

  return (
    <>
      <dl className="row g-0 g-sm-2 g-md-0 border-bottom border-dark border-2 py-3 pb-md-2 mb-1">
        <dt className="col">
          <small className="d-block">{t("申請者")}</small>
        </dt>
        <dt className="col-4 col-sm-3 col-md-4 pe-sm-3 pe-md-0 text-end">
          <small className="d-block"> </small>
        </dt>
        <dt className="col-12 col-sm-6 col-md-12 order-sm-first order-md-last">
          <dl className="row g-0 g-sm-2 g-md-0 align-items-sm-center align-items-md-start">
            <dt className="col-5 col-sm-6 pe-sm-3 pe-md-0 text-sm-end text-md-start">
              <small className="d-block">{t("オファー価格")}</small>
            </dt>
            <dt className="col text-end text-sm-start text-md-end">
              <small className="d-block">{t("有効期限")}</small>
            </dt>
          </dl>
        </dt>
      </dl>
    </>
  );
};

export const RowItem = (ItemOffers) => {
  const { v_item_offer, history, t } = ItemOffers;
  const { propsItemOffers, set_propsItemOffers, dispatch_gets, user_id } = ItemOffers;

  // closed_at: "2022-05-26T04:03:00.000Z"
  // coin_id: 2
  // created_at: "2022-05-25T04:04:02.000Z"
  // fixed_flg: false
  // item_id: 229
  // item_offer_id: 126
  // price: "1"
  // usd_price: "2623.3741569176056174"
  // user_detail: {name01: "椋本3", name02: "達也", birthday: "1988-04-13T00:00:00+09:00", postal_code: "532-0028",…}
  // user_id: "553815e2-b7c8-486a-b497-01912c9243f9"
  // user_id_by: 88
  // user_name: "椋本達也1"
  // uuid: "731eea15-83e8-4b5c-b8f7-f55a9fe12369"

  return (
    <>
      <dl className="row g-1 g-sm-2 py-3 pt-md-2 mb-1 align-items-sm-center align-items-lg-start align-items-xl-center border-bottom">
        <dd className="col text-truncate overflow-hidden">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: `/users/${v_item_offer.user_id_by}`,
              });
            }}
          >
            {/* <div className="d-flex verified-text"> */}
            <span className="text-truncate">{v_item_offer.user_name_by}</span>
            {/* </div> */}
          </a>
        </dd>
        <dd className="col-4 col-sm-3 col-md-4 pe-sm-3 pe-md-0 text-end">
          <div className="btn-group btn-group-sm text-nowrap">
            {v_item_offer.user_id_my && (
              <>
                <button
                  className="btn btn-sm btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#offerAllowModal"
                  onClick={(e) => {
                    e.preventDefault();
                    set_propsItemOffers({
                      ...propsItemOffers,
                      item_id: v_item_offer.item_id,
                      item_detail_id: v_item_offer.item_detail_id,
                      item_offer_id: v_item_offer.item_offer_id,
                      type: "allow",
                    });
                  }}
                >
                  {t("許可")}
                </button>
              </>
            )}
            {v_item_offer.user_id_my && (
              <>
                <button
                  className="btn btn-sm btn-outline-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#offerDeclineModal"
                  onClick={(e) => {
                    e.preventDefault();
                    set_propsItemOffers({
                      ...propsItemOffers,
                      item_id: v_item_offer.item_id,
                      item_detail_id: v_item_offer.item_detail_id,
                      item_offer_id: v_item_offer.item_offer_id,
                      type: "decline",
                    });
                  }}
                >
                  {t("拒否")}
                </button>
              </>
            )}
            {v_item_offer.user_id_by_my && (
              <>
                <button
                  className="btn btn-sm btn-outline-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#offerDeclineModal"
                  onClick={(e) => {
                    e.preventDefault();
                    set_propsItemOffers({
                      ...propsItemOffers,
                      item_id: v_item_offer.item_id,
                      item_detail_id: v_item_offer.item_detail_id,
                      item_offer_id: v_item_offer.item_offer_id,
                      type: "decline",
                    });
                  }}
                >
                  {t("取消")}
                </button>
              </>
            )}
          </div>
        </dd>
        <dd className="col-12 col-sm-6 col-md-12 order-sm-first order-md-last">
          <dl className="row g-0 g-sm-2 g-md-0 align-items-sm-center align-items-md-start mt-2 mt-sm-0 mt-lg-2">
            <dd className="col-5 col-sm-6 pe-sm-3 pe-md-0 text-sm-end text-md-start">
              <span
                className="fw-bold"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={v_item_offer.coin_label}
              >
                <sub className="me-1">{v_item_offer.coin_icon_tag}</sub>
                {v_item_offer.item_offer_price}
              </span>
              <small className="d-block ps-2 ps-sm-0 ps-md-2">
                <sub>$</sub>
                {v_item_offer.item_offer_price_usd_label}
              </small>
            </dd>
            <dd className="col text-end text-sm-start text-md-end">
              <small> {v_item_offer.closed_at_countdown_label}</small>
            </dd>
          </dl>
        </dd>
      </dl>
    </>
  );
};
