import React from "react";
import ReactDOM from "react-dom";
// import ReCAPTCHA from "react-google-recaptcha";
import env from "../../stores/env";

import ReCAPTCHA from "./react-google-recaptcha/lib/index";
const SITE_KEY = env.ReCAPTCHA;
const DELAY = 1500;

class App extends React.Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      callback: "not fired",
      value: "[empty]",
      load: false,
      expired: "false",
    };
    this._reCaptchaRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true });
    }, DELAY);
    // console.log("didMount - reCaptcha Ref-", this._reCaptchaRef);
  }

  componentWillUnmount() {
    this.setState({ load: false });
  }

  handleChange = (value) => {
    // console.log("Captcha value:", value);
    // this.setState({ value });
    this.props.set_recaptcha(value);
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: "true" });
  };

  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
    // console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
  };

  render() {
    const { value, callback, load, expired } = this.state || {};
    return (
      <div className="App">
        {/* <h3>Recaptcha loaded callback: {callback}</h3> */}
        {/* <h5>Recaptcha value: {value}</h5> */}
        {/* <h5>Expired: {expired}</h5> */}
        {load && (
          <ReCAPTCHA
            style={{ display: "inline-block" }}
            // theme="dark"
            ref={this._reCaptchaRef}
            sitekey={SITE_KEY}
            onChange={this.handleChange}
            asyncScriptOnLoad={this.asyncScriptOnLoad}
          />
        )}
      </div>
    );
  }
}

export default App;
