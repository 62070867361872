import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export const HeaderHot = (Collections) => {
  const { propsTopCollections, set_propsTopCollections, dispatch_gets, user_id } = Collections;
  return <></>;
};

export const RowHot = (Collections) => {
  const {
    v_collection,
    history,
    t,
    propsTopCollections,
    dispatch_put_top_collections_id_favorite,
    dispatch_type,
    type,
  } = Collections;

  return (
    <>
      <div className="col">
        <div className="card product product-collection">
          <div className="product-body position-relative">
            <div className="product-media">
              <div className="cover ratio ratio-1x3">
                <img
                  src={v_collection.collection_cover_uri}
                  width="100%"
                  height="100%"
                  className="card-img cover-item img-fluid"
                  alt={v_collection.collection_name}
                />
              </div>
              <div className="product-action">
                <button
                  type="button"
                  className={`btn rounded-pill tmty-btn-love ${
                    v_collection.collection_favorited_my ? "active" : ""
                  }`}
                  role="button"
                  // data-bs-toggle="button"
                  autoComplete="off"
                  aria-pressed="true"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch_put_top_collections_id_favorite({
                      id: v_collection.collection_id,
                      search: propsTopCollections.search,
                    });
                  }}
                >
                  {v_collection.collection_favorited_count}
                </button>
              </div>
            </div>
            {/* <div className="product-icon icon ratio ratio-1x1 icon-square icon-space verified"> */}
            <div className="product-icon icon ratio ratio-1x1 icon-square icon-space">
              <div className="media-object">
                <img
                  src={v_collection.collection_icon_uri}
                  className="icon-item"
                  width="100%"
                  height="100%"
                  alt={v_collection.collection_name}
                />
                {v_collection.collection_collabo_flg && (
                  <div
                    className="position-absolute bottom-0 end-0 badge rounded-pill bg-white text-body p-1 lh-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="\u30B3\u30E9\u30DC\u30EC\u30FC\u30C8"
                    style={{
                      transform: "translate(25%, 25%)",
                    }}
                  >
                    <i className="icomoon-collab text-secondary" />
                  </div>
                )}
              </div>
            </div>
            <h3 className="product-title justify-content-center text-truncate overflow-hidden">
              <a
                className="stretched-link text-dark"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  // if (type == "collections") {
                  //   dispatch_type("collection-items", v_collection.collection_id);
                  // } else {
                  history.push(`/collections/${v_collection.collection_id}/items`);
                  // }
                }}
              >
                {/* <div className="d-flex justify-content-center verified-text"> */}
                <span className="text-truncate">{v_collection.collection_name}</span>
                {/* </div> */}
              </a>
            </h3>
            {/* <span className="badge rounded-pill bg-light text-dark">
              <sub>$</sub>123,456,789
            </span> */}
          </div>
          <div className="product-footer position-relative overflow-hidden">
            {/* <div class="media media-xs overflow-hidden"> */}
            <div className="media media-xs overflow-hidden">
              <div className="media-object">
                <div
                  className={
                    v_collection.collection_collabo_flg
                      ? "mask-hex mask-hex-xs align-middle"
                      : "icon icon-pill"
                  }
                >
                  <figure className="object-item ratio ratio-1x1 m-0">
                    <img src={v_collection.collection_user_icon_uri} className="icon-item" />
                  </figure>
                </div>
              </div>
              <div className="media-body text-truncate overflow-hidden">
                <a
                  className="stretched-link text-dark"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`/users/${v_collection.collection_user_id}`);
                  }}
                >
                  <span className="text-truncate">{v_collection.collection_user_name}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        {}
      </div>
    </>
  );
};
