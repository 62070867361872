import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";
import LiMember from "./_liMember";

import CustomImage from "./CustomImage";
import { hideModal, showModal } from "config/util";

export default function Contents({ Page, Collection }) {
  const { maps, t, history } = Page;
  const { propsCollection, set_propsCollection, dispatch_put_collections_id } = Collection;

  const props = propsCollection;
  const set_props = set_propsCollection;

  const owner = props.collection_owner_user;
  const members = props.collection_members;

  // auth_flg:
  // true
  // collection_id:
  // 47
  // join_flg:
  // true
  // owner_flg:
  // true
  // reward_first:
  // '30'
  // reward_second:
  // '30'
  // user_icon_mime:
  // 'image/webp'
  // user_icon_uri:
  // 'https://www-staging.tmty.io/uploads/profile/9400dac9-1a53-4653-8f4d-93df42b12cd4/01G55NQWKGEJYX2TYJNGKM53MC.webp?t=1654826726'
  // user_id:
  // '9400dac9-1a53-4653-8f4d-93df42b12cd4'
  // user_name:
  // 'むく'
  // user_nmid:
  // 'mukumoto'

  return (
    <>
      <div action="collection-detail.html" entype="multpart/form-data">
        <div className="main-header pt-3 pb-2">
          <h2 className="h4 m-0 text-truncate">{t("コラボコレクションを変更")}</h2>
          <div className="btn-group">
            <a
              className="btn btn-outline-secondary text-dark rounded-pill px-3 me-2 text-nowrap"
              href="javascript:history.back()"
            >
              <i className="fa-solid fa-xmark" />
              <span className="d-none d-sm-inline-block"> {t("キャンセル")}</span>
            </a>
            <button
              type="button"
              name="confirm"
              className="btn btn-primary rounded-pill px-3 text-nowrap"
              role="button"
              // data-bs-toggle="modal"
              // data-bs-target="#processDialog"
              onClick={(e) => {
                e.preventDefault();
                // showModal("modalSuccessfully");

                dispatch_put_collections_id(
                  {
                    id: props.collection_id,
                    category_id: props.category_id,
                    collection_icon: props.collection_icon,
                    collection_cover: props.collection_cover,
                    collection_nmid: props.collection_nmid,
                    name: props.collection_name,
                    collection_description: props.collection_description,
                    link_twitter: props.link_twitter,
                    link_facebook: props.link_facebook,
                    link_discord: props.link_discord,
                    link_instagram: props.link_instagram,
                    link_other: props.link_other,
                    active_flg: true,
                    collabo_flg: props.collection_collabo_flg,
                    collection_user_name: props.collection_user_name,
                    collection_user_icon: props.collection_user_icon,
                    collection_members: props.collection_members,
                  },
                  () => {
                    history.push(`/collections/${props.collection_id}/items`);
                  },
                  () => {}
                );
              }}
            >
              {t("保存")}
            </button>
          </div>
        </div>
        {/* <div className="main-header pt-3 pb-2">
          <h2 className="h4 m-0 text-truncate">{t("コラボコレクションを作成")}</h2>
          <div className="btn-group">
            <a
              className="btn btn-outline-secondary text-dark rounded-pill px-3 me-2 text-nowrap"
              href="javascript:history.back()"
            >
              <i className="fa-solid fa-xmark" />
              <span className="d-none d-sm-inline-block"> {t("キャンセル")}</span>
            </a>
            <button
              type="button"
              name="confirm"
              className="btn btn-primary rounded-pill px-3 text-nowrap"
              role="button"
              // data-bs-toggle="modal"
              // data-bs-target="#processDialog"
              onClick={() => {
                showModal("processDialog");
              }}
            >
              {t("保存")}
            </button>
          </div>
        </div> */}
        <div className="row g-3 g-lg-4">
          <div className="col-lg-6 col-xl-7">
            <div className="row row-cols-1 g-3">
              <div className="col">
                <h3 className="h5 border-bottom border-2 border-dark mb-3 pb-2">{t("基本設定")}</h3>
                {}
                <div className="row g-2 mb-4">
                  <div className="col-md-4 order-md-last">
                    <div className="row g-2 mb-2">
                      {/* <div className="col-sm col-md-12 col-xxl"> */}
                      <div className="col-sm col-md-12">
                        {/* <div
                        className="form-file border rounded-2 p-2 d-flex flex-column justify-content-center"
                        style={{
                          minHeight: "190px",
                        }}
                      >
                        <div className="mb-2">
                          <span className="text-muted">コレクションのアイコン</span>
                        </div>
                        {}
                        <div className="d-flex justify-content-center my-auto">
                          <label
                            htmlFor="collectionLogo"
                            className="btn btn-link bg-light text-secondary py-4 px-5 m-0"
                          >
                            <i className="fa-solid fa-file-arrow-up fa-2x" />
                          </label>
                          <input type="file" id="collectionLogo" required />
                        </div>
                        {}
                      </div> */}

                        <CustomImage
                          id="collectionLogo"
                          title={t("コレクションアイコン")}
                          image_path={props.collection_icon_uri}
                          image={props.collection_icon}
                          mime_type={props.collection_icon_mime}
                          set_image_path={(base64, file) => {
                            if (
                              ![
                                "image/jpeg",
                                "image/png",
                                "image/svg+xml",
                                "image/gif",
                                "image/webp",
                                // "video/mp4",
                              ].includes(file.type)
                            ) {
                              alert(t("対応するメディアではありません"));
                              return;
                            }

                            set_props({
                              ...props,
                              collection_icon: file,
                              collection_icon_mime: file.type,
                              collection_icon_uri: base64,
                            });
                          }}
                        />
                      </div>
                      <div className="col">
                        {/* <div
                        className="form-file border rounded-2 p-2 d-flex flex-column justify-content-center"
                        style={{
                          minHeight: "190px",
                        }}
                      >
                        <div className="mb-2">
                          <span className="text-muted">カバー画像</span>
                        </div>
                        {}
                        <div className="d-flex justify-content-center my-auto">
                          <label
                            htmlFor="collectionCover"
                            className="btn btn-link bg-light text-secondary py-4 px-5 m-0"
                          >
                            <i className="fa-solid fa-file-arrow-up fa-2x" />
                          </label>
                          <input type="file" id="collectionCover" />
                        </div>
                        {}
                      </div> */}
                        <CustomImage
                          id="collectionCover"
                          title={t("カバー画像")}
                          image_path={props.collection_cover_uri}
                          image={props.collection_cover}
                          mime_type={props.collection_cover_mime}
                          set_image_path={(base64, file) => {
                            if (
                              ![
                                "image/jpeg",
                                "image/png",
                                "image/svg+xml",
                                "image/gif",
                                "image/webp",
                                // "video/mp4",
                              ].includes(file.type)
                            ) {
                              alert(t("対応するメディアではありません"));
                              return;
                            }

                            set_props({
                              ...props,
                              collection_cover: file,
                              collection_cover_mime: file.type,
                              collection_cover_uri: base64,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-2">
                      <select
                        onChange={(e) => {
                          const val = e.target.value;
                          set_props({
                            ...props,
                            category_id: val,
                          });
                        }}
                        className="form-select"
                        id="collectionCategory"
                        aria-label="\u30AB\u30C6\u30B4\u30EA"
                        value={props.category_id}
                      >
                        {Object.keys(maps.nft_categories).map((k, idx) => {
                          return <option value={k}>{t(maps.nft_categories[k])}</option>;
                        })}
                      </select>
                      <label htmlFor="collectionCategory">{t("カテゴリ")}</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="collectionName"
                        placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3\u540D"
                        maxLength={40}
                        onChange={(e) => {
                          const val = e.target.value;
                          set_props({
                            ...props,
                            collection_name: val,
                          });
                        }}
                        value={props.collection_name}
                      />
                      <label htmlFor="collectionName">{t("コレクション名")}</label>
                    </div>
                    <div className="form-floating mb-2">
                      <textarea
                        type="text"
                        className="form-control"
                        id="collectionDisc"
                        placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3\u306E\u8AAC\u660E"
                        maxLength={300}
                        style={{
                          height: "256px",
                        }}
                        defaultValue={""}
                        onChange={(e) => {
                          const val = e.target.value;
                          set_props({
                            ...props,
                            collection_description: val,
                          });
                        }}
                        value={props.collection_description}
                      />
                      <label htmlFor="collectionDisc">{t("コレクションの説明")}</label>
                      <div id="collectionDiscHelp" className="form-text">
                        最大1,000文字
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <h4 className="h6 mb-1">{t("クリエイター報酬")}</h4>
                  <small className="d-block mb-3 text-muted">
                    {t("最大10％まで設定できます。")}
                  </small>
                  <div className="row row-cols-1 row-cols-md-2 g-2">
                    <div className="col">
                      <div className="input-group">
                        <select
                          className="form-select"
                          aria-label="Creator Rewards"
                          aria-describedby="rewards"
                          value={props.collection_creator_reward}
                          onChange={(e) => {
                            const val = e.target.value;
                            set_props({
                              ...props,
                              collection_creator_reward: val,
                            });
                          }}
                          disabled
                        >
                          <option>—</option>
                          <option>0.5</option>
                          <option>1</option>
                          <option>1.5</option>
                          <option>2</option>
                          <option>2.5</option>
                          <option>3</option>
                          <option>3.5</option>
                          <option>4</option>
                          <option>4.5</option>
                          <option>5</option>
                          <option>5.5</option>
                          <option>6</option>
                          <option>6.5</option>
                          <option>7</option>
                          <option>7.5</option>
                          <option>8</option>
                          <option>8.5</option>
                          <option>9</option>
                          <option>9.5</option>
                          <option>10</option>
                        </select>
                        <span className="input-group-text" id="rewards">
                          <i className="fa-solid fa-percent" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="mb-4">
                  <h4 className="h6">{t("リンク設定")}</h4>
                  <div className="row row-cols-1 row-cols-md-2 g-2">
                    <div className="col">
                      <div className="form-floating">
                        <input
                          type="url"
                          className="form-control"
                          id="collectionLinkTwitter"
                          placeholder="Twitter"
                          onChange={(e) => {
                            const val = e.target.value;
                            set_props({
                              ...props,
                              link_twitter: val,
                            });
                          }}
                          value={props.link_twitter}
                        />
                        <label htmlFor="collectionLinkTwitter">Twitter</label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating">
                        <input
                          type="url"
                          className="form-control"
                          id="collectionLinkFacebbok"
                          placeholder="Facebook"
                          onChange={(e) => {
                            const val = e.target.value;
                            set_props({
                              ...props,
                              link_facebook: val,
                            });
                          }}
                          value={props.link_facebook}
                        />
                        <label htmlFor="collectionLinkFacebbok">Facebook</label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating">
                        <input
                          type="url"
                          className="form-control"
                          id="collectionLinkDiscord"
                          placeholder="Discord"
                          onChange={(e) => {
                            const val = e.target.value;
                            set_props({
                              ...props,
                              link_discord: val,
                            });
                          }}
                          value={props.link_discord}
                        />
                        <label htmlFor="collectionLinkDiscord">Discord</label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating">
                        <input
                          type="url"
                          className="form-control"
                          id="collectionLinkInstagram"
                          placeholder="Instagram"
                          onChange={(e) => {
                            const val = e.target.value;
                            set_props({
                              ...props,
                              link_instagram: val,
                            });
                          }}
                          value={props.link_instagram}
                        />
                        <label htmlFor="collectionLinkInstagram">Instagram</label>
                      </div>
                    </div>
                    {/* <div className="col">
                    <div className="form-floating">
                      <input
                        type="url"
                        className="form-control"
                        id="collectionLinkWeb"
                        placeholder="\u305D\u306E\u4ED6URL"
                      />
                      <label htmlFor="collectionLinkWeb">その他URL</label>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <h3 className="h5 border-bottom border-2 border-dark mb-3 pb-2">コラボチーム設定</h3>
            <div className="row g-2 mb-3">
              <div className="col-md-4 order-md-last">
                {/* <div
                className="form-file border rounded-2 p-2 d-flex flex-column justify-content-center mb-2"
                style={{
                  minHeight: "130px",
                }}
              >
                <div className="mb-2">
                  <span className="text-muted">コラボのアイコン</span>
                </div>
                <div className="d-flex justify-content-center my-auto">
                  <label
                    htmlFor="teamLogo"
                    className="btn btn-link bg-light text-secondary py-4 px-5 m-0"
                  >
                    <i className="fa-solid fa-file-arrow-up fa-2x" />
                  </label>
                  <input type="file" id="teamLogo" required />
                </div>
              </div> */}
                <CustomImage
                  id="collaboLogo"
                  title={t("コラボチームアイコン")}
                  minHeight={"130px"}
                  image_path={props.collection_user_icon_uri}
                  image={props.collection_user_icon}
                  mime_type={props.collection_user_icon_mime}
                  set_image_path={(base64, file) => {
                    if (
                      ![
                        "image/jpeg",
                        "image/png",
                        "image/svg+xml",
                        "image/gif",
                        "image/webp",
                        // "video/mp4",
                      ].includes(file.type)
                    ) {
                      alert(t("対応するメディアではありません"));
                      return;
                    }

                    set_props({
                      ...props,
                      collection_user_icon: file,
                      collection_user_icon_mime: file.type,
                      collection_user_icon_uri: base64,
                    });
                  }}
                />
              </div>
              <div className="col">
                <div className="form-floating mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="teamnName"
                    placeholder="\u30C8\u30E2\u30CB\u30C6\u30A3\u30B3\u30E9\u30DC"
                    maxLength={40}
                    onChange={(e) => {
                      const val = e.target.value;
                      set_props({
                        ...props,
                        collection_user_name: val,
                      });
                    }}
                    value={props.collection_user_name}
                  />
                  <label htmlFor="teamnName">{t("コラボチーム名")}</label>
                </div>
                <div className="form-floating mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="teamID"
                    placeholder="@tomonity_team"
                    maxLength={40}
                    onChange={(e) => {
                      // const val = e.target.value;
                      // set_props({
                      //   ...props,
                      //   collection_user_nmid: val,
                      // });
                    }}
                    value={props.collection_user_nmid}
                    disabled
                  />
                  <label htmlFor="teamID">{t("コラボチームID")}</label>
                </div>
              </div>
            </div>
            <div className="mb-4 overflow-hidden">
              <h4 className="h6 mb-1">コラボメンバー</h4>
              {}
              <div className="row g-3 mb-2 border-bottom border-2">
                <div className="col"> </div>
                <div className="col text-nowrap text-end">
                  <b>権限</b>
                  <span
                    className="ms-1"
                    data-bs-toggle="tooltip"
                    data-bs-html="true"
                    title="\u300C\u30A2\u30AB\u30A6\u30F3\u30C8\u306E\u7DE8\u96C6\u300D\u300C\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3\u306E\u7DE8\u96C6\u300D\u300C\u30A2\u30A4\u30C6\u30E0\u306E\u8FFD\u52A0\u300D\u306E\u6A29\u9650\u3092\u4E0E\u3048\u308B\u30A2\u30AB\u30A6\u30F3\u30C8\u306E\u30B9\u30A4\u30C3\u30C1\u3092ON\u306B\u3057\u307E\u3059\u3002"
                  >
                    <i className="fa-solid fa-circle-question" />
                  </span>
                </div>
                <div className="col-5">
                  <b>報酬の分配</b>
                  <div className="row g-1 mb-1">
                    <div className="col text-center text-truncate">
                      <span className="d-block mt-sm-1 small lh-1">一次{}</span>
                    </div>
                    <div className="col text-center text-truncate">
                      <span className="d-block mt-sm-1 small lh-1">二次{}</span>
                    </div>
                  </div>
                </div>
              </div>
              {}
              <h4 className="h6 mb-2">オーナー</h4>
              <div className="row g-3 mb-3">
                <div className="col overflow-hidden">
                  <div
                    className="media media-sm"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Japantic @Japan_Chic"
                  >
                    <div className="media-object">
                      <div className="icon icon-pill">
                        <img
                          src={
                            owner.user_icon_uri
                              ? owner.user_icon_uri
                              : "/dist/tmty/assets/default/icon.webp"
                          }
                          className="icon-item"
                          width="100%"
                          height="auto"
                          alt="Japantic"
                        />
                      </div>
                    </div>
                    <div className="media-body text-truncate">
                      <a className="d-block small text-truncate" href="index.html" target="_blank">
                        {owner.user_name}
                      </a>
                      <small className="d-block text-muted text-truncate">@{owner.user_nmid}</small>
                    </div>
                  </div>
                </div>
                <div className="col-auto text-end">
                  <div className="form-check-reverse form-switch m-0 py-0 ms-2 justify-content-end">
                    <label className="form-check-label" htmlFor="admin-1" />
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="admin-1"
                      defaultChecked
                      disabled
                    />
                  </div>
                </div>
                <div className="col-5">
                  <div className="row g-1">
                    <div className="col text-center">{owner.reward_first}%</div>
                    <div className="col text-center">{owner.reward_second}%</div>
                  </div>
                </div>
              </div>
              <h4 className="h6 mb-0">メンバー</h4>
              <ul className="list-group list-group-flush">
                {members.map((m, idx) => {
                  return (
                    <li className="list-group-item px-0">
                      <div className="row g-3">
                        <div className="col overflow-hidden">
                          <div
                            className="media media-sm"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                          >
                            <div className="media-object">
                              <div className="icon icon-pill">
                                <img
                                  src={m.user_icon_uri}
                                  className="icon-item"
                                  width="100%"
                                  height="auto"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="media-body text-truncate">
                              <a
                                className="d-block small text-truncate"
                                href="index.html"
                                target="_blank"
                              >
                                {m.user_name}
                              </a>
                              <small className="d-block text-muted text-truncate">
                                @{m.user_nmid}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end">
                          <div className="form-check-reverse form-switch m-0 ms-2 py-0 justify-content-end">
                            <label className="form-check-label" htmlFor="admin-2" />
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="admin-2"
                              checked={m.auth_flg}
                              onClick={(e) => {
                                const val = e.target.value;
                                const members = props.collection_members;
                                members[idx].auth_flg = !members[idx].auth_flg;
                                set_props({
                                  ...props,
                                  collection_members: members,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="row g-1">
                            <div className="col text-center">{m.reward_first}%</div>
                            <div className="col text-center">{m.reward_second}%</div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              {}
            </div>
            <div className="alert alert-warning" role="alert">
              <div className="d-flex align-items-center mb-2 ps-2">
                <i className="fa-solid fa-triangle-exclamation me-2" />
                <span className="d-block fw-bold">ご注意ください</span>
              </div>
              <ul>
                <li className="mb-2">
                  コラボコレクションはメンバー全員が参加した時点で正式スタート(一般公開)となります。
                </li>
                <li className="mb-2">
                  1人でもメンバーの承認が得られなかった場合は、コラボコレクションを再度作成する必要があります。
                </li>
                <li className="mb-2">
                  「クリエイター報酬」「報酬の分配」全ての報酬割合（%）は、設定後変更できません。
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
