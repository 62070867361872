// import { post_accounts_kycs } from "stores_api/accounts_kycs";

import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import ImageUploader from "../../components/CustomUploads";

// const [images, set_images] = React.useState([]);
{/* <MultiImageUpload images={images} set_images={set_images} /> */}

const AppContainer = ({ images, set_images}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const onDrop = (pictureFiles, pictureDataURLs) => {
    // set_images(images.concat(pictureFiles));
    set_images(pictureFiles);
  };

  return (
    <>
      <ImageUploader
        style={{ marginLeft: 15, marginRight: 15 }}
        withIcon={false}
        withPreview={true}
        label=""
        buttonText={t("画像をアップロード")}
        onChange={onDrop}
        imgExtension={[".jpg", ".gif", ".png", ".webp", ".svg"]}
        // imgExtension={[".jpg", ".jpeg", ".png"]}
        maxFileSize={10000000}
        // 1000m1000k
        // 10MB
        fileSizeError=" file size is too big"
      />
    </>
  );
};

export default AppContainer;
