import { createReducer } from "redux-act";

import {
  post_variable,
  post_authin,
  post_authin_data,
  post_authin_error,
  post_authout,
  post_authouthome,
  post_authin_code,
  post_authin_code_data,
  post_authin_code_error,
  post_register,
  post_register_data,
  post_register_error,
  post_register_code,
  post_register_code_data,
  post_register_code_error,
  post_register_resend,
  post_register_resend_data,
  post_register_resend_error,
  post_password,
  post_password_data,
  post_password_error,
  post_password_code,
  post_password_code_data,
  post_password_code_error,
  post_password_reset,
  post_password_reset_data,
  post_password_reset_error,
  get_maps,
  get_maps_data,
  get_maps_error,
  post_contact,
  post_contact_data,
  post_contact_error,
} from "./actions";

export const reduce_variable = createReducer(
  {
    [post_variable]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload });
      return newState;
    },
  },
  {}
);

export const reduce_post_authin = createReducer(
  {
    [post_authin]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_authin_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.hash = payload.data.hash;
      newState.isLoading = false;
      return newState;
    },
    [post_authin_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [post_authout]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.token = "";
      return newState;
    },
    [post_authouthome]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.token = "";
      return newState;
    },
  },
  {
    token: "",
    isLoading: false,
  }
);

export const reduce_post_authin_code = createReducer(
  {
    [post_authin_code]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_authin_code_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.hash = payload.data.hash;
      newState.isLoading = false;
      return newState;
    },
    [post_authin_code_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_register = createReducer(
  {
    [post_register]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_register_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.hash = payload.data.hash;
      newState.isLoading = false;
      return newState;
    },
    [post_register_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_register_code = createReducer(
  {
    [post_register_code]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_register_code_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [post_register_code_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_register_resend = createReducer(
  {
    [post_register_resend]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_register_resend_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [post_register_resend_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_password = createReducer(
  {
    [post_password]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_password_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.hash = payload.data.hash;
      newState.isLoading = false;
      return newState;
    },
    [post_password_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_password_code = createReducer(
  {
    [post_password_code]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_password_code_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.hash = payload.data.hash;
      newState.isLoading = false;
      return newState;
    },
    [post_password_code_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_password_reset = createReducer(
  {
    [post_password_reset]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_password_reset_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.hash = payload.data.hash;
      newState.isLoading = false;
      return newState;
    },
    [post_password_reset_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_get_maps = createReducer(
  {
    [get_maps]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_maps_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_maps_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);

export const reduce_post_contact = createReducer(
  {
    [post_contact]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_contact_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_contact_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);

export default {
  reduce_variable,
  reduce_get_maps,
  reduce_post_contact,
  reduce_post_authin,
  reduce_post_authin_code,
  reduce_post_register,
  reduce_post_register_code,
  reduce_post_register_resend,
  reduce_post_password,
  reduce_post_password_code,
  reduce_post_password_reset,
};
