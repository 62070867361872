import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import Menu from "./Menu";
import Logout from "./Logout";
import { view_user } from "../../stores/Users/view";
import LiLanguage from "components/Li/LiLanguage";

export default function Contents() {
  const dispatch = useDispatch();
  const history2 = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  const user = reduce_get_accounts.user;
  const v_user = view_user(user);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts?.user?.mail_address;
  const user_name = reduce_get_accounts?.user?.username;

  const history = {
    push: (arg) => {
      const globalNav = document.getElementById("globalNav");
      const modal = window.bootstrap.Offcanvas.getInstance(globalNav);
      if (modal) {
        modal.hide();
      }

      history2.push(arg);
    },
  };

  return (
    <>
      <nav
        className="offcanvas offcanvas-start flex-shrink-0 flex-grow-0"
        tabIndex={-1}
        id="globalNav"
        aria-labelledby="globalNavLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="globalNavLabel">
            <a
              className="tmty-brand tmty-brand-full d-md-none"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/home`,
                });
              }}
            >
              <img
                src="/dist/tmty/assets/brand/tomonity-typography.webp"
                width="auto"
                height="auto"
                alt="tomonity"
              />
            </a>
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body align-items-center p-0">
          <div className="navbar-nav text-nowrap">
            {!token && (
              <div className="btn-group px-3 mb-3 d-md-none">
                <a
                  className="btn btn-primary rounded-pill w-100 me-2"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `/signup`,
                    });
                  }}
                >
                  {t("アカウントを作成")}
                </a>
                <a
                  className="btn btn-outline-primary rounded-pill w-100"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    const pathname = window.location.pathname;
                    sessionStorage.setItem("PATH_FIRST", pathname);
                    history.push({
                      pathname: `/signin`,
                    });
                  }}
                >
                  {t("ログイン")}
                </a>
              </div>
            )}

            {/* <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/collections`,
                });
              }}
            >
              検索
            </a> */}

            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/categories`,
                });
              }}
            >
              {t("カテゴリ")}
            </a>
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/rankings`,
                });
              }}
            >
              {t("ランキング")}
            </a>
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/activities`,
                });
              }}
            >
              {t("アクティビティ")}
            </a>

            <div className="dropdown mt-3 mt-md-0">
              <button
                type="button"
                className="btn btn-white dropdown-toggle"
                id="languageNav"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                <i className="fa-solid fa-earth-asia me-1" />
                {i18n.language === "ja" && "日本語"}
                {i18n.language === "en" && "English"}
              </button>
              <div
                className="dropdown-menu dropdown-menu-end"
                style={{
                  width: "280px",
                }}
              >
                <ul className="nav nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      {t("言語")}
                    </button>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          通貨
                        </button>
                      </li> */}
                </ul>
                <div className="tab-content" id="pills-tabContent" aria-labelledby="languageNav">
                  <div className="tab-content" id="pills-tabContent" aria-labelledby="languageNav">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <ul className="list-unstyled row row-cols-2 g-2 mb-0">
                        <li className="col">
                          <button
                            type="button"
                            className={`dropdown-item ${i18n.language === "en" ? "active" : ""}`}
                            onClick={(e) => {
                              e.preventDefault();
                              i18n.changeLanguage("en");
                              sessionStorage.setItem("AcceptLanguage", "en");
                            }}
                          >
                            English
                          </button>
                        </li>
                        {/* <li className="col">
                              <button type="button" className="dropdown-item">
                                Español
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                Français
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                Português
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                Русский
                              </button>
                            </li> */}
                        <li className="col">
                          <button
                            type="button"
                            className={`dropdown-item ${i18n.language === "ja" ? "active" : ""}`}
                            onClick={(e) => {
                              e.preventDefault();
                              i18n.changeLanguage("ja");
                              sessionStorage.setItem("AcceptLanguage", "ja");
                            }}
                          >
                            日本語
                          </button>
                        </li>
                        {/* <li className="col">
                              <button type="button" className="dropdown-item">
                                简体中文
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                繁体中文
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                한국어
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                Tiếng Việt
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                Indonesian
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                ภาษาไทย
                              </button>
                            </li> */}
                      </ul>
                    </div>
                    {/* <div
                          className="tab-pane fade"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <ul className="list-unstyled row row-cols-2 g-2 mb-0">
                            <li>
                              <button type="button" className="dropdown-item active">
                                <small>
                                  USD <span className="text-muted">—</span>{" "}
                                </small>
                                $
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                <small>
                                  CNY <span className="text-muted">—</span>{" "}
                                </small>
                                £
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                <small>
                                  JPY <span className="text-muted">—</span>{" "}
                                </small>
                                ¥
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                <small>
                                  KRW <span className="text-muted">—</span>{" "}
                                </small>
                                ₩
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                <small>
                                  EUR <span className="text-muted">—</span>{" "}
                                </small>
                                €
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                <small>
                                  GBP <span className="text-muted">—</span>{" "}
                                </small>
                                £
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                <small>
                                  HKD <span className="text-muted">—</span>{" "}
                                </small>
                                $
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                <small>
                                  IDR <span className="text-muted">—</span>{" "}
                                </small>
                                Rp
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                <small>
                                  INR <span className="text-muted">—</span>{" "}
                                </small>
                                ₹
                              </button>
                            </li>
                            <li className="col">
                              <button type="button" className="dropdown-item">
                                <small>
                                  RUB <span className="text-muted">—</span>{" "}
                                </small>
                                ₽
                              </button>
                            </li>
                          </ul>
                        </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* {token && (
              <a
                className="nav-link d-none d-sm-block"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  // history.push({
                  //   pathname: `/home`,
                  // });
                  history.push({
                    pathname: `/users/${v_user.user_id}/created`,
                  });
                }}
              >
                {user_name}
              </a>
            )} */}
          </div>
        </div>
      </nav>
    </>
  );
}
