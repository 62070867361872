import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { useSpring, animated } from "react-spring";
import Modal from "@material-ui/core/Modal";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CardIcon from "components/Card/CardIcon.js";
import Close from "@material-ui/icons/Close";
import styles from "assets/jss/chaintier/views/regularFormsStyle";

const useStylesModal = makeStyles((theme) => ({
  card: {
    marginTop: "10px !important",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "95%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "95%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40%",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "0px solid #000",
    boxShadow: theme.shadows[5],
    borderRadius: 6,
  },
  card_lg: {
    marginTop: "10px !important",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "95%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "95%",
    },
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "60%",
    },
  },
  card_ex: {
    marginTop: "10px !important",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "95%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "95%",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80%",
    },
  },
  card_xl: {
    marginTop: "10px !important",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "95%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "95%",
    },
    [theme.breakpoints.up("md")]: {
      width: "95%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "95%",
    },
  },
  card_max: {
    marginTop: "0px !important",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "0px solid #000",
    boxShadow: theme.shadows[5],
    borderRadius: 6,
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export default function CustomModal({
  modal,
  close_modal,
  children,
  size,
  className,
  color,
  title,
  Icon,
}) {
  const useStyles = makeStyles(styles);

  const classesModal = useStylesModal();
  const classes = useStyles();

  React.useEffect(() => {
    return function cleanup() {};
  }, []);

  let classCard = classesModal.card;
  let cardstyle = {};
  let maxBodyHeight = window.innerHeight - 140;

  if (!Icon) {
    cardstyle.marginTop = 0;
  }

  switch (size) {
    case "md":
      classCard = classesModal.card;
      break;
    case "lg":
      classCard = classesModal.card_lg;
      break;
    case "ex":
      classCard = classesModal.card_ex;
      break;
    case "xl":
      classCard = classesModal.card_xl;
      break;
    case "max":
      classCard = classesModal.card_max;
      cardstyle.height = window.innerHeight;
      maxBodyHeight = window.innerHeight - (49 + 15 + 15);
      break;

    default:
      break;
  }

  return (
    <Modal
      open={modal}
      onClose={() => {
        close_modal();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classesModal.modal + " " + className}
      closeAfterTransition
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   timeout: 400,
      // }}
    >
      {/* <Fade
        in={modal}
        onClose={() => {
          close_modal();
        }}
      > */}
      <div
        onMouseDown={() => {
          close_modal();
        }}
      >
        <div
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          className={classCard}
        >
          <Card className="modal-card" style={cardstyle}>
            <CardHeader color={color} icon>
              {Icon && (
                <CardIcon color={color} onClick={() => {}}>
                  {Icon}
                </CardIcon>
              )}
              <h4 className={classes.cardIconTitle}>
                {title}
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => {
                    close_modal();
                  }}
                >
                  <Close className={classes.modalClose} />
                </Button>
              </h4>
            </CardHeader>
            <CardBody>
              <div
                style={{
                  overflowX: "hidden",
                  overflowY: "auto",
                  maxHeight: maxBodyHeight,
                }}
              >
                {children}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      {/* </Fade> */}
    </Modal>
  );
}
