import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  zeroPadding,
} from "config/util";

export const view_user = (prop, maps, t) => {
  if (!prop) return {};
  return {
    ...prop,
    user_icon_uri: prop.user_icon_uri ? prop.user_icon_uri : "/dist/tmty/assets/default/icon.webp",
    user_icon_mime: prop.user_icon_mime ? prop.user_icon_mime : "",
    user_name: prop.display_name,
    user_message: prop.user_message,
    user_nmid: prop.user_nmid ? prop.user_nmid : prop.username,
    user_id: prop.user_id,
    user_uuid: prop.user_id,
  };
};

// import { view_user } from "../../stores/Users/view";
// const user = reduce_get_accounts.user;
// const v_user = view_user(user);
