import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
// import Sidebar from "./Sidebar";
import { view_collection } from "../../stores/Collections/view";
import { view_user } from "../../stores/Users/view";

export default function Contents({ Page }) {
  const { type, dispatch_type, word, set_word } = Page;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();
  const { user_id } = useParams();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_users = useSelector((state) => state.reduce_get_users);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const collection = useSelector((state) => state.reduce_get_items.collection);
  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection);

  const user = reduce_get_users.user;
  const v_user = view_user(user);

  return (
    <>
      <div className="main-header mb-0">
        <div className="container ps-0">
          <ul className="nav canopy">
            <li className="nav-item">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch_type("collections");
                }}
                className={`nav-link ${type == "collections" && "active"}`}
                aria-current="page"
              >
                <span className="canopy-text">{t("コレクション")}</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch_type("items");
                }}
                className={`nav-link ${type == "items" && "active"}`}
              >
                <span className="canopy-text">{t("アイテム")}</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch_type("users");
                }}
                className={`nav-link ${type == "users" && "active"}`}
              >
                <span className="canopy-text">{t("ユーザ")}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
