import {
  fork,
  take,
  put,
  join,
  select,
  takeLatest,
  takeEvery,
  call,
} from "redux-saga/effects";

import { post_authout } from "../Auth/actions";

import {
  api_get_categories,
  api_get_categories_id,
  api_post_categories,
  api_put_categories_id,
  api_delete_categories_id,
} from "./api";

import {
  get_categories,
  get_categories_data,
  get_categories_error,
  get_categories_id,
  get_categories_id_data,
  get_categories_id_error,
  post_categories,
  post_categories_data,
  post_categories_error,
  put_categories_id,
  put_categories_id_data,
  put_categories_id_error,
  delete_categories_id,
  delete_categories_id_data,
  delete_categories_id_error,
} from "./actions";

export function* run_get_categories({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_categories, payload.data, token);
  if (!error) {
    yield put(get_categories_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_categories_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_categories_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(
    api_get_categories_id,
    payload.data,
    token
  );
  if (!error) {
    yield put(get_categories_id_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_categories_id_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_categories({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_categories, payload.data, token);
  if (!error) {
    yield put(post_categories_data({ data }));
    yield put(get_categories(payload));
  } else {
    yield put(post_categories_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_categories_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(
    api_put_categories_id,
    payload.data,
    token
  );
  if (!error) {
    yield put(put_categories_id_data({ data }));
    yield put(get_categories_id(payload));
  } else {
    yield put(put_categories_id_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_categories_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(
    api_delete_categories_id,
    payload.data,
    token
  );
  if (!error) {
    yield put(delete_categories_id_data({ data }));
    yield put(get_categories(payload));
  } else {
    yield put(delete_categories_id_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* flow_get_categories() {
  yield takeLatest(get_categories, run_get_categories);
}
export function* flow_get_categories_id() {
  yield takeLatest(get_categories_id, run_get_categories_id);
}
export function* flow_post_categories() {
  yield takeLatest(post_categories, run_post_categories);
}
export function* flow_put_categories_id() {
  yield takeLatest(put_categories_id, run_put_categories_id);
}
export function* flow_delete_categories_id() {
  yield takeLatest(delete_categories_id, run_delete_categories_id);
}

export default function* rootSaga() {
  yield fork(flow_get_categories);
  yield fork(flow_get_categories_id);
  yield fork(flow_post_categories);
  yield fork(flow_put_categories_id);
  yield fork(flow_delete_categories_id);
  
}
