import axios from "../axios";
import env from "../env";

export const api_post_authin = (data) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`authin`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_authin_code = (data, hash) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`authin/code/${hash}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_register = (data) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`register`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_register_code = (data, hash) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`register/code`}/${hash}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_register_resend = (data, hash) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`register/resend`}/${hash}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_password = (data) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`password`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_password_code = (data, hash) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`password/code`}/${hash}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_password_reset = (data, hash) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`password/reset`}/${hash}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
    data,
  };
  return axios(options);
};

export const api_get_maps = (data) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`maps`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};



export const api_post_contact = (data, hash) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`contact`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
    data,
  };
  return axios(options);
};