import {
  fork,
  take,
  put,
  join,
  select,
  takeLatest,
  takeEvery,
  call,
} from "redux-saga/effects";

import { post_authout } from "../Auth/actions";

import {
  api_get_items,
  api_get_items_id,
  api_post_items,
  api_put_items_id,
  api_delete_items_id,
  api_post_orders,
  api_post_send,
  api_put_items_id_favorite,
} from "./api";

import {
  get_items,
  get_items_data,
  get_items_error,
  get_items_id,
  get_items_id_data,
  get_items_id_error,
  post_items,
  post_items_data,
  post_items_error,
  put_items_id,
  put_items_id_data,
  put_items_id_error,
  delete_items_id,
  delete_items_id_data,
  delete_items_id_error,
  post_orders,
  post_orders_data,
  post_orders_error,
  post_send,
  post_send_data,
  post_send_error,
  put_items_id_favorite,
  put_items_id_favorite_data,
  put_items_id_favorite_error,
} from "./actions";

export function* run_get_items({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_items, payload.data, token);
  if (!error) {
    yield put(get_items_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_items_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_items_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(
    api_get_items_id,
    payload.data,
    token
  );
  if (!error) {
    yield put(get_items_id_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_items_id_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_items({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_items, payload.data, token);
  if (!error) {
    yield put(post_items_data({ data }));
    yield put(get_items(payload));
  } else {
    yield put(post_items_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_items_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(
    api_put_items_id,
    payload.data,
    token
  );
  if (!error) {
    yield put(put_items_id_data({ data }));
    yield put(get_items_id(payload));
  } else {
    yield put(put_items_id_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_items_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(
    api_delete_items_id,
    payload.data,
    token
  );
  if (!error) {
    yield put(delete_items_id_data({ data }));
    yield put(get_items(payload));
  } else {
    yield put(delete_items_id_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}


export function* run_post_orders({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_orders, payload.data, token);
  if (!error) {
    yield put(post_orders_data({ data }));
    yield put(get_items(payload));
    // yield put(get_orders(payload));
  } else {
    yield put(post_orders_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_send({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_send, payload.data, token);
  if (!error) {
    yield put(post_send_data({ data }));
    yield put(get_items(payload));
    // yield put(get_send(payload));
  } else {
    yield put(post_send_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_items_id_favorite({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(
    api_put_items_id_favorite,
    payload.data,
    token
  );
  if (!error) {
    yield put(put_items_id_favorite_data({ data }));
    yield put(get_items_id(payload));
  } else {
    yield put(put_items_id_favorite_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* flow_get_items() {
  yield takeLatest(get_items, run_get_items);
}
export function* flow_get_items_id() {
  yield takeLatest(get_items_id, run_get_items_id);
}
export function* flow_post_items() {
  yield takeLatest(post_items, run_post_items);
}
export function* flow_put_items_id() {
  yield takeLatest(put_items_id, run_put_items_id);
}
export function* flow_delete_items_id() {
  yield takeLatest(delete_items_id, run_delete_items_id);
}
export function* flow_post_orders() {
  yield takeLatest(post_orders, run_post_orders);
}

export function* flow_post_send() {
  yield takeLatest(post_send, run_post_send);
}

export function* flow_put_items_id_favorite() {
  yield takeLatest(put_items_id_favorite, run_put_items_id_favorite);
}

export default function* rootSaga() {
  yield fork(flow_get_items);
  yield fork(flow_get_items_id);
  yield fork(flow_post_items);
  yield fork(flow_put_items_id);
  yield fork(flow_delete_items_id);
  yield fork(flow_post_orders);
  yield fork(flow_post_send);
  yield fork(flow_put_items_id_favorite);
  
}
