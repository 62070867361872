import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import { view_collection } from "../../stores/Collections/view";
import { view_user } from "../../stores/Users/view";

import UsersPager from "../../views_components/PagerUsers/UsersPager";
import UsersList from "../../views_components/PagerUsers/UsersList";
import { hideModal, showModal } from "config/util";
export default function Contents(Users) {
  const { propsUsers, set_propsUsers, dispatch_gets, user_id } = Users;
  // const { type, dispatch_type } = Page;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_users = useSelector((state) => state.reduce_get_users);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const collection = useSelector((state) => state.reduce_get_items.collection);

  const user = reduce_get_users.user;
  const v_user = view_user(user);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  const collection = useSelector((state) => state.reduce_get_items.collection);
  const v_collection = view_collection(collection);

  return (
    <>
      <div
        className="modal fade"
        id="addMember"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="addMemberLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
          <div className="modal-content h-100">
            <div className="modal-header">
              <h5 className="modal-title text-truncate" id="addMemberLabel">
                メンバーを追加 
                <span className="fs-6 fw-normal">
                  <small>(相互フォローのみ)</small>
                </span>
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body p-0 bg-light">
              <div className="sticky-top bg-secondary">
                <div className="p-2">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="ユーザーを検索..."
                    value={propsUsers.name}
                    onChange={(e) => {
                      const val = e.target.value;
                      // set_propsUsers({
                      //   ...propsUsers,
                      //   name: val,
                      // });
                      dispatch_gets({ ...propsUsers.search, name: val }, 1);
                    }}
                  />
                </div>
              </div>
              <div className="media-group bg-white">
                <UsersPager Users={Users} />
                <UsersList Users={Users} />
              </div>
            </div>
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-primary">
                確定
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
// <div className="modal-content h-100">
//   <div className="modal-header">
//     {/* <h5 className="modal-title text-truncate" id="addMemberLabel">
//       {v_user.user_name}
//     </h5> */}
//     <h5 className="modal-title text-truncate" id="addMemberLabel">
//       メンバーを追加
//       <span className="fs-6 fw-normal">
//         <small>(相互フォローのみ)</small>
//       </span>
//     </h5>
//     <button
//       type="button"
//       className="btn-close"
//       data-bs-dismiss="modal"
//       aria-label="Close"
//     />
//   </div>
//   <div className="modal-body p-0">
//     <ul className="nav nav-pills nav-fill follow-nav" role="tablist">
//       {/* <li className="nav-item" role="presentation">
//         <button
//           className={`nav-link ${propsUsers.search.followings == 1 ? "active" : ""}`}
//           id="pills-following-tab"
//           data-bs-toggle="pill"
//           data-bs-target="#pills-following"
//           type="button"
//           role="tab"
//           aria-controls="pills-following"
//           aria-selected={propsUsers.search.followings == 1 ? "true" : "false"}
//           onClick={() => {
//             // dispatch_gets({ ...propsUsers.search, user_id, followings: 1, followers: 0 });
//           }}
//         >
//           {t("フォロー")}
//         </button>
//       </li>
//       <li className="nav-item" role="presentation">
//         <button
//           className={`nav-link ${propsUsers.search.followers == 1 ? "active" : ""}`}
//           id="pills-followers-tab"
//           data-bs-toggle="pill"
//           data-bs-target="#pills-followers"
//           type="button"
//           role="tab"
//           aria-controls="pills-followers"
//           aria-selected={propsUsers.search.followers == 1 ? "true" : "false"}
//           onClick={() => {
//             // dispatch_gets({ ...propsUsers.search, user_id, followings: 0, followers: 1 });
//           }}
//         >
//           {t("フォロワー")}
//         </button>
//       </li> */}
//     </ul>
//     <div className="tab-content" id="pills-tabContent">
//       <div
//         className="tab-pane media-group px-3 active"
//         id="pills-following"
//         role="tabpanel"
//         aria-labelledby="pills-following-tab"
//       >
//         <UsersPager Users={Users} />
//         <UsersList Users={Users} />
//       </div>
//       <div
//         className="tab-pane media-group px-3"
//         id="pills-followers"
//         role="tabpanel"
//         aria-labelledby="pills-followers-tab"
//       >
//         <UsersPager Users={Users} />
//         <UsersList Users={Users} />
//       </div>
//     </div>
//   </div>
// </div>
// </div>
