import Header from "../../views_components/Component/Header";
import _sidebar from "./_sidebar";
import ItemsPager from "../../views_components/PagerItems/ItemsPager";
import ItemsList from "../../views_components/PagerItems/ItemsList";
import Collections from "@material-ui/icons/Collections";
import _mainheader from "./_mainheader";
import _modaljoin from "./_modaljoin";
import Footer from "../../views_components/Component/Footer";

import ActivitiesPager from "../../views_components/PagerActivities/ActivitiesPager";
import ActivitiesList from "../../views_components/PagerActivities/ActivitiesList";

export default function Contents({ Page, Items, Activities }) {
  const { type, dispatch_type, v_collection, history } = Page;

  const owner_user = v_collection.collection_owner_user;
  const members = v_collection.collection_members;

  const members_joined = members.filter((m) => m.join_flg);
  const members_unjoined = members.filter((m) => !m.join_flg);

  return (
    <>
      <div className="ms-md-3">
        <h3 className="h5 mt-1 mb-0">コラボメンバー</h3>
        <div className="row g-1 mb-2 pb-2 border-bottom">
          <div className="col-auto ms-auto text-end border-end">
            <span className="pe-2">権限</span>
          </div>
          <div className="col-6">
            <span className="ps-2">報酬の分配</span>
            <div className="row g-1 mb-1 ps-2">
              <div className="col text-end text-truncate">
                <span className="d-block mt-sm-1 small lh-1">
                  一次
                  <span className="d-block d-sm-inline d-md-block d-lg-inline ms-sm-1 text-truncate">
                    (販売売上)
                  </span>
                </span>
              </div>
              <div className="col-7 text-end text-truncate">
                <span className="d-block mt-sm-1 small lh-1">
                  二次
                  <span className="d-block d-sm-inline d-md-block d-lg-inline ms-sm-1 text-truncate">
                    (クリエイター報酬)
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <h4 className="h6 mb-2">オーナー</h4>
        <div className="row g-1 mb-3">
          <div className="col overflow-hidden">
            <div
              className="media media-sm"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title=""
            >
              <div className="media-object">
                <div className="icon icon-pill">
                  <img
                    src={owner_user.user_icon_uri}
                    className="icon-item"
                    width="100%"
                    height="auto"
                    alt="Japantic"
                  />
                </div>
              </div>
              <div className="media-body text-truncate">
                <a
                  className="d-block small-xs-only text-truncate"
                  href="#"
                  // target="_blank"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `/users/${owner_user.user_id}`,
                    });
                  }}
                >
                  {owner_user.user_name}
                </a>
                <span className="d-block text-muted small-xs-only lh-1 text-truncate">
                  @{owner_user.user_nmid}
                </span>
              </div>
            </div>
          </div>
          <div className="col-auto text-end border-end">
            <span className="ms-1 me-2 badge bg-success text-light">許可</span>
          </div>
          <div className="col-6">
            <div className="row g-1 mb-1 ps-2">
              <div className="col text-end">{owner_user.reward_first}%</div>
              <div className="col-7 text-end">{owner_user.reward_second}%</div>
            </div>
          </div>
        </div>
        <h4 className="h6 mb-2">メンバー</h4>
        <h5 className="h6 mb-2 pb-1 border-bottom">参加済み</h5>
        <ul className="list-group list-group-flush mb-2">
          {members_joined.map((m) => {
            return (
              <li className="list-group-item px-0">
                <div className="row g-1">
                  <div className="col overflow-hidden">
                    <div
                      className="media media-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                    >
                      <div className="media-object">
                        <div className="icon icon-pill">
                          <img
                            src={m.user_icon_uri}
                            className="icon-item"
                            width="100%"
                            height="auto"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="media-body text-truncate">
                        <a
                          className="d-block small-xs-only text-truncate"
                          href="index.html"
                          target="_blank"
                        >
                          {m.user_name}
                        </a>
                        <span className="d-block text-muted small-xs-only lh-1 text-truncate">
                          @{m.user_nmid}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto text-end border-end">
                    {m.auth_flg ? (
                      <span className="ms-1 me-2 badge bg-success text-light">許可</span>
                    ) : (
                      <span className="me-2 badge bg-light text-secondary">——</span>
                    )}
                  </div>
                  <div className="col-6">
                    <div className="row g-1 mb-1 ps-2">
                      <div className="col text-end">{m.reward_first}%</div>
                      <div className="col-7 text-end">{m.reward_second}%</div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <h5 className="h6 mb-2 pb-1 border-bottom">未参加</h5>
        <ul className="list-group list-group-flush mb-2">
          {members_unjoined.map((m) => {
            const user_icon_uri = m.user_icon_uri
              ? m.user_icon_uri
              : "/dist/tmty/assets/default/icon.webp";

            return (
              <li className="list-group-item px-0">
                <div className="row g-1">
                  <div className="col overflow-hidden">
                    <div
                      className="media media-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                    >
                      <div className="media-object">
                        <div className="icon icon-pill">
                          <img
                            src={user_icon_uri}
                            className="icon-item"
                            width="100%"
                            height="auto"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="media-body text-truncate">
                        <a
                          className="d-block small-xs-only text-truncate"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/users/${m.user_id}/items`);
                          }}
                          target="_blank"
                        >
                          {m.user_name}
                        </a>
                        <span className="d-block text-muted small-xs-only lh-1 text-truncate">
                          @{m.user_nmid}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto text-end border-end">
                    {m.auth_flg ? (
                      <span className="ms-1 me-2 badge bg-success text-light">許可</span>
                    ) : (
                      <span className="me-2 badge bg-light text-secondary">——</span>
                    )}
                  </div>
                  <div className="col-6">
                    <div className="row g-1 mb-1 ps-2">
                      <div className="col text-end">{m.reward_first}%</div>
                      <div className="col-7 text-end">{m.reward_second}%</div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <div className="d-none d-md-flex d-grid gap-2 justify-content-end mt-3">
          {v_collection.collection_owner && (
            <>
              <button
                className="btn btn-link me-md-2"
                role="button"
                data-bs-toggle="modal"
                data-bs-target="#teamWithdraw"
              >
                <i className="fa-solid fa-user-xmark me-2" />
                削除
              </button>
            </>
          )}

          {v_collection.collection_joiner && (
            <button
              className="btn btn-primary"
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#teamJoin"
            >
              <i className="fa-solid fa-user-plus me-2" />
              参加
            </button>
          )}
        </div>
        <div id="collapseShopping" className="collapse fixed-bottom shadow d-md-none show">
          <div className="container position-relative pt-2 pb-4 bg-white">
            <div className="btn-group bg-whtie d-flex">
              {v_collection.collection_owner && (
                <button
                  className="btn btn-link d-block"
                  role="button"
                  data-bs-toggle="modal"
                  data-bs-target="#teamWithdraw"
                >
                  <i className="fa-solid fa-user-xmark me-2" />
                  削除
                </button>
              )}

              {v_collection.collection_joiner && (
                <button
                  className="btn btn-primary d-block"
                  role="button"
                  data-bs-toggle="modal"
                  data-bs-target="#teamJoin"
                >
                  <i className="fa-solid fa-user-plus me-2" />
                  参加
                </button>
              )}
            </div>
          </div>
        </div>
        {}
      </div>
    </>
  );
}
