import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import IntlTelInput from "./IntlTelInput";
import styles from "assets/jss/chaintier/components/customInputStyle.js";
import CustomInput2 from "components/CustomInput/CustomInput.js";

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  useEffect(() => {
    window.jQuery(".intl-tel-readonly").attr("readonly", true);
  }, []);
  const classes = useStyles();

  const [focused, set_focused] = useState(false);
  const [raw_number, set_raw_number] = useState(props.value);

  const {
    shrink,
    set_value,
    country_code,
    set_country_code,
    justWidth,
    formControlProps,
    labelText,
    id,
    labelProps,
    readOnly,
    error,
    white,
    success,
    helperText,
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: raw_number && error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: raw_number && error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  var formControlClasses;

  if (formControlProps !== undefined) {
    formControlClasses = classNames(formControlProps.className, classes.formControl);
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });
  return (
    <>
      <FormControl
        {...formControlProps}
        fullWidth={!justWidth}
        className={formControlClasses}
        onFocus={() => {
          set_focused(true);
        }}
        onBlur={() => {
          set_focused(false);
        }}
      >
        {labelText !== undefined ? (
          <InputLabel
            className={`MuiMyLabel ${classes.labelRoot} ${labelClasses} MuiFormLabel-root ${
              shrink || focused || raw_number ? "MuiInputLabel-shrink" : ""
            }`}
            htmlFor={id}
            {...labelProps}
          >
            {labelText}
          </InputLabel>
        ) : null}
        <IntlTelInput
          containerClassName={`intl-tel-input ${underlineClasses} ${
            !readOnly && focused ? "Mui-focused" : ""
          } MuiInput-root MuiInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-ci71or-MuiInputBase-root-MuiInput-root`}
          inputClassName={`MuiInput-input MuiInputBase-input ${
            readOnly ? "intl-tel-readonly" : ""
          }`}
          preferredCountries={["jp", "us"]}
          defaultCountry={country_code}
          placeholder=""
          allowDropdown={!readOnly}
          value={raw_number}
          onSelectFlag={(value, obj, formattedvalue, isValid) => {
            set_country_code && set_country_code(obj.iso2);
            set_raw_number(value);

            if (isValid) {
              set_value && set_value(formattedvalue.replace(/-/g, "").replace(/ /g, ""));
            } else {
              set_value && set_value("");
            }
          }}
          onPhoneNumberChange={(isValid, value, obj, formattedvalue) => {
            set_country_code && set_country_code(obj.iso2);
            set_raw_number(value);
            if (isValid) {
              set_value && set_value(formattedvalue.replace(/-/g, "").replace(/ /g, ""));
            } else {
              set_value && set_value("");
            }
          }}
          format
        />
        {helperText !== undefined ? (
          <FormHelperText id={id + "-text"} className={helpTextClasses}>
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
      <div style={{ visibility: "hidden", height: 0 }}>
        <CustomInput2 type="text" />
      </div>
    </>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
};
