import React, { Fragment, useEffect, useState } from "react";
import Recaptcha from "./Recaptcha";
import { useDispatch, useSelector } from "react-redux";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en_app from "./locale/en/app.json";
import en_validate from "./locale/en/validate.json";
import ja_app from "./locale/ja/app.json";

const en = { ...en_app, ...en_validate };
const ja = { ...ja_app };

i18n.use(initReactI18next).init({
  resources: {
    en: {
      // translation: {サインイン: "Sign in"},
      translation: en,
    },
    ja: {
      // translation: {サインイン: "ログイン"},
      translation: ja,
    },
  },
  lng: "ja",
  fallbackLng: "ja",
  interpolation: { escapeValue: false },
});

const I18nInit = () => {
  let language = "";
  const windowlanguage = window.navigator.languages
    ? window.navigator.languages[0]
    : "en";

  if (["ja", "en"].indexOf(windowlanguage) >= 0) {
    language = windowlanguage;
  } else {
    language = "en";
  }

  useEffect(() => {
    if (sessionStorage.getItem("AcceptLanguage")) {
      i18n.changeLanguage(sessionStorage.getItem("AcceptLanguage"));
    } else {
      i18n.changeLanguage(language);
      sessionStorage.setItem("AcceptLanguage", language);
    }
    // i18n.changeLanguage("ja");
  }, []);

  return (
    <>
      <Recaptcha />
    </>
  );
};

export default I18nInit;
