import React, { FC, useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { getSearch, setSearch } from "config/util";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import { ChainLink } from "components/ChainLink";
import { get_activities } from "stores/Activities/actions";

import { get_withdraws, post_withdraws, delete_withdraws_id } from "stores/Withdraws/actions";

import {
  post_wallets_id_withdraws_id_withdraw,
  post_wallets_id_deposit,
} from "stores/Wallets/actions";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const App = ({ branch, children }) => {
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();

  const dispatch = useDispatch();

  const { coin } = useParams();

  const maps = useSelector((state) => state.reduce_get_maps);
  const user_id = useSelector((state) => state.reduce_get_accounts.user?.user_id);

  const loading_post_withdraws = useSelector((state) => state.reduce_post_withdraws.isLoading);
  const loading_post_wallets_id_withdraws_id_withdraw = useSelector(
    (state) => state.reduce_post_wallets_id_withdraws_id_withdraw.isLoading
  );
  const loading_post_wallets_id_deposit = useSelector(
    (state) => state.reduce_post_wallets_id_deposit.isLoading
  );
  const loading_delete_withdraws_id = useSelector(
    (state) => state.reduce_delete_withdraws_id.isLoading
  );

  const loading =
    loading_post_withdraws ||
    loading_post_wallets_id_withdraws_id_withdraw ||
    loading_post_wallets_id_deposit ||
    loading_delete_withdraws_id;

  const [propsWithdraws, set_propsWithdraws] = useState({
    search: {
      page: 1,
      limit: 10,
      symbol: coin,
      sort: [],
      ...branch?.search,
    },
  });

  const [propsActivities, set_propsActivities] = useState({
    search: {
      page: 1,
      limit: 10,
      type: [40, 41, 42, 43, 44, 45],
      user_id,
      coin,
      sort: [["created_at", "DESC"]],
      sort_key: [0],
      ...branch?.search,
    },
  });

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_get_activities = (data, next, prev) => {
    dispatch(
      get_activities({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_get_withdraws = (data, next, prev) => {
    dispatch(
      get_withdraws({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_post_withdraws = (data, next, prev) => {
    dispatch(
      post_withdraws({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_post_wallets_id_withdraws_id_withdraw = (data, next, prev) => {
    dispatch(
      post_wallets_id_withdraws_id_withdraw({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_post_wallets_id_deposit = (data, next, prev) => {
    dispatch(
      post_wallets_id_deposit({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_delete_withdraws_id = (data, next, prev) => {
    dispatch(
      delete_withdraws_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_activities = (search, page = 1) => {
    dispatch_get_activities({
      search: { ...search, page },
    });
    set_propsActivities({
      search: { ...search, page },
    });
  };

  const set_get_withdraws = (search, page = 1) => {
    dispatch_get_withdraws({
      search: { ...search, page },
    });
    set_propsWithdraws({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    set_get_activities({ ...propsActivities.search, coin });
    set_get_withdraws({ ...propsWithdraws.search, symbol: coin });
  }, [coin]);

  const newchildren = React.cloneElement(children, {
    Withdraws: {
      propsWithdraws,
      set_propsWithdraws,
      dispatch_post_withdraws,
      dispatch_delete_withdraws_id,
      dispatch_post_wallets_id_withdraws_id_withdraw,
      dispatch_post_wallets_id_deposit,
      dispatch_gets: set_get_withdraws,
      branch,
    },
    Activities: {
      propsActivities,
      set_propsActivities,
      dispatch_gets: set_get_activities,
      list: "wallet",
      branch,
    },
  });

  return (
    <>
      <Helmet>
        <title>tomonity</title>
      </Helmet>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }}
        open={loading}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      {newchildren}
    </>
  );
};

export default App;
