import { createAction, createReducer } from 'redux-act';

export const get_users = createAction("GET_USERS");
export const get_users_data = createAction("GET_USERS_DATA");
export const get_users_error = createAction("GET_USERS_ERROR");
export const get_users_id = createAction("GET_USERS_ID");
export const get_users_id_data = createAction("GET_USERS_ID_DATA");
export const get_users_id_error = createAction("GET_USERS_ID_ERROR");
export const post_users = createAction("POST_USERS");
export const post_users_data = createAction("POST_USERS_DATA");
export const post_users_error = createAction("POST_USERS_ERROR");
export const put_users_id = createAction("PUT_USERS_ID");
export const put_users_id_data = createAction("PUT_USERS_ID_DATA");
export const put_users_id_error = createAction("PUT_USERS_ID_ERROR");
export const delete_users_id = createAction("DELETE_USERS_ID");
export const delete_users_id_data = createAction("DELETE_USERS_ID_DATA");
export const delete_users_id_error = createAction("DELETE_USERS_ID_ERROR");
