import React, { FC, useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getSearch, setSearch } from "config/util";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";

import { ChainLink } from "components/ChainLink";

import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import socketIOClient from "socket.io-client";
import env from "../../stores/env";

import { get_items } from "stores/Items/actions";
import { get_collections } from "stores/Collections/actions";
import { get_users_my_id } from "stores/User/actions";
import { get_items_id_offers } from "stores/ItemOffers/actions";
import { get_activities } from "stores/Activities/actions";
import { get_users } from "stores/Users/actions";

let socket = null;

const App = ({ branch, children }) => {
  const { user_id } = useParams();

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  // const user_page = sessionStorage.getItem("user_page");
  // const [type, set_type] = React.useState(user_page);

  // useEffect(() => {
  // set_type(user_page);
  // }, [user_page]);

  const [type, set_type] = React.useState(branch.type);

  const dispatch_type = (name, collection_id) => {
    // 結局はURLでアクセスすることが求められる可能性があるページはそのようにするために
    // パスの段階で別のコンポーネントで作成しなければならない、コンポーネントの初期値違いは
    // 再レンダーされない

    // URLアクセスが求められない時は、セッションストレージで行うのがベストかもしれない
    // sessionStorage.setItem("user_page", name)
    // set_type(name);
    if (name == "created") {
      history.push(`/users/${user_id}/created`);
    }
    if (name == "collections") {
      set_type("collections");
      history.push(`/users/${user_id}/collections`);
    }

    if (name == "favoritecollections") {
      history.push(`/users/${user_id}/favoritecollections`);
    }

    if (name == "collection-items") {
      set_get_items({ ...propsItems.search, collection_id, type: "collected" });
      set_type("collection-items");
      // history.push(`/users/${user_id}/collections`);
    }

    if (name == "items") {
      history.push(`/users/${user_id}/items`);
    }

    if (name == "favoriteitems") {
      history.push(`/users/${user_id}/favoriteitems`);
    }

    if (name == "activities") {
      history.push(`/users/${user_id}/activities`);
    }

    if (name == "itemoffers") {
      history.push(`/users/${user_id}/itemoffers`);
    }

    if (name == "followers") {
      history.push(`/users/${user_id}/followers`);
    }

    if (name == "followings") {
      history.push(`/users/${user_id}/followings`);
    }
  };

  const dispatch_page = (name, collection_id) => {
    // 結局はURLでアクセスすることが求められる可能性があるページはそのようにするために
    // パスの段階で別のコンポーネントで作成しなければならない、コンポーネントの初期値違いは
    // 再レンダーされない

    // URLアクセスが求められない時は、セッションストレージで行うのがベストかもしれない
    // sessionStorage.setItem("user_page", name)
    // =======================================
    if (name == "created") {
      set_get_collections({ ...propsCollections.search, user_id, type: "created" });
    }
    if (name == "collections") {
      set_get_collections({ ...propsCollections.search, user_id, type: "collected" });
    }

    if (name == "favoritecollections") {
      set_get_collections({ ...propsCollections.search, user_id, type: "favorite" });
    }

    // if (name == "collection-items") {
    //   set_get_items({ ...propsItems.search, collection_id, type: "collected" });
    // }

    if (name == "items") {
      set_get_items({ ...propsItems.search, user_id, collection_id: null, type: "collected" });
    }

    if (name == "favoriteitems") {
      set_get_items({ ...propsItems.search, user_id, collection_id: null, type: "favorite" });
    }

    if (name == "activities") {
      set_get_activities({ ...propsActivities.search, user_id });
    }

    if (name == "itemoffers") {
      set_get_items_id_offers({ ...propsItemOffers.search, user_id });
    }

    if (name == "followers") {
      set_get_users({ ...propsUsers.search, user_id, followings: 1, followers: 0 });
    }

    if (name == "followings") {
      set_get_users({ ...propsUsers.search, user_id, followings: 0, followers: 1 });
    }
  };

  useEffect(() => {
    if (user_id) {
      set_get_users_my_id(user_id);
    }
    dispatch_page(branch.type);
    set_type(branch.type);
  }, [branch.type, user_id]);

  // useEffect(() => {
  //   if (user_id) {
  //     set_get_users_my_id(user_id);
  //   }
  //   dispatch_page(branch.type);
  //   set_type(branch.type);
  // }, [user_id]);

  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const maps = useSelector((state) => state.reduce_get_maps);
  const history = useHistory();

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_get_users_my_id = (data, next, prev) => {
    dispatch(
      get_users_my_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_users_my_id = (id) => {
    dispatch_get_users_my_id(
      {
        id,
      },
      (data) => {}
    );
  };

  const [propsCollections, set_propsCollections] = useState({
    search: {
      active_flg: true,
      page: 1,
      limit: 12,
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_collections = (data, next, prev) => {
    dispatch(
      get_collections({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_collections = (search, page = 1) => {
    dispatch_get_collections({
      search: { ...search, page },
    });
    set_propsCollections({
      search: { ...search, page },
    });
  };

  const [propsItems, set_propsItems] = useState({
    search: {
      active_flg: true,
      page: 1,
      limit: 12,
      user_id,
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_items = (data, next, prev) => {
    dispatch(
      get_items({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_items = (search, page = 1) => {
    dispatch_get_items({
      search: { ...search, page },
    });
    set_propsItems({
      search: { ...search, page },
    });
  };

  const [propsActivities, set_propsActivities] = useState({
    search: {
      active_flg: true,
      type: [0, 10, 11, 21, 22, 30, 31, 32, 34, 35],
      page: 1,
      limit: 10,
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_activities = (data, next, prev) => {
    dispatch(
      get_activities({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_activities = (search, page = 1) => {
    dispatch_get_activities({
      search: { ...search, page },
    });
    set_propsActivities({
      search: { ...search, page },
    });
  };

  const [propsItemOffers, set_propsItemOffers] = useState({
    search: {
      page: 1,
      limit: 10,
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_items_id_offers = (data, next, prev) => {
    dispatch(
      get_items_id_offers({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_items_id_offers = (search, page = 1) => {
    dispatch_get_items_id_offers({
      search: { ...search, page },
    });
    set_propsItemOffers({
      search: { ...search, page },
    });
  };

  const [propsUsers, set_propsUsers] = useState({
    search: {
      active_flg: true,
      followings: branch.followings,
      followers: branch.followers,
      page: 1,
      limit: 10,
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_users = (data, next, prev) => {
    dispatch(
      get_users({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_users = (search, page = 1) => {
    dispatch_get_users({
      search: { ...search, page },
    });
    set_propsUsers({
      search: { ...search, page },
    });
  };

  const loading_items = useSelector((state) => state.reduce_get_items.isLoading);
  const loading_get_users_my_id = useSelector((state) => state.reduce_get_users_my.isLoadingId);

  const newchildren = React.cloneElement(children, {
    Collections: {
      propsCollections,
      set_propsCollections,
      dispatch_gets: set_get_collections,
      user_id,
      branch,
      list: "user",
      dispatch_type,
      type,
    },
    Items: {
      propsItems,
      set_propsItems,
      dispatch_gets: set_get_items,
      loading: loading_items,
      branch,
      list: "user",
      dispatch_type,
      type,
    },
    ItemOffers: {
      propsItemOffers,
      set_propsItemOffers,
      dispatch_gets: set_get_items_id_offers,
      user_id,
      branch,
      list: "user",
      dispatch_type,
      type,
    },
    Activities: {
      propsActivities,
      set_propsActivities,
      dispatch_gets: set_get_activities,
      branch,
      list: "user",
      dispatch_type,
      type,
    },
    Users: {
      propsUsers,
      set_propsUsers,
      dispatch_gets: set_get_users,
      branch,
      list: "user",
      user_id,
      dispatch_type,
      type,
    },
    Page: { type, dispatch_type },
  });
  if (loading_get_users_my_id) return <></>;

  return (
    <>
      <Helmet>
        <title>tomonity</title>
      </Helmet>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      {newchildren}
    </>
  );
};

export default App;
