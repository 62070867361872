import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";

import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";

// import { view_item_offer } from "../../stores/Items/view";

export default function Dashboard(Items) {
  const { propsItems, set_propsItems, dispatch_gets, user_id } = Items;
  const t = Items.t;

  return (
    <>
      <div className="btn-toolbar ms-auto" role="toolbar" aria-label="\u691C\u7D22\u6761\u4EF6">
        <div className="filter-only-xs me-sm-1">
          <button
            className="btn btn-outline-secondary"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#searchFilter"
            aria-controls="searchFilter"
          >
            <i className="fa-solid fa-filter" />
          </button>
        </div>

        <div className="dropdown me-1" data-bs-toggle="tooltip" data-bs-placement="top" title="">
          <button
            type="button"
            className="btn btn-outline-secondary dropdown-toggle"
            id="filSort"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-solid fa-arrow-down-wide-short" />
          </button>
          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="filSort">
            {/* <li>
    <button type="button" className="dropdown-item">
      出品順
    </button>
  </li>

  <li>
    <button type="button" className="dropdown-item">
      出品順
    </button>
  </li>
  <li>
    <button type="button" className="dropdown-item">
      オファー順
    </button>
  </li>
  <li>
    <hr className="dropdown-divider" />
  </li>
  <li>
    <button type="button" className="dropdown-item">
      まもなく終了
    </button>
  </li>
  <li>
    <hr className="dropdown-divider" />
  </li> */}
            <li>
              <button
                type="button"
                className={`dropdown-item ${propsItems.search.sort_type == "item_id" && "active"}`}
                onClick={() => {
                  const search = {
                    ...propsItems.search,
                    sort_type: "item_id",
                  };

                  dispatch_gets(search, 1);
                  // set_propsItems({
                  //   search: { ...search },
                  // });
                }}
                // sort_type: "item_id",
                // sort_type: "price_lower",
                // sort_type: "price_higher",
              >
                作成順
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`dropdown-item ${propsItems.search.sort_type == "price_lower" &&
                  "active"}`}
                onClick={() => {
                  const search = {
                    ...propsItems.search,
                    sort_type: "price_lower",
                  };
                  if (!search.sale_types || search.sale_types.length == 0) {
                    search.sale_types = [1, 2];
                  }
                  dispatch_gets(search, 1);

                  // set_propsItems({
                  //   search: { ...search },
                  // });
                }}
              >
                価格の安い順
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`dropdown-item ${propsItems.search.sort_type == "price_higher" &&
                  "active"}`}
                onClick={() => {
                  const search = {
                    ...propsItems.search,
                    sort_type: "price_higher",
                  };
                  if (!search.sale_types || search.sale_types.length == 0) {
                    search.sale_types = [1, 2];
                  }
                  dispatch_gets(search, 1);

                  // set_propsItems({
                  //   search: { ...search },
                  // });
                }}
              >
                価格の高い順
              </button>
            </li>
            {/* <li>
    <button type="button" className="dropdown-item">
      最高出品額順
    </button>
  </li>
  <li>
    <hr className="dropdown-divider" />
  </li>
  <li>
    <button type="button" className="dropdown-item">
      閲覧数順
    </button>
  </li>
  <li>
    <button type="button" className="dropdown-item">
      人気順
    </button>
  </li> */}
          </ul>
        </div>

        {/* <button
          type="button"
          className="btn btn-outline-secondary"
          role="button"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title=""
        >
          <i className="fa-solid fa-grip-lines" />
        </button> */}
        {}
      </div>
    </>
  );
}
