import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import moment from "moment/moment";

import LiLanguage from "components/Li/LiLanguage";
import LiAccount from "components/Li/LiAccount";
import { getMessage } from "config/util";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";
import { view_item } from "../../stores/Items/view";
import CustomDate from "components/CustomDate/CustomDate.js";
import CustomDateTime from "components/CustomDateTime/CustomDateTime.js";
import { hideModal, showModal } from "config/util";
import _sidebar from "./_sidebar";
import _modal_item_send from "./_modal_item_send";

import UsersPager from "../../views_components/PagerUsers/UsersPager";
import UsersList from "../../views_components/PagerUsers/UsersList";

export default function Contents({ Users, Items }) {
  const { propsItems, set_propsItems, dispatch_put_items_id, loadingId } = Items;
  const { propsUsers, set_propsUsers, dispatch_gets } = Users;

  const props = propsItems;
  const set_props = set_propsItems;

  const [item_send, set_item_send] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();
  const { item_id } = useParams();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const maps = useSelector((state) => state.reduce_get_maps);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const item = useSelector((state) => state.reduce_get_items.item);
  const v_item = view_item(item, maps, t);

  const coin_ids = { 1: "Bitcoin", 2: "Ethereum" };
  const sale_types = { 1: "固定価格", 2: "オークション" };

  Users.t = t;
  Users.item_send = item_send;
  Users.set_item_send = set_item_send;
  return (
    <>
      <div>
        <Header />
        <div className="container container-main">
          {/* {_sidebar(Items)} */}
          <main className="main">
            <div action="created-item-detail.html" entype="multpart/form-data">
              <div className="main-header pt-3 pb-2 ps-lg-3">
                <h2 className="h4 m-0 text-truncate"> {t("アイテムを転送")}</h2>
                <div className="btn-group">
                  <a
                    className="btn btn-outline-secondary text-dark rounded-pill px-3 me-2 text-nowrap"
                    href="javascript:history.back()"
                  >
                    <i className="fa-solid fa-xmark" />
                    <span className="d-none d-sm-inline-block"> {t("キャンセル")}</span>
                  </a>
                  {/* <button
                    type="button"
                    name="confirm"
                    className="btn btn-primary rounded-pill px-3 text-nowrap"
                    role="button"
                    data-bs-toggle="modal"
                    data-bs-target="#processDialog"
                  >
                    保存
                  </button> */}
                </div>
              </div>
              <div className="flex-grow-1 px-3 d-sm-block" action="">
                <div className="search-control">
                  <input
                    id="almighty"
                    type="search"
                    // autoFocus={true}
                    className="form-control"
                    list="datalistOptions"
                    placeholder={t("ユーザー名を入力してください")}
                    aria-label="Search"
                    value={propsUsers.name}
                    onChange={(e) => {
                      const val = e.target.value;
                      // set_propsUsers({
                      //   ...propsUsers,
                      //   name: val,
                      // });
                      dispatch_gets({ ...propsUsers.search, name: val }, 1);
                    }}
                  />
                </div>
                <datalist id="datalistOptions"></datalist>
                <button className="btn btn-outline-secondary d-none" type="button">
                  {t("検索")}
                </button>
              </div>
              <UsersPager Users={Users} />
              <div className="ms-md-3 mt-0">
                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-3">
                  {/* <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5 g-2 g-sm-3"> */}
                  <UsersList Users={Users} Items={Items} />
                </div>
              </div>
              {_modal_item_send(Users, Items)}
              <div
                className="modal fade modal-alert py-5"
                id="processDialog"
                data-bs-keyboard="false"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content rounded-4 shadow">
                    <div className="modal-body p-4 text-center">
                      <h5 className="mb-2">{t("確認")}</h5>
                      <p className="mb-0">{t("入力内容を保存してもいいですか？")}</p>
                    </div>
                    <div className="modal-footer flex-nowrap p-0">
                      <button
                        type="button"
                        className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0"
                        data-bs-dismiss="modal"
                      >
                        {t("キャンセル")}
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"
                        // data-bs-target="#modalSuccessfully"
                        // data-bs-toggle="modal"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch_put_items_id(
                            {
                              // ...props,
                              search: props.search,

                              item_detail_id: props.item_detail_id,
                              id: props.item_id,
                              sale_type: props.sale_type,
                              sale_flg: props.sale_flg,
                              coin_id: props.coin_id,
                              price: props.price,
                              iscredit: props.iscredit,
                              opened_at: props.opened_at,
                              closed_at: props.closed_at,
                            },
                            () => {
                              hideModal("processDialog");
                              history.push(`/items/${item_id}`);
                              // showModal("modalSuccessfully");
                            }
                          );
                        }}
                      >
                        <strong>{t("はい")}</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
