import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";

import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";

import { view_item_offer } from "../../stores/ItemOffers/view";

export const HeaderUser = (ItemOffers) => {
  const { propsItemOffers, set_propsItemOffers, dispatch_gets, user_id } = ItemOffers;
  const t = ItemOffers.t;

  return (
    <>
      <dl className="row g-0 g-sm-2 g-md-0 border-bottom border-dark border-2 py-3 pb-md-2 mb-1">
        <dt className="col-7 col-md">
          <button className="btn btn-sm w-100 fw-bold px-0 text-start btn-white">
            {t("アイテム")}
          </button>
        </dt>
        <dt className="col-5 col-md-3 col-lg-4 order-sm-last">
          <dl className="row g-1 g-md-2 g-xl-3 mb-0">
            <dt className="col text-end order-sm-last">
              <button className="btn btn-sm w-100 fw-bold px-0 text-end btn-link">
                {propsItemOffers.search.user_id && t("受信日")}
                {propsItemOffers.search.user_id_by && t("送信日")}
                {/* <i className="fa-solid fa-caret-down ms-2" /> */}
              </button>
            </dt>
            <dt className="col-12 col-sm text-end fw-bold">
              <button className="btn btn-sm w-100 fw-bold px-0 text-end btn-white">
                {t("有効期限")}
              </button>
            </dt>
          </dl>
        </dt>
        <dt className="col-12 col-md">
          <dl className="row g-0 g-md-2 g-xl-3 mb-0">
            <dt className="col-6 col-md ps-md-3">
              <button className="btn btn-sm w-100 fw-bold px-0 text-start btn-white">
                {propsItemOffers.search.user_id && t("申請者")}
                {propsItemOffers.search.user_id_by && t("申請先")}
              </button>
            </dt>
            <dt className="col-6 col-md pe-md-3 order-md-first text-end">
              <button className="btn btn-sm w-100 fw-bold px-0 text-end btn-white">
                {t("価格")}
              </button>
            </dt>
          </dl>
        </dt>
      </dl>
    </>
  );
};

export const RowUser = (ItemOffers) => {
  const { v_item_offer, history, t } = ItemOffers;

  return (
    <>
      <dl className="row g-1 g-sm-2 py-3 pt-md-2 mb-1 align-items-md-center border-bottom">
        <dd className="col-7 col-md mb-0 overflow-hidden">
          <div className="media mb-3 mb-md-0">
            <div className="media-object object-item position-relative">
              <a className="stretched-link" href="#">
                <img
                  src={v_item_offer.item_icon_uri}
                  className="rounded-1"
                  width="100%"
                  height="auto"
                  alt=""
                />
              </a>
            </div>
            <div className="media-body text-truncate overflow-hidden">
              <a
                className="d-block my-1 small-xs-only my-1 text-body"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/collections/${v_item_offer.collection_id}/items`);
                }}
              >
                {/* <div className="d-flex verified-text"> */}
                <span className="text-truncate">{v_item_offer.collection_name}</span>
                {/* </div> */}
              </a>
              <a
                className="d-block text-truncate"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/items/${v_item_offer.item_id}`);
                }}
              >
                <span className="fw-bold">{v_item_offer.item_name}</span>
              </a>
            </div>
          </div>
        </dd>
        <dd className="col-5 col-md-3 col-lg-4 order-md-last">
          <dl className="row g-0 g-md-2 g-md-0 align-items-md-center">
            <dd className="col text-end order-sm-last fw-bold">
              <small data-bs-toggle="tooltip" title="2022-04-06 12:29:21">
                {v_item_offer.created_at_countdown_label}
              </small>
            </dd>
            <dd className="col-12 col-sm text-end">
              <small data-bs-toggle="tooltip" title="2022-05-01 12:29:21 JST">
                {v_item_offer.closed_at_countdown_label}
              </small>
            </dd>
          </dl>
        </dd>
        <dd className="col-12 col-md">
          <dl className="row g-0 g-md-2 g-md-0 align-items-md-center">
            <dd className="col-7 col-md ps-md-3 text-truncate overflow-hidden">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/users/${v_item_offer.user_id_by}`);
                }}
              >
                {/* <div className="d-flex verified-text">{v_item_offer.user_name_by}</div> */}
                <div className="d-flex">{v_item_offer.user_name_by}</div>
              </a>
            </dd>
            <dd className="col-5 col-md order-md-first text-end pe-md-3">
              {/* BTC: 1, ETH: 2, NEO: 3, IOST: 4, */}
              <span
                className="fw-bold"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={v_item_offer.coin_label}
              >
                <sub className="me-1">{v_item_offer.coin_icon_tag}</sub>
              </span>
              {v_item_offer.price}
              <small className="d-block ps-2 ps-sm-0 ps-md-2">
                <sub>$</sub>
                {v_item_offer.offer_usd_price_label}
              </small>
            </dd>
          </dl>
        </dd>
      </dl>
    </>
  );
};
