import React, { useState, useEffect, Component } from "react";
import moment from "moment/moment";
import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  countdown2label,
  zeroPadding,
  toNumber,
  createBr,
} from "config/util";

import env from "../../stores/env";

export const view_item = (prop, maps, t) => {
  const now = moment();

  // const 販売開始以降 = moment(prop.opened_at).isSameOrBefore(now);
  // const 販売終了未満 = moment(prop.closed_at).isAfter(now);
  // const 販売期間内 = 販売開始以降 && 販売終了未満;

  // const 販売中 = prop.sale_flg && prop.sale_flg;
  const 販売中 = prop.item_sale_on;
  const 通常販売 = prop.item_sale_type == 1;
  // const 支払い済 = prop.order_item?.order?.deposit_status == 2;

  // const 転送中 = prop.order_item?.finished_flg == false;
  const 処理中 = prop.item_process_flg;
  const 所有者 = prop.item_detail_owner;
  const C2 = 販売中 && 通常販売 && !処理中 && 所有者;
  const C3 = 販売中 && 通常販売 && !処理中 && !所有者;
  const C4 = !販売中 && 通常販売 && !処理中 && 所有者;
  const C5 = !販売中 && 通常販売 && !処理中 && !所有者;
  const E4 = !販売中 && 通常販売 && 処理中 && 所有者;
  const E5 = !販売中 && 通常販売 && 処理中 && !所有者;
  const G2 = 販売中 && !通常販売 && !処理中 && 所有者;
  const G3 = 販売中 && !通常販売 && !処理中 && !所有者;
  const G4 = !販売中 && !通常販売 && !処理中 && 所有者;
  const G5 = !販売中 && !通常販売 && !処理中 && !所有者;
  const I4 = !販売中 && !通常販売 && 処理中 && 所有者;
  const I5 = !販売中 && !通常販売 && 処理中 && !所有者;

  const 通常販売中 = C2 || C3;
  const オークション販売中 = G2 || G3;
  const 出品する = C4 || G4;
  const 編集する = C2 || G2;
  // const 注文する = C3;

  const opened = moment2obj(prop.item_sale_opened_at);
  const closed = moment2obj(prop.item_sale_closed_at);
  const countdown = moment2countdown(prop.item_sale_closed_at);

  // const item_sale_price = prop.item_detail?.price;
  // const item_sale_coin = prop.coin_id == 1 ? "BTC" : prop.coin_id == 2 ? "ETH" : "OTHER";

  const resView = {
    ...prop,
    active_flg_label: prop.active_flg ? "表示する" : "表示しない",
    item_icon_uri: toLowUrl(prop.item_icon_uri, prop.item_icon_mime),
    item_icon_uri_low: toLowUrl(prop.item_icon_uri, prop.item_icon_mime),
    item_icon_uri_high: toHighUrl(prop.item_icon_uri, prop.item_icon_mime),

    thumbnail_path: prop.thumbnail_uri,
    thumbnail_path_low: toLowUrl(prop.thumbnail_uri, prop.thumbnail_mime_type),
    thumbnail_path_high: toHighUrl(prop.thumbnail_uri, prop.thumbnail_mime_type),

    item_created_at_label: toMomentString(prop.created_at, "YYYY/MM/DD"),

    // countdown_label:
    //   countdown.days == -1
    //     ? ""
    //     : // : `${t("残り")} ${countdown.days} ${t("日")} ${countdown.hours} ${t("時間")} ${countdown.minutes} ${t("分")}`,
    //       `${countdown.days} days ${countdown.hours}:${countdown.minutes}:${countdown.seconds}`,

    // countdown,
    // closed,
    // opened,
    sale_price_default: prop.sale_price,
    favorite_count: prop.favorite_count,
    favorite_flg: prop.favorite_flg,
    item_favorited_count: prop.item_favorited_count,
    item_favorited_my: prop.item_favorited_my,
    // item_icon_uri: prop.item_icon_uri,
    item_name: prop.name,
    item_description: prop.description,
    item_description_br: createBr(prop.description),

    item_sale_flg: prop.sale_flg,
    offer_flg: prop.offer_flg,

    offer_price: prop.offer_price,
    所有者,

    販売中,
    通常販売中,
    オークション販売中,
    出品する,
    編集する,
    // 注文する,
    item_owner: true,
    // item_owner: false,
  };

  resView.owner_icon_uri = prop.owner_icon_uri ?? "/dist/tmty/assets/default/icon.webp";
  resView.creator_icon_uri = prop.creator_icon_uri ?? "/dist/tmty/assets/default/icon.webp";

  // item_opened_at_label: toMomentString(prop.item_sale_opened_at, "YYYY/MM/DD HH:mm"),

  const item_sale_closed_at_countdown = moment2countdown(prop.item_sale_closed_at);
  resView.item_sale_closed_at_countdown_label = countdown2label(item_sale_closed_at_countdown, t);
  // resView.item_sale_closed_at_countdown_label = toMomentString(prop.item_sale_closed_at, "YYYY-MM-DD HH:mm");
  resView.item_sale_closed_at = prop.item_sale_closed_at;
  resView.item_sale_closed_at_label = toMomentString(
    prop.item_sale_closed_at,
    "YYYY年MM月DD日 HH時mm分"
  );

  const item_sale_opened_at_countdown = moment2countdown(prop.item_sale_opened_at);
  resView.item_sale_opened_at_countdown_label = countdown2label(item_sale_opened_at_countdown, t);
  // resView.item_sale_opened_at_countdown_label = toMomentString(prop.item_sale_opened_at, "YYYY-MM-DD HH:mm");

  resView.item_sale_coin_tag =
    prop.item_sale_coin == "BTC" ? (
      <i className="icomoon-brand-btc" />
    ) : prop.item_sale_coin == "ETH" ? (
      <i className="icomoon-brand-eth" />
    ) : prop.item_sale_coin == "IOST" ? (
      <i className="icomoon-brand-iost" />
    ) : prop.item_sale_coin == "USDT" ? (
      <i className="icomoon-brand-usdt" />
    ) : (
      <></>
    );

  resView.item_sale_coin_tag_color =
    prop.item_sale_coin == "BTC" ? (
      <i className="icomoon-brand-btc btc-color" />
    ) : prop.item_sale_coin == "ETH" ? (
      <i className="icomoon-brand-eth eth-color" />
    ) : prop.item_sale_coin == "IOST" ? (
      <i className="icomoon-brand-iost iost-color" />
    ) : prop.item_sale_coin == "USDT" ? (
      <i className="icomoon-brand-usdt usdt-color" />
    ) : (
      <></>
    );

  resView.coin_platform_tag =
    prop.platform_id == 2 ? (
      <i className="icomoon-brand-eth" />
    ) : prop.platform_id == 4 ? (
      <i className="icomoon-brand-iost" />
    ) : prop.platform_id == 10 ? (
      <i className="icomoon-brand-polygon" />
    ) : (
      <></>
    );

  resView.coin_platform_label =
    prop.platform_id == 2 ? (
      "Ethereum"
    ) : prop.platform_id == 4 ? (
      "IOST"
    ) : prop.platform_id == 10 ? (
      "Polygon"
    ) : (
      <></>
    );

  resView.standard_label =
    prop.standard_id == 1 ? "ERC721" : prop.standard_id == 2 ? "ERC1155" : "";

  resView.symbol_name =
    prop.symbol_id == 6
      ? "TMTY721(Rinkeby)"
      : prop.symbol_id == 7
      ? "TMTY721(IOST)"
      : prop.symbol_id == 9
      ? "TMTY721(Polygon)"
      : prop.symbol_id == 11
      ? "TMTY1155(Rinkeby)"
      : "";

  if (env.PROJECT_KEY == "8019d1cc-39c8-4c73-8c9d-9795d16e078d") {
    resView.contract_address =
      prop.symbol_id == 6
        ? "0xDE6A44432e...83Fe"
        : prop.symbol_id == 7
        ? "ContractEwdi...HBou"
        : prop.symbol_id == 9
        ? "0x80113cff56...8336"
        : prop.symbol_id == 11
        ? "0xDE6A44432e...83Fe"
        : "";

    resView.contract_uri =
      prop.symbol_id == 6
        ? "https://rinkeby.etherscan.io/address/0xde6a44432e83ae8ba9ae389cb3bd88bbd07783fe"
        : prop.symbol_id == 7
        ? "https://www.iostabc.com/contract/ContractEwdiq5JnaLwyDiPZfXLYP8knCuYypPev6G3nrFYrHBou"
        : prop.symbol_id == 9
        ? "https://polygonscan.com/address/0x80113cff5612BEf15F65d07D050B63A09f7d8336"
        : prop.symbol_id == 11
        ? "https://rinkeby.etherscan.io/address/0xde6a44432e83ae8ba9ae389cb3bd88bbd07783fe"
        : "";
  } else {
    resView.contract_address =
      prop.symbol_id == 6
        ? "0xDE6A44432e...83Fe"
        : prop.symbol_id == 7
        ? "Contract4cat...w7W7"
        : prop.symbol_id == 9
        ? "0xceee29b881...8c2e"
        : prop.symbol_id == 11
        ? "0xDE6A44432e...83Fe"
        : "";

    resView.contract_uri =
      prop.symbol_id == 6
        ? "https://rinkeby.etherscan.io/address/0xde6a44432e83ae8ba9ae389cb3bd88bbd07783fe"
        : prop.symbol_id == 7
        ? "https://www.iostabc.com/contract/Contract4catHJND92UQWcD7nSbEz4Csgm18CgSVHvWyFJTfw7W7"
        : prop.symbol_id == 9
        ? "https://polygonscan.com/address/0xceee29b881eaf5d8440b2ec4c738c9a440c78c2e"
        : prop.symbol_id == 11
        ? "https://rinkeby.etherscan.io/address/0xde6a44432e83ae8ba9ae389cb3bd88bbd07783fe"
        : "";
  }

  resView.contract_name =
    prop.symbol_id == 6
      ? "TMTY721(Rinkeby)"
      : prop.symbol_id == 7
      ? "TMTY721(IOST)"
      : prop.symbol_id == 9
      ? "TMTY721(Polygon)"
      : prop.symbol_id == 11
      ? "TMTY1155(Rinkeby)"
      : "";

  // [COIN.NRC721]: "TMTY721(Rinkeby)",
  // [COIN.IORC721]: "TMTY721(IOST)",
  // [COIN.PORC721]: "TMTY721(Polygon)",
  // [COIN.NRC1155]: "TMTY1155(Rinkeby)",

  // NRC721: 6,
  // IORC721: 7,
  // JPY: 8,
  // PORC721: 9,
  // POLYGON: 10,
  // NRC1155: 11,

  const collection = prop.collection;

  if (collection) {
    resView.collection_cover_uri = collection.collection_cover_uri;
    resView.collection_icon_uri = collection.collection_icon_uri;
    resView.collection_description = collection.collection_description;
    resView.collection_description_br = createBr(collection.collection_description);

    resView.category_name = collection.category_name;
    resView.collection_id = collection.collection_id;
    resView.collection_name = collection.collection_name;
    resView.collection_creator_reward = collection.collection_creator_reward;

    resView.collection_link_twitter = collection.data?.link_twitter;
    resView.collection_link_facebook = collection.data?.link_facebook;
    resView.collection_link_instagram = collection.data?.link_instagram;
    resView.collection_link_discord = collection.data?.link_discord;
    resView.collection_link_other = collection.data?.link_other;

    resView.category_icon_tag =
      collection.category_id == "c801984a-f926-4ffc-a8c1-c48bc4307d0a" ? (
        <i className="fa-solid fa-brush me-1" />
      ) : collection.category_id == "1fc59b04-1a89-4644-a73a-7e82fcf8efbc" ? (
        <i className="fa-solid fa-lightbulb me-1" />
      ) : collection.category_id == "f31f2447-8800-4ff2-a36d-d82b475bba59" ? (
        <i className="fa-solid fa-at me-1" />
      ) : collection.category_id == "853c87ab-076a-417d-b2e0-a3a4d8c13492" ? (
        <i className="fa-solid fa-itunes-note me-1" />
      ) : collection.category_id == "6fc94309-7a9a-41a0-b520-cfc72dde88fc" ? (
        <i className="fa-solid fa-camera-retro me-1" />
      ) : collection.category_id == "9bd3e56a-6321-4a41-8801-51780bc8866b" ? (
        <i className="fa-solid fa-baseball-bat-ball me-1" />
      ) : collection.category_id == "a0934b49-6337-41ae-ad89-21e1356154cf" ? (
        <i className="fa-solid fa-gamepad me-1" />
      ) : collection.category_id == "c49aded5-4f2c-4140-9056-a926774600ee" ? (
        <i className="fa-solid fa-box-open me-1" />
      ) : collection.category_id == "6b99b864-f386-4b67-8130-3a82fb0bbe47" ? (
        <i className="fa-solid fa-vr-cardboard me-1" />
      ) : collection.category_id == "" ? (
        <i className="fa-solid fa-xxx me-1" />
      ) : (
        <></>
      );

    resView.category_icon_link =
      collection.category_id == "c801984a-f926-4ffc-a8c1-c48bc4307d0a"
        ? "/categories/id/c801984a-f926-4ffc-a8c1-c48bc4307d0a"
        : collection.category_id == "1fc59b04-1a89-4644-a73a-7e82fcf8efbc"
        ? "/categories/id/1fc59b04-1a89-4644-a73a-7e82fcf8efbc"
        : collection.category_id == "f31f2447-8800-4ff2-a36d-d82b475bba59"
        ? "/categories/id/f31f2447-8800-4ff2-a36d-d82b475bba59"
        : collection.category_id == "853c87ab-076a-417d-b2e0-a3a4d8c13492"
        ? "/categories/id/853c87ab-076a-417d-b2e0-a3a4d8c13492"
        : collection.category_id == "6fc94309-7a9a-41a0-b520-cfc72dde88fc"
        ? "/categories/id/6fc94309-7a9a-41a0-b520-cfc72dde88fc"
        : collection.category_id == "9bd3e56a-6321-4a41-8801-51780bc8866b"
        ? "/categories/id/9bd3e56a-6321-4a41-8801-51780bc8866b"
        : collection.category_id == "a0934b49-6337-41ae-ad89-21e1356154cf"
        ? "/categories/id/a0934b49-6337-41ae-ad89-21e1356154cf"
        : collection.category_id == "c49aded5-4f2c-4140-9056-a926774600ee"
        ? "/categories/id/c49aded5-4f2c-4140-9056-a926774600ee"
        : collection.category_id == "6b99b864-f386-4b67-8130-3a82fb0bbe47"
        ? "/categories/id/6b99b864-f386-4b67-8130-3a82fb0bbe47"
        : collection.category_id == ""
        ? "/categories"
        : "";
  }

  //   item_order
  //   item_order_coin: "ETH"
  // item_order_coin_id: 2
  // item_order_price: "0.2"
  // item_order_price_usd: "524.67483138352112348"

  // オーナー、クリエイターは
  // nftを結合しているため、そこから取得する、
  // アドレスなど、取得できる情報が多いから今回はそれで
  // ECサイトの時は、以下で行う
  // オーナー、クリエイターはそれぞれ
  // itemagent_id,コレクションagent_id
  // で取得することができるが、

  const nft = prop.nft;

  if (nft) {
    // resView.nft_contract_address = nft.contract_address;
    // resView.nft_created_at = nft.created_at;
    // resView.nft_creator_address = nft.creator_address;
    // resView.nft_creator_flg = nft.creator_flg;
    // resView.nft_creator_icon = nft.creator_icon
    //   ? nft.creator_icon
    //   : "/dist/tmty/assets/default/icon.webp";

    // resView.nft_creator_id = nft.creator_id;
    // resView.nft_creator_name = nft.creator_name;
    // resView.nft_description = nft.description;
    // resView.nft_holder_address = nft.holder_address;
    // resView.nft_holder_flg = nft.holder_flg;
    // resView.nft_holder_icon = nft.holder_icon
    //   ? nft.holder_icon
    //   : "/dist/tmty/assets/default/icon.webp";

    // resView.nft_holder_id = nft.holder_id;
    // resView.nft_holder_name = nft.holder_name;
    // resView.nft_image_uri = nft.image_uri;
    // resView.nft_symbol = nft.symbol;
    // resView.nft_token_id = nft.token_id;
    // resView.nft_token_name = nft.token_name;
    // resView.nft_nft_token_id = nft.nft_token_id;
    resView.nft_token_id2 = nft.token_id;
    resView.nft_metadata = nft.metadata.description;
    resView.nft_metadata_image = nft.metadata.image;
    resView.nft_metadata_name = nft.metadata.name;
    resView.nft_metadata_thumbnail = nft.metadata.thumbnail_url;
  }

  resView.item_sale_price = prop.item_sale_price;
  resView.item_sale_coin_id = prop.item_sale_coin_id;
  resView.item_sale_coin = prop.item_sale_coin;
  resView.item_sale_price_jpy = prop.item_sale_price_jpy;
  resView.item_sale_price_usd = prop.item_sale_price_usd;

  if (maps) {
    const coin_decimals = maps.coin_decimals;
    resView.item_sale_price_usd_label = toNumber(prop.item_sale_price_usd, coin_decimals["USDT"]);
    resView.item_order_price_usd_label = toNumber(prop.item_order_price_usd, coin_decimals["USDT"]);
  }

  return resView;
};

export const view_item_send = (prop, maps, t) => {
  const resView = {};

  return resView;
};
