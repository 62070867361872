import React, { FC, useEffect, useRef, useState, createContext } from "react";
// import { AppRouter } from "../Navigator/Router";
import { AppRouter } from "../Navigator/Router_material";

export const DisplayContext = createContext();

let innerHeight = window.innerHeight;
let outerHeight = window.outerHeight;
let windowHeight = innerHeight - 50;

const App = () => {
  // const [windowHeight, set_windowHeight] = React.useState(innerHeight - 50);

  const resizeFunction = () => {
    // if (outerHeight != window.outerHeight) {
    //   set_windowHeight(window.innerHeight);
    //   innerHeight = window.innerHeight;
    //   outerHeight = window.outerHeight;
    // }

    // if (innerHeight < window.innerHeight) {
    //   set_windowHeight(window.innerHeight);
    //   innerHeight = window.innerHeight;
    //   outerHeight = window.outerHeight;
    // }
  };

  React.useEffect(() => {
    window.addEventListener("resize", resizeFunction);
    return function cleanup() {
      window.removeEventListener("resize", resizeFunction);
    };
  });

  return (
    <>
      <DisplayContext.Provider value={windowHeight}>
        <AppRouter />
      </DisplayContext.Provider>
    </>
  );
};

export default App;
