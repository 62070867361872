import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { view_item } from "../../stores/Items/view";
import { view_collection } from "../../stores/Collections/view";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function Contents(Item) {
  // const { dispatch_put_items_id_favorite } = Item;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const item = useSelector((state) => state.reduce_get_items.item);

  return (
    <>
      <div data-bs-toggle="tooltip" data-bs-placement="top" title="">
        <button
          type="button"
          // className="btn btn-white rounded-pill px-3 me-2"
          className="btn btn-white rounded-pill px-3"
          id="userReact"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onClick={() => {}}
        >
          <i className="fa-solid fa-ellipsis" />
          {/* <i className="fa-solid fa-link fa-fw" /> */}
          {/* <i className="fa-solid fa-share-alt fa-fw" /> */}
        </button>

        <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="userShere">
          {/* <li>
            <button type="button" className="dropdown-item">
              <i className="fa-solid fa-heart-crack fa-fw me-3" />
              {t("ウォッチリストを解除")}
            </button>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li> */}
          <li>
            <CopyToClipboard
              text={`https://www.tmty.io/collections/${v_collection.collection_nmid}/items`}
              onCopy={() => {
                alert("コレクションのリンクをコピーしました。");
              }}
            >
              <button type="button" className="dropdown-item">
                <i className="fa-solid fa-link fa-fw me-3" />
                {t("リンクをコピー")}
              </button>
            </CopyToClipboard>
          </li>

          {/* <li>
            <button type="button" className="dropdown-item">
              <i className="fa-solid fa-clipboard-check fa-fw me-3" />
              {t("コレクションIDをコピー")}
            </button>
          </li>
          <li>
            <button type="button" className="dropdown-item">
              <i className="fa-solid fa-clipboard-check fa-fw me-3" />
              {t("ユーザIDをコピー")}
            </button>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <button type="button" className="dropdown-item text-danger">
              <i className="fa-solid fa-font-awesome fa-fw me-3" />
              {t("問題を報告")}
            </button>
          </li> */}
        </ul>
      </div>
    </>
  );
}
