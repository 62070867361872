import {
  fork,
  take,
  takeEvery,
  takeLatest,
  put,
  join,
  select,
  call,
} from "redux-saga/effects";
import { post_authout } from "../Auth/actions";

import {
  get_accounts_kyc,
  get_accounts_kyc_data,
  get_accounts_kyc_error,
  get_accounts_totp,
  get_accounts_totp_data,
  get_accounts_totp_error,
  get_accounts,
  get_accounts_data,
  get_accounts_error,
  put_accounts,
  put_accounts_data,
  put_accounts_error,
  put_accounts_extra,
  put_accounts_extra_data,
  put_accounts_extra_error,
  delete_accounts,
  delete_accounts_data,
  delete_accounts_error,
  delete_accounts_delete,
  delete_accounts_delete_data,
  delete_accounts_delete_error,
  post_accounts_images,
  post_accounts_images_data,
  post_accounts_images_error,
  delete_accounts_images_id,
  delete_accounts_images_id_data,
  delete_accounts_images_id_error,
  post_accounts_verify,
  post_accounts_verify_data,
  post_accounts_verify_error,
  post_accounts_auth,
  post_accounts_auth_data,
  post_accounts_auth_error,
  post_accounts_auth_code,
  post_accounts_auth_code_data,
  post_accounts_auth_code_error,
  put_accounts_security,
  put_accounts_security_data,
  put_accounts_security_error,
  get_notification,
  get_notification_data,
  get_notification_error,
  post_notification,
  post_notification_data,
  post_notification_error,
  // get_users,
  // get_users_data,
  // get_users_error,
  // get_users_id,
  // get_users_id_data,
  // get_users_id_error,
} from "./actions";

import {
  api_get_accounts_kyc,
  api_get_accounts_totp,
  api_get_accounts,
  api_put_accounts,
  api_put_accounts_extra,
  api_delete_accounts,
  api_delete_accounts_delete,
  api_post_accounts_verify,
  api_post_accounts_auth,
  api_post_accounts_auth_code,
  api_put_accounts_security,
  api_post_accounts_images,
  api_delete_accounts_images_id,
  // api_get_users,
  // api_get_users_id,
  api_get_notification,
  api_post_notification,
} from "./api";

export function* run_get_accounts_kyc({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_accounts_kyc, payload.data, token);
  if (!error) {
    yield put(get_accounts_kyc_data({ data }));

    !payload.next || payload.next(data);
  } else {
    yield put(get_accounts_kyc_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_accounts_totp({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(
    api_get_accounts_totp,
    payload.data,
    token
  );
  if (!error) {
    yield put(get_accounts_totp_data({ data }));

    !payload.next || payload.next(data);
  } else {
    yield put(get_accounts_totp_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_accounts({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_accounts, payload.data, token);
  if (!error) {
    yield put(get_accounts_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_accounts_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_accounts({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_put_accounts, payload.data, token);
  if (!error) {
    yield put(put_accounts_data({ data }));
    yield put(get_accounts({ data }));

    !payload.next || payload.next(data);
  } else {
    yield put(put_accounts_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}


export function* run_put_accounts_extra({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_put_accounts_extra, payload.data, token);
  if (!error) {
    yield put(put_accounts_extra_data({ data }));
    yield put(get_accounts({ data }));

    !payload.next || payload.next(data);
  } else {
    yield put(put_accounts_extra_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_accounts({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_delete_accounts, payload.data, token);
  if (!error) {
    yield put(delete_accounts_data({ data }));

    !payload.next || payload.next(data);
  } else {
    yield put(delete_accounts_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_accounts_delete({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { hash } = yield select((state) => state.reduce_delete_accounts);

  const { data, error } = yield call(
    api_delete_accounts_delete,
    payload.data,
    token,
    hash
  );
  if (!error) {
    yield put(delete_accounts_delete_data({ data }));
    yield put(post_authout({ data }));

    !payload.next || payload.next(data);
  } else {
    yield put(delete_accounts_delete_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_accounts_verify({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(
    api_post_accounts_verify,
    payload.data,
    token
  );
  if (!error) {
    yield put(post_accounts_verify_data({ data }));

    !payload.next || payload.next(data);
  } else {
    yield put(post_accounts_verify_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_accounts_auth({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(
    api_post_accounts_auth,
    payload.data,
    token
  );
  if (!error) {
    yield put(post_accounts_auth_data({ data }));

    !payload.next || payload.next(data);
  } else {
    yield put(post_accounts_auth_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_accounts_auth_code({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { hash } = yield select((state) => state.reduce_post_accounts_auth);

  const { data, error } = yield call(
    api_post_accounts_auth_code,
    payload.data,
    token,
    hash
  );

  if (!error) {
    yield put(post_accounts_auth_code_data({ data }));
    yield put(get_accounts({ data }));

    !payload.next || payload.next(data);
  } else {
    yield put(post_accounts_auth_code_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_accounts_security({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(
    api_put_accounts_security,
    payload.data,
    token
  );
  if (!error) {
    yield put(put_accounts_security_data({ data }));
    yield put(get_accounts({ data }));

    !payload.next || payload.next(data);
  } else {
    yield put(put_accounts_security_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_accounts_images({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(
    api_post_accounts_images,
    payload.data,
    token
  );
  if (!error) {
    yield put(post_accounts_images_data({ data }));
    yield put(get_accounts({ data }));

    !payload.next || payload.next(data);
  } else {
    yield put(post_accounts_images_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_accounts_images_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(
    api_delete_accounts_images_id,
    payload.data,
    token
  );
  if (!error) {
    yield put(delete_accounts_images_id_data({ data }));
    yield put(get_accounts_kyc({ data }));

    !payload.next || payload.next(data);
  } else {
    yield put(delete_accounts_images_id_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_notification({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_notification, payload.data, token);
  if (!error) {
    yield put(get_notification_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_notification_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_notification({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const notification = yield select(
    (stores) => stores.reduce_post_notification
  );
  payload.data.token = notification.token;
  const { data, error } = yield call(
    api_post_notification,
    payload.data,
    token
  );
  if (!error) {
    yield put(post_notification_data({ data }));
    // yield put(get_notification_data({ }));

    !payload.next || payload.next(data);
  } else {
    yield put(post_notification_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

// export function* run_get_users({ payload }) {
//   const { token } = yield select((stores) => stores.reduce_post_authin);
//   const { data, error } = yield call(api_get_users, payload.data, token);
//   if (!error) {
//     yield put(get_users_data({ data }));

//     !payload.next || payload.next(data);
//   } else {
//     yield put(get_users_error({ error }));
//     if (error.response && error.response.status === 401)
//       yield put(post_authout({}));
//     !payload.prev || payload.prev(error);
//   }
// }

// export function* run_get_users_id({ payload }) {
//   const { token } = yield select((stores) => stores.reduce_post_authin);
//   const { data, error } = yield call(api_get_users_id, payload.data, token);
//   if (!error) {
//     yield put(get_users_id_data({ data }));

//     !payload.next || payload.next(data);
//   } else {
//     yield put(get_users_id_error({ error }));
//     if (error.response && error.response.status === 401)
//       yield put(post_authout({}));
//     !payload.prev || payload.prev(error);
//   }
// }

export function* flow_get_accounts_kyc() {
  yield takeLatest(get_accounts_kyc, run_get_accounts_kyc);
}
export function* flow_get_accounts_totp() {
  yield takeLatest(get_accounts_totp, run_get_accounts_totp);
}
export function* flow_get_accounts() {
  yield takeLatest(get_accounts, run_get_accounts);
}
export function* flow_put_accounts() {
  yield takeLatest(put_accounts, run_put_accounts);
}

export function* flow_put_accounts_extra() {
  yield takeLatest(put_accounts_extra, run_put_accounts_extra);
}
export function* flow_delete_accounts() {
  yield takeLatest(delete_accounts, run_delete_accounts);
}
export function* flow_delete_accounts_delete() {
  yield takeLatest(delete_accounts_delete, run_delete_accounts_delete);
}
export function* flow_post_accounts_auth_verify() {
  yield takeLatest(post_accounts_verify, run_post_accounts_verify);
}

export function* flow_post_accounts_auth() {
  yield takeLatest(post_accounts_auth, run_post_accounts_auth);
}
export function* flow_post_accounts_auth_code() {
  yield takeLatest(post_accounts_auth_code, run_post_accounts_auth_code);
}
export function* flow_put_accounts_security() {
  yield takeLatest(put_accounts_security, run_put_accounts_security);
}
export function* flow_post_accounts_images() {
  yield takeLatest(post_accounts_images, run_post_accounts_images);
}
export function* flow_delete_accounts_images_id() {
  yield takeLatest(delete_accounts_images_id, run_delete_accounts_images_id);
}
export function* flow_get_notification() {
  yield takeLatest(get_notification, run_get_notification);
}
export function* flow_post_notification() {
  yield takeLatest(post_notification, run_post_notification);
}
// export function* flow_get_users() {
//   yield takeLatest(get_users, run_get_users);
// }
// export function* flow_get_users_id() {
//   yield takeLatest(get_users_id, run_get_users_id);
// }

export default function* saga() {
  yield fork(flow_get_accounts_kyc);
  yield fork(flow_get_accounts_totp);
  yield fork(flow_get_accounts);
  yield fork(flow_put_accounts);
  yield fork(flow_put_accounts_extra);
  yield fork(flow_delete_accounts);
  yield fork(flow_delete_accounts_delete);

  yield fork(flow_post_accounts_images);
  yield fork(flow_delete_accounts_images_id);

  yield fork(flow_post_accounts_auth_verify);
  yield fork(flow_post_accounts_auth);
  yield fork(flow_post_accounts_auth_code);
  yield fork(flow_put_accounts_security);

  yield fork(flow_get_notification);
  yield fork(flow_post_notification);

  // yield fork(flow_get_users);
  // yield fork(flow_get_users_id);
}
