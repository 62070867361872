import { createAction, createReducer } from 'redux-act';

export const get_accounts_cards = createAction("GET_ACCOUNTS_CARDS");
export const get_accounts_cards_data = createAction("GET_ACCOUNTS_CARDS_DATA");
export const get_accounts_cards_error = createAction("GET_ACCOUNTS_CARDS_ERROR");
export const get_accounts_cards_id = createAction("GET_ACCOUNTS_CARDS_ID");
export const get_accounts_cards_id_data = createAction("GET_ACCOUNTS_CARDS_ID_DATA");
export const get_accounts_cards_id_error = createAction("GET_ACCOUNTS_CARDS_ID_ERROR");
export const post_accounts_cards = createAction("POST_ACCOUNTS_CARDS");
export const post_accounts_cards_data = createAction("POST_ACCOUNTS_CARDS_DATA");
export const post_accounts_cards_error = createAction("POST_ACCOUNTS_CARDS_ERROR");
export const put_accounts_cards_id = createAction("PUT_ACCOUNTS_CARDS_ID");
export const put_accounts_cards_id_data = createAction("PUT_ACCOUNTS_CARDS_ID_DATA");
export const put_accounts_cards_id_error = createAction("PUT_ACCOUNTS_CARDS_ID_ERROR");
export const delete_accounts_cards_id = createAction("DELETE_ACCOUNTS_CARDS_ID");
export const delete_accounts_cards_id_data = createAction("DELETE_ACCOUNTS_CARDS_ID_DATA");
export const delete_accounts_cards_id_error = createAction("DELETE_ACCOUNTS_CARDS_ID_ERROR");

export const post_cards_id_buy = createAction("POST_CARDS_ID_BUY");
export const post_cards_id_buy_data = createAction("POST_CARDS_ID_BUY_DATA");
export const post_cards_id_buy_error = createAction("POST_CARDS_ID_BUY_ERROR");

export const post_cards_id_pay = createAction("POST_CARDS_ID_PAY");
export const post_cards_id_pay_data = createAction("POST_CARDS_ID_PAY_DATA");
export const post_cards_id_pay_error = createAction("POST_CARDS_ID_PAY_ERROR");

export const put_cards_id_favorite = createAction("POST_CARDS_ID_FAVORITE");
export const put_cards_id_favorite_data = createAction("POST_CARDS_ID_FAVORITE_DATA");
export const put_cards_id_favorite_error = createAction("POST_CARDS_ID_FAVORITE_ERROR");
