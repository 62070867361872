import axios from "../axios";
import env from "../env";

export const api_get_collections = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`collections`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    // headers: {
    //   "Content-Type": "application/json",
    //   Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    // },
    params: data,
  };
  return axios(options);
};

export const api_get_collections_id = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`collections/${data.id}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_post_collections = (data, token) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`collections`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    data,
    formData: true,
  };
  return axios(options);

  // const options = {
  //   method: "post",
  //   url: `${env.REACT_APP_API_URL}${`collections/d127a70e-d743-42a1-b58b-70eeadfd398d`}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
  //   },
  //   data,
  //   formData: true
  // };
  // return axios(options);
};

export const api_put_collections_id = (data, token) => {
  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`collections/${data.id}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    data,
    formData: true,
  };
  return axios(options);
};

export const api_delete_collections_id = (data, token) => {
  const options = {
    method: "delete",
    url: `${env.REACT_APP_API_URL}${`collections/${data.id}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    data,
  };
  return axios(options);
};


export const api_put_collections_id_favorite = (data, token) => {
  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`collections/${data.id}/favorite`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    data,
    formData: true,
  };
  return axios(options);
};

export const api_put_collections_id_join = (data, token) => {
  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`collections/${data.id}/join`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    data,
    formData: true,
  };
  return axios(options);
};

