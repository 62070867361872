import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import { view_collection } from "../../stores/Collections/view";
import { view_user } from "../../stores/Users/view";

export default function Contents(Contact) {
  const { propsContact, set_propsContact, dispatch_post_contact } = Contact;
  const props = propsContact;
  const set_props = set_propsContact;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();
  const { user_id } = useParams();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_users = useSelector((state) => state.reduce_get_users);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const collection = useSelector((state) => state.reduce_get_items.collection);
  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection);

  const user = reduce_get_users.user;
  const v_user = view_user(user);

  return (
    <>
      <section className="py-5 bg-white">
        <div className="container py-sm-4">
          <div className="mb-5 mb-sm-3 pb-sm-1">
            <h3 className="h1 text-center">{t("スペシャリストに相談")}</h3>
            <div className="d-grid col-sm-10 col-lg-8 col-xl-6 mx-auto">
              <div className="position-relative m-4 mb-0">
                <div
                  className="progress"
                  style={{
                    height: "1px",
                  }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "50%",
                    }}
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <span
                  className="position-absolute top-0 start-0 translate-middle rounded-pill btn btn-sm btn-primary"
                  style={{
                    cursor: "unset",
                  }}
                >
                  {t("入力")}
                </span>
                <span
                  className="position-absolute top-0 start-50 translate-middle rounded-pill btn btn-sm btn-light text-secondary"
                  style={{
                    cursor: "unset",
                  }}
                >
                  {t("確認")}
                </span>
                <span
                  className="position-absolute top-0 start-100 translate-middle rounded-pill btn btn-sm btn-light text-secondary"
                  style={{
                    cursor: "unset",
                  }}
                >
                  <i className="fa-solid fa-paper-plane" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container pb-5">
          <div action="contact-form-confirm.html" className="needs-validation" noValidate>
            <div className="d-grid col-sm-10 col-lg-8 col-xl-6 my-4 mx-auto">
              <div className="row g-3">
                <div className="col-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      id="inputNameLast"
                      className="form-control"
                      placeholder="田中"
                      required
                      value={props.last_name}
                      onChange={(e) => {
                        const val = e.target.value;
                        set_props({
                          ...props,
                          last_name: val,
                        });
                      }}
                    />
                    <label htmlFor="inputNameLast">{t("ニックネーム")}</label>
                    <div className="invalid-feedback">{t("正しく入力してください")}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      id="inputNameFirst"
                      className="form-control"
                      placeholder="太郎"
                      required
                      value={props.first_name}
                      onChange={(e) => {
                        const val = e.target.value;
                        set_props({
                          ...props,
                          first_name: val,
                        });
                      }}
                    />
                    <label htmlFor="inputNameFirst">{t("ユーザーID")}</label>
                    <div className="invalid-feedback">{t("正しく入力してください")}</div>
                  </div>
                </div>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  id="inputEmail"
                  className="form-control"
                  placeholder="name@example.com"
                  required
                  value={props.mail_address}
                  onChange={(e) => {
                    const val = e.target.value;
                    set_props({
                      ...props,
                      mail_address: val,
                    });
                  }}
                />
                <label htmlFor="inputEmail">{t("メールアドレス")}</label>
                <div className="invalid-feedback">{t("正しく入力してください")}</div>
              </div>
              <div className="form-floating mb-5">
                <input
                  type="email"
                  id="inputEmail"
                  className="form-control"
                  placeholder="name@example.com"
                  required
                  value={props.mail_address2}
                  onChange={(e) => {
                    const val = e.target.value;
                    set_props({
                      ...props,
                      mail_address2: val,
                    });
                  }}
                />
                <label htmlFor="inputEmail">{t("メールアドレスの確認")}</label>
                <div className="invalid-feedback">{t("正しく入力してください")}</div>
              </div>
              <div className="form-floating mb-3">
                <select
                  id="inputCountry"
                  className="form-select"
                  required
                  value={props.country}
                  onChange={(e) => {
                    const val = e.target.value;
                    set_props({
                      ...props,
                      country: val,
                    });
                  }}
                >
                  <option>{t("選択してください")}</option>
                  <option>{t("日本")}</option>
                </select>
                <label htmlFor="inputCountry">{t("ご利用している国")}</label>
                <div className="invalid-feedback">{t("選択してください")}</div>
              </div>
              <div className="form-floating mb-3">
                <select
                  id="inputDevice"
                  className="form-select"
                  required
                  value={props.device}
                  onChange={(e) => {
                    const val = e.target.value;
                    set_props({
                      ...props,
                      device: val,
                    });
                  }}
                >
                  <option>{t("選択してください")}</option>
                  <option>PC</option>
                  <option>Mobile</option>
                </select>
                <label htmlFor="inputDevice">{t("ご利用端末")}</label>
                <div className="invalid-feedback">{t("選択してください")}</div>
              </div>
              <div className="row g-3">
                <div className="col-sm">
                  <div className="form-floating mb-3">
                    <select
                      id="inputOS"
                      className="form-select"
                      required
                      value={props.os}
                      onChange={(e) => {
                        const val = e.target.value;
                        set_props({
                          ...props,
                          os: val,
                        });
                      }}
                    >
                      <option>{t("選択してください")}</option>
                      <option>Windows</option>
                      <option>Apple macOS</option>
                      <option>Ubuntu</option>
                      <option>Fedora</option>
                      <option>Solaris</option>
                      <option>Free BSD</option>
                      <option>Chrome OS</option>
                      <option>CentOS</option>
                      <option>Debian</option>
                      <option>Deepin</option>
                      <option>その他</option>
                    </select>
                    <label htmlFor="inputOS">OS</label>
                    <div className="invalid-feedback">{t("選択してください")}</div>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="form-floating mb-5">
                    <select
                      id="inputWebbrowser"
                      className="form-select"
                      required
                      value={props.browser}
                      onChange={(e) => {
                        const val = e.target.value;
                        set_props({
                          ...props,
                          browser: val,
                        });
                      }}
                    >
                      <option>{t("選択してください")}</option>
                      <option>Google Chrome</option>
                      <option>Internet Explorer</option>
                      <option>Microsoft Edge</option>
                      <option>Safari</option>
                      <option>Mozilla Firefox</option>
                      <option>{t("その他")}</option>
                    </select>
                    <label htmlFor="inputWebbrowser">{t("ブラウザ")}</label>
                    <div className="invalid-feedback">{t("選択してください")}</div>
                  </div>
                </div>
              </div>
              <div className="form-floating mb-3">
                <select
                  className="form-select"
                  id="inputFeedback"
                  value={props.category}
                  onChange={(e) => {
                    const val = e.target.value;
                    set_props({
                      ...props,
                      category: val,
                    });
                  }}
                >
                  <option>{t("選択してください")}</option>
                  <optgroup label={t("プラットフォーム")}>
                    <option selected>{t("ブロックチェーン")}</option>
                    <option>{t("ウォレット")}</option>
                    <option>{t("サーバー")}</option>
                    <option>{t("セキュリティ")}</option>
                    <option>{t("サービス手数料")}</option>
                    <option>{t("アカウント")}</option>
                  </optgroup>
                  {/* <optgroup label="パートナープログラム">
                    <option>Value</option>
                    <option>Value</option>
                  </optgroup>
                  <optgroup label="デベロッパー">
                    <option>Value</option>
                    <option>Value</option>
                  </optgroup> */}
                </select>
                <label htmlFor="inputFeedback">{t("フィードバックの種類")}</label>
                <div className="invalid-feedback">{t("選択してください")}</div>
              </div>
              <div className="form-floating mb-5">
                <textarea
                  id="inputMessage"
                  className="form-control"
                  placeholder={t("具体的に且つ理解しやすい内容でお願いします。")}
                  style={{
                    height: "300px",
                  }}
                  defaultValue={""}
                  value={props.message}
                  onChange={(e) => {
                    const val = e.target.value;
                    set_props({
                      ...props,
                      message: val,
                    });
                  }}
                />
                <label htmlFor="inputMessage">{t("ご相談内容")}</label>
                <div className="invalid-feedback">{t("正しく入力してください")}</div>
              </div>
              <div className="form-check mb-3">
                <div className="d-flex">
                  <input
                    type="checkbox"
                    id="inputConsent"
                    className="form-check-input flex-shrink-0 me-2"
                    required
                    checked={props.consent}
                    onChange={(e) => {
                      const val = e.target.checked;
                      set_props({
                        ...props,
                        consent: val,
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor="inputConsent">
                    {t("tomonityの「")}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`https://help.tmty.io/tos/`, "_blank", "noreferrer");
                      }}
                    >
                      {t("利用規約")}
                    </a>
                    {t("」と「")}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`https://help.tmty.io/tos/`, "_blank", "noreferrer");
                      }}
                    >
                      {t("プライバシーポリシー")}
                    </a>
                    {t(" 」に同意しますか？")}
                  </label>
                </div>
                <div className="invalid-feedback">{t("同意されていません")}</div>
              </div>
            </div>
            <div className="d-grid col-sm-4 col-lg-3 mb-5 mx-auto">
              <button
                type="button"
                className="btn btn-primary rounded-pill px-4 btn-lg d-flex align-items-center"
                onClick={() => {
                  if (props.mail_address != props.mail_address2) {
                    alert(t("メールアドレスが一致していません"));
                    return;
                  }
                  if (!props.consent) {
                    alert(t("同意されていません"));
                    return;
                  }

                  set_props({
                    ...props,
                    type: 2,
                  });
                }}
              >
                {t("送信内容の確認")}
                <i className="fa-solid fa-angle-right ms-auto" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
// recaptcha,
// mail_address,
// full_name,
// user_name,
// phone_number,
// message,

// onChange={(e) => {
//   const val = e.target.value;
//   set_props({
//     ...props,
//     token_name: val,
//   });
// }}
