import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import { hideModal, showModal } from "config/util";

import _modalSuccessfully from "./_modalSuccessfully";
import _processDialog from "./_processDialog";

export default function Contents({ Collections, idx, m }) {
  const {
    propsCollections,
    set_propsCollections,
    dispatch_post_collections,
    loadingId,
  } = Collections;

  const props = propsCollections;
  const set_props = set_propsCollections;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const history_modalSuccessfully = {
    push: (arg) => {
      hideModal("modalSuccessfully");
      hideModal("processDialog");
      history.push(arg);
    },
  };

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const maps = useSelector((state) => state.reduce_get_maps);

  const { user_icon, user_name, user_id, auth, reward_first, reward_second, disabled } = m;

  const isowner = idx === false ? true : false;

  const mb = isowner ? " mb-4" : "";

  return (
    <>
      <div className={"row g-1 align-items-center" + mb}>
        <div className="col overflow-hidden">
          <div
            className="media media-sm"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title=""
          >
            <div className="media-object">
              <div className="icon icon-pill">
                <img
                  src={user_icon}
                  className="icon-item"
                  width="100%"
                  height="auto"
                  // alt="Vertical Person"
                />
              </div>
            </div>
            <div className="media-body text-truncate">
              <a className="d-block small text-truncate" href="../user.html" target="_blank">
                {user_name}
              </a>
              <small className="d-block text-muted text-truncate">@{user_id}</small>
            </div>
          </div>
        </div>
        <div className="col-auto">
          <div className="form-check-reverse form-switch m-0 py-0 justify-content-end">
            <label className="form-check-label" htmlFor="admin-2" />
            <input
              className="form-check-input"
              type="checkbox"
              id="admin-2"
              checked={auth}
              disabled={disabled}
              onClick={() => {
                // const val = e.target.value;

                const members = props.members;
                members[idx].auth = !members[idx].auth;

                set_props({
                  ...props,
                  members: members,
                });
              }}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="input-group input-group-sm">
            <select
              className="form-select"
              aria-label="Creator Rewards"
              aria-describedby="rewards"
              value={reward_first}
              onChange={(e) => {
                const val = e.target.value;

                if (isowner) {
                  const owner = props.owner;
                  owner.reward_first = val;

                  set_props({
                    ...props,
                    owner: owner,
                  });
                } else {
                  const members = props.members;
                  members[idx].reward_first = val;

                  set_props({
                    ...props,
                    members: members,
                  });
                }
              }}
            >
              {/* <option>一次(販売売上)</option> */}
              <option>0</option>
              <option>5</option>
              <option>10</option>
              <option>15</option>
              <option>20</option>
              <option>25</option>
              <option>30</option>
              <option>35</option>
              <option>40</option>
              <option>45</option>
              <option>50</option>
              <option>55</option>
              <option>60</option>
              <option>65</option>
              <option>70</option>
              <option>75</option>
              <option>80</option>
              <option>85</option>
              <option>90</option>
              <option>95</option>
              <option>100</option>
            </select>
            <span className="d-none d-sm-block input-group-text" id="rewards">
              <i className="fa-solid fa-percent" />
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="input-group input-group-sm">
            <select
              className="form-select"
              aria-label="Creator Rewards"
              aria-describedby="rewards"
              value={reward_second}
              onChange={(e) => {
                const val = e.target.value;

                if (isowner) {
                  const owner = props.owner;
                  owner.reward_second = val;

                  set_props({
                    ...props,
                    owner: owner,
                  });
                } else {
                  const members = props.members;
                  members[idx].reward_second = val;

                  set_props({
                    ...props,
                    members: members,
                  });
                }
              }}
            >
              {/* <option>二次(クリエイター報酬)</option> */}
              <option>0</option>
              <option>5</option>
              <option>10</option>
              <option>15</option>
              <option>20</option>
              <option>25</option>
              <option>30</option>
              <option>35</option>
              <option>40</option>
              <option>45</option>
              <option>50</option>
              <option>55</option>
              <option>60</option>
              <option>65</option>
              <option>70</option>
              <option>75</option>
              <option>80</option>
              <option>85</option>
              <option>90</option>
              <option>95</option>
              <option>100</option>
            </select>
            <span className="d-none d-sm-block input-group-text" id="rewards">
              <i className="fa-solid fa-percent" />
            </span>
          </div>
        </div>
        <div className="col-auto">
          {isowner ? (
            <button
              className="btn btn-link btn-sm link-danger disabled"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="\u30B3\u30E9\u30DC\u304B\u3089\u5916\u3059"
              disabled="disabled"
            >
              <i className="fa-solid fa-trash-can" />
            </button>
          ) : (
            <button
              className="btn btn-link btn-sm link-danger"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="\u30B3\u30E9\u30DC\u304B\u3089\u5916\u3059"
              onClick={() => {
                const members = props.members;
                members.splice(idx, 1);

                set_props({
                  ...props,
                  members: members,
                });
              }}
            >
              <i className="fa-solid fa-trash-can" />
            </button>
          )}
        </div>
      </div>
    </>
  );
}
