import axios from "../axios";
import env from "../env";

export const api_get_accounts_kyc = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`accounts/kyc`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_get_accounts_totp = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`accounts/totp`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_get_accounts = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`accounts`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_put_accounts = (data, token) => {
  const params = new FormData();
  for (var key in data) {
    if (key === "images") {
      for (var image of data.images) {
        params.append("images[]", image);
      }
    } else {
      params.append(key, data[key]);
    }
  }

  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`accounts`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: params,
    // data,
  };
  return axios(options);
};

export const api_put_accounts_extra = (data, token) => {
  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`accounts/extra`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_delete_accounts = (data, token) => {
  const options = {
    method: "delete",
    url: `${env.REACT_APP_API_URL}${`accounts`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_delete_accounts_delete = (data, token, hash) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`accounts/delete/${hash}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_accounts_verify = (data, token) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`accounts/verify`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_accounts_auth = (data, token) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`accounts/auth`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_accounts_auth_code = (data, token, hash) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`accounts/auth/code/${hash}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_put_accounts_security = (data, token) => {
  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`accounts/security`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_accounts_images = (data, token) => {
  // const params = new FormData();
  // for (var key in data) {
  //   if (key === "images") {
  //     for (var image of data.images) {
  //       params.append("images[]", image);
  //     }
  //   } else {
  //     params.append(key, data[key]);
  //   }
  // }

  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`accounts/images`}`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    // data: params,
    data,
    formData: true,
  };

  return axios(options);
};

export const api_delete_accounts_images_id = (data, token) => {
  const options = {
    method: "delete",
    url: `${env.REACT_APP_API_URL}${`accounts/images/${data.id}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_get_notification = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`notification`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_post_notification = (data, token) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`notification`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  // alert(JSON.stringify(data))
  return axios(options);
};

// export const api_get_users = (data, token) => {
//   const options = {
//     method: "get",
//     url: `${env.REACT_APP_API_URL}${`users`}`,
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
//     },
//     params: data,
//   };
//   return axios(options);
// };

// export const api_get_users_id = (data, token) => {
//   const options = {
//     method: "get",
//     url: `${env.REACT_APP_API_URL}${`users/${data.id}`}`,
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
//     },
//     params: data,
//   };
//   return axios(options);
// };
