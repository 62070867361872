import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import { QRCodeSVG } from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Header from "../../views_components/Component/Header";

import ActivitiesPager from "../../views_components/PagerActivities/ActivitiesPager";
import ActivitiesList from "../../views_components/PagerActivities/ActivitiesList";
import WithdrawsPager from "../../views_components/PagerWithdraws/WithdrawsPager";
import WithdrawsList from "../../views_components/PagerWithdraws/WithdrawsList";

import { hideModal, showModal } from "config/util";
import { view_wallets } from "../../stores/Wallets/view";

export default function Contents(Withdraws) {
  const { propsWithdraws, set_propsWithdraws, dispatch_post_withdraws } = Withdraws;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const { coin } = useParams();

  const [active_tab, set_active_tab] = useState("預入");

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_wallets = useSelector((state) => state.reduce_get_wallets);
  const reduce_get_withdraws = useSelector((state) => state.reduce_get_withdraws);

  const maps = useSelector((state) => state.reduce_get_maps);

  const coin_decimals = maps.coin_decimals;
  const coins = maps.coins;

  useEffect(() => {}, []);
  const coin_fullname = coins[coin];

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const wallets = reduce_get_wallets?.wallets;
  const v_wallets = view_wallets(wallets, maps, t, coin);

  const withdraws = reduce_get_withdraws?.withdraws;
  // const v_withdraws = view_withdraws(withdraws,  maps,t, coin);

  const mindeposit = {
    BTC: 0.0002,
    ETH: 0.0025,
    IOST: 0,
    USDT: 0,
  };
  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  return (
    <>
      <div
        className="modal fade"
        id="walletsCreateModal"
        tabIndex={-1}
        aria-labelledby="walletsCreateModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="walletsCreateModalLabel">
              {t("アドレスを登録")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <dl className="row g-2">
                <dt className="col-sm-4">{t("ラベル")}</dt>
                <dd className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) => {
                      const val = e.target.value;
                      set_propsWithdraws({
                        ...propsWithdraws,
                        label: val,
                      });
                    }}
                    value={propsWithdraws.label}
                  />
                </dd>
                <dt className="col-sm-4">{t("アドレス")}</dt>
                <dd className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) => {
                      const val = e.target.value;
                      set_propsWithdraws({
                        ...propsWithdraws,
                        address: val,
                      });
                    }}
                    value={propsWithdraws.address}
                  />
                </dd>
              </dl>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                {t("キャンセル")}
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  // hideModal("walletsCreateModal");
                  // e.preventDefault();
                  // alert("dispatch_post_wallets");
                  // coin;
                  dispatch_post_withdraws(
                    {
                      // symbol: propsWithdraws.symbol,
                      symbol: coin,
                      label: propsWithdraws.label,
                      address: propsWithdraws.address,
                      search: propsWithdraws.search,
                    },
                    () => {
                      hideModal("walletsCreateModal");
                    },
                    () => {}
                  );
                }}
              >
                {t("登録")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
