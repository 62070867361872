import { createReducer } from "redux-act";

import {
  get_markets_rates,
  get_markets_rates_data,
  get_markets_rates_error,
  get_markets_ohlcs,
  get_markets_ohlcs_data,
  get_markets_ohlcs_error,
  get_markets_ohlc,
  get_markets_ohlc_data,
  get_markets_ohlc_error,
  get_markets_orders,
  get_markets_orders_data,
  get_markets_orders_error,
  get_wallets,
  get_wallets_data,
  get_wallets_error,
  put_wallets,
  put_wallets_data,
  put_wallets_error,
  post_wallets,
  post_wallets_data,
  post_wallets_error,
  put_wallets_id_deposit,
  put_wallets_id_deposit_data,
  put_wallets_id_deposit_error,
  post_wallets_id_staking,
  post_wallets_id_staking_data,
  post_wallets_id_staking_error,
  delete_wallets_id,
  delete_wallets_id_data,
  delete_wallets_id_error,
  post_markets_exchanges,
  post_markets_exchanges_data,
  post_markets_exchanges_error,
  put_markets_exchanges_id_cancel,
  put_markets_exchanges_id_cancel_data,
  put_markets_exchanges_id_cancel_error,
  get_histories_orderings,
  get_histories_orderings_data,
  get_histories_orderings_error,
  put_histories_orderings_id,
  put_histories_orderings_id_data,
  put_histories_orderings_id_error,
  get_histories_orders,
  get_histories_orders_data,
  get_histories_orders_error,
  get_histories_transactions,
  get_histories_transactions_data,
  get_histories_transactions_error,
  get_histories_affiliates,
  get_histories_affiliates_data,
  get_histories_affiliates_error,
  get_histories_bids,
  get_histories_bids_data,
  get_histories_bids_error,
  post_wallets_id_withdraws_id_withdraw,
  post_wallets_id_withdraws_id_withdraw_data,
  post_wallets_id_withdraws_id_withdraw_error,
  post_wallets_id_deposit,
  post_wallets_id_deposit_data,
  post_wallets_id_deposit_error,
} from "./actions";

export const reduce_get_markets_rates = createReducer(
  {
    [get_markets_rates]: (state, payload) => {
      const newState = Object.assign({}, state, {});

      newState.isLoading = true;
      return newState;
    },
    [get_markets_rates_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_markets_rates_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);

export const reduce_get_markets_ohlcs = createReducer(
  {
    [get_markets_ohlcs]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = true;
      return newState;
    },
    [get_markets_ohlcs_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_markets_ohlcs_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [get_markets_ohlc]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      // newState.isLoading = true;
      return newState;
    },
    [get_markets_ohlc_data]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      const ohlc = payload.data.ohlc;
      const ohlcs = newState.ohlcs;

      if (ohlcs.length != 0) {
        const ohlcmax = ohlcs.reduce((a, b) => (Number(a.time) > Number(b.time) ? a : b));

        // ohlcs.sort((a, b) => {
        //   return Number(a.time) > Number(b.time);
        // });
        const timecurrent = Number(ohlc.time);
        const timemax0 = Number(ohlcmax.time);
        const unix_minute = state.unix_minute;

        const ordinal = (timecurrent - timemax0) / unix_minute;

        for (let index = 0; index < ordinal; index++) {
          const timetarget = timemax0 + (index + 1) * unix_minute;
          ohlcs.push({ time: timetarget });
        }

        const newohlcs = ohlcs.filter((x) => !(x.time == ohlc.time));
        newohlcs.push({
          time: Number(ohlc.time),
          open: Number(ohlc.open),
          high: Number(ohlc.high),
          low: Number(ohlc.low),
          close: Number(ohlc.close),
          buy_volume: Number(ohlc.buy_volume),
          sell_volume: Number(ohlc.sell_volume),
        });
        newState.ohlcs = newohlcs;
      } else {
        newState.ohlcs = [
          {
            time: Number(ohlc.time),
            open: Number(ohlc.open),
            high: Number(ohlc.high),
            low: Number(ohlc.low),
            close: Number(ohlc.close),
            buy_volume: Number(ohlc.buy_volume),
            sell_volume: Number(ohlc.sell_volume),
          },
        ];
      }

      // newState.isLoading = false;
      return newState;
    },
    [get_markets_ohlc_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      // newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);

// export const reduce_get_markets_ohlc = createReducer(
//   {
//     [get_markets_ohlc]: (state, payload) => {
//       const newState = Object.assign({}, state, {});

//       newState.isLoading = true;
//       return newState;
//     },
//     [get_markets_ohlc_data]: (state, payload) => {
//       const newState = Object.assign({}, state, { ...payload.data });

//       newState.isLoading = false;
//       return newState;
//     },
//     [get_markets_ohlc_error]: (state, payload) => {
//       const newState = Object.assign({}, state, {});
//       newState.isLoading = false;
//       return newState;
//     },
//   },
//   {
//     isLoading: true,
//   }
// );

export const reduce_get_markets_orders = createReducer(
  {
    [get_markets_orders]: (state, payload) => {
      const newState = Object.assign({}, state, {});

      newState.isLoading = true;
      return newState;
    },
    [get_markets_orders_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_markets_orders_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);

export const reduce_get_wallets = createReducer(
  {
    [get_wallets]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_wallets_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_wallets_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);

export const reduce_put_wallets = createReducer(
  {
    [put_wallets]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_wallets_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_wallets_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_wallets = createReducer(
  {
    [post_wallets]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_wallets_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_wallets_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_wallets_id_deposit = createReducer(
  {
    [put_wallets_id_deposit]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_wallets_id_deposit_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_wallets_id_deposit_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_wallets_id_staking = createReducer(
  {
    [post_wallets_id_staking]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_wallets_id_staking_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_wallets_id_staking_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_wallets_id = createReducer(
  {
    [delete_wallets_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_wallets_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [delete_wallets_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_markets_exchanges = createReducer(
  {
    [post_markets_exchanges]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_markets_exchanges_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [post_markets_exchanges_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_markets_exchanges_id_cancel = createReducer(
  {
    [put_markets_exchanges_id_cancel]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_markets_exchanges_id_cancel_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [put_markets_exchanges_id_cancel_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_get_histories_orderings = createReducer(
  {
    [get_histories_orderings]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_histories_orderings_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [get_histories_orderings_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);

export const reduce_put_histories_orderings_id = createReducer(
  {
    [put_histories_orderings_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_histories_orderings_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [put_histories_orderings_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_get_histories_orders = createReducer(
  {
    [get_histories_orders]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_histories_orders_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [get_histories_orders_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);

export const reduce_get_histories_transactions = createReducer(
  {
    [get_histories_transactions]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_histories_transactions_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [get_histories_transactions_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);

export const reduce_get_histories_affiliates = createReducer(
  {
    [get_histories_affiliates]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_histories_affiliates_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [get_histories_affiliates_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);

export const reduce_get_histories_bids = createReducer(
  {
    [get_histories_bids]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_histories_bids_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [get_histories_bids_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: true,
  }
);

export const reduce_post_wallets_id_withdraws_id_withdraw = createReducer(
  {
    [post_wallets_id_withdraws_id_withdraw]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_wallets_id_withdraws_id_withdraw_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_wallets_id_withdraws_id_withdraw_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_wallets_id_deposit = createReducer(
  {
    [post_wallets_id_deposit]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_wallets_id_deposit_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_wallets_id_deposit_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export default {
  reduce_get_markets_rates,
  reduce_get_markets_ohlcs,
  reduce_get_markets_orders,
  reduce_get_wallets,
  reduce_put_wallets,
  reduce_post_wallets,
  reduce_delete_wallets_id,
  reduce_put_wallets_id_deposit,
  reduce_post_wallets_id_staking,
  reduce_post_markets_exchanges,
  reduce_put_markets_exchanges_id_cancel,

  reduce_get_histories_orderings,
  reduce_put_histories_orderings_id,

  reduce_get_histories_orders,
  reduce_get_histories_transactions,
  reduce_get_histories_affiliates,
  reduce_get_histories_bids,
  reduce_post_wallets_id_withdraws_id_withdraw,
  reduce_post_wallets_id_deposit,
};
