import React, { FC, useEffect, useRef, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { getMessage, toMomentString, toggleAry } from "config/util";
import { Type } from "./_type";
import { initReactI18next, useTranslation } from "react-i18next";

export const HeaderItem = (Activities) => {
  const t = Activities.t;

  return (
    <>
      <dl className="row g-0 g-sm-2 g-md-0 border-bottom border-dark border-2 py-3 pb-md-2 mb-1">
        <dt className="col-7 col-sm-2 col-lg-7 col-xl-2 mb-2 mb-sm-0 mb-lg-2 mb-xl-0">
          <small className="d-block">{t("イベント")}</small>
        </dt>
        <dt className="col-5 col-sm-3 col-lg-5 col-xl-3 text-end order-sm-last order-lg-0 order-xl-last">
          <small className="d-block">{t("日時")}</small>
        </dt>
        <dt className="col-5 col-sm col-lg-5 col-xl text-sm-end text-lg-start text-xl-end pe-3">
          <small className="d-block">{t("価格")}</small>
        </dt>
        <dt className="col col-sm-4 col-lg col-xl-4 align-items-center">
          <dl className="row g-0 g-sm-2 g-md-0 align-items-center">
            <dt className="col-12 col-sm col-lg-12 col-xl text-truncate overflow-hidden">
              <small className="d-block">{t("どこから")}</small>
            </dt>
            <dt className="col-auto d-sm-none d-lg-block d-xl-none">
              <small>
                <i
                  className="fa-solid fa-turn-up fa-fw text-muted me-1"
                  style={{
                    transform: "rotate(90deg)",
                  }}
                />
              </small>
            </dt>
            <dt className="col text-truncate overflow-hidden">
              <small className="d-block">{t("どこへ")}</small>
            </dt>
          </dl>
        </dt>
      </dl>
    </>
  );
};

export const RowItem = (Activities) => {
  const { v_activity, history, t } = Activities;

  return (
    <>
      <dl className="row g-1 g-sm-2 py-3 pt-md-2 mb-1 align-items-sm-center align-items-lg-start align-items-xl-center border-bottom">
        <dd className="col-7 col-sm-2 col-lg-7 col-xl-2 mb-2 mb-sm-0 mb-lg-2 mb-xl-0">
          <Type type={v_activity.activity_type} name={v_activity.activity_name} />
        </dd>
        <dd className="col-5 col-sm-3 col-lg-5 col-xl-3 text-end order-sm-last order-lg-0 order-xl-last">
          {v_activity.explorer_uri && (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.open(`${v_activity.explorer_uri}`, "_blank", "noreferrer");
              }}
            >
              {v_activity.created_at_countdown_label}
              {v_activity.explorer_uri && (
                <i className="fa-solid fa-arrow-up-right-from-square ms-2" />
              )}
            </a>
          )}
          {!v_activity.explorer_uri && <>{v_activity.created_at_countdown_label}</>}
        </dd>
        <dd className="col-5 col-sm col-lg-5 col-xl text-sm-end text-lg-start text-xl-end pe-3">
          {v_activity.activity_price_has && (
            <>
              <span data-bs-toggle="tooltip" data-bs-placement="top" title={v_activity.coin_label}>
                <sub className="me-1">{v_activity.coin_icon_tag}</sub>

                {v_activity.activity_price_label}
              </span>
              <small className="d-block ps-2 ps-sm-0 ps-md-2">
                <sub>$</sub>
                {v_activity.activity_price_usd_label}
              </small>
            </>
          )}
        </dd>
        <dd className="col col-sm-4 col-lg col-xl-4 align-items-center">
          <dl className="row g-0 g-sm-2 g-md-0 align-items-center">
            <dd className="col-12 col-sm col-lg-12 col-xl text-truncate overflow-hidden">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/users/${v_activity.user_id_by}`);
                }}
              >
                {v_activity.user_name_by}
              </a>
            </dd>
            <dd className="col-auto d-sm-none d-lg-block d-xl-none">
              <small>
                <i
                  className="fa-solid fa-turn-up fa-fw text-muted me-1"
                  style={{
                    transform: "rotate(90deg)",
                  }}
                />
              </small>
            </dd>
            <dd className="col text-truncate overflow-hidden">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/users/${v_activity.user_id}`);
                }}
              >
                {/* <div className="d-flex verified-text"> */}
                <span className="text-truncate">{v_activity.user_name}</span>
                {/* </div> */}
              </a>
            </dd>
          </dl>
        </dd>
      </dl>
    </>
  );
};

export const SearchItem = (Activities) => {
  const { propsActivities, set_propsActivities, dispatch_gets } = Activities;
  const history = useHistory();
  const dispatch = useDispatch();

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };
  const [t, i18n] = useTranslation();
  const count = useSelector((state) => state.reduce_get_activities.count);

  const types = propsActivities.search.type ?? [];
  const platforms = propsActivities.search.platform ?? [];

  const dispatch_gets_type = (val) => {
    const search = {
      ...propsActivities.search,
      type: toggleAry(types, val),
      // type:[val],
    };
    dispatch_gets(search, 1);
  };

  const dispatch_gets_platform = (val) => {
    const search = {
      ...propsActivities.search,
      platform: toggleAry(platforms, val),
      // platform:[val],
    };
    dispatch_gets(search, 1);
  };

  const loading = useSelector((state) => state.reduce_get_activities.isLoading);
  const datalist = useSelector((state) => state.reduce_get_activities.activities);

  return (
    <>
      <div className="btn-toolbar ms-auto" role="toolbar" aria-label="\u691C\u7D22\u6761\u4EF6">
        <div className="dropdown" data-bs-toggle="tooltip" data-bs-placement="top" title="">
          <button
            type="button"
            className="btn btn-outline-secondary dropdown-toggle"
            id="filSort"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-solid fa-cart-shopping" />
            {types.includes(11) && `${t("発行")}`}
            {types.includes(21) && `${t("出品")}`}
            {types.includes(22) && `${t("入札")}`}
            {types.includes(30) && `${t("転送")}`}
            {types.includes(31) && `${t("販売")}`}
          </button>
          <ul className="dropdown-menu dropdown-menu-end shadow-sm" aria-labelledby="filSort">
            <li>
              <button
                type="button"
                className={`dropdown-item ${types.includes(11) && "active"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch_gets_type(11);
                }}
              >
                {t("発行")}
              </button>
            </li>

            <li>
              <button
                type="button"
                className={`dropdown-item ${types.includes(21) && "active"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch_gets_type(21);
                }}
              >
                {t("出品")}
              </button>
            </li>
            {/* <li>
              <button
                type="button"
                className={`dropdown-item ${types.includes(22) && "active"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch_gets_type(22);
                }}
              >
                入札
              </button>
            </li> */}

            <li>
              <button
                type="button"
                className={`dropdown-item ${types.includes(30) && "active"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch_gets_type(30);
                }}
              >
                {t("転送")}
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`dropdown-item ${types.includes(31) && "active"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch_gets_type(31);
                }}
              >
                {t("販売")}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
