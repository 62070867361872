import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";
import CustomPhone from "components/CustomPhone/CustomPhone.js";
import CustomImage from "./CustomImage";
import CustomCircle from "components/CustomCircle/CustomCircle.js";

import { hideModal, showModal } from "config/util";

export default function Contents({ Account }) {
  const {
    propsAccount,
    set_propsAccount,
    dispatch_post_accounts_images,
    dispatch_type,
    branch,
  } = Account;

  const method = branch.method;

  const props = propsAccount;
  const set_props = set_propsAccount;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts.user);
  const user_icon_uri = reduce_get_accounts.user_icon_uri;
  const user_icon_mime = reduce_get_accounts.user_icon_mime;

  const loading = useSelector((state) => state.reduce_post_accounts_images.isLoading);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const maps = useSelector((state) => state.reduce_get_maps);
  const maps_languages = { ja: "日本語", en: "English" };
  return (
    <>
      <div>
        <Header />
        <div className="container container-main">
          <main className="main">
            <div action="created-collection-detail.html" entype="multpart/form-data">
              <div className="main-header pt-3 pb-2">
                <h2 className="h4 m-0 text-truncate">{t("プロフィール情報の変更")}</h2>
                <div className="btn-group">
                  <a
                    className="btn btn-outline-secondary text-dark rounded-pill px-3 me-2 text-nowrap"
                    href="javascript:history.back()"
                  >
                    <i className="fa-solid fa-xmark" />
                    <span className="d-none d-sm-inline-block"> {t("キャンセル")}</span>
                  </a>
                  <button
                    type="button"
                    name="confirm"
                    className="btn btn-primary rounded-pill px-3 text-nowrap"
                    role="button"
                    // data-bs-toggle="modal"
                    // data-bs-target="#processDialog"
                    onClick={(e) => {
                      e.preventDefault();
                      // const data = JSON.stringify({
                      //   link_twitter: props.link_twitter,
                      //   link_facebook: props.link_facebook,
                      //   link_instagram: props.link_instagram,
                      //   link_discord: props.link_discord,
                      //   // link_other: props.link_other,
                      // });
                      const data = {
                        link_twitter: props.link_twitter,
                        link_facebook: props.link_facebook,
                        link_instagram: props.link_instagram,
                        link_discord: props.link_discord,
                        // link_other: props.link_other,
                      };
                      
                      dispatch_post_accounts_images(
                        {
                          image: props.image,
                          type: 2,
                          display_name: props.display_name,
                          message: props.message,
                          message2: props.message2,
                          language: props.language,
                          data,
                        },
                        () => {
                          dispatch_type();
                        },
                        () => {}
                      );
                    }}
                  >
                    <CustomCircle title={t("変更")} loading={loading} size={16} />
                  </button>
                </div>
              </div>
              <div className="row g-3 p-xl-3">
                <div className="col-md-12 col-xl-12">
                  <div className="row row-cols-1 row-cols-xl-3 g-3">
                    <div className="col">
                      <h3 className="h5">{t("プロフィールアイコン")} </h3>
                      <div className="mb-4">
                        <div className="form-floating mb-2">
                          <CustomImage
                            id="collectionLogo"
                            title={t("プロフィールアイコン")}
                            image_path={props.user_icon_uri}
                            image={props.user_icon}
                            mime_type={props.user_icon_mime}
                            set_image_path={(base64, file) => {
                              if (
                                ![
                                  "image/jpeg",
                                  "image/png",
                                  "image/svg+xml",
                                  "image/gif",
                                  "image/webp",
                                  // "video/mp4",
                                ].includes(file.type)
                              ) {
                                alert("対応するメディアではありません");
                                return;
                              }

                              set_props({
                                ...props,
                                user_icon: file,
                                image: file,
                                user_icon_mime: file.type,
                                user_icon_uri: base64,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <h3 className="h5"> {t("プロフィール情報")}</h3>
                      <div className="mb-4">
                        <div className="form-floating mb-2">
                          <input
                            type="text"
                            className="form-control"
                            id="collectionId"
                            placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3ID"
                            inputMode="email"
                            maxLength={30}
                            onChange={(e) => {
                              const val = e.target.value;
                              set_props({
                                ...props,
                                display_name: val,
                              });
                            }}
                            value={props.display_name}
                          />
                          <label htmlFor="collectionId"> {t("ニックネーム")}</label>
                        </div>
                        <div className="form-floating mb-2">
                          <textarea
                            type="text"
                            className="form-control"
                            id="collectionDisc"
                            placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3\u306E\u8AAC\u660E"
                            maxLength={300}
                            style={{
                              height: "200px",
                            }}
                            onChange={(e) => {
                              const val = e.target.value;
                              set_props({
                                ...props,
                                message: val,
                              });
                            }}
                            value={props.message}
                          />
                          <label htmlFor="collectionDisc"> {t("メッセージ")}</label>
                        </div>
                        <div className="form-floating mb-2">
                          <textarea
                            type="text"
                            className="form-control"
                            id="collectionDisc2"
                            placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3\u306E\u8AAC\u660E"
                            style={{
                              height: "200px",
                            }}
                            maxLength={120}
                            onChange={(e) => {
                              const val = e.target.value;
                              set_props({
                                ...props,
                                message2: val,
                              });
                            }}
                            value={props.message2}
                          />
                          <label htmlFor="collectionDisc2"> {t("お知らせ")}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col mb-4">
                      <h3 className="h5">{t("ソーシャルリンク")}</h3>
                      <div className="form-floating mb-2">
                        <input
                          type="url"
                          className="form-control"
                          id="collectionLinkTwitter"
                          placeholder="Twitter"
                          onChange={(e) => {
                            const val = e.target.value;
                            set_props({
                              ...props,
                              link_twitter: val,
                            });
                          }}
                          value={props.link_twitter}
                        />
                        <label htmlFor="collectionLinkTwitter">{t("Twitter")}</label>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="url"
                          className="form-control"
                          id="collectionLinkFacebbok"
                          placeholder="Facebook"
                          onChange={(e) => {
                            const val = e.target.value;
                            set_props({
                              ...props,
                              link_facebook: val,
                            });
                          }}
                          value={props.link_facebook}
                        />
                        <label htmlFor="collectionLinkFacebbok">{t("Facebook")}</label>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="url"
                          className="form-control"
                          id="collectionLinkInstagram"
                          placeholder="Instagram"
                          onChange={(e) => {
                            const val = e.target.value;
                            set_props({
                              ...props,
                              link_instagram: val,
                            });
                          }}
                          value={props.link_instagram}
                        />
                        <label htmlFor="collectionLinkFacebbok">{t("Instagram")}</label>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="url"
                          className="form-control"
                          id="collectionLinkDiscord"
                          placeholder="Discord"
                          onChange={(e) => {
                            const val = e.target.value;
                            set_props({
                              ...props,
                              link_discord: val,
                            });
                          }}
                          value={props.link_discord}
                        />
                        <label htmlFor="collectionLinkDiscord">{t("Discord")}</label>
                      </div>
                      <h3 className="h5">{t("言語設定")}</h3>
                      <div className="mb-4">
                        <div className="form-floating mb-2">
                          <select
                            onChange={(e) => {
                              const val = e.target.value;
                              set_props({
                                ...props,
                                language: val,
                              });
                            }}
                            className="form-select"
                            id="collectionCategory"
                            aria-label="\u30AB\u30C6\u30B4\u30EA"
                            value={props.language}
                          >
                            {Object.keys(maps_languages).map((k, idx) => {
                              return <option value={k}>{t(maps_languages[k])}</option>;
                            })}
                          </select>
                          <label htmlFor="collectionCategory">{t("言語")}</label>
                        </div>
                      </div>
                      {/* <div className="form-floating mb-2">
                        <input
                          type="url"
                          className="form-control"
                          id="collectionLinkInstagram"
                          placeholder="Instagram"
                          onChange={(e) => {
                            const val = e.target.value;
                            set_props({
                              ...props,
                              link_instagram: val,
                            });
                          }}
                          value={props.link_instagram}
                        />
                        <label htmlFor="collectionLinkInstagram">{t("Instagram")}</label>
                      </div> */}
                      {/* <div className="form-floating mb-2">
                        <input
                          type="url"
                          className="form-control"
                          id="collectionLinkWeb"
                          placeholder="\u305D\u306E\u4ED6URL"
                          onChange={(e) => {
                            const val = e.target.value;
                            set_props({
                              ...props,
                              link_other: val,
                            });
                          }}
                          value={props.link_other}
                        />
                        <label htmlFor="collectionLinkWeb">{t("その他URL")}</label>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
