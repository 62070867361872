import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";
import CustomPhone from "components/CustomPhone/CustomPhone.js";
import CustomCircle from "components/CustomCircle/CustomCircle.js";

import { hideModal, showModal } from "config/util";

export default function Contents({ Account }) {
  const {
    propsAccount,
    set_propsAccount,
    dispatch_get_accounts_totp,
    dispatch_type,
    branch,
  } = Account;
  const method = branch.method;

  const props = propsAccount;
  const set_props = set_propsAccount;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts.user);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const mail_address_current = reduce_get_accounts.mail_address;
  const phone_number_current = reduce_get_accounts.phone_number;
  const [image, set_image] = React.useState("");

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;
  const loading = useSelector((state) => state.reduce_get_accounts_totp.isLoading);

  const maps = useSelector((state) => state.reduce_get_maps);

  return (
    <>
      <div>
        <Header />
        <div className="container container-main">
          <main className="main">
            <div action="created-collection-detail.html" entype="multpart/form-data">
              <div className="main-header pt-3 pb-2">
                <h2 className="h4 m-0 text-truncate">{t("AuthenticatorのQRコード発行")}</h2>
                <div className="btn-group">
                  <a
                    className="btn btn-outline-secondary text-dark rounded-pill px-3 me-2 text-nowrap"
                    href="javascript:history.back()"
                  >
                    <i className="fa-solid fa-xmark" />
                    <span className="d-none d-sm-inline-block">{" "}{t("キャンセル")}</span>
                  </a>
                  <button
                    type="button"
                    name="confirm"
                    className="btn btn-primary rounded-pill px-3 text-nowrap"
                    role="button"
                    // data-bs-toggle="modal"
                    // data-bs-target="#processDialog"
                    onClick={(e) => {
                      e.preventDefault();

                      dispatch_get_accounts_totp({}, (data) => {
                        dispatch_type();
                        set_image(data.qrcode);
                      });
                    }}
                  >
                    <CustomCircle title={t("発行")} loading={loading} size={16} />
                  </button>
                </div>
              </div>

              <div className="row g-3 p-xl-3">
                <div className="col-md-6 col-xl-8">
                  <h3 className="h5">
                    {t("QRコード発行を行うと、以前に発行したものは使用できなくなります。")}
                  </h3>
                  {/* <div className="row row-cols-1 row-cols-xl-2 g-3">
                    <div className="col">
                      <div className="media mb-2 bg-light p-2">
                        <div className="media-body overflow-hidden">
                          <span className="d-block">{mail_address_current}</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="row row-cols-1 row-cols-xl-2 g-3">
                    <div className="col">
                      <div className="mb-4">
                        <div className="form-floating mb-2">
                          <img style={{ width: 200 }} src={image} onClick={() => {}} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
