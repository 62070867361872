import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import Footer from "../../views_components/Component/Footer";
import { getMessage } from "config/util";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import { view_collection } from "../../stores/Collections/view";

import CollectionsList from "../../views_components/ArcadeCollections/CollectionsList";

import TopCollectionsList from "../../views_components/TopCollections/TopCollectionsList";
import TopCollectionsPager from "../../views_components/TopCollections/TopCollectionsPager";

import TopSellersList from "../../views_components/TopSellers/TopSellersList";
import TopSellersPager from "../../views_components/TopSellers/TopSellersPager";

import TopBuyersList from "../../views_components/TopBuyers/TopBuyersList";
import TopBuyersPager from "../../views_components/TopBuyers/TopBuyersPager";

import TopCollabosList from "../../views_components/TopCollabos/TopCollabosList";
import TopCollabosPager from "../../views_components/TopCollabos/TopCollabosPager";




import HotCollectionsList from "../../views_components/HotCollections/HotCollectionsList";
import HotItemsList from "../../views_components/HotItems/HotItemsList";

export default function Contents({
  Home,
  Collections,
  TopCollections,
  TopItems,
  TopSellers,
  TopBuyers,
  TopCollabos,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const [height, set_height] = useState();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  useEffect(() => {
    set_height(window.jQuery("#header1").height());
  }, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  return (
    <>
      <div>
        <Header />
        <div className="container-main">
          <main className="main mt-2 mt-sm-0 pb-0">
            {CollectionsList(Collections)}
            <section className="bg-white">
              <div className="container py-5">
                <div className="d-flex justify-content-center align-items-center mb-4 mb-sm-5">
                  <h3 className="mb-0">{t("トップコレクション")}</h3>
                  {TopCollectionsPager(TopCollections)}
                </div>
                <div className="mx-lg-5 px-lg-5 mb-3">
                  {TopCollectionsList(TopCollections)}
                  <div className="text-center">
                    <a
                      className="btn btn-outline-primary btn-lg rounded-pill px-4"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/rankings`);
                      }}
                    >
                      {t("ランキングを見る")}
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="container py-5">
                <h3 className="mb-4 mb-sm-5 text-center">{t("注目のアイテム")}</h3>
                {HotItemsList(TopItems)}
              </div>
            </section>
            <section className="bg-white">
              <div className="container py-5">
                <div className="d-flex justify-content-center align-items-center mb-4 mb-sm-5">
                  <div className="d-flex align-items-center">
                    <h3 className="mb-0">{t("トップセラー")}</h3>
                    {/* <div className="dropdown">
                      <button
                        className="btn btn-link btn-lg dropdown-toggle ps-1"
                        type="button"
                        id="topSeller"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="h3">セラー</span>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="topSeller">
                        <li>
                          <button className="dropdown-item active" type="button">
                            セラー
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item" type="button">
                            バイヤー
                          </button>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  {TopSellersPager(TopSellers)}
                </div>
                <div className="mx-lg-5 px-lg-5 mb-3">
                  {TopSellersList(TopSellers)}
                  {/* <div className="text-center">
                    <a
                      className="btn btn-outline-primary btn-lg rounded-pill px-4"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/rankings`);
                      }}
                    >
                      {t("ランキングを見る")}
                    </a>
                  </div> */}
                </div>
              </div>
            </section>
            <section className="bg-white">
              <div className="container py-5">
                <div className="d-flex justify-content-center align-items-center mb-4 mb-sm-5">
                  <div className="d-flex align-items-center">
                    <h3 className="mb-0">{t("トップバイヤー")}</h3>
                    {/* <div className="dropdown">
                      <button
                        className="btn btn-link btn-lg dropdown-toggle ps-1"
                        type="button"
                        id="topSeller"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="h3">セラー</span>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="topSeller">
                        <li>
                          <button className="dropdown-item active" type="button">
                            セラー
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item" type="button">
                            バイヤー
                          </button>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  {TopBuyersPager(TopBuyers)}
                </div>
                <div className="mx-lg-5 px-lg-5 mb-3">
                  {TopBuyersList(TopBuyers)}
                  {/* <div className="text-center">
                    <a
                      className="btn btn-outline-primary btn-lg rounded-pill px-4"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/rankings`);
                      }}
                    >
                      {t("ランキングを見る")}
                    </a>
                  </div> */}
                </div>
              </div>
            </section>
            <section className="bg-white">
              <div className="container py-5">
                <div className="d-flex justify-content-center align-items-center mb-4 mb-sm-5">
                  <div className="d-flex align-items-center">
                    <h3 className="mb-0">{t("トップコラボ")}</h3>
                  </div>
                  {TopCollabosPager(TopCollabos)}
                </div>
                <div className="mx-lg-5 px-lg-5 mb-3">{TopCollabosList(TopCollabos)}</div>
              </div>
            </section>
            <section>
              <div className="container py-5">
                <h3 className="mb-4 mb-sm-5 text-center">{t("注目のコレクション")}</h3>
                {HotCollectionsList(TopCollections)}
              </div>
            </section>
            <section className="bg-white pb-5">
              <div className="container py-5">
                <h3 className="mb-4 mb-sm-5 text-center">{t("カテゴリ")}</h3>
                <div className="mx-lg-5 px-lg-5 mb-5">
                  <div className="row row-cols-3 row-cols-sm-4 row-cols-md-5 g-3 mb-4">
                    <div className="col">
                      <div className="card card-body d-flex py-3 position-relative">
                        <a
                          className="m-auto text-center stretched-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              `/categories/id/${"c801984a-f926-4ffc-a8c1-c48bc4307d0a"}`
                            );
                          }}
                        >
                          <i className="mt-2 mb-3 text-brand-gradient fa-fw fa-2x fa-solid fa-brush" />
                          <span className="d-block">{t("アート")}</span>
                        </a>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card card-body d-flex py-3 position-relative">
                        <a
                          className="m-auto text-center stretched-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              `/categories/id/${"1fc59b04-1a89-4644-a73a-7e82fcf8efbc"}`
                            );
                          }}
                        >
                          <i className="mt-2 mb-3 text-brand-gradient fa-fw fa-2x fa-solid fa-lightbulb" />
                          <span className="d-block">{t("コレクター")}</span>
                        </a>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card card-body d-flex py-3 position-relative">
                        <a
                          className="m-auto text-center stretched-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              `/categories/id/${"f31f2447-8800-4ff2-a36d-d82b475bba59"}`
                            );
                          }}
                        >
                          <i className="mt-2 mb-3 text-brand-gradient fa-fw fa-2x fa-solid fa-at" />
                          <span className="d-block">{t("ドメイン")}</span>
                        </a>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card card-body d-flex py-3 position-relative">
                        <a
                          className="m-auto text-center stretched-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              `/categories/id/${"853c87ab-076a-417d-b2e0-a3a4d8c13492"}`
                            );
                          }}
                        >
                          <i className="mt-2 mb-3 text-brand-gradient fa-fw fa-2x fa-brands fa-itunes-note" />
                          <span className="d-block">{t("音楽")}</span>
                        </a>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card card-body d-flex py-3 position-relative">
                        <a
                          className="m-auto text-center stretched-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              `/categories/id/${"6fc94309-7a9a-41a0-b520-cfc72dde88fc"}`
                            );
                          }}
                        >
                          <i className="mt-2 mb-3 text-brand-gradient fa-fw fa-2x fa-solid fa-camera-retro" />
                          <span className="d-block">{t("写真")}</span>
                        </a>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card card-body d-flex py-3 position-relative">
                        <a
                          className="m-auto text-center stretched-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              `/categories/id/${"9bd3e56a-6321-4a41-8801-51780bc8866b"}`
                            );
                          }}
                        >
                          <i className="mt-2 mb-3 text-brand-gradient fa-fw fa-2x fa-solid fa-baseball-bat-ball" />
                          <span className="d-block">{t("スポーツ")}</span>
                        </a>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card card-body d-flex py-3 position-relative">
                        <a
                          className="m-auto text-center stretched-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              `/categories/id/${"a0934b49-6337-41ae-ad89-21e1356154cf"}`
                            );
                          }}
                        >
                          <i className="mt-2 mb-3 text-brand-gradient fa-fw fa-2x fa-solid fa-gamepad" />
                          <span className="d-block">
                            {t("トレカ")}
                            {/* トレ
                            <span className="d-none d-sm-inline">ーディング</span>カ
                            <span className="d-none d-sm-inline">ード</span> */}
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card card-body d-flex py-3 position-relative">
                        <a
                          className="m-auto text-center stretched-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              `/categories/id/${"c49aded5-4f2c-4140-9056-a926774600ee"}`
                            );
                          }}
                        >
                          <i className="mt-2 mb-3 text-brand-gradient fa-fw fa-2x fa-solid fa-box-open" />
                          <span className="d-block">{t("ユーティリティ")}</span>
                        </a>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card card-body d-flex py-3 position-relative">
                        <a
                          className="m-auto text-center stretched-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              `/categories/id/${"6b99b864-f386-4b67-8130-3a82fb0bbe47"}`
                            );
                          }}
                        >
                          <i className="mt-2 mb-3 text-brand-gradient fa-fw fa-2x fa-solid fa-vr-cardboard" />
                          <span className="d-block">{t("メタバース")}</span>
                        </a>
                      </div>
                    </div>
                    <div className="col d-none d-sm-block">
                      <div className="card card-body d-flex py-3 position-relative">
                        <a
                          className="m-auto text-center stretched-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/categories`);
                          }}
                        >
                          <i className="mt-2 mb-3 text-brand-gradient fa-fw fa-2x fa-solid fa-book-open-reader" />
                          <span className="d-block">{t("すべてのカテゴリ")}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="text-center d-sm-none">
                    <a
                      className="btn btn-outline-primary btn-lg rounded-pill px-4"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/categories`);
                      }}
                    >
                      {t("すべてのカテゴリ")}
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <Footer />
      </div>
    </>
  );
}
