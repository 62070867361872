import {
  fork,
  take,
  put,
  join,
  select,
  takeLatest,
  call,
} from "redux-saga/effects";

import { post_authout } from "../Auth/actions";

import {
  api_get_accounts_cards,
  api_get_accounts_cards_id,
  api_post_accounts_cards,
  api_put_accounts_cards_id,
  api_delete_accounts_cards_id,
  api_post_cards_id_buy,
  api_post_cards_id_pay,
  api_put_cards_id_favorite,
} from "./api";

import {
  get_accounts_cards,
  get_accounts_cards_data,
  get_accounts_cards_error,
  get_accounts_cards_id,
  get_accounts_cards_id_data,
  get_accounts_cards_id_error,
  post_accounts_cards,
  post_accounts_cards_data,
  post_accounts_cards_error,
  put_accounts_cards_id,
  put_accounts_cards_id_data,
  put_accounts_cards_id_error,
  delete_accounts_cards_id,
  delete_accounts_cards_id_data,
  delete_accounts_cards_id_error,
  post_cards_id_buy,
  post_cards_id_buy_data,
  post_cards_id_buy_error,
  post_cards_id_pay,
  post_cards_id_pay_data,
  post_cards_id_pay_error,
  put_cards_id_favorite,
  put_cards_id_favorite_data,
  put_cards_id_favorite_error,
} from "./actions";

import { backward } from "../../Navigator/Router_material";

export function* run_get_accounts_cards({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  if (!token) {
    yield put(get_accounts_cards_error({}));
    backward(token);
  }

  const { data, error } = yield call(api_get_accounts_cards, payload.data, token);
  if (!error) {
    yield put(get_accounts_cards_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_accounts_cards_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_accounts_cards_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(
    api_get_accounts_cards_id,
    payload.data,
    token
  );
  if (!error) {
    yield put(get_accounts_cards_id_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_accounts_cards_id_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_accounts_cards({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_accounts_cards, payload.data, token);
  if (!error) {
    yield put(post_accounts_cards_data({ data }));
    // yield put(get_accounts_cards(payload));
  } else {
    yield put(post_accounts_cards_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_accounts_cards_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(
    api_put_accounts_cards_id,
    payload.data,
    token
  );
  if (!error) {
    yield put(put_accounts_cards_id_data({ data }));
    yield put(get_accounts_cards_id(payload));
  } else {
    yield put(put_accounts_cards_id_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_accounts_cards_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(
    api_delete_accounts_cards_id,
    payload.data,
    token
  );
  if (!error) {
    yield put(delete_accounts_cards_id_data({ data }));
    yield put(get_accounts_cards(payload));
  } else {
    yield put(delete_accounts_cards_id_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_cards_id_buy({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_cards_id_buy, payload.data, token);
  if (!error) {
    yield put(post_cards_id_buy_data({ data }));
    yield put(get_accounts_cards_id(payload));
    !payload.next || payload.next(data);
  } else {
    yield put(post_cards_id_buy_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}


export function* run_post_cards_id_pay({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_cards_id_pay, payload.data, token);
  if (!error) {
    yield put(post_cards_id_pay_data({ data }));
    yield put(get_accounts_cards_id(payload));
    !payload.next || payload.next(data);
  } else {
    yield put(post_cards_id_buy_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_cards_id_favorite({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(
    api_put_cards_id_favorite,
    payload.data,
    token
  );
  if (!error) {
    yield put(put_cards_id_favorite_data({ data }));
    yield put(get_accounts_cards_id(payload));
    // yield put(get_accounts_cards(payload));
    !payload.next || payload.next(data);
  } else {
    yield put(put_cards_id_favorite_error({ error }));
    if (error.response && error.response.status === 401)
      yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* flow_get_accounts_cards() {
  yield takeLatest(get_accounts_cards, run_get_accounts_cards);
}
export function* flow_get_accounts_cards_id() {
  yield takeLatest(get_accounts_cards_id, run_get_accounts_cards_id);
}
export function* flow_post_accounts_cards() {
  yield takeLatest(post_accounts_cards, run_post_accounts_cards);
}
export function* flow_put_accounts_cards_id() {
  yield takeLatest(put_accounts_cards_id, run_put_accounts_cards_id);
}
export function* flow_delete_accounts_cards_id() {
  yield takeLatest(delete_accounts_cards_id, run_delete_accounts_cards_id);
}
export function* flow_post_cards_id_buy() {
  yield takeLatest(post_cards_id_buy, run_post_cards_id_buy);
}


export function* flow_post_cards_id_pay() {
  yield takeLatest(post_cards_id_pay, run_post_cards_id_pay);
}

export function* flow_put_cards_id_favorite() {
  yield takeLatest(put_cards_id_favorite, run_put_cards_id_favorite);
}

export default function* rootSaga() {
  yield fork(flow_get_accounts_cards);
  yield fork(flow_get_accounts_cards_id);
  yield fork(flow_post_accounts_cards);
  yield fork(flow_put_accounts_cards_id);
  yield fork(flow_delete_accounts_cards_id);
  yield fork(flow_post_cards_id_buy);
  yield fork(flow_post_cards_id_pay);
  yield fork(flow_put_cards_id_favorite);
}
