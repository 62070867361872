import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import { view_collection } from "../../stores/Collections/view";
import { view_user } from "../../stores/Users/view";
import { put_users_id } from "stores/Users/actions";
import { put_users_my_id } from "stores/User/actions";

import { getMessage } from "config/util";
import _more_options from "./_more_options";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { hideModal, showModal } from "config/util";
import _teamWaiting from "../../views_page/User/_teamWaiting";

export default function Contents(Users) {
  const { propsUsers, set_propsUsers, dispatch_gets, user_id } = Users;

  const [isShort, set_isShort] = React.useState(true);

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_users_my = useSelector((state) => state.reduce_get_users_my);
  // const loading_put_users_my_id = useSelector((state) => state.reduce_put_users_my_id.isLoading);
  // const v_collection = view_collection(collection);

  const loading_get_users = useSelector((state) => state.reduce_get_users.isLoadingId);
  const reduce_get_users = useSelector((state) => state.reduce_get_users);

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if (!message) return;
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_put_users_my_id = (data, next, prev) => {
    dispatch(
      put_users_my_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const user_self = reduce_get_accounts.user;
  const v_self = view_user(user_self);
  // const collection = useSelector((state) => state.reduce_get_items.collection);
  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection);
  // const members = v_collection.collection_members;

  // const user_self = reduce_get_users.user;
  // const v_self = view_user(user_self);

  const self = reduce_get_users_my.user;
  const v_user = view_user(self);

  const link_twitter = v_user.link_twitter;
  const link_facebook = v_user.link_facebook;
  const link_instagram = v_user.link_instagram;
  const link_discord = v_user.link_discord;

  const collection_user_active = v_user.collection_user_active ? v_user.collection_user_active : [];
  const collection_user_inactive = v_user.collection_user_inactive
    ? v_user.collection_user_inactive
    : [];

  const collection_owner_user = v_user.collection_owner_user ? v_user.collection_owner_user : {};
  const collection_members = v_user.collection_members ? v_user.collection_members : [];

  return (
    <>
      <div className="sidebar shadow-end">
        <div className="sidebar-sticky">
          <div className="me-md-3">
            <div className="position-relative">
              <div className="d-flex justify-content-between align-items-start my-3">
                {v_user.collabo_flg ? (
                  <div className="media-object">
                    <div className="mask-hex">
                      <figure
                        className="object-item ratio ratio-1x1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title=""
                      >
                        <img
                          src={v_collection.collection_user_icon_uri}
                          className="icon-item"
                          alt=""
                        />
                      </figure>
                    </div>
                  </div>
                ) : (
                  <div className="media-object">
                    {/* <div className="verified"> */}
                    <div className="icon icon-pill">
                      <img
                        src={v_user?.user_icon_uri}
                        className="icon-item"
                        width={70}
                        height={70}
                        alt=""
                      />
                    </div>
                    {/* </div> */}
                  </div>
                )}
                <div
                  className="btn-group flex-column"
                  style={{
                    zIndex: 2,
                  }}
                >
                  {/* {_more_options(Items)} */}

                  {v_user.myself && (
                    <>
                      <a
                        className="btn btn-outline-dark rounded-pill text-nowrap px-3"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push({
                            pathname: `/account-image`,
                          });
                        }}
                      >
                        {t("プロフィール編集")}
                      </a>
                      <a
                        className="btn btn-link rounded-pill text-nowrap px-3"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/collections/create`);
                        }}
                      >
                        <i className="fa-solid fa-plus me-2" />
                        {t("コレクション作成")}
                      </a>
                      {/* 本番ガード */}
                      <a
                        className="btn btn-link rounded-pill text-nowrap px-3"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/collaborate/create`);
                        }}
                      >
                        <i className="fa-solid fa-plus me-2" />
                        {t("コラボコレクション作成")}
                      </a>
                    </>
                  )}
                  {!v_user.myself && !v_user.collabo_flg && (
                    <button
                      type="button"
                      className={`btn btn-outline-dark rounded-pill ${v_user.user_favorited_my &&
                        "active"}`}
                      data-bs-toggle="button"
                      autoComplete="off"
                      aria-pressed={v_user.user_favorited_my ? "true" : "false"}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch_put_users_my_id(
                          {
                            id: v_user.user_id,
                          },
                          () => {},
                          () => {}
                        );
                      }}
                    >
                      {v_user.user_favorited_my ? t("フォロー中") : t("フォロー")}
                    </button>
                  )}
                </div>
              </div>
              <div className="text-black d-block mb-2">
                <h2 className="product-title mb-1">
                  <a
                    className="stretched-link text-dark"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`/users/${v_user.user_id}`);
                    }}
                  >
                    {/* <span className="verified-text">{v_user.user_name}</span> */}
                    {v_user.user_name}
                  </a>
                </h2>
                {/* <span className="d-block">@{v_user.user_nmid}</span> */}
              </div>
            </div>
            <div
              className="list-group-item border-start-none border-end-none ps-0"
              style={{ border: "none" }}
            >
              <CopyToClipboard
                text={v_user.user_nmid}
                onCopy={() => {
                  alert(t("ユーザーIDをコピーしました。"));
                }}
              >
                <span data-bs-toggle="tooltip" data-bs-placement="top" title="">
                  <i className="fa-solid fa-clipboard-check fa-fw me-2" title="" />
                  {v_user.user_nmid}
                </span>
              </CopyToClipboard>
            </div>
            <p>{isShort ? v_user.user_message_short_br : v_user.user_message_br}</p>
            <span
              style={{ fontWeight: 600, textDecoration: "underline" }}
              onClick={() => {
                set_isShort(false);
              }}
            >
              {isShort ? "もっと見る＞" : ""}
            </span>
            {/* font-weight: 600; */}
            {/* text-decoration: underline; */}
            {/* <div className="closet mt-2">
              <input id="pText" type="checkbox" />
              <label for="pText"></label>
              <div className="closet-body">
                <p className="m-0"></p>
              </div>
            </div> */}
            {v_user.user_message2 && (
              <div className="alert alert-info alert-dismissible fade show mt-3" role="alert">
                <small className="d-block fw-bold">{t("お知らせ")}</small>
                <small>{v_user.user_message2_br}</small>
              </div>
            )}
          </div>
          <div className="list-group list-group-flush">
            <aside className="list-group-item ps-0 d-flex" role="link">
              {link_twitter && (
                <a
                  className="btn btn-link btn-sm rounded-pill me-2"
                  href
                  target
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${link_twitter}`, "_blank", "noreferrer");
                  }}
                >
                  <i className="fab fa-twitter fa-fw" />
                </a>
              )}
              {link_facebook && (
                <a
                  className="btn btn-link btn-sm rounded-pill me-2"
                  href
                  target
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${link_facebook}`, "_blank", "noreferrer");
                  }}
                >
                  <i className="fab fa-facebook-f fa-fw" />
                </a>
              )}
              {link_instagram && (
                <a
                  className="btn btn-link btn-sm rounded-pill me-2"
                  href
                  target
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${link_instagram}`, "_blank", "noreferrer");
                  }}
                >
                  <i className="fab fa-instagram fa-fw" />
                </a>
              )}
              {link_discord && (
                <a
                  className="btn btn-link btn-sm rounded-pill me-2"
                  href="#"
                  target
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${link_discord}`, "_blank", "noreferrer");
                  }}
                >
                  <i className="fab fa-discord fa-fw" />
                </a>
              )}
            </aside>
            <div className="list-group-item ps-0 d-flex flex-nowrap">
              {!v_user.collabo_flg && (
                <>
                  <span className="mb-0 me-3">
                    <a
                      href="javascript:void(0);"
                      className="text-black"
                      role="button"
                      data-bs-toggle="modal"
                      data-bs-target="#userFollow"
                      onClick={() => {
                        dispatch_gets({
                          ...propsUsers.search,
                          user_id,
                          followings: 1,
                          followers: 0,
                        });
                      }}
                    >
                      <span className="fw-bold">{v_user.user_favoriting_count}</span>
                      <span className="ms-1 text-gray">{t("フォロー")}</span>
                    </a>
                  </span>
                  <span className="mb-0">
                    <a
                      href="javascript:void(0);"
                      className="text-black"
                      role="button"
                      data-bs-toggle="modal"
                      data-bs-target="#userFollow"
                      onClick={() => {
                        dispatch_gets({
                          ...propsUsers.search,
                          user_id,
                          followings: 0,
                          followers: 1,
                        });
                      }}
                    >
                      <span className="fw-bold">{v_user.user_favorited_count}</span>
                      <span className="ms-1 text-gray">{t("フォロワー")}</span>
                    </a>
                  </span>
                </>
              )}
            </div>
          </div>

          {v_user.collabo_flg ? (
            <>
              <div className="ps-0 pt-2 pb-0 pe-0 pe-md-3">
                <div className="d-flex align-items-center mb-2">
                  <span className="fw-bold">コラボメンバー</span>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn btn-link rounded-pill btn-sm pe-0"
                      role="button"
                      data-bs-toggle="modal"
                      data-bs-target="#allteams"
                    >
                      メンバーの詳細
                    </button>
                  </div>
                </div>
                <div className="d-flex flex-wrap gap-1">
                  <figure
                    className="icon icon-sm icon-pill m-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/users/${collection_owner_user.user_id}/items`);
                      }}
                    >
                      <img
                        src={collection_owner_user.user_icon_uri}
                        className="icon-item"
                        width="100%"
                        height="auto"
                        alt=""
                      />
                    </a>
                  </figure>

                  {collection_members.map((m) => {
                    return (
                      <figure
                        className="icon icon-sm icon-pill m-0"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title=""
                      >
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/users/${m.user_id}/items`);
                          }}
                        >
                          <img
                            src={m.user_icon_uri}
                            className="icon-item"
                            width="100%"
                            height="auto"
                            alt=""
                          />
                        </a>
                      </figure>
                    );
                  })}

                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="\u3059\u3079\u3066\u306E\u30E1\u30F3\u30D0\u30FC\u3068\u5831\u916C\u306E\u5206\u914D"
                  >
                    <button
                      type="button"
                      className="btn btn-link-white rounded-pill"
                      role="button"
                      data-bs-toggle="modal"
                      data-bs-target="#allteams"
                    >
                      ...
                    </button>
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="ps-0 pt-2 pe-0 pe-md-3">
                <div className="d-flex align-items-center mb-2">
                  <span className="d-block fw-bold">所属コラボ</span>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn btn-link rounded-pill btn-sm pe-0"
                      role="button"
                      data-bs-toggle="modal"
                      data-bs-target="#teams"
                    >
                      すべての参加コラボ
                    </button>
                  </div>
                </div>

                <div className="hstack gap-1">
                  {collection_user_active.map((c) => {
                    return (
                      <div className="mask-hex">
                        <figure
                          className="object-item ratio ratio-1x1"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={c.collection_name}
                        >
                          <a
                            href="#"
                            onClick={(e) => {
                              history.push(`/collections/${c.collection_id}/items`);
                            }}
                          >
                            <img
                              src={c.collection_icon_uri}
                              className="icon-item"
                              alt={c.collection_name}
                            />
                          </a>
                        </figure>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="ps-0 pt-2 pe-0 pe-md-3">
                <div className="d-flex align-items-center mb-2">
                  <span className="d-block fw-bold">待機中のコラボ</span>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn btn-link rounded-pill btn-sm pe-0"
                      role="button"
                      data-bs-toggle="modal"
                      data-bs-target="#teamsWaiting2"
                    >
                      すべての待機中コラボ
                    </button>
                  </div>
                </div>
                <div className="hstack gap-1">
                  {collection_user_inactive.map((c) => {
                    return (
                      <div className="mask-hex">
                        <figure
                          className="object-item ratio ratio-1x1"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={c.collection_name}
                        >
                          <a
                            href="#"
                            onClick={(e) => {
                              history.push(`/collections/${c.collection_id}/items`);
                            }}
                          >
                            <img
                              src={c.collection_icon_uri}
                              className="icon-item"
                              alt={c.collection_name}
                            />
                          </a>
                        </figure>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}

          {/* <div className="accordion mb-3" id="newAccordion">
            <div className="accordion-item border-start-0 border-end-0">
              <div className="accordion-header" id="trophys-heading">
                <button
                  type="button"
                  className="accordion-button ps-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#trophys"
                  aria-expanded="true"
                  aria-controls="trophys"
                  style={{
                    paddingTop: ".9rem",
                    paddingBottom: ".9rem",
                  }}
                >
                  <i className="fa-solid fa-trophy fa-fw me-2" />
                  トロフィー  
                </button>
              </div>
              <div
                id="trophys"
                className="accordion-collapse collapse show"
                aria-labelledby="trophys-heading"
              >
                <div className="accordion-body px-0">
                  <div className="d-flex flex-wrap gap-2">
                    <small className="badge bg-light text-muted">
                      <i
                        className="fa-solid fa-trophy fa-fw me-1"
                        style={{
                          color: "#d4ca83",
                        }}
                      />
                      100万のフォロワー
                    </small>
                    <small className="badge bg-light text-muted">
                      <i
                        className="fa-solid fa-trophy fa-fw me-1"
                        style={{
                          color: "#d4ca83",
                        }}
                      />
                      トップセラー
                    </small>
                    <small className="badge bg-light text-muted">
                      <i
                        className="fa-solid fa-trophy fa-fw me-1"
                        style={{
                          color: "#dec7c3",
                        }}
                      />
                      1Kアイテム達成
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item border-start-0 border-end-0">
              <div className="accordion-header" id="roles-heading">
                <button
                  type="button"
                  className="accordion-button ps-0 collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#roles"
                  aria-expanded="false"
                  aria-controls="roles"
                  style={{
                    paddingTop: ".9rem",
                    paddingBottom: ".9rem",
                  }}
                >
                  <i className="fa-solid fa-award me-2" />
                  ロール  
                </button>
              </div>
              <div
                id="roles"
                className="accordion-collapse collapse"
                aria-labelledby="roles-heading"
              >
                <div className="accordion-body px-0">–</div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div
        className="modal fade"
        id="teams"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="userTeamsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
          <div className="modal-content h-100">
            <div className="modal-header">
              <h5 className="modal-title text-truncate" id="userTeamsLabel">
                所属コラボ
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body py-0">
              <div className="media-group">
                {collection_user_active.map((c) => {
                  return (
                    <div className="media position-relative">
                      <div className="media-object">
                        <div className="mask-hex">
                          <figure
                            className="object-item ratio ratio-1x1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={c.collection_name}
                          >
                            <img
                              src={c.collection_icon_uri}
                              className="icon-item"
                              alt={c.collection_name}
                            />
                          </figure>
                        </div>
                      </div>
                      <div className="media-body">
                        <h3 className="media-title">
                          <a
                            className="stretched-link"
                            href="#"
                            onClick={(e) => {
                              hideModal("teams");
                              history.push(`/collections/${c.collection_id}/items`);
                            }}
                          >
                            {c.collection_name}
                          </a>
                          <span>@{c.collection_id}</span>
                        </h3>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {_teamWaiting({ modalId: "teamsWaiting2", history, collection_user_inactive })}
      <div
        className="modal fade"
        id="allteams"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="userTeamsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
          <div className="modal-content h-100">
            <div className="modal-header">
              <h5 className="modal-title text-truncate" id="userTeamsLabel">
                コラボメンバーと報酬の分配
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body p-0">
              <div className="row g-1 px-3 py-2 border-bottom sticky-top bg-white">
                <div className="col-5 ms-auto">
                  <span className="d-block text-center">報酬の分配</span>
                  <div className="row g-0">
                    <div className="col text-end">
                      <small className="d-block">一次</small>
                    </div>
                    <div className="col text-end">
                      <small className="d-block">二次</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-light p-3">
                <small className="d-block mb-2 fw-bold">オーナー</small>
                <div className="row g-1 position-relative">
                  <div className="col overflow-hidden">
                    <div
                      className="media"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                    >
                      <div className="media-object">
                        <div className="icon icon-pill">
                          <img
                            src={collection_owner_user.user_icon_uri}
                            className="icon-item"
                            width="100%"
                            height="auto"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="media-body text-truncate">
                        <h3 className="media-title mb-0 text-truncate">
                          <a
                            className="stretched-link"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              hideModal("allteams");
                              history.push({
                                pathname: `/users/${collection_owner_user.user_id}`,
                              });
                            }}
                          >
                            {collection_owner_user.user_name}
                          </a>
                          <span className="d-block fw-normal">
                            @{collection_owner_user.user_nmid}
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="row g-0">
                      <div className="col text-end">{collection_owner_user.reward_first}%</div>
                      <div className="col text-end">{collection_owner_user.reward_second}%</div>
                    </div>
                  </div>
                </div>
              </div>
              <small className="d-block pb-1 p-3 fw-bold">メンバー</small>
              <ul className="list-group list-group-flush px-3">
                {collection_members.map((m) => {
                  return (
                    <li className="list-group-item px-0 position-relative">
                      <div className="row g-1">
                        <div className="col overflow-hidden">
                          <div
                            className="media"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                          >
                            <div className="media-object">
                              <div className="icon icon-pill">
                                <img
                                  src={m.user_icon_uri}
                                  className="icon-item"
                                  width="100%"
                                  height="auto"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="media-body text-truncate">
                              <h3 className="media-title mb-0 text-truncate">
                                <a
                                  className="stretched-link"
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    hideModal("allteams");
                                    history.push({
                                      pathname: `/users/${m.user_id}`,
                                    });
                                  }}
                                >
                                  {m.user_name}
                                </a>
                                <span className="d-block fw-normal">@{m.user_nmid}</span>
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="row g-0">
                            <div className="col text-end">{m.reward_first}%</div>
                            <div className="col text-end">{m.reward_second}%</div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
