import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import LiLanguage from "components/Li/LiLanguage";
import LiAccount from "components/Li/LiAccount";
import { getMessage } from "config/util";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";

import CustomImage from "./CustomImage";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import CustomDate from "components/CustomDate/CustomDate.js";
import CustomSwitch from "components/CustomSwitch/CustomSwitch.js";

import { hideModal, showModal } from "config/util";

import _modalSuccessfully from "./_modalSuccessfully";
import _processDialog from "./_processDialog";
import _editor from "./_editor";
import _modalfollow from "./_modalfollow";

export default function Contents({ Collections, Users }) {
  const {
    propsCollections,
    set_propsCollections,
    dispatch_post_collections,
    loadingId,
  } = Collections;

  const props = propsCollections;
  const set_props = set_propsCollections;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  const userslist = useSelector((state) => state.reduce_get_users.users);

  const isNofollower = userslist.length == 0;

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const maps = useSelector((state) => state.reduce_get_maps);

  Users.dispatch_users_list = (o) => {
    for (const member of props.members) {
      if (member.user_uuid == o.user_uuid) {
        alert("すでに追加されています");
        return;
      }
    }

    const members = props.members;
    members.push({
      user_uuid: o.user_uuid,
      user_icon: o.user_icon,
      user_name: o.user_name,
      user_id: o.user_nmid,
      auth: true,
      reward_first: 0,
      reward_second: 0,
      disabled: false,
    });
    // members[idx].auth = !members[idx].auth;
    set_props({
      ...props,
      members: members,
    });

    hideModal("addMember");
  };

  return (
    <>
      <div>
        <Header />
        <div className="container container-main">
          <main className="main">
            <div action="collection-detail.html" entype="multpart/form-data">
              <div className="main-header pt-3 pb-2">
                <h2 className="h4 m-0 text-truncate">{t("コラボブランドを作成")}</h2>
                <div className="btn-group">
                  <a
                    className="btn btn-outline-secondary text-dark rounded-pill px-3 me-2 text-nowrap"
                    href="javascript:history.back()"
                  >
                    <i className="fa-solid fa-xmark" />
                    <span className="d-none d-sm-inline-block"> {t("キャンセル")}</span>
                  </a>
                  <button
                    type="button"
                    name="confirm"
                    className="btn btn-primary rounded-pill px-3 text-nowrap"
                    role="button"
                    // data-bs-toggle="modal"
                    // data-bs-target="#processDialog"
                    onClick={() => {
                      showModal("processDialog");
                    }}
                  >
                    {t("保存")}
                  </button>
                </div>
              </div>
              {/* {!loadingId && editor()} */}
              {isNofollower && (
                <>
                  <div className="alert alert-danger" role="alert">
                    <div className="d-flex align-items-center mb-2 ps-2">
                      <i className="fa-solid fa-triangle-exclamation me-2" />
                      <span className="d-block fw-bold">ご注意ください</span>
                    </div>
                    <ul className="mb-0">
                      <li>メンバーを追加するには相互フォローしているユーザーが必要です</li>
                    </ul>
                  </div>
                  {/* <span style={{ color: "red", fontSize: 18 }}>
                  メンバーを追加するには相互フォローしているユーザーが必要です
                </span> */}
                </>
              )}
              {_editor(Collections)}
              {_processDialog(Collections)}
            </div>
            {_modalSuccessfully(Collections)}
            {_modalfollow(Users)}
          </main>
        </div>
      </div>
    </>
  );
}
