import { fork, take, put, join, select, takeLatest, takeEvery, call } from "redux-saga/effects";

import { post_authout } from "../Auth/actions";

import {
  api_get_transactions,
  api_get_transactions_id,
  api_post_transactions,
  api_put_transactions_id,
  api_delete_transactions_id,
} from "./api";

import {
  get_transactions,
  get_transactions_data,
  get_transactions_error,
  get_transactions_id,
  get_transactions_id_data,
  get_transactions_id_error,
  post_transactions,
  post_transactions_data,
  post_transactions_error,
  put_transactions_id,
  put_transactions_id_data,
  put_transactions_id_error,
  delete_transactions_id,
  delete_transactions_id_data,
  delete_transactions_id_error,
} from "./actions";

export function* run_get_transactions({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_transactions, payload.data, token);
  if (!error) {
    yield put(get_transactions_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_transactions_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_transactions_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_transactions_id, payload.data, token);
  if (!error) {
    yield put(get_transactions_id_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_transactions_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_transactions({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_transactions, payload.data, token);
  if (!error) {
    yield put(post_transactions_data({ data }));
    yield put(get_transactions(payload));
  } else {
    yield put(post_transactions_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_transactions_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_transactions_id, payload.data, token);
  if (!error) {
    yield put(put_transactions_id_data({ data }));
    yield put(get_transactions_id(payload));
  } else {
    yield put(put_transactions_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_transactions_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_delete_transactions_id, payload.data, token);
  if (!error) {
    yield put(delete_transactions_id_data({ data }));
    yield put(get_transactions(payload));
  } else {
    yield put(delete_transactions_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* flow_get_transactions() {
  yield takeLatest(get_transactions, run_get_transactions);
}
export function* flow_get_transactions_id() {
  yield takeLatest(get_transactions_id, run_get_transactions_id);
}
export function* flow_post_transactions() {
  yield takeLatest(post_transactions, run_post_transactions);
}
export function* flow_put_transactions_id() {
  yield takeLatest(put_transactions_id, run_put_transactions_id);
}
export function* flow_delete_transactions_id() {
  yield takeLatest(delete_transactions_id, run_delete_transactions_id);
}

export default function* rootSaga() {
  yield fork(flow_get_transactions);
  yield fork(flow_get_transactions_id);
  yield fork(flow_post_transactions);
  yield fork(flow_put_transactions_id);
  yield fork(flow_delete_transactions_id);
}
