import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import LiLanguage from "components/Li/LiLanguage";
import LiAccount from "components/Li/LiAccount";
import { getMessage } from "config/util";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";
import { view_item } from "../../stores/Items/view";
import CustomDate from "components/CustomDate/CustomDate.js";
import { hideModal, showModal } from "config/util";

export default function Contents(Items) {
  const { propsItems, set_propsItems, dispatch_put_items_id, loadingId } = Items;

  const props = propsItems;
  const set_props = set_propsItems;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();
  const { item_id } = useParams();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const maps = useSelector((state) => state.reduce_get_maps);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const item = useSelector((state) => state.reduce_get_items.item);
  const v_item = view_item(item, maps, t);

  const coin_ids = { 1: "Bitcoin", 2: "Ethereum" };
  const sale_types = { 1: "固定価格", 2: "オークション" };

  return (
    <>
      <div className="sidebar shadow-end d-none d-lg-flex flex-lg-column">
        <div className="sidebar-sticky">
          <div className="bg-light p-3 mb-3 text-center">
            <h3 className="h5 text-start">{t("プレビュー")}</h3>
            <div
              className="card product product-item mx-auto text-start"
              style={{
                maxWidth: "320px",
              }}
            >
              <div className="product-body">
                <div className="product-media object-item">
                  <img
                    src={v_item.item_icon_uri}
                    width="100%"
                    height="100%"
                    className="card-img img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="product-title">{v_item.item_name}</h3>
                <div className="d-md-flex">
                  <div className="hstack gap-2">
                    {/* <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="GIF\u30A2\u30CB\u30E1\u30FC\u30B7\u30E7\u30F3"
                    >
                      <img
                        src="/dist/tmty/assets/icons/gif.svg"
                        width={18}
                        height={18}
                        alt="GIF\u30A2\u30CB\u30E1\u30FC\u30B7\u30E7\u30F3"
                      />
                    </span>
                    <span
                      className="text-secondary"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                    >
                      <i className="fa-solid fa-handshake-angle fa-fw" />
                    </span> */}
                  </div>
                  <div className="ms-auto">
                    <div className="d-flex flex-column align-items-center align-items-md-end" />
                  </div>
                </div>
              </div>
              <div className="product-footer">
                <div className="collection-icon">
                  <img src={v_item.collection_icon_uri} width={22} height={22} alt="" />
                </div>
                <span className="product-author text-truncate">{v_item.collection_name}</span>
              </div>
            </div>
            {}
          </div>
          <ul className="list-group list-group-flush mb-4">
            <li className="list-group-item ps-0">
              <h3 className="h5 m-0">
                {t("手数料")}{" "}
                <a className="btn btn-link btn-sm ms-2" href>
                  {t("手数料について")}
                  <i className="fa-solid fa-arrow-up-right-from-square ms-1" />
                </a>
              </h3>
            </li>
            <li className="list-group-item d-flex ps-0">
              {t("サービス手数料")}
              <span className="ms-auto">{v_item.service_commission}%</span>
            </li>
            <li className="list-group-item d-flex ps-0">
              {t("クリエイター報酬")}
              <span className="ms-auto">{v_item.collection_creator_reward}%</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
