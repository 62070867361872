import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

// import { view_user } from "../../stores/Users/view";
import { view_user } from "../../stores/Accounts/view";
import { view_wallets } from "../../stores/Wallets/view";
import { toNumber } from "config/util";
import { hideModal, showModal } from "config/util";

export default function Contents({ modalId, history, collection_user_inactive }) {
  const inactives = collection_user_inactive ? collection_user_inactive : [];

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="teamsWaitingLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
          <div className="modal-content h-100">
            <div className="modal-header">
              <h5 className="modal-title text-truncate" id="teamsWaitingLabel">
                待機中のコラボ
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body py-0">
              <div className="media-group">
                {inactives.map((c) => {
                  return (
                    <div className="media position-relative">
                      <div className="media-object">
                        <div className="mask-hex">
                          <figure
                            className="object-item ratio ratio-1x1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={c.collection_name}
                          >
                            <img
                              src={c.collection_icon_uri}
                              className="icon-item"
                              alt={c.collection_name}
                            />
                          </figure>
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="media-action">
                          <h3 className="media-title">
                            <a
                              className="stretched-link"
                              href="#"
                              onClick={(e) => {
                                hideModal(modalId);
                                history.push(`/collections/${c.collection_id}/items`);
                              }}
                            >
                              {c.collection_name}
                            </a>
                            <span className="fw-normal">@{c.collection_id}</span>
                          </h3>
                          <div className="flex-shrink-0">
                            {c.unjoin_flg && (
                              <span className="badge bg-danger text-light ms-3">NEW</span>
                            )}
                          </div>
                        </div>
                        {/* <h3 className="media-title">
                          <a
                            className="stretched-link"
                            href="#"
                            onClick={(e) => {
                              hideModal(modalId);
                              history.push(`/collections/${c.collection_id}/items`);
                            }}
                          >
                            {c.collection_name}
                          </a>
                          <span>@{c.collection_id}</span>
                        </h3> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
