import { combineReducers } from "redux";
import { createAction, createReducer } from "redux-act";

import {
  get_transactions,
  get_transactions_data,
  get_transactions_error,
  get_transactions_id,
  get_transactions_id_data,
  get_transactions_id_error,
  post_transactions,
  post_transactions_data,
  post_transactions_error,
  put_transactions_id,
  put_transactions_id_data,
  put_transactions_id_error,
  delete_transactions_id,
  delete_transactions_id_data,
  delete_transactions_id_error,
} from "./actions";

const forupdate = (array, unit, id) => {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element[id] === unit[id]) {
      array[index] = unit;
    }
  }
  return array;
};

export const reduce_get_transactions = createReducer(
  {
    [get_transactions]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      if (!payload.data.search) {
        newState.transactionslist = [];
      } else if (payload.data.search.page == 1) {
        newState.transactionslist = [];
        // newState.same = false;
        // newState.page = 1;
      } else {
        // newState.same = newState.page == payload.data.search.page;
        // newState.page = payload.data.search.page;
      }
      newState.isLoading = true;
      return newState;
    },
    [get_transactions_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      // if (!newState.same) {
      newState.transactionslist = newState.transactionslist.concat(payload.data.transactions);
      // }

      newState.isLoading = false;
      return newState;
    },
    [get_transactions_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [get_transactions_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_transactions_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      const activity = payload.data.activity;
      const transactions = state.transactions;
      const transactionslist = state.transactionslist;

      if (transactions) newState.transactions = forupdate(transactions, activity, "activity_id");
      if (transactionslist) newState.transactionslist = forupdate(transactionslist, activity, "activity_id");
      newState.isLoading = false;

      return newState;
    },
    [get_transactions_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
 isLoading: false,
    activity: {},
    transactions: [],
    transactionslist: [],
    count: 0,
  }
);

export const reduce_post_transactions = createReducer(
  {
    [post_transactions]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_transactions_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_transactions_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_transactions_id = createReducer(
  {
    [put_transactions_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_transactions_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_transactions_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_transactions_id = createReducer(
  {
    [delete_transactions_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_transactions_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [delete_transactions_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export default {
  reduce_get_transactions,
  reduce_post_transactions,
  reduce_put_transactions_id,
  reduce_delete_transactions_id,
};
