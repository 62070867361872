import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export const HeaderItem = (Users) => {
  const { propsUsers, set_propsUsers, dispatch_gets, user_id } = Users;
  return <></>;
};

export const RowItem = (Users) => {
  const { v_user, history, t, dispatch_put_users_id } = Users;

  return (
    <>
      <div className="col">
        <div className="card card-body text-start overflow-hidden position-relative p-3">
          <div className="media">
            <div className="media-object">
              {/* <div className="icon icon-pill verified"> */}
              <div className="icon icon-pill">
                <img
                  src={v_user.user_icon_uri}
                  className="icon-item"
                  width="100%"
                  height="auto"
                  alt="tomonity official"
                />
              </div>
            </div>
            <div className="media-body text-truncate overflow-hidden">
              <h3 className="media-title mb-0">
                <a
                  className="stretched-link text-dark"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`/users/${v_user.user_id}/items`);
                  }}
                >
                  {/* <div className="d-flex verified-text"> */}
                  <span className="text-truncate">{v_user.user_name}</span>
                  {/* </div> */}
                </a>
              </h3>
              <span className="d-block text-muted text-truncate">@{v_user.user_nmid}</span>
            </div>
            <div
              className="flex-shrink-0 ms-3"
              style={{
                zIndex: 2,
              }}
            >
              {!v_user.myself && (
                <button
                  type="button"
                  className={`btn btn-outline-dark rounded-pill ${
                    v_user.user_favorited_my ? "active" : ""
                  }`}
                  role="button"
                  data-bs-toggle="button"
                  autoComplete="off"
                  aria-pressed={v_user.user_favorited_my ? "true" : "false"}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch_put_users_id(
                      {
                        id: v_user.user_id,
                      },
                      () => {},
                      () => {}
                    );
                  }}
                >
                  {v_user.user_favorited_my ? t("フォロー中") : t("フォロー")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
