import { fork, take, put, join, select, takeLatest, takeEvery, call } from "redux-saga/effects";

import { post_authout } from "../Auth/actions";

import {
  api_get_collabos,
  api_get_collabos_id,
  api_post_collabos,
  api_put_collabos_id,
  api_delete_collabos_id,
  api_put_collabos_id_favorite,

} from "./api";

import {
  get_collabos,
  get_collabos_data,
  get_collabos_error,
  get_collabos_id,
  get_collabos_id_data,
  get_collabos_id_error,
  post_collabos,
  post_collabos_data,
  post_collabos_error,
  put_collabos_id,
  put_collabos_id_data,
  put_collabos_id_error,
  delete_collabos_id,
  delete_collabos_id_data,
  delete_collabos_id_error,
  put_collabos_id_favorite,
  put_collabos_id_favorite_data,
  put_collabos_id_favorite_error,
} from "./actions";

export function* run_get_collabos({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_collabos, payload.data, token);
  if (!error) {
    yield put(get_collabos_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_collabos_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_collabos_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_collabos_id, payload.data, token);
  if (!error) {
    yield put(get_collabos_id_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_collabos_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_collabos({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_collabos, payload.data, token);
  if (!error) {
    yield put(post_collabos_data({ data }));
    // yield put(get_collabos(payload));
    !payload.next || payload.next(data);
  } else {
    yield put(post_collabos_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_collabos_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_collabos_id, payload.data, token);
  if (!error) {
    yield put(put_collabos_id_data({ data }));
    yield put(get_collabos_id(payload));
  } else {
    yield put(put_collabos_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_collabos_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_delete_collabos_id, payload.data, token);
  if (!error) {
    yield put(delete_collabos_id_data({ data }));
    yield put(get_collabos(payload));
  } else {
    yield put(delete_collabos_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_collabos_id_favorite({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_collabos_id_favorite, payload.data, token);
  if (!error) {
    yield put(put_collabos_id_favorite_data({ data }));
    yield put(get_collabos_id(payload));
  } else {
    yield put(put_collabos_id_favorite_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* flow_get_collabos() {
  yield takeLatest(get_collabos, run_get_collabos);
}
export function* flow_get_collabos_id() {
  yield takeLatest(get_collabos_id, run_get_collabos_id);
}
export function* flow_post_collabos() {
  yield takeLatest(post_collabos, run_post_collabos);
}
export function* flow_put_collabos_id() {
  yield takeLatest(put_collabos_id, run_put_collabos_id);
}
export function* flow_delete_collabos_id() {
  yield takeLatest(delete_collabos_id, run_delete_collabos_id);
}
export function* flow_put_collabos_id_favorite() {
  yield takeLatest(put_collabos_id_favorite, run_put_collabos_id_favorite);
}


export default function* rootSaga() {
  yield fork(flow_get_collabos);
  yield fork(flow_get_collabos_id);
  yield fork(flow_post_collabos);
  yield fork(flow_put_collabos_id);
  yield fork(flow_delete_collabos_id);
  yield fork(flow_put_collabos_id_favorite);
}
