import { fork, take, put, join, select, takeLatest, takeEvery, call } from "redux-saga/effects";

import { post_authout } from "../Auth/actions";

import {
  api_get_nfts,
  api_get_nfts_id,
  api_post_nfts,
  api_put_nfts_id,
  api_delete_nfts_id,
} from "./api";

import {
  get_nfts,
  get_nfts_data,
  get_nfts_error,
  get_nfts_id,
  get_nfts_id_data,
  get_nfts_id_error,
  post_nfts,
  post_nfts_data,
  post_nfts_error,
  put_nfts_id,
  put_nfts_id_data,
  put_nfts_id_error,
  delete_nfts_id,
  delete_nfts_id_data,
  delete_nfts_id_error,
} from "./actions";

export function* run_get_nfts({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_nfts, payload.data, token);
  if (!error) {
    yield put(get_nfts_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_nfts_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_nfts_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_nfts_id, payload.data, token);
  if (!error) {
    yield put(get_nfts_id_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_nfts_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_nfts({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_nfts, payload.data, token);
  if (!error) {
    yield put(post_nfts_data({ data }));
    yield put(get_nfts(payload));
  } else {
    yield put(post_nfts_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_nfts_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_nfts_id, payload.data, token);
  if (!error) {
    yield put(put_nfts_id_data({ data }));
    yield put(get_nfts_id(payload));
  } else {
    yield put(put_nfts_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_nfts_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_delete_nfts_id, payload.data, token);
  if (!error) {
    yield put(delete_nfts_id_data({ data }));
    yield put(get_nfts(payload));
  } else {
    yield put(delete_nfts_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* flow_get_nfts() {
  yield takeLatest(get_nfts, run_get_nfts);
}
export function* flow_get_nfts_id() {
  yield takeLatest(get_nfts_id, run_get_nfts_id);
}
export function* flow_post_nfts() {
  yield takeLatest(post_nfts, run_post_nfts);
}
export function* flow_put_nfts_id() {
  yield takeLatest(put_nfts_id, run_put_nfts_id);
}
export function* flow_delete_nfts_id() {
  yield takeLatest(delete_nfts_id, run_delete_nfts_id);
}

export default function* rootSaga() {
  yield fork(flow_get_nfts);
  yield fork(flow_get_nfts_id);
  yield fork(flow_post_nfts);
  yield fork(flow_put_nfts_id);
  yield fork(flow_delete_nfts_id);
}
