import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { hideModal, showModal } from "config/util";

export default function Contents(Page) {
  const { type, dispatch_type, word, set_word, ref_search } = Page ?? {};

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const [height, set_height] = useState();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const history = {
  //   push: (arg) => {
  //     const globalNav = document.getElementById("globalNav");
  //     const modal = window.bootstrap.Offcanvas.getInstance(globalNav);
  //     if (modal) {
  //       modal.hide();
  //     }

  //     history2.push(arg);
  //   },
  // };

  const next = () => {
    hideModal("searchField");

    if (!type) {
      history.push({
        pathname: `/explore`,
        focus: true,
      });
    }

    if (type == "collections") {
      // history.push({
      //   pathname: `/explore`,
      //   focus: true,
      // });
    }
    if (type == "items") {
      // history.push({
      //   pathname: `/explore`,
      //   focus: true,
      // });
    }
    if (type == "users") {
      // history.push({
      //   pathname: `/explore`,
      //   focus: true,
      // });
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="searchField"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="searchFieldLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              {}
              <button className="btn" type="button" data-bs-dismiss="modal" aria-label="Close">
                <i className="fa-solid fa-arrow-left fa-fw" /> {t("検索")}
              </button>
              {}
            </div>
            <div className="modal-body">
              <div action="collections.html">
                <input
                  className="form-control"
                  type="search"
                  placeholder={t("アイテム、コレクション、ユーザを検索")}
                  aria-label="Search"
                  autoFocus
                  ref={ref_search}
                  value={word}
                  onChange={(e) => {
                    const val = e.target.value;
                    sessionStorage.setItem("SearchWord", val);
                    if (set_word) {
                      set_word(val);
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key == "Enter") {
                      next();
                    }
                  }}
                />
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-primary rounded-pill px-4"
                // onFocus={() => {
                //   if (set_word) {
                //   } else {
                //     history.push({
                //       pathname: `/collections`,
                //       focus: true,
                //     });
                //   }
                // }}
                onClick={() => {
                  next();
                }}
              >
                {t("検索")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
