import { createAction, createReducer } from 'redux-act';

export const get_items_id_offers = createAction("GET_ITEMS_ID_OFFERS");
export const get_items_id_offers_data = createAction("GET_ITEMS_ID_OFFERS_DATA");
export const get_items_id_offers_error = createAction("GET_ITEMS_ID_OFFERS_ERROR");
export const get_items_id_offers_id = createAction("GET_ITEMS_ID_OFFERS_ID");
export const get_items_id_offers_id_data = createAction("GET_ITEMS_ID_OFFERS_ID_DATA");
export const get_items_id_offers_id_error = createAction("GET_ITEMS_OFFERS_ID_ERROR");

export const post_items_id_offers = createAction("POST_ITEMS_ID_OFFERS");
export const post_items_id_offers_data = createAction("POST_ITEMS_ID_OFFERS_DATA");
export const post_items_id_offers_error = createAction("POST_ITEMS_ID_OFFERS_ERROR");

export const put_items_id_offers_id = createAction("PUT_ITEMS_ID_OFFERS_ID");
export const put_items_id_offers_id_data = createAction("PUT_ITEMS_ID_OFFERS_ID_DATA");
export const put_items_id_offers_id_error = createAction("PUT_ITEMS_ID_OFFERS_ID_ERROR");

export const delete_items_id_offers_id = createAction("DELETE_ITEMS_ID_OFFERS_ID");
export const delete_items_id_offers_id_data = createAction("DELETE_ITEMS_ID_OFFERS_ID_DATA");
export const delete_items_id_offers_id_error = createAction("DELETE_ITEMS_ID_OFFERS_ID_ERROR");