import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { ChainLink } from "../../components/ChainLink";
import { getMessage } from "config/util";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import { post_accounts_auth_code } from "stores/Accounts/actions";

export default function Contents({ branch, children }) {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  const [propsAccount, set_propsAccount] = useState({});
  const [page, set_page] = useState(false);

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_post_accounts_auth_code = (data, next, prev) => {
    dispatch(
      post_accounts_auth_code({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_type = (name) => {
    if (name == "password") {
      dispatch_message(t("パスワードが変更されました"), "primary");
    }
    if (name == "user") {
      dispatch_message(t("ユーザーネームが変更されました"), "primary");
    }
    if (name == "phone") {
      dispatch_message(t("電話番号が変更されました"), "primary");
    }
    if (name == "mail") {
      dispatch_message(t("メールアドレスが変更されました"), "primary");
    }
    set_page(true);
  };

  const newchildren = React.cloneElement(children, {
    Account: {
      propsAccount,
      set_propsAccount,
      dispatch_post_accounts_auth_code,
      dispatch_message,
      dispatch_type,
      page,
      branch,
    },
  });

  return (
    <>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => set_snack(false)}
        close
      />
      <ChainLink
        hrefs={
          [
            // "/dist/tmty/dist/js/vendor/mobile-side-navigation-zeynepjs/zeynep.min.css",
            // "/dist/tmty/dist/css/base.css",
            // "/dist/tmty/dist/css/ja-jp.css",
          ]
        }
      >
        <Helmet>
          <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <title>tomonity</title>
          {/* <script src="dist/material/js/particle.js"></script> */}
        </Helmet>
        {newchildren}
      </ChainLink>
    </>
  );
}
