import moment from "moment/moment";
import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  countdown2label,
  zeroPadding,
  toNumber,
  createBr,
} from "config/util";

export const view_item_bid = (prop, maps, t) => {
  if (!t) return {};
  if (!prop) return {};
  if (!maps) return {};

  const resView = {
    ...prop,
  };
  const coin_decimals = maps.coin_decimals;

  resView.item_sale_price = prop.item_sale_price;
  resView.item_sale_price_usd_label = toNumber(prop.item_sale_price_usd, coin_decimals["USDT"]);

  const created_at_countdown = moment2countdown(prop.created_at);
  resView.created_at_countdown_label = countdown2label(created_at_countdown, t);
  // resView.created_at_countdown_label = toMomentString(prop.created_at, "YYYY-MM-DD HH:mm");
  // ${countdown.hours}:${countdown.minutes}:${countdown.seconds}
  // resView.countdown = countdown;

  return resView;
};
