"use strict";

exports.__esModule = true;
exports["default"] = void 0;

var _recaptcha = _interopRequireDefault(require("./recaptcha"));

var _reactAsyncScript = _interopRequireDefault(require("react-async-script"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var callbackName = "onloadcallback";
var globalName = "grecaptcha";

function getOptions() {
  return typeof window !== "undefined" && window.recaptchaOptions || {};
}

function getURL() {
  var dynamicOptions = getOptions();
  var hostname = dynamicOptions.useRecaptchaNet ? "recaptcha.net" : "www.google.com";
  return "https://" + hostname + "/recaptcha/api.js?onload=" + callbackName + "&render=explicit";
  // return "/dist_self/js/recaptcha-api.js?onload=onloadcallback&render=explicit";
  
}

var _default = (0, _reactAsyncScript["default"])(getURL, {
  callbackName: callbackName,
  globalName: globalName
})(_recaptcha["default"]);

exports["default"] = _default;