import { combineReducers } from "redux";
import { createAction, createReducer } from "redux-act";

import {
  get_items,
  get_items_data,
  get_items_error,
  get_items_id,
  get_items_id_data,
  get_items_id_error,
  post_items,
  post_items_data,
  post_items_error,
  put_items_id,
  put_items_id_data,
  put_items_id_error,
  delete_items_id,
  delete_items_id_data,
  delete_items_id_error,
  post_orders,
  post_orders_data,
  post_orders_error,
  post_send,
  post_send_data,
  post_send_error,
  post_items_id_offer,
  post_items_id_offer_data,
  post_items_id_offer_error,
  put_items_id_favorite,
  put_items_id_favorite_data,
  put_items_id_favorite_error,
} from "./actions";

const forupdate = (array, unit, id) => {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element[id] === unit[id]) {
      array[index] = unit;
    }
  }
  return array;
};

export const reduce_get_items = createReducer(
  {
    [get_items]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_items_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_items_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [get_items_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoadingId = true;
      return newState;
    },
    [get_items_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      const item = payload.data.item;
      const items = state.items;
      if (items) newState.items = forupdate(items, item, "item_id");
      newState.isLoadingId = false;

      return newState;
    },
    [get_items_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoadingId = false;
      return newState;
    },
  },
  {
    isLoading: false,
    isLoadingId: false,
    item: {},
    items: [],
    count: 0,
  }
);

export const reduce_post_items = createReducer(
  {
    [post_items]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_items_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_items_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_items_id = createReducer(
  {
    [put_items_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_items_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_items_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_items_id = createReducer(
  {
    [delete_items_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_items_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [delete_items_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_orders = createReducer(
  {
    [post_orders]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_orders_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_orders_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_post_send = createReducer(
  {
    [post_send]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_send_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_send_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_items_id_favorite = createReducer(
  {
    [put_items_id_favorite]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_items_id_favorite_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_items_id_favorite_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export default {
  reduce_get_items,
  reduce_post_items,
  reduce_put_items_id,
  reduce_delete_items_id,
  reduce_post_orders,
  reduce_post_send,
  reduce_put_items_id_favorite,
};
