import { combineReducers } from "redux";
import { createAction, createReducer } from "redux-act";

import {
  get_items_id_bids,
  get_items_id_bids_data,
  get_items_id_bids_error,
  get_items_id_bids_id,
  get_items_id_bids_id_data,
  get_items_id_bids_id_error,
  post_items_id_bids,
  post_items_id_bids_data,
  post_items_id_bids_error,
  put_items_id_bids_id,
  put_items_id_bids_id_data,
  put_items_id_bids_id_error,
  delete_items_id_bids_id,
  delete_items_id_bids_id_data,
  delete_items_id_bids_id_error,
} from "./actions";

const forupdate = (array, unit, id) => {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element[id] === unit[id]) {
      array[index] = unit;
    }
  }
  return array;
};

export const reduce_get_items_id_bids = createReducer(
  {
    [get_items_id_bids]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_items_id_bids_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_items_id_bids_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [get_items_id_bids_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoadingId = true;
      return newState;
    },
    [get_items_id_bids_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      const item_bid = payload.data.item_bid;
      const item_bids = state.item_bids;
      if (item_bids) newState.item_bids = forupdate(item_bids, item_bid, "item_id");
      newState.isLoadingId = false;

      return newState;
    },
    [get_items_id_bids_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoadingId = false;
      return newState;
    },
  },
  {
    isLoading: false,
    isLoadingId: false,
    item_bid: {},
    item_bids: [],
    count: 0,
  }
);

export const reduce_post_items_id_bids = createReducer(
  {
    [post_items_id_bids]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_items_id_bids_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_items_id_bids_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_items_id_bids_id = createReducer(
  {
    [put_items_id_bids_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_items_id_bids_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_items_id_bids_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_items_id_bids_id = createReducer(
  {
    [delete_items_id_bids_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_items_id_bids_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [delete_items_id_bids_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export default {
  reduce_get_items_id_bids,
  reduce_post_items_id_bids,
  reduce_put_items_id_bids_id,
  reduce_delete_items_id_bids_id,
};
