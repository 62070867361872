import { createAction } from "redux-act";

export const get_accounts_kyc = createAction("GET_ACCOUNTS_KYC");
export const get_accounts_kyc_data = createAction("GET_ACCOUNTS_KYC_DATA");
export const get_accounts_kyc_error = createAction("GET_ACCOUNTS_KYC_ERROR");


export const get_accounts_totp = createAction("GET_ACCOUNTS_totp");
export const get_accounts_totp_data = createAction("GET_ACCOUNTS_totp_DATA");
export const get_accounts_totp_error = createAction("GET_ACCOUNTS_totp_ERROR");

export const get_accounts = createAction("GET_ACCOUNTS");
export const get_accounts_data = createAction("GET_ACCOUNTS_DATA");
export const get_accounts_error = createAction("GET_ACCOUNTS_ERROR");

export const put_accounts = createAction("PUT_ACCOUNTS");
export const put_accounts_data = createAction("PUT_ACCOUNTS_DATA");
export const put_accounts_error = createAction("PUT_ACCOUNTS_ERROR");


export const put_accounts_extra = createAction("PUT_ACCOUNTS_EXTRA");
export const put_accounts_extra_data = createAction("PUT_ACCOUNTS_EXTRA_DATA");
export const put_accounts_extra_error = createAction("PUT_ACCOUNTS_EXTRA_ERROR");

export const delete_accounts = createAction("DELETE_ACCOUNTS");
export const delete_accounts_data = createAction("DELETE_ACCOUNTS_DATA");
export const delete_accounts_error = createAction("DELETE_ACCOUNTS_ERROR");

export const delete_accounts_delete = createAction("DELETE_ACCOUNTS_DELETE");
export const delete_accounts_delete_data = createAction("DELETE_ACCOUNTS_DELETE_DATA");
export const delete_accounts_delete_error = createAction("DELETE_ACCOUNTS_DELETE_ERROR");

export const post_accounts_code = createAction("POST_ACCOUNTS_CODE");
export const post_accounts_code_data = createAction("POST_ACCOUNTS_CODE_DATA");
export const post_accounts_code_error = createAction("POST_ACCOUNTS_CODE_ERROR");

export const post_accounts_images = createAction("POST_ACCOUNTS_IMAGES");
export const post_accounts_images_data = createAction(
  "POST_ACCOUNTS_IMAGES_DATA"
);
export const post_accounts_images_error = createAction(
  "POST_ACCOUNTS_IMAGES_ERROR"
);

export const delete_accounts_images_id = createAction("DELETE_ACCOUNTS_IMAGES_ID");
export const delete_accounts_images_id_data = createAction(
  "DELETE_ACCOUNTS_IMAGES_ID_DATA"
);
export const delete_accounts_images_id_error = createAction(
  "DELETE_ACCOUNTS_IMAGES_ID_ERROR"
);



export const post_accounts_verify = createAction("POST_ACCOUNTS_VERIFY");
export const post_accounts_verify_data = createAction("POST_ACCOUNTS_VERIFY_DATA");
export const post_accounts_verify_error = createAction("POST_ACCOUNTS_VERIFY_ERROR");



export const post_accounts_auth = createAction("POST_ACCOUNTS_AUTH");
export const post_accounts_auth_data = createAction("POST_ACCOUNTS_AUTH_DATA");
export const post_accounts_auth_error = createAction("POST_ACCOUNTS_AUTH_ERROR");

export const post_accounts_auth_code = createAction("POST_ACCOUNTS_AUTH_CODE");
export const post_accounts_auth_code_data = createAction("POST_ACCOUNTS_AUTH_CODE_DATA");
export const post_accounts_auth_code_error = createAction("POST_ACCOUNTS_AUTH_CODE_ERROR");

export const put_accounts_security = createAction("PUT_ACCOUNTS_SECURITY");
export const put_accounts_security_data = createAction("PUT_ACCOUNTS_SECURITY_DATA");
export const put_accounts_security_error = createAction("PUT_ACCOUNTS_SECURITY_ERROR");




export const get_notification = createAction("GET_NOTIFICATION");
export const get_notification_data = createAction("GET_NOTIFICATION_DATA");
export const get_notification_error = createAction("GET_NOTIFICATION_ERROR");

export const post_notification = createAction("POST_NOTIFICATION");
export const post_notification_data = createAction("POST_NOTIFICATION_DATA");
export const post_notification_error = createAction("POST_NOTIFICATION_ERROR");
export const set_notification = createAction("SET_NOTIFICATION");

// export const get_users = createAction("GET_USERS");
// export const get_users_data = createAction("GET_USERS_DATA");
// export const get_users_error = createAction("GET_USERS_ERROR");

// export const get_users_id = createAction("GET_USERS_ID");
// export const get_users_id_data = createAction("GET_USERS_ID_DATA");
// export const get_users_id_error = createAction("GET_USERS_ID_ERROR");