import { combineReducers } from "redux";
import { createAction, createReducer } from "redux-act";

import {
  get_withdraws,
  get_withdraws_data,
  get_withdraws_error,
  get_withdraws_id,
  get_withdraws_id_data,
  get_withdraws_id_error,
  post_withdraws,
  post_withdraws_data,
  post_withdraws_error,
  put_withdraws_id,
  put_withdraws_id_data,
  put_withdraws_id_error,
  delete_withdraws_id,
  delete_withdraws_id_data,
  delete_withdraws_id_error,
  put_withdraws_id_favorite,
  put_withdraws_id_favorite_data,
  put_withdraws_id_favorite_error,
} from "./actions";

const forupdate = (array, unit, id) => {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element[id] === unit[id]) {
      array[index] = unit;
    }
  }
  return array;
};

export const reduce_get_withdraws = createReducer(
  {
    [get_withdraws]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_withdraws_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      // if (!newState.same) {
      newState.withdrawslist = newState.withdrawslist.concat(payload.data.withdraws);
      // }

      newState.isLoading = false;
      return newState;
    },
    [get_withdraws_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [get_withdraws_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_withdraws_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      const withdraw = payload.data.withdraw;
      const withdraws = state.withdraws;
      const withdrawslist = state.withdrawslist;

      if (withdraws) newState.withdraws = forupdate(withdraws, withdraw, "wallet_id");
      if (withdrawslist) newState.withdrawslist = forupdate(withdrawslist, withdraw, "wallet_id");

      newState.isLoading = false;

      return newState;
    },
    [get_withdraws_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
    withdraw: {},
    withdraws: [],
    withdrawslist: [],
    count: 0,
  }
);

export const reduce_post_withdraws = createReducer(
  {
    [post_withdraws]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_withdraws_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_withdraws_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_withdraws_id = createReducer(
  {
    [put_withdraws_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_withdraws_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_withdraws_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_withdraws_id = createReducer(
  {
    [delete_withdraws_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_withdraws_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [delete_withdraws_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);


export const reduce_put_withdraws_id_favorite = createReducer(
  {
    [put_withdraws_id_favorite]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_withdraws_id_favorite_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_withdraws_id_favorite_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export default {
  reduce_get_withdraws,
  reduce_post_withdraws,
  reduce_put_withdraws_id,
  reduce_delete_withdraws_id,

};
