import React, { FC, useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getSearch, setSearch } from "config/util";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { get_collections } from "stores/Collections/actions";
import { get_items } from "stores/Items/actions";
import { get_users } from "stores/Users/actions";
import { ChainLink } from "components/ChainLink";

import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import socketIOClient from "socket.io-client";
import env from "../../stores/env";

let socket = null;

const App = ({ branch, children }) => {
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const maps = useSelector((state) => state.reduce_get_maps);
  const datalist_collections = useSelector((state) => state.reduce_get_collections.collections);

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const [type, set_type] = React.useState("collections");
  const [word, set_word] = React.useState(sessionStorage.getItem("SearchWord"));

  const [propsCollections, set_propsCollections] = useState({
    search: {
      active_flg: true,
      page: 1,
      limit: 12,
      // sort: [["updated_at", "DESC"]],
      ...branch?.search,
    },
  });

  const dispatch_get_collections = (data, next, prev) => {
    dispatch(
      get_collections({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_collections = (search, page = 1) => {
    dispatch_get_collections({
      search: { ...search, page },
    });
    set_propsCollections({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    set_get_collections({ ...propsCollections.search, name: word });
  }, []);

  const [propsItems, set_propsItems] = useState({
    search: {
      active_flg: true,
      page: 1,
      limit: 10,
      // sort: [["items_detail","updated_at", "DESC"]],
      // sort: [["updated_at", "DESC"]],
      sale_types: [],
      sort_type: "item_id",

      ...branch?.search,
    },
  });

  const dispatch_get_items = (data, next, prev) => {
    dispatch(
      get_items({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_items = (search, page = 1) => {
    dispatch_get_items({
      search: { ...search, page },
    });
    set_propsItems({
      search: { ...search, page },
    });
  };

  const [propsUsers, set_propsUsers] = useState({
    search: {
      active_flg: true,
      followings: branch.followings,
      followers: branch.followers,
      page: 1,
      limit: 12,
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_users = (data, next, prev) => {
    dispatch(
      get_users({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_users = (search, page = 1) => {
    dispatch_get_users({
      search: { ...search, page },
    });
    set_propsUsers({
      search: { ...search, page },
    });
  };

  const set_get = (name, w) => {
    if (name == "collections") {
      set_get_collections({ ...propsCollections.search, name: w });
      // history.push(`/collections`);
    }
    if (name == "items") {
      set_get_items({ ...propsItems.search, name: w });
      // history.push(`/items`);
    }
    if (name == "users") {
      set_get_users({ ...propsUsers.search, name: w });
      // history.push(`/users`);
    }
  };

  const dispatch_type = (name) => {
    set_type(name);
    set_get(name, word);
  };

  const newchildren = React.cloneElement(children, {
    Collections: {
      propsCollections,
      set_propsCollections,
      dispatch_gets: set_get_collections,
      branch,
      list: "explore",
      dispatch_type,
      type,
    },
    Items: {
      propsItems,
      set_propsItems,
      dispatch_gets: set_get_items,
      branch,
      list: "user",
      dispatch_type,
      type,
    },
    Users: {
      propsUsers,
      set_propsUsers,
      dispatch_gets: set_get_users,
      branch,
      list: "item",
      dispatch_type,
      type,
    },
    Page: {
      type,
      dispatch_type,
      word,
      set_word: (w) => {
        set_word(w);
        set_get(type, w);
      },
    },
  });

  return (
    <>
      <Helmet>
        <title>tomonity</title>
      </Helmet>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      {newchildren}
    </>
  );
};

export default App;
