import React, { FC, useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { getSearch, setSearch } from "config/util";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import { ChainLink } from "components/ChainLink";
import { get_activities } from "stores/Activities/actions";

import { get_withdraws, post_withdraws, delete_withdraws_id } from "stores/Withdraws/actions";

import {
  post_wallets_id_withdraws_id_withdraw,
  post_wallets_id_deposit,
} from "stores/Wallets/actions";

const App = ({ branch, children }) => {
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const maps = useSelector((state) => state.reduce_get_maps);
  const user_id = useSelector((state) => state.reduce_get_accounts.user?.user_id);

  const [propsWithdraws, set_propsWithdraws] = useState({
    search: {
      page: 1,
      limit: 10,
      sort: [],
      ...branch?.search,
    },
  });

  const [propsMyWallet, set_propsMyWallet] = useState({
    search: {
      page: 1,
      limit: 10,
      sort: [],
      ...branch?.search,
      // ...propsSearch?.search,
    },
  });

  const dispatch_get_withdraws = (data, next, prev) => {
    dispatch(
      get_withdraws({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_post_withdraws = (data, next, prev) => {
    dispatch(
      post_withdraws({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_post_wallets_id_withdraws_id_withdraw = (data, next, prev) => {
    dispatch(
      post_wallets_id_withdraws_id_withdraw({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_post_wallets_id_deposit = (data, next, prev) => {
    dispatch(
      post_wallets_id_deposit({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_delete_withdraws_id = (data, next, prev) => {
    dispatch(
      delete_withdraws_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_withdraws = (search, page = 1) => {
    dispatch_get_withdraws({
      search: { ...search, page },
    });
    set_propsWithdraws({
      search: { ...search, page },
    });
  };

  const [propsActivities, set_propsActivities] = useState({
    search: {
      page: 1,
      limit: 10,
      user_id,
      type: [40, 41, 42, 43, 44, 45],
      sort: [["created_at", "DESC"]],
      sort_key: [0],
      ...branch?.search,
    },
  });

  const dispatch_get_activities = (data, next, prev) => {
    dispatch(
      get_activities({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };
  const set_get_activities = (search, page = 1) => {
    dispatch_get_activities({
      search: { ...search, page },
    });
    set_propsActivities({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    set_get_activities(propsActivities.search);
  }, []);

  useEffect(() => {
    if (user_id) {
      set_propsActivities({ search: { ...propsActivities.search, user_id } });
    } else {
      history.push({
        pathname: `/signin`,
      });
    }
  }, [user_id]);

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  useEffect(() => {}, []);

  const newchildren = React.cloneElement(children, {
    MyWallet: {
      propsMyWallet,
      set_propsMyWallet,
      branch,
    },
    Activities: {
      propsActivities,
      set_propsActivities,
      dispatch_gets: set_get_activities,
      list: "wallet",
      branch,
    },
    Withdraws: {
      propsWithdraws,
      set_propsWithdraws,
      dispatch_post_withdraws,
      dispatch_delete_withdraws_id,
      dispatch_post_wallets_id_withdraws_id_withdraw,
      dispatch_post_wallets_id_deposit,
      dispatch_gets: set_get_withdraws,
      branch,
    },
  });

  return (
    <>
      <Helmet>
        <title>tomonity</title>
      </Helmet>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      {newchildren}
    </>
  );
};

export default App;
