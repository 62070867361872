import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { view_item } from "../../stores/Items/view";

export default function Contents(Item, Activities) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const maps = useSelector((state) => state.reduce_get_maps);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const item = useSelector((state) => state.reduce_get_items.item);
  const v_item = view_item(item, maps, t);

  return (
    <>
      <div className="row g-3 mb-2 mb-lg-4 d-md-none">
        <div className="col overflow-hidden">
          <h3 className="h6 mb-1 mb-md-2">{t("オーナー")}</h3>
          <div className="media btn btn-white text-start px-0 position-relative">
            <div className="media-object">
              <div
                className={v_item.owner_collabo_flg ? "mask-hex icon icon-pill" : "icon icon-pill"}
              >
                <img
                  src={v_item.owner_icon_uri}
                  className="icon-item"
                  width="100%"
                  height="auto"
                  alt=""
                />
              </div>
            </div>
            <div className="media-body text-truncate overflow-hidden">
              <a
                className="stretched-link"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/users/${v_item.owner_id}`);
                }}
              >
                {v_item.owner_name}
              </a>
              <span className="d-block text-muted text-truncate">@{v_item.owner_nmid}</span>
            </div>
          </div>
        </div>
        <div className="col overflow-hidden">
          <h3 className="h6 mb-1 mb-md-2">{t("クリエイター")}</h3>
          <div className="media btn btn-white text-start px-0 position-relative">
            <div className="media-object">
              {/* <div className="icon icon-pill verified"> */}
              <div
                className={
                  v_item.creator_collabo_flg ? "mask-hex icon icon-pill" : "icon icon-pill"
                }
              >
                <img
                  src={v_item.creator_icon_uri}
                  className="icon-item"
                  width="100%"
                  height="auto"
                  alt=""
                />
              </div>
            </div>
            <div className="media-body text-truncate overflow-hidden">
              <a
                className="stretched-link"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/users/${v_item.creator_id}`);
                }}
              >
                {/* <div className="d-flex verified-text"> */}
                <div className="d-flex">
                  <span className="text-truncate">{v_item.creator_name}</span>
                </div>
              </a>
              <span className="d-block text-muted text-truncate">@{v_item.creator_nmid}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
