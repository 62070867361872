import React, { FC, useEffect, useRef, useState } from "react";
import moment from "moment/moment";

import axios from "../stores/axios";
import queryString from "query-string";

export const toValueLabel = (obj) => {
  let ary = [];
  if (obj) {
    // ary = Object.entries(obj).map(([key, value]) => ({ key, value }));

    // for (var key in Object.keys(obj)) {
    for (var key in obj) {
      // const o = obj[Number(key)];
      const o = obj[key];
      ary.push({ value: key, label: o });
    }
  } else {
  }

  return ary;
};

export const toMomentString = (obj, formatStr) => {
  //   if (moment.isMoment(obj)) {
  //     return obj.format(formatStr);
  //   }
  if (obj) {
    const mom = moment(obj);
    if (mom.isValid()) {
      return mom.format(formatStr);
    }
  }

  return "";
};

export const getMessage = (error) => {
  let message = "";

  if (error.response) {
    const data = error.response.data;
    if (data == "Unauthorized") {
      // return "ログイン画面へ遷移します";
      return "";
    }

    if (data) {
      if (data.errors) {
        if (Object.keys(data.errors).length == 0) {
          message = data.message;
        } else {
          let err1 = data.errors[Object.keys(data.errors)[0]];
          message = err1;
        }
      } else {
        message = data.message;
      }
    }
  } else {
    message = error.message;
  }
  return message;
};

export const toRound = (num, n) => {
  const a = Math.round(num * Math.pow(10, n)) / Math.pow(10, n);
  return a;
};

export const toRoundFixed = (num, n) => {
  const a = Math.round(num * Math.pow(10, n)) / Math.pow(10, n);
  return a.toFixed(n);
};

export const ObjectKeys = (object) => {
  // if (Array.isArray(object) && object.length==0) return []

  // const options = Object.keys(object).map((x) => Number(x));
  // const options = Object.keys(object).map((x) => convertInt(x));
  const options = Object.keys(object);

  // const options = Object.keys(object) as (keyof typeof object)[]

  // for( var item in object ) {

  //     console.log( item );

  // }

  // Object.keys(obj).map(Number);
  // const options = Object.keys(object) as Array<keyof typeof object>;
  return options;
};

export const ObjectKeysInt = (object) => {
  const options = Object.keys(object).map((x) => convertInt(x));
  return options;
};

const convertInt = (key) => {
  const intKey = parseInt(key);
  if (isNaN(intKey)) return key;
  return intKey;
};

export const moment2countdown = (obj) => {
  if (!obj) {
    return {};
  }

  // diffメソッドを使って、日時の差を、ミリ秒で取得
  const diff = moment(obj).diff(moment());

  // ミリ秒からdurationオブジェクトを生成
  const duration = moment.duration(diff);

  // 日・時・分・秒を取得
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export const countdown2label = (countdown, t) => {
  const { days, hours, minutes, seconds } = countdown;

  if (countdown.days < 0) {
    if (countdown.days == -1) {
      if (hours == 0) {
        if (minutes == 0) {
          return `${seconds * -1} ${t("秒前")}`;
        } else {
          return `${minutes * -1} ${t("分前")}`;
        }
      } else {
        return `${hours * -1} ${t("時間前")}`;
      }
    } else {
      return `${days * -1} ${t("日前")}`;
    }
  } else {
    if (days == 0 && hours == 0 && minutes == 0) {
      return `${t("あと")} ${seconds} ${t("秒")}`;
    }
    if (days == 0 && hours == 0) {
      return `${t("あと")} ${minutes} ${t("分")}`;
    }
    if (days == 0) {
      return `${t("あと")} ${hours} ${t("時間")}`;
    }

    if (days >= 365) {
      return `${t("あと")} ${Math.floor(days / 365)} ${t("年")}`;
    }

    if (days >= 30) {
      return `${t("あと")} ${Math.floor(days / 30)} ${t("ヶ月")}`;
    }

    return `${t("あと")} ${days} ${t("日")}`;
  }
};

export const moment2obj = (obj) => {
  if (!obj) {
    return {};
  }

  const m = moment(obj);

  return {
    dateString: toMomentString(obj, "YYYY/MM/DD"),
    timeString: toMomentString(obj, "HH:mm:ss"),
    monthString: toMomentString(obj, "MMMM"),
    ampmString: toMomentString(obj, "h:ma"),
    year: m.year(), // 年
    month: m.month(), // 月 ※ 0〜11の値!
    date: m.date(), // 日
    day: m.day(), // 曜日
    hours: m.hours(), // 時
    minutes: m.minutes(), // 分
    seconds: m.seconds(), // 秒
  };
};

// export const toNumber = (num, n) => {
//   try {
//     if (!num) return <></>;

//     const a = Math.round(num * Math.pow(10, n)) / Math.pow(10, n);

//     const b = a.toFixed(n);
//     const declist = b.split(".")[1].split("");

//     let decindex = 0;

//     const rev = declist.concat().reverse();
//     for (const i in rev) {
//       if (rev[i] != 0) {
//         decindex = rev.length - i;
//         break;
//       }
//     }

//     return (
//       <>
//         {b.split(".")[0]}
//         {declist.every((d) => d == 0) ? " " : "."}
//         {declist.map((_, idx) => {
//           if (idx >= decindex) {
//             return <span style={{ color: "transparent" }}>{_}</span>;
//           } else {
//             return <span>{_}</span>;
//           }
//         })}
//       </>
//     );
//   } catch (error) {
//     console.log(error);
//   }
// };

export const toNumber = (num, n) => {
  if (num == 0) return 0;
  if (!num) return "";
  if (num === "　") return "　";
  const number = Number(num);

  if (!isNumber(number)) return "";

  if (n | (n == 0)) {
    /* 切り上げ */
    const ceil = Math.ceil(number * Math.pow(10, n)) / Math.pow(10, n);
    /* 切り捨て */
    const floor = Math.floor(number * Math.pow(10, n)) / Math.pow(10, n);
    // 四捨五入
    const round = Math.round(number * Math.pow(10, n)) / Math.pow(10, n);

    return floor.toLocaleString(undefined, { maximumFractionDigits: n });
  }
  return Number(num).toLocaleString(undefined, { maximumFractionDigits: 20 });
};

export const toKM = (num) => {
  if (num == 0) return 0;
  if (!num) return "";
  if (num === "　") return "　";
  const number = Number(num);

  if (!isNumber(number)) return "";

  if (number > 1000000) {
    return `${Math.floor(number / 100000) / 10}M`;
  }

  if (number > 1000) {
    // return `${Math.floor(number / 1000)}K`;
    return `${Math.floor(number / 100) / 10}K`;
  }

  return `${Math.floor(number * 100) / 100}`;
  // return `${number}`;
};

// 丸めて欲しくない場合には、maximumFractionDigitsオプションを使用します。
// var num = 12345.6789;
// num.toLocaleString( undefined, { maximumFractionDigits: 20 });  // "12,345.6789"

function comma(num) {
  return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

const isNumber = function(value) {
  return typeof value === "number" && isFinite(value);
};

const toUnderLevel = (path, mime_type, level) => {
  if (!path) return path;

  switch (mime_type) {
    // case "image/jpeg":
    // case "image/png":
    // case "image/gif":
    case "audio/mpeg":
    case "video/mp4":
      return path;
  }

  const filename_dot_extend = path.split("/").reverse()[0];
  var extend = filename_dot_extend.split(".")[1];
  const newpath = path.replace(`.${extend}`, `_${level}.${extend}`);
  return newpath;
};

export const toLowUrl = (path, mime_type) => {
  return toUnderLevel(path, mime_type, "low");
};

export const toHighUrl = (path, mime_type) => {
  return toUnderLevel(path, mime_type, "high");
};

export const createBr = (text) => {
  if (text) {
    // if (sessionStorage.getItem("AcceptLanguage") == "en") {
    //   text = translate(text);
    // }

    const brphase = text.split(/(\n)/).map((x) => {
      return <>{x.match(/\n/) ? <br /> : x}</>;
    });

    return brphase;
  } else {
    return <></>;
  }
};

// export const translate = (text) => {
//   const options = {
//     method: "get",
//     url: `https://api-free.deepl.com/v2/translate`,
//     headers: {
//       "Content-Type": "application/json",
//     },
//     params: {
//       auth_key: "220d9b9c-79b8-9668-0ced-805c55bea310",
//       text,
//       target_lang: "EN",
//     },
//   };
//   const result = axios(options);
//   const res = result.data.translations[0]?.text;
//   return res;
// };

// const translate = async (word) => {
//   word = encodeURI(word);
//   const url = `https://api-free.deepl.com/v2/translate?auth_key=220d9b9c-79b8-9668-0ced-805c55bea310:fx&text=${word}&target_lang=EN`;
//   const result = await Axios(url);
//   return result.data.translations[0]?.text;
// };

// data.description2 = await translate(data.description);
// if (data.metadata.profiles[0]) {
//   data.metadata.profiles[0].description2 = await translate(
//     data.metadata.profiles[0].description
//   );
// }

// if (data.metadata.profiles[1]) {
//   data.metadata.profiles[1].description2 = await translate(
//     data.metadata.profiles[1].description
//   );
// }

export const zeroPadding = (NUM, LEN) => {
  return (Array(LEN).join("0") + NUM).slice(-LEN);
};

export const o2akv = (obj) => {
  if (!obj) return [];
  const ary = Object.entries(obj).map(([key, value]) => ({ key, value }));
  return ary;
};

export const getSearch = (ls) => {
  if (ls) {
    const qs = queryString.parse(ls);
    // const qs = new URLSearchParams(search);
    if (qs.search) {
      const search = JSON.parse(qs.search);
      return { search };
    }
  }
};

export const setSearch = (search, history) => {
  if (search) {
    const js = JSON.stringify(search);
    // const qs = queryString.stringify(propsExams.search);
    const params = new URLSearchParams();
    params.append("search", js);
    history.push({ search: params.toString() });
  }
};

export const hideModal = (id) => {
  const element = document.getElementById(id);
  const modal = window.bootstrap.Modal.getInstance(element);
  if (modal) {
    modal.hide();
  }
};

export const showModal = (id) => {
  const element = document.getElementById(id);
  const modal =
    window.bootstrap.Modal.getInstance(element) ?? new window.bootstrap.Modal(element, {});
  if (modal) {
    modal.show();
  }
};

export const toggleAry = (ary, val) => {
  if (ary.includes(val)) {
    return ary.filter((x) => x != val);
  } else {
    ary.push(val);
    return ary;
  }
};
