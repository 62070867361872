import { fork, take, put, join, select, takeLatest, takeEvery, call } from "redux-saga/effects";

import { post_authout } from "../Auth/actions";

import {
  api_get_activities,
  api_get_activities_id,
  api_post_activities,
  api_put_activities_id,
  api_delete_activities_id,
} from "./api";

import {
  get_activities,
  get_activities_data,
  get_activities_error,
  get_activities_id,
  get_activities_id_data,
  get_activities_id_error,
  post_activities,
  post_activities_data,
  post_activities_error,
  put_activities_id,
  put_activities_id_data,
  put_activities_id_error,
  delete_activities_id,
  delete_activities_id_data,
  delete_activities_id_error,
} from "./actions";

export function* run_get_activities({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_activities, payload.data, token);
  if (!error) {
    yield put(get_activities_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_activities_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_activities_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_activities_id, payload.data, token);
  if (!error) {
    yield put(get_activities_id_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_activities_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_activities({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_activities, payload.data, token);
  if (!error) {
    yield put(post_activities_data({ data }));
    yield put(get_activities(payload));
  } else {
    yield put(post_activities_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_activities_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_activities_id, payload.data, token);
  if (!error) {
    yield put(put_activities_id_data({ data }));
    yield put(get_activities_id(payload));
  } else {
    yield put(put_activities_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_activities_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_delete_activities_id, payload.data, token);
  if (!error) {
    yield put(delete_activities_id_data({ data }));
    yield put(get_activities(payload));
  } else {
    yield put(delete_activities_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* flow_get_activities() {
  yield takeLatest(get_activities, run_get_activities);
}
export function* flow_get_activities_id() {
  yield takeLatest(get_activities_id, run_get_activities_id);
}
export function* flow_post_activities() {
  yield takeLatest(post_activities, run_post_activities);
}
export function* flow_put_activities_id() {
  yield takeLatest(put_activities_id, run_put_activities_id);
}
export function* flow_delete_activities_id() {
  yield takeLatest(delete_activities_id, run_delete_activities_id);
}

export default function* rootSaga() {
  yield fork(flow_get_activities);
  yield fork(flow_get_activities_id);
  yield fork(flow_post_activities);
  yield fork(flow_put_activities_id);
  yield fork(flow_delete_activities_id);
}
