import React, { FC, useEffect, useRef, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import { initReactI18next, useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomLoading from "components/CustomLoading/CustomLoading.js";
import { DisplayContext } from "../../Navigator/Router_material";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { getMessage, toMomentString } from "config/util";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import moment from "moment";

import { getSearch, setSearch } from "config/util";
import MainFixed from "../../components/CustomLoadingScreen/MainFixed";

export default function Dashboard(TopCollections) {
  const { propsTopCollections, set_propsTopCollections, dispatch_gets, type } = TopCollections;
  const history = useHistory();
  const dispatch = useDispatch();

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if (!message) return;
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const location_search = useLocation().search;

  const datalist_collections = useSelector((state) => state.reduce_get_top_collections.collections);
  const count_collections = useSelector((state) => state.reduce_get_top_collections.count);
  const loading_collections = useSelector((state) => state.reduce_get_top_collections.isLoading);

  let datalist = datalist_collections;
  let count = count_collections;
  let loading = loading_collections;

  useEffect(() => {
    // dispatch_gets(propsCollections.search, 1);
  }, []);

  const [t, i18n] = useTranslation();

  const theme = useTheme();
  const primary = theme.palette.primary.main;

  const { windowHeight, tableHeight } = useContext(DisplayContext);

  const location = useLocation();
  const nonce = location.nonce;
  const [type_button, set_type_button] = useState("all");

  const [mount, set_mount] = React.useState(true);
  const [load, set_load] = useState(true);
  const ref_load = useRef(load);

  useEffect(() => {
    ref_load.current = load;
  }, [load]);

  useEffect(() => {
    if (ref_load.current) {
      set_load(loading);
    }
  }, [loading]);

  useEffect(() => {
    set_mount(false);
    set_load(true);
  }, [nonce]);

  useEffect(() => {
    set_mount(true);
  }, [mount]);

  if (!mount) {
    return <></>;
  }

  if (!datalist) {
    return <></>;
  }

  const height = tableHeight;

  return (
    <>
      {/* <MainFixed loading={loading && ref_load.current} /> */}
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      <div className="dropdown">
        <button
          className="btn btn-link btn-lg dropdown-toggle ms-2 pe-0"
          type="button"
          id="topCollections"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="h3">
            {type_button == "day"
              ? t("24時間")
              : type_button == "week"
              ? t("1週間")
              : type_button == "month"
              ? t("30日間")
              : type_button == "all"
              ? t("すべて")
              : ""}
          </span>
        </button>
        <ul className="dropdown-menu" aria-labelledby="topCollections">
          <li>
            <button
              set_type_button
              className={`dropdown-item ${type_button == "day" && "active"}`}
              type="button"
              onClick={() => {
                set_type_button("day");
                dispatch_gets({
                  ...propsTopCollections.search,
                  from: moment()
                    .add(-1, "d")
                    .format(),
                  to: moment().format(),
                });
              }}
            >
              {t("24時間")}
            </button>
          </li>
          <li>
            <button
              className={`dropdown-item ${type_button == "week" && "active"}`}
              type="button"
              onClick={() => {
                set_type_button("week");
                dispatch_gets({
                  ...propsTopCollections.search,
                  from: moment()
                    .add(-1, "w")
                    .format(),
                  to: moment().format(),
                });
              }}
            >
              {t("1週間")}
            </button>
          </li>
          <li>
            <button
              className={`dropdown-item ${type_button == "month" && "active"}`}
              type="button"
              onClick={() => {
                set_type_button("month");
                dispatch_gets({
                  ...propsTopCollections.search,
                  from: moment()
                    .add(-1, "M")
                    .format(),
                  to: moment().format(),
                });
              }}
            >
              {t("30日間")}
            </button>
          </li>
          <li>
            <button
              className={`dropdown-item ${type_button == "all" && "active"}`}
              type="button"
              onClick={() => {
                set_type_button("all");
                dispatch_gets({
                  ...propsTopCollections.search,
                  from: moment()
                    .add(-1, "Y")
                    .format(),
                  to: moment().format(),
                });
              }}
            >
              {t("すべて")}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}
