import React from "react";
import ReactDOM from "react-dom";

import { AppStore } from "./Provider/Store";
// import AppProvider from "./Test/material-ui_pickers";
// import AppStore from "./Test/material-ui_pickers";
// import AppStore from './Test/demo2'
// import AppStore from './Test/janus'

// import "bootstrap/dist/css/bootstrap.css";
// import "perfect-scrollbar/css/perfect-scrollbar.css";

import "assets/scss/chaintier.scss?v=1.8.0";
// import "assets/scss/common.scss?v=1.1.0";
// import "assets/scss/component.scss?v=1.1.0";

import "react-app-polyfill/ie11";
// import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";

import "lightgallery.js/dist/css/lightgallery.css";
import "lightgallery.js/dist/css/lg-transitions.css";
// import { Font } from "@react-pdf/renderer";
// require('react-web-vector-icons/fonts');
// Font.register(require("./Font/Nasu-Regular.ttf"), {
//   family: "Nasu-Regular",
// });

// Font.register(require("./Font/Nasu-Regular.ttf"), {
//   family: "Nasu-Bold",
// });

ReactDOM.render(<AppStore />, document.getElementById("root"));
