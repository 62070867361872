import { cardTitle, successColor, dangerColor } from "assets/jss/chaintier.js";
import customCheckboxRadioSwitch from "assets/jss/chaintier/customCheckboxRadioSwitch.js";
import { grayColor } from "assets/jss/chaintier.js";

const regularFormsStyle = {
  ...customCheckboxRadioSwitch,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  staticFormGroup: {
    marginLeft: "0",
    marginRight: "0",
    paddingBottom: "10px",
    margin: "8px 0 0 0",
    position: "relative",
    "&:before,&:after": {
      display: "table",
      content: '" "',
    },
    "&:after": {
      clear: "both",
    },
  },
  staticFormControl: {
    marginBottom: "0",
    paddingTop: "8px",
    paddingBottom: "8px",
    minHeight: "34px",
  },
  inputAdornment: {
    marginRight: "8px",
    position: "relative",
  },
  inputAdornmentIconSuccess: {
    color: successColor[0] + "!important",
  },
  inputAdornmentIconError: {
    color: dangerColor[0] + "!important",
  },
  center: {
    textAlign: "center",
  },
  centerVertical: {
    // flexDirection: 'column',
    display:"flex",
    alignItems: "flex-end",
    // justifyContent: "flex-end",
    height: "100%",
  },

  modalCloseButton: {
    color: grayColor[0],
    marginTop: "-12px",
    WebkitAppearance: "none",
    padding: "0",
    cursor: "pointer",
    background: "0 0",
    border: "0",
    fontSize: "inherit",
    opacity: ".9",
    textShadow: "none",
    fontWeight: "700",
    lineHeight: "1",
    float: "right",
  },
  modalClose: {
    width: "16px",
    height: "16px",
  },
};

export default regularFormsStyle;
