import { combineReducers } from "redux";
import { createAction, createReducer } from "redux-act";

import {
  get_activities,
  get_activities_data,
  get_activities_error,
  get_activities_id,
  get_activities_id_data,
  get_activities_id_error,
  post_activities,
  post_activities_data,
  post_activities_error,
  put_activities_id,
  put_activities_id_data,
  put_activities_id_error,
  delete_activities_id,
  delete_activities_id_data,
  delete_activities_id_error,
} from "./actions";

const forupdate = (array, unit, id) => {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element[id] === unit[id]) {
      array[index] = unit;
    }
  }
  return array;
};

export const reduce_get_activities = createReducer(
  {
    [get_activities]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      if (!payload.data.search) {
        newState.activitieslist = [];
      } else if (payload.data.search.page == 1) {
        newState.activitieslist = [];
        // newState.same = false;
        // newState.page = 1;
      } else {
        // newState.same = newState.page == payload.data.search.page;
        // newState.page = payload.data.search.page;
      }
      newState.isLoading = true;
      return newState;
    },
    [get_activities_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      // if (!newState.same) {
      newState.activitieslist = newState.activitieslist.concat(payload.data.activities);
      // }

      newState.isLoading = false;
      return newState;
    },
    [get_activities_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [get_activities_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_activities_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      const activity = payload.data.activity;
      const activities = state.activities;
      const activitieslist = state.activitieslist;

      if (activities) newState.activities = forupdate(activities, activity, "activity_id");
      if (activitieslist) newState.activitieslist = forupdate(activitieslist, activity, "activity_id");
      newState.isLoading = false;

      return newState;
    },
    [get_activities_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
 isLoading: false,
    activity: {},
    activities: [],
    activitieslist: [],
    count: 0,
  }
);

export const reduce_post_activities = createReducer(
  {
    [post_activities]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_activities_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_activities_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_activities_id = createReducer(
  {
    [put_activities_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_activities_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_activities_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_activities_id = createReducer(
  {
    [delete_activities_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_activities_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [delete_activities_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export default {
  reduce_get_activities,
  reduce_post_activities,
  reduce_put_activities_id,
  reduce_delete_activities_id,
};
