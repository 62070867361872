import { createAction, createReducer } from 'redux-act';

export const get_items = createAction("GET_ITEMS");
export const get_items_data = createAction("GET_ITEMS_DATA");
export const get_items_error = createAction("GET_ITEMS_ERROR");
export const get_items_id = createAction("GET_ITEMS_ID");
export const get_items_id_data = createAction("GET_ITEMS_ID_DATA");
export const get_items_id_error = createAction("GET_ITEMS_ID_ERROR");
export const post_items = createAction("POST_ITEMS");
export const post_items_data = createAction("POST_ITEMS_DATA");
export const post_items_error = createAction("POST_ITEMS_ERROR");
export const put_items_id = createAction("PUT_ITEMS_ID");
export const put_items_id_data = createAction("PUT_ITEMS_ID_DATA");
export const put_items_id_error = createAction("PUT_ITEMS_ID_ERROR");
export const delete_items_id = createAction("DELETE_ITEMS_ID");
export const delete_items_id_data = createAction("DELETE_ITEMS_ID_DATA");
export const delete_items_id_error = createAction("DELETE_ITEMS_ID_ERROR");

export const post_orders = createAction("POST_ORDERS");
export const post_orders_data = createAction("POST_ORDERS_DATA");
export const post_orders_error = createAction("POST_ORDERS_ERROR");

export const post_send = createAction("POST_SEND");
export const post_send_data = createAction("POST_SEND_DATA");
export const post_send_error = createAction("POST_SEND_ERROR");

export const put_items_id_favorite = createAction("PUT_ITEMS_ID_FAVORITE");
export const put_items_id_favorite_data = createAction("PUT_ITEMS_ID_FAVORITE_DATA");
export const put_items_id_favorite_error = createAction("PUT_ITEMS_ID_FAVORITE_ERROR");