import moment from "moment/moment";
import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  zeroPadding,
  toNumber,
  createBr,
} from "config/util";
import { view_collection } from "../../stores/Collections/view";

export const view_top_collection = (prop, maps, t) => {
  const now = moment();

  let resView = {
    ...prop,
  };

  const collection = prop.collection;

  if (collection) {
    resView = { ...resView, ...view_collection(collection, maps, t) };
  }

  if (maps) {
    const coin_decimals = maps.coin_decimals;
    resView.item_sale_price_usd_label = toNumber(prop.item_sale_price_usd, coin_decimals["USDT"]);
    resView.item_order_price_usd_label = toNumber(prop.item_order_price_usd, coin_decimals["USDT"]);
    resView.min_price_usd_label = toNumber(prop.min_price_usd, coin_decimals["USDT"]);
    resView.sum_price_usd_label = toNumber(prop.sum_price_usd, coin_decimals["USDT"]);
    resView.percent_sign = Number(prop.percent) >= 0;
    resView.percent_label =
      Number(prop.percent) >= 0 ? Number(prop.percent) : -1 * Number(prop.percent);
  }

  return resView;
};

export const view_top_item = (prop, maps, t) => {
  const now = moment();

  const resView = {
    ...prop,
  };

  const item = prop.item;

  if (item) {
    resView.item_cover_uri = item.item_cover_uri;
    resView.item_icon_uri = item.item_icon_uri;
    resView.item_description = item.item_description;
    resView.item_description_br = createBr(item.item_description);

    resView.category_name = item.category_name;
    resView.item_id = item.item_id;
    resView.item_name = item.item_name;
  }

  if (maps) {
    const coin_decimals = maps.coin_decimals;
    resView.item_sale_price_usd_label = toNumber(prop.item_sale_price_usd, coin_decimals["USDT"]);
    resView.item_order_price_usd_label = toNumber(prop.item_order_price_usd, coin_decimals["USDT"]);
    resView.min_price_usd_label = toNumber(prop.min_price_usd, coin_decimals["USDT"]);
    resView.sum_price_usd_label = toNumber(prop.sum_price_usd, coin_decimals["USDT"]);
    resView.percent_sign = Number(prop.percent) > 0;
    resView.percent_label =
      Number(prop.percent) > 0 ? Number(prop.percent) : -1 * Number(prop.percent);
  }

  return resView;
};

export const view_top_seller = (prop, maps, t) => {
  const now = moment();

  let resView = {
    ...prop,
    user_icon_uri: prop.user_icon_uri ? prop.user_icon_uri : "/dist/tmty/assets/default/icon.webp",
  };

  if (maps) {
    const coin_decimals = maps.coin_decimals;
    resView.item_sale_price_usd_label = toNumber(prop.item_sale_price_usd, coin_decimals["USDT"]);
    resView.item_order_price_usd_label = toNumber(prop.item_order_price_usd, coin_decimals["USDT"]);
    resView.min_price_usd_label = toNumber(prop.min_price_usd, coin_decimals["USDT"]);
    resView.sum_price_usd_label = toNumber(prop.sum_price_usd, coin_decimals["USDT"]);
    resView.percent_sign = Number(prop.percent) > 0;
    resView.percent_label =
      Number(prop.percent) > 0 ? Number(prop.percent) : -1 * Number(prop.percent);
  }

  return resView;
};

export const view_top_buyer = (prop, maps, t) => {
  const now = moment();

  let resView = {
    ...prop,
    user_icon_uri: prop.user_icon_uri ? prop.user_icon_uri : "/dist/tmty/assets/default/icon.webp",
  };

  if (maps) {
    const coin_decimals = maps.coin_decimals;
    resView.item_sale_price_usd_label = toNumber(prop.item_sale_price_usd, coin_decimals["USDT"]);
    resView.item_order_price_usd_label = toNumber(prop.item_order_price_usd, coin_decimals["USDT"]);
    resView.min_price_usd_label = toNumber(prop.min_price_usd, coin_decimals["USDT"]);
    resView.sum_price_usd_label = toNumber(prop.sum_price_usd, coin_decimals["USDT"]);
    resView.percent_sign = Number(prop.percent) > 0;
    resView.percent_label =
      Number(prop.percent) > 0 ? Number(prop.percent) : -1 * Number(prop.percent);
  }

  return resView;
};


export const view_top_collabo = (prop, maps, t) => {
  const now = moment();

  let resView = {
    ...prop,
    user_icon_uri: prop.user_icon_uri ? prop.user_icon_uri : "/dist/tmty/assets/default/icon.webp",
  };

  if (maps) {
    const coin_decimals = maps.coin_decimals;
    resView.item_sale_price_usd_label = toNumber(prop.item_sale_price_usd, coin_decimals["USDT"]);
    resView.item_order_price_usd_label = toNumber(prop.item_order_price_usd, coin_decimals["USDT"]);
    resView.min_price_usd_label = toNumber(prop.min_price_usd, coin_decimals["USDT"]);
    resView.sum_price_usd_label = toNumber(prop.sum_price_usd, coin_decimals["USDT"]);
    resView.percent_sign = Number(prop.percent) > 0;
    resView.percent_label =
      Number(prop.percent) > 0 ? Number(prop.percent) : -1 * Number(prop.percent);
  }

  return resView;
};
