import axios from "../axios";
import env from "../env";


export const api_get_markets_rates = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`markets/rates`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_get_markets_ohlcs = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`markets/ohlcs`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_get_markets_ohlc = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`markets/ohlc`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_get_markets_orders = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`markets/orders`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_get_wallets = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`wallets`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_put_wallets = (data, token) => {
  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`wallets`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_wallets = (data, token) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`wallets`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_put_wallets_id_deposit = (data, token) => {
  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`wallets/${data.id}/deposit`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_wallets_id_staking = (data, token) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`wallets/${data.id}/staking`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};


export const api_delete_wallets_id = (data, token) => {
  const options = {
    method: "delete",
    url: `${env.REACT_APP_API_URL}${`wallets/${data.id}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_markets_exchanges = (data, token) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`markets/exchanges`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  // alert(JSON.stringify(data))
  return axios(options);
};

export const api_put_markets_exchanges_id_cancel = (data, token) => {
  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`markets/exchanges/${data.id}/cancel`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_get_histories_orderings = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`histories/orderings`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_put_histories_orderings_id = (data, token) => {
  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`histories/orderings/${data.id}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_get_histories_orders = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`histories/orders`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_get_histories_transactions = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`histories/transactions`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: data,
  };
  return axios(options);
};


export const api_get_histories_affiliates = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`histories/affiliates`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_get_histories_bids = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`histories/bids`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: data,
  };
  return axios(options);
};


export const api_post_wallets_id_withdraws_id_withdraw = (data, token) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`wallets/${data.id}/withdraws/${data.withdraw_id}/withdraw`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};

export const api_post_wallets_id_deposit = (data, token) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`wallets/${data.id}/deposit`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};