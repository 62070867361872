import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import LiLanguage from "components/Li/LiLanguage";
import LiAccount from "components/Li/LiAccount";
import { getMessage } from "config/util";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";

export default function Contents({ MyItemEdit }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;
  return (
    <>
      <div>
        <Header />
        <div className="container container-main align-items-stretch">
          <div className="sidebar shadow-end d-none d-lg-block">
            <div className="sidebar-sticky">
              <Menu myhome={true} suffix={"-s"} />
            </div>
          </div>
          <main className="main">
            <div action="/items/:item_id" entype="multpart/form-data">
              <div className="main-header">
                <div className="container d-flex justify-content-between align-items-center px-0 pt-3 pb-2 ps-md-3">
                  <h2 className="h4 m-0">{t("アイテムを変更")}</h2>
                  <div>
                    <a
                      className="btn btn-outline-secondary text-dark rounded-pill px-3 me-1 me-1 text-nowrap"
                      href="javascript:history.back()"
                    >
                      <i className="fa-solid fa-xmark" />
                      <span className="d-none d-sm-inline-block"> {t("キャンセル")}</span>
                    </a>
                    <button className="btn btn-primary rounded-pill px-3 text-nowrap">
                      {t("保存")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="ms-md-3 mt-0">
                <div className="row row-cols-1 row-cols-lg-2">
                  <div className="col">
                    <h3 className="h5">{t("基本設定")}</h3>
                    <div className="media mb-2 bg-light p-2">
                      <div className="media-object">
                        <div className="icon icon-square">
                          <img
                            src="/dist/tmty/example/collections/2-1-icon.webp"
                            className="icon-item"
                            width="100%"
                            height="auto"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="media-body overflow-hidden">
                        <p className="m-0 text-truncate"></p>
                        <span className="d-block"></span>
                      </div>
                    </div>
                    <div
                      className="form-file border rounded-2 mb-3"
                      style={{
                        minHeight: "190px",
                      }}
                    >
                      <div className="mb-2 p-2">
                        <span className="text-muted">{t("アイテムのメディア")}</span>
                      </div>
                      <div className="row">
                        <div className="col-4 mx-auto mb-3">
                          <label htmlFor="collectionLogo" className="ratio ratio-1x1 object-item">
                            <img src="/dist/tmty/example/collections/2-1-item-16.webp" alt />
                          </label>
                          <input type="file" id="collectionLogo" />
                        </div>
                      </div>
                      <div className="alert alert-secondary mb-0" role="alert">
                        <dl className="row g-1 mb-0 small">
                          <dt className="col-3 mb-0">{t("対応メディア")}</dt>
                          <dd className="col-9 mb-0">JPG, PNG, GIF, SVG, MP4, WEBM, MP3</dd>
                          <dt className="col-3 mb-0">{t("最大容量")}</dt>
                          <dd className="col-9 mb-0">100 MB</dd>
                        </dl>
                      </div>
                    </div>
                    <div className="form-check form-switch mb-4">
                      {/* <input className="form-check-input" type="checkbox" id="cks-1" />
                      <label className="form-check-label" htmlFor="cks-1">
                        センシティブなメディア
                      </label> */}
                      <span className="ms-1" data-bs-toggle="tooltip" data-bs-html="true" title="">
                        <i className="fa-solid fa-circle-question" />
                      </span>
                    </div>
                    <div className="mb-4">
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          maxLength={40}
                          id="itemName"
                          placeholder="\u30A2\u30A4\u30C6\u30E0\u540D"
                          defaultValue="Let It Go Dog GIF"
                        />
                        <label htmlFor="itemName">{t("アイテム名")}</label>
                      </div>
                      <div className="form-floating mb-2">
                        <textarea
                          type="text"
                          className="form-control"
                          id="collectionDisc"
                          placeholder="\u30A2\u30A4\u30C6\u30E0\u306E\u8AAC\u660E"
                          maxLength={300}
                          style={{
                            height: "200px",
                          }}
                          defaultValue={""}
                        />
                        <label htmlFor="collectionDisc">{t("アイテムの説明")}</label>
                      </div>
                      <div className="form-floating mb-2">
                        <select
                          className="form-select"
                          id="collectionCategory"
                          aria-label="\u30D6\u30ED\u30C3\u30AF\u30C1\u30A7\u30FC\u30F3"
                        >
                          <option>–</option>
                          <option selected>Ethereum</option>
                          <option>Polygon</option>
                          <option>Klaytn</option>
                        </select>
                        <label htmlFor="collectionCategory">{t("ブロックチェーン")}</label>
                      </div>
                      {/* <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="itemURL"
                          placeholder="\u30A2\u30A4\u30C6\u30E0\u306E\u7D39\u4ECBURL"
                          defaultValue="https://www.google.com/search?q=%E3%82%8F%E3%82%93%E3%82%8F%E3%82%93%E3%83%AF%E3%83%B3%E3%83%80%E3%83%BC%E3%83%A9%E3%83%B3%E3%83%89&client=safari&source=hp&ei=Kl5FYurUKZGO-AaN46PAAg&iflsig=AHkkrS4AAAAAYkVsOi-0KsYFJSE-BQTIY_8wMMOJkvtU&oq=%E3%82%8F%E3%82%93%E3%82%8F%E3%82%93&gs_lcp=Cgdnd3Mtd2l6EAMYADIRCAAQgAQQsQMQgwEQsQMQgwEyBQgAEIAEMgsIABCABBCxAxCxAzILCAAQgAQQsQMQsQMyEQgAEIAEELEDEIMBELEDEIMBMhEIABCABBCxAxCDARCxAxCDATIECAAQAzIKCAAQgAQQsQMQBDoNCAAQgAQQsQMQgwEQBDoGCAAQBBADOggIABCABBCxAzoHCAAQgAQQBFAAWPEXYK8eaAVwAHgAgAFWiAG_BZIBATmYAQCgAQGwAQA&sclient=gws-wiz"
                        />
                        <label htmlFor="itemURL">アイテムの紹介URL</label>
                      </div> */}
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-4">
                      <div className="d-flex align-items-center mb-2">
                        <h3 className="h5 m-0">{t("プロパティ")}</h3>
                        <button
                          type="button"
                          className="btn btn-outline-primary rounded-pill btn-sm ms-auto"
                        >
                          {t("プロパティを作成")}
                        </button>
                      </div>
                      <div className="row g-1 mb-2">
                        <div className="col-4">
                          <select
                            className="selectpicker form-control"
                            id="filter-category"
                            data-style="btn-white border"
                            data-live-search="true"
                            data-header=" \u30D7\u30ED\u30D1\u30C6\u30A3\u540D"
                            aria-label="\u30D7\u30ED\u30D1\u30C6\u30A3\u540D"
                            title=""
                          >
                            <option selected>{t("色")}</option>
                            <option>{t("コンセプト")}</option>
                          </select>
                        </div>
                        <div className="col">
                          <select
                            className="selectpicker form-control"
                            id="filter-category"
                            multiple
                            data-style="btn-white border"
                            data-live-search="true"
                            data-header=" \u30D7\u30ED\u30D1\u30C6\u30A3\u60C5\u5831"
                            aria-label="\u30D7\u30ED\u30D1\u30C6\u30A3\u60C5\u5831"
                            title=""
                          >
                            <option>ピンク</option>
                            <option>パープル</option>
                            <option selected>ブルー</option>
                            <option selected>ホワイト</option>
                            <option>グレー</option>
                            <option>ブラック</option>
                          </select>
                        </div>
                        <div className="col-auto">
                          <button
                            type="button"
                            className="btn btn-outline-danger border-0"
                            data-bs-toggle="tooltip"
                            data-bs-html="true"
                            title=""
                          >
                            <i className="fa-solid fa-minus small" />
                          </button>
                        </div>
                      </div>
                      <div className="row g-1 mb-2">
                        <div className="col-4">
                          <select
                            className="selectpicker form-control"
                            id="filter-category"
                            data-style="btn-white border"
                            data-live-search="true"
                            data-header=" \u30D7\u30ED\u30D1\u30C6\u30A3\u540D"
                            aria-label="\u30D7\u30ED\u30D1\u30C6\u30A3\u540D"
                            title=""
                          >
                            <option>色</option>
                            <option selected>コンセプト</option>
                          </select>
                        </div>
                        <div className="col">
                          <select
                            className="selectpicker form-control"
                            id="filter-category"
                            multiple
                            data-style="btn-white border"
                            data-live-search="true"
                            data-header=" \u30D7\u30ED\u30D1\u30C6\u30A3\u60C5\u5831"
                            aria-label="\u30D7\u30ED\u30D1\u30C6\u30A3\u60C5\u5831"
                            title=""
                          >
                            <option>ハロウィン</option>
                            <option selected>クリスマス</option>
                            <option>お正月</option>
                          </select>
                        </div>
                        <div className="col-auto">
                          <button
                            type="button"
                            className="btn btn-outline-danger border-0"
                            data-bs-toggle="tooltip"
                            data-bs-html="true"
                            title=""
                          >
                            <i className="fa-solid fa-minus small" />
                          </button>
                        </div>
                      </div>
                      <div className="text-end mt-3">
                        <button
                          type="button"
                          className="btn btn-outline-primary rounded-pill ms-auto"
                        >
                          <i className="fa-solid fa-plus" />
                          <span className="ps-1">追加</span>
                        </button>
                      </div>
                    </div>
                    <div className="mb-4">
                      <h3 className="h5">予約公開</h3>
                      <p className="text-muted small">
                        ・予約公開の日時が未設定の場合すぐに公開されます。
                        <br />
                        ・コレクションの公開予約日時以前の設定はコレクション公開日時を優先に公開されます。
                      </p>
                      <div className="input-daterange hstack gap-2" id="datepicker">
                        <div className="form-floating w-100">
                          <input
                            type="text"
                            className="form-control"
                            id="itemDateStart"
                            name="start"
                            placeholder="\u516C\u958B\u65E5\u6642"
                          />
                          <label htmlFor="itemDateStart">公開日時</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-end my-4">
                  <button type="button" className="btn btn-white text-danger">
                    <i className="fa-solid fa-trash-can fa-fw me-2" />
                    アイテムを削除
                  </button>
                </div>
              </div>
              <div
                className="modal fade modal-alert py-5"
                id="processDialog"
                data-bs-keyboard="false"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content rounded-4 shadow">
                    <div className="modal-body p-4 text-center">
                      <h5 className="mb-2">確認</h5>
                      <p className="mb-0">入力内容を保存してもいいですか？</p>
                    </div>
                    <div className="modal-footer flex-nowrap p-0">
                      <button
                        type="button"
                        className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0"
                        data-bs-dismiss="modal"
                      >
                        キャンセル
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"
                        data-bs-target="#modalSuccessfully"
                        data-bs-toggle="modal"
                      >
                        <strong>はい</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="modalSuccessfully"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-6 shadow">
                  <div className="modal-body p-md-5">
                    <h2 className="fw-bold mb-0 text-center">アイテムを作成しました!</h2>
                    <div className="my-4 text-center">
                      <h5>アイテムをシェアしよう</h5>
                      <aside className="mb-3" role="link">
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-twitter text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-instagram text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-discord text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-linkedin-in text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fa-brands fa-telegram fa-fw" />
                        </a>
                      </aside>
                    </div>
                    <a
                      className="btn btn-lg rounded-pill btn-primary w-100"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/items/:item_id/sell");
                      }}
                    >
                      今すぐ販売を開始
                    </a>
                    <a
                      className="btn btn-lg rounded-pill btn-outline-primary mt-2 w-100"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/items/:item_id");
                      }}
                    >
                      アイテム詳細へ
                    </a>
                    <a
                      className="btn btn-lg rounded-pill btn-outline-primary mt-2 w-100"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/collections/:collection_id/items");
                      }}
                    >
                      コレクション詳細へ
                    </a>
                    <a
                      className="btn btn-lg rounded-pill btn-link mt-2 w-100"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/items/create");
                      }}
                    >
                      続けてアイテムを作成
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
