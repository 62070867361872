import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import LiLanguage from "components/Li/LiLanguage";
import LiAccount from "components/Li/LiAccount";
import { getMessage } from "config/util";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";

import { view_collection } from "../../stores/Collections/view";
import CustomImage from "./CustomImage";

import { hideModal, showModal } from "config/util";

export default function Contents(Items) {
  const { propsItems, set_propsItems, dispatch_post_nfts } = Items;

  const props = propsItems;
  const set_props = set_propsItems;

  const { collection_id } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection);
  const maps = useSelector((state) => state.reduce_get_maps);

  return (
    <>
      <div
        className="modal fade"
        id="modalSuccessfully"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-6 shadow">
            <div className="modal-body p-md-5">
              <h2 className="fw-bold mb-0 text-center">{t("アイテムを作成しました!")}</h2>
              <div className="row mt-4 mb-3">
                <div className="col-4 mx-auto">
                  <div htmlFor="collectionLogo" className="ratio ratio-1x1 object-item">
                    <img src="/dist/tmty/example/collections/2-1-item-16.webp" alt />
                  </div>
                </div>
              </div>
              {/* <div className="mb-4 text-center">
                <h5>シェアしよう</h5>
                <aside className="mb-3" role="link">
                  <a className="btn btn-dark btn-sm rounded-pill me-2" href="#" target="_blank">
                    <i className="fab fa-twitter text-white fa-fw" />
                  </a>
                  <a className="btn btn-dark btn-sm rounded-pill me-2" href="#" target="_blank">
                    <i className="fab fa-facebook-f text-white fa-fw" />
                  </a>
                  <a className="btn btn-dark btn-sm rounded-pill me-2" href="#" target="_blank">
                    <i className="fab fa-instagram text-white fa-fw" />
                  </a>
                  <a className="btn btn-dark btn-sm rounded-pill me-2" href="#" target="_blank">
                    <i className="fab fa-discord text-white fa-fw" />
                  </a>
                  <a className="btn btn-dark btn-sm rounded-pill me-2" href="#" target="_blank">
                    <i className="fab fa-linkedin-in text-white fa-fw" />
                  </a>
                  <a className="btn btn-dark btn-sm rounded-pill me-2" href="#" target="_blank">
                    <i className="fa-brands fa-telegram fa-fw" />
                  </a>
                </aside>
              </div> */}
              <a
                className="btn btn-lg rounded-pill btn-primary w-100"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/items/:item_id/sell");
                }}
              >
                {t("今すぐ出品する!")}
              </a>
              <a className="btn btn-lg rounded-pill btn-outline-primary mt-2 w-100" href="#">
                {t("アイテム詳細へ")}
              </a>
              <a className="btn btn-lg rounded-pill btn-outline-primary mt-2 w-100" href="#">
                {t("コレクション詳細へ")}
              </a>
              <a className="btn btn-lg rounded-pill btn-link mt-2 w-100" href="#">
                {t("続けてアイテムを作成")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
