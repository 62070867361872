import { fork, takeLatest, takeEvery, put, join, select, call } from "redux-saga/effects";

import { post_authout } from "../Auth/actions";

import {
  api_get_markets_rates,
  api_get_markets_ohlcs,
  api_get_markets_ohlc,
  api_get_markets_orders,
  api_get_wallets,
  api_put_wallets,
  api_post_wallets,
  api_delete_wallets_id,
  api_put_wallets_id_deposit,
  api_post_wallets_id_staking,
  api_post_markets_exchanges,
  api_put_markets_exchanges_id_cancel,
  api_get_histories_orderings,
  api_put_histories_orderings_id,
  api_get_histories_orders,
  api_get_histories_transactions,
  api_get_histories_affiliates,
  api_get_histories_bids,
  api_post_wallets_id_withdraws_id_withdraw,
  api_post_wallets_id_deposit,
} from "./api";

import {
  get_markets_rates,
  get_markets_rates_data,
  get_markets_rates_error,
  get_markets_ohlcs,
  get_markets_ohlcs_data,
  get_markets_ohlcs_error,
  get_markets_ohlc,
  get_markets_ohlc_data,
  get_markets_ohlc_error,
  get_markets_orders,
  get_markets_orders_data,
  get_markets_orders_error,
  get_wallets,
  get_wallets_data,
  get_wallets_error,
  put_wallets,
  put_wallets_data,
  put_wallets_error,
  post_wallets,
  post_wallets_data,
  post_wallets_error,
  put_wallets_id_deposit,
  put_wallets_id_deposit_data,
  put_wallets_id_deposit_error,
  post_wallets_id_staking,
  post_wallets_id_staking_data,
  post_wallets_id_staking_error,
  delete_wallets_id,
  delete_wallets_id_data,
  delete_wallets_id_error,
  post_markets_exchanges,
  post_markets_exchanges_data,
  post_markets_exchanges_error,
  put_markets_exchanges_id_cancel,
  put_markets_exchanges_id_cancel_data,
  put_markets_exchanges_id_cancel_error,
  get_histories_orderings,
  get_histories_orderings_data,
  get_histories_orderings_error,
  put_histories_orderings_id,
  put_histories_orderings_id_data,
  put_histories_orderings_id_error,
  get_histories_orders,
  get_histories_orders_data,
  get_histories_orders_error,
  get_histories_transactions,
  get_histories_transactions_data,
  get_histories_transactions_error,
  get_histories_affiliates,
  get_histories_affiliates_data,
  get_histories_affiliates_error,
  get_histories_bids,
  get_histories_bids_data,
  get_histories_bids_error,
  post_wallets_id_withdraws_id_withdraw,
  post_wallets_id_withdraws_id_withdraw_data,
  post_wallets_id_withdraws_id_withdraw_error,
  post_wallets_id_deposit,
  post_wallets_id_deposit_data,
  post_wallets_id_deposit_error,
} from "./actions";

export function* run_get_markets_rates({ payload }) {
  const { data, error } = yield call(api_get_markets_rates, payload.data);
  if (!error) {
    yield put(get_markets_rates_data({ data }));

    !payload.page || payload.page(data);
  } else {
    yield put(get_markets_rates_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_get_markets_ohlcs({ payload }) {
  const { data, error } = yield call(api_get_markets_ohlcs, payload.data);
  if (!error) {
    yield put(get_markets_ohlcs_data({ data }));

    !payload.page || payload.page(data);
  } else {
    yield put(get_markets_ohlcs_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_get_markets_ohlc({ payload }) {
  const { data, error } = yield call(api_get_markets_ohlc, payload.data);
  if (!error) {
    yield put(get_markets_ohlc_data({ data }));

    !payload.page || payload.page(data);
  } else {
    yield put(get_markets_ohlc_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_get_markets_orders({ payload }) {
  const { data, error } = yield call(api_get_markets_orders, payload.data);
  if (!error) {
    yield put(get_markets_orders_data({ data }));

    !payload.page || payload.page(data);
  } else {
    yield put(get_markets_orders_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_get_wallets({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_wallets, payload.data, token);
  if (!error) {
    yield put(get_wallets_data({ data }));

    !payload.page || payload.page(data);
  } else {
    yield put(get_wallets_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_put_wallets({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_wallets, payload.data, token);
  if (!error) {
    yield put(put_wallets_data({ data }));
    yield put(get_wallets({ data: payload.data.search }));

    !payload.page || payload.page(data);
  } else {
    yield put(put_wallets_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_post_wallets({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_wallets, payload.data, token);
  if (!error) {
    yield put(post_wallets_data({ data }));
    yield put(get_wallets({ data: payload.data.search }));

    !payload.page || payload.page(data);
  } else {
    yield put(post_wallets_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_delete_wallets_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_delete_wallets_id, payload.data, token);
  if (!error) {
    yield put(delete_wallets_id_data({ data }));
    yield put(get_wallets({ data: payload.data.search }));

    !payload.page || payload.page(data);
  } else {
    yield put(delete_wallets_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_post_markets_exchanges({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_post_markets_exchanges, payload.data, token);
  if (!error) {
    yield put(post_markets_exchanges_data({ data }));
    // yield put(get_wallets({ data: payload.data.search }));

    !payload.page || payload.page(data);
  } else {
    yield put(post_markets_exchanges_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_put_markets_exchanges_id_cancel({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_put_markets_exchanges_id_cancel, payload.data, token);
  if (!error) {
    yield put(put_markets_exchanges_id_cancel_data({ data }));
    yield put(get_histories_orderings({ data: payload.data.search }));

    !payload.page || payload.page(data);
  } else {
    yield put(put_markets_exchanges_id_cancel_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_get_histories_orderings({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_histories_orderings, payload.data, token);
  if (!error) {
    yield put(get_histories_orderings_data({ data }));
    !payload.page || payload.page(data);
  } else {
    yield put(get_histories_orderings_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_put_histories_orderings_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_put_histories_orderings_id, payload.data, token);
  if (!error) {
    yield put(put_histories_orderings_id_data({ data }));

    !payload.page || payload.page(data);
  } else {
    yield put(put_histories_orderings_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_get_histories_orders({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_histories_orders, payload.data, token);
  if (!error) {
    yield put(get_histories_orders_data({ data }));

    !payload.page || payload.page(data);
  } else {
    yield put(get_histories_orders_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_get_histories_transactions({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_histories_transactions, payload.data, token);
  if (!error) {
    yield put(get_histories_transactions_data({ data }));

    !payload.page || payload.page(data);
  } else {
    yield put(get_histories_transactions_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_get_histories_affiliates({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_histories_affiliates, payload.data, token);
  if (!error) {
    yield put(get_histories_affiliates_data({ data }));

    !payload.page || payload.page(data);
  } else {
    yield put(get_histories_affiliates_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_get_histories_bids({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_histories_bids, payload.data, token);
  if (!error) {
    yield put(get_histories_bids_data({ data }));

    !payload.page || payload.page(data);
  } else {
    yield put(get_histories_bids_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_put_wallets_id_deposit({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_put_wallets_id_deposit, payload.data, token);
  if (!error) {
    yield put(put_wallets_id_deposit_data({ data }));

    !payload.page || payload.page(data);
  } else {
    yield put(put_wallets_id_deposit_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_post_wallets_id_staking({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_post_wallets_id_staking, payload.data, token);
  if (!error) {
    yield put(post_wallets_id_staking_data({ data }));

    !payload.page || payload.page(data);
  } else {
    yield put(post_wallets_id_staking_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.toast || payload.toast(error);
  }
}

export function* run_post_wallets_id_withdraws_id_withdraw({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_wallets_id_withdraws_id_withdraw, payload.data, token);
  if (!error) {
    yield put(post_wallets_id_withdraws_id_withdraw_data({ data }));
    yield put(get_wallets({ data: payload.data.search }));
    !payload.next || payload.next(data);
  } else {
    yield put(post_wallets_id_withdraws_id_withdraw_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_wallets_id_deposit({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_wallets_id_deposit, payload.data, token);
  if (!error) {
    yield put(post_wallets_id_deposit_data({ data }));
    yield put(get_wallets({ data: payload.data.search }));
    !payload.next || payload.next(data);
  } else {
    yield put(post_wallets_id_deposit_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* flow_get_markets_rates() {
  yield takeLatest(get_markets_rates, run_get_markets_rates);
}
export function* flow_get_markets_ohlcs() {
  yield takeLatest(get_markets_ohlcs, run_get_markets_ohlcs);
}
export function* flow_get_markets_ohlc() {
  yield takeLatest(get_markets_ohlc, run_get_markets_ohlc);
}
export function* flow_get_markets_orders() {
  yield takeLatest(get_markets_orders, run_get_markets_orders);
}
export function* flow_get_wallets() {
  yield takeLatest(get_wallets, run_get_wallets);
}
export function* flow_put_wallets() {
  yield takeLatest(put_wallets, run_put_wallets);
}
export function* flow_post_wallets() {
  yield takeLatest(post_wallets, run_post_wallets);
}
export function* flow_delete_wallets_id() {
  yield takeLatest(delete_wallets_id, run_delete_wallets_id);
}
export function* flow_post_markets_exchanges() {
  yield takeLatest(post_markets_exchanges, run_post_markets_exchanges);
}
export function* flow_put_markets_exchanges_id_cancel() {
  yield takeLatest(put_markets_exchanges_id_cancel, run_put_markets_exchanges_id_cancel);
}

export function* flow_put_wallets_id_deposit() {
  yield takeLatest(put_wallets_id_deposit, run_put_wallets_id_deposit);
}

export function* flow_post_wallets_id_staking() {
  yield takeLatest(post_wallets_id_staking, run_post_wallets_id_staking);
}

export function* flow_get_histories_orderings() {
  yield takeLatest(get_histories_orderings, run_get_histories_orderings);
}
export function* flow_put_histories_orderings_id() {
  yield takeLatest(put_histories_orderings_id, run_put_histories_orderings_id);
}
export function* flow_get_histories_orders() {
  yield takeLatest(get_histories_orders, run_get_histories_orders);
}
export function* flow_get_histories_transactions() {
  yield takeLatest(get_histories_transactions, run_get_histories_transactions);
}
export function* flow_get_histories_affiliates() {
  yield takeLatest(get_histories_affiliates, run_get_histories_affiliates);
}

export function* flow_get_histories_bids() {
  yield takeLatest(get_histories_bids, run_get_histories_bids);
}

export function* flow_post_wallets_id_withdraws_id_withdraw() {
  yield takeLatest(post_wallets_id_withdraws_id_withdraw, run_post_wallets_id_withdraws_id_withdraw);
}
export function* flow_post_wallets_id_deposit() {
  yield takeLatest(post_wallets_id_deposit, run_post_wallets_id_deposit);
}

export default function* saga() {
  yield fork(flow_get_markets_rates);
  yield fork(flow_get_markets_ohlcs);
  yield fork(flow_get_markets_ohlc);
  yield fork(flow_get_markets_orders);

  yield fork(flow_get_wallets);
  yield fork(flow_put_wallets);
  yield fork(flow_post_wallets);
  yield fork(flow_delete_wallets_id);

  yield fork(flow_post_markets_exchanges);
  yield fork(flow_put_markets_exchanges_id_cancel);

  yield fork(flow_get_histories_orderings);
  yield fork(flow_put_histories_orderings_id);
  yield fork(flow_get_histories_orders);
  yield fork(flow_get_histories_transactions);
  yield fork(flow_get_histories_affiliates);
  yield fork(flow_get_histories_bids);

  yield fork(flow_put_wallets_id_deposit);
  yield fork(flow_post_wallets_id_staking);
  yield fork(flow_post_wallets_id_withdraws_id_withdraw);
  yield fork(flow_post_wallets_id_deposit);
}
