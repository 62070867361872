import axios from "../axios";
import env from "../env";

export const api_get_items_id_offers = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`items/${data.item_id}/offers`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_get_items_id_offers_id = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`items/${data.item_id}/offers/${data.item_offer_id}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_post_items_id_offers = (data, token) => {
  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`items/${data.item_id}/offers`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },

    data,
    formData: true,
  };
  return axios(options);
};

export const api_put_items_id_offers_id = (data, token) => {
  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`items/${data.item_id}/offers/${data.item_offer_id}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    data,
    formData: true,
  };
  return axios(options);
};

export const api_delete_items_id_offers_id = (data, token) => {
  const options = {
    method: "delete",
    url: `${env.REACT_APP_API_URL}${`items/${data.item_id}/offers/${data.item_offer_id}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token ? token : env.REACT_APP_API_KEY}`,
    },
    data,
  };
  return axios(options);
};
