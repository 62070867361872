import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import CustomDate from "components/CustomDate/CustomDate.js";
import { hideModal, showModal } from "config/util";

// import { view_collection } from "../../stores/Collections/view";
import { view_item } from "../../stores/Items/view";
import moment from "moment/moment";

const _modal_item_order = (Item) => {
  const { propsItem, set_propsItem, dispatch_post_orders, maps, t, v_item } = Item;

  const pay_ids = maps.pay_ids ?? {};

  return (
    <>
      <div
        className="modal fade"
        id="itemOrderModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-6 shadow">
            <div className="modal-body">
              <h2 className="fw-bold mb-0 text-center">{t("アイテムを購入しますか？")}</h2>
              <div className="row mt-4 mb-3">
                <div className="col-4 mx-auto">
                  <div htmlFor="collectionLogo" className="ratio ratio-1x1 object-item">
                    <img src={v_item.item_icon_uri} />
                  </div>
                </div>
              </div>
              <dl className="row g-2 py-2 px-lg-4 align-items-center bg-light">
                <dt className="col-sm-4 mt-md-0">{t("現在の価格")}</dt>
                <dd className="col-sm-8">
                  <span className="fs-5">
                    <sub className="me-1">{v_item.item_sale_coin_tag_color}</sub>
                    {v_item.item_sale_price}
                  </span>
                  <small className="d-block text-muted">
                    <sub>$</sub>
                    {v_item.item_sale_price_usd_label}
                  </small>
                </dd>
                {/* <dt className="col-sm-4">支払い方法</dt>
                <dd className="col-sm-8">
                  <select
                    className="form-select"
                    id="collectionCategory"
                    aria-label="\u30AB\u30C6\u30B4\u30EA"
                    value={propsItem.pay_id}
                    onChange={(e) => {
                      const val = e.target.value;
                      set_propsItem({
                        ...propsItem,
                        pay_id: val,
                      });
                    }}
                  >
                    {Object.keys(pay_ids).map((k, idx) => {
                      return <option value={k}>{pay_ids[k]}</option>;
                    })}
                  </select>
                </dd> */}
              </dl>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                {t("キャンセル")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  // hideModal("itemOrderModal");
                  e.preventDefault();
                  dispatch_post_orders(
                    {
                      item_id: v_item.item_id,
                      item_detail_id: v_item.item_detail_id,
                      search: propsItem.search,
                      // pay_id: PayCst.PAY.WALLET_PAID,
                      pay_id: 0,
                    },
                    () => {
                      hideModal("itemOrderModal");
                    }
                  );
                }}
              >
                {t("購入")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default _modal_item_order;

// <div
// className="modal fade"
// id="itemOrderModal"
// tabIndex={-1}
// aria-labelledby="itemOrderModalLabel"
// aria-hidden="true"
// >
// <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
//   <div className="modal-content">
//     <div className="modal-header">
//       <h5 className="modal-title" id="itemOrderModalLabel">
//         購入する
//       </h5>
//       <button
//         type="button"
//         className="btn-close"
//         data-bs-dismiss="modal"
//         aria-label="Close"
//       />
//     </div>
//     <div className="modal-body">
//       <dl className="row g-2">
//         {/* <dt className="col-sm-4">コイン</dt>
//         <dd className="col-sm-8">
//           <select
//             className="form-select"
//             id="collectionCategory"
//             aria-label="\u30AB\u30C6\u30B4\u30EA"
//             value={propsItem.coin}
//             onChange={(e) => {
//               const val = e.target.value;
//               set_propsItem({
//                 ...propsItem,
//                 coin: val,
//               });
//             }}
//           >
//             {Object.keys(maps.coins).map((k, idx) => {
//               return <option value={k}>{maps.coins[k]}</option>;
//             })}
//           </select>
//         </dd> */}
//         <dt className="col-sm-4">支払い金額</dt>
//         <dd className="col-sm-8">
//           <span
//             className="display-6 lh-1 fw-bold"
//             data-bs-toggle="tooltip"
//             data-bs-placement="top"
//             title="ETH"
//           >
//             <sub>
//               <i className="fa-brands fa-ethereum fa-fw tc-eth" />
//             </sub>
//             {v_item.item_sale_price}
//           </span>
//           <span className="d-block mt-1">
//             <sub>$</sub>
//             {v_item.item_sale_price_usd_label}
//           </span>
//         </dd>

//         {/* <dt className="col-sm-4">金額</dt>
//         <dd className="col-sm-8">
//           <input
//             type="text"
//             className="form-control"
//             placeholder=""
//             onChange={(e) => {
//               const val = e.target.value;
//               set_propsItem({
//                 ...propsItem,
//                 price: val,
//               });
//             }}
//             value={propsItem.price}
//           />
//         </dd>
//         <dt className="col-sm-4">有効期限</dt>
//         <dd className="col-sm-8">
//           <CustomDate
//             readOnly={false}
//             labelText={t("いつまで")}
//             type="text"
//             autoComplete="off"
//             value={propsItem.closed_at}
//             set_value={(val) => {
//               set_propsItem({
//                 ...propsItem,
//                 closed_at: val,
//               });
//             }}
//           />
//         </dd> */}
//       </dl>
//     </div>
//     <div className="modal-footer">
//       <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
//         キャンセル
//       </button>
//       <button
//         type="button"
//         className="btn btn-primary"
//         onClick={(e) => {
//           // hideModal("itemOrderModal");
//           e.preventDefault();
//           dispatch_post_orders(
//             {
//               item_id: v_item.item_id,
//               item_detail_id: v_item.item_detail_id,
//               search: propsItem.search,
//               // pay_id: PayCst.PAY.WALLET_PAID,
//               pay_id: 0,
//             },
//             () => {
//               hideModal("itemOrderModal");
//             }
//           );
//         }}
//       >
//         購入
//       </button>
//     </div>
//   </div>
// </div>
// </div>
