import { combineReducers } from "redux";
import { createAction, createReducer } from "redux-act";

import {
  get_nfts,
  get_nfts_data,
  get_nfts_error,
  get_nfts_id,
  get_nfts_id_data,
  get_nfts_id_error,
  post_nfts,
  post_nfts_data,
  post_nfts_error,
  put_nfts_id,
  put_nfts_id_data,
  put_nfts_id_error,
  delete_nfts_id,
  delete_nfts_id_data,
  delete_nfts_id_error,
} from "./actions";

const forupdate = (array, unit, id) => {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element[id] === unit[id]) {
      array[index] = unit;
    }
  }
  return array;
};

export const reduce_get_nfts = createReducer(
  {
    [get_nfts]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_nfts_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_nfts_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [get_nfts_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_nfts_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      const nft = payload.data.nft;
      const nfts = state.nfts;

      if (nfts) newState.nfts = forupdate(nfts, nft, "nft_token_id");
      newState.isLoading = false;

      return newState;
    },
    [get_nfts_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
 isLoading: false,
    nft: {},
    nfts: [],
    count: 0,
  }
);

export const reduce_post_nfts = createReducer(
  {
    [post_nfts]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_nfts_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_nfts_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_nfts_id = createReducer(
  {
    [put_nfts_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_nfts_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_nfts_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_nfts_id = createReducer(
  {
    [delete_nfts_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_nfts_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [delete_nfts_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export default {
  reduce_get_nfts,
  reduce_post_nfts,
  reduce_put_nfts_id,
  reduce_delete_nfts_id,
};
