import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import LiLanguage from "components/Li/LiLanguage";
import LiAccount from "components/Li/LiAccount";
import { getMessage } from "config/util";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";

import CustomImage from "./CustomImage";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import CustomDate from "components/CustomDate/CustomDate.js";
import CustomSwitch from "components/CustomSwitch/CustomSwitch.js";

import { hideModal, showModal } from "config/util";

export default function Contents(Collections) {
  const { propsCollections, set_propsCollections, dispatch_post_collabos, loadingId } = Collections;

  const props = propsCollections;
  const set_props = set_propsCollections;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const maps = useSelector((state) => state.reduce_get_maps);

  return (
    <>
      <div
        className="modal fade"
        id="processDialog"
        // data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
          <div className="modal-content rounded-6 shadow">
            <div className="modal-body p-0 bg-body">
              <img
                src={props.collection_cover_uri}
                className="modal-header p-0 img-fluid"
                width="100%"
                alt
              />
              <div className="media p-3">
                <div className="media-object">
                  <div className="ratio ratio-1x1 object-item icon icon-square">
                    <img src={props.collection_icon_uri} className="icon-item img-fluid" alt />
                  </div>
                </div>
                <div className="media-body">
                  <h3 className="media-title">{props.collection_name}</h3>
                </div>
              </div>
              <span className="badge bg-light text-body mb-2 mx-3">
                <i className="fa-solid fa-brush fa-fw me-1" />
                {t(maps.nft_categories[props.category_id])}
              </span>
              <div className="closet mx-3">
                {/* <input id="pText" type="checkbox" /> */}
                {/* <label htmlFor="pText" /> */}
                <div className="closet-body">
                  <p className="m-0">{props.collection_description}</p>
                </div>
              </div>
              <aside className="btn-group m-3" role="link">
                {props.link_twitter && (
                  <a
                    className="btn btn-white-link btn-sm rounded-pill"
                    href={props.link_twitter}
                    target="_blank"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Twitter"
                  >
                    <i className="fa-fw fa-brands fa-twitter" />
                  </a>
                )}
                {props.link_facebook && (
                  <a
                    className="btn btn-white-link btn-sm rounded-pill"
                    href={props.link_facebook}
                    target="_blank"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Facebook"
                  >
                    <i className="fa-fw fa-brands fa-facebook-f" />
                  </a>
                )}
                {props.link_discord && (
                  <a
                    className="btn btn-white-link btn-sm rounded-pill"
                    href={props.link_discord}
                    target="_blank"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Discord"
                  >
                    <i className="fa-fw fa-brands fa-discord" />
                  </a>
                )}
                {props.link_instagram && (
                  <a
                    className="btn btn-white-link btn-sm rounded-pill"
                    href={props.link_instagram}
                    target="_blank"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Instagram"
                  >
                    <i className="fa-fw fa-brands fa-instagram" />
                  </a>
                )}

                {/* 
                <a
                  className="btn btn-white-link btn-sm rounded-pill"
                  href="https://google.com"
                  target="_blank"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Website"
                >
                  <i className="fa-fw fa-solid fa-globe" />
                </a> */}
              </aside>
              <div className="d-flex justify-content-between p-3 border-top border-bottom">
                <span className="fw-bold">クリエイター報酬</span>
                {props.collection_creator_reward}%
              </div>
              <div className="p-3 bg-white">
                <div className="media mb-2">
                  <div className="media-object">
                    <div className="mask-hex">
                      <figure
                        className="object-item ratio ratio-1x1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="\u30CF\u30F3\u30C9\u30B7\u30A7\u30EB"
                      >
                        <img src={props.collection_user_icon_uri} className="icon-item" alt />
                      </figure>
                    </div>
                  </div>
                  <div className="media-body">
                    <h3 className="media-title">
                      {props.collection_user_name}
                      <span className="fw-normal">@{props.collection_user_nmid}</span>
                    </h3>
                  </div>
                </div>
                <h6 className="mt-1 mb-0">コラボメンバー</h6>
                {}
                <div className="row g-1 mb-2 pb-2 border-bottom">
                  <div className="col-auto ms-auto text-end">
                    <span className="pe-2">権限</span>
                  </div>
                  <div className="col-4 text-center">
                    <span>報酬の分配</span>
                    <div className="row g-0">
                      <div className="col text-end">
                        <span className="d-block small lh-1">一次{}</span>
                      </div>
                      <div className="col text-end">
                        <span className="d-block small lh-1">二次{}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <small className="d-block mb-2 fw-bold">オーナー</small>
                <div className="row g-1 mb-3">
                  {/* user_icon, user_name, user_id, auth, reward_first, reward_second, disabled */}
                  <div className="col overflow-hidden">
                    <div
                      className="media media-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                    >
                      <div className="media-object">
                        <div className="icon icon-pill">
                          <img
                            src={props.owner.user_icon}
                            className="icon-item"
                            width="100%"
                            height="auto"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="media-body text-truncate">
                        <a
                          className="d-block small text-truncate"
                          href="index.html"
                          target="_blank"
                        >
                          {props.owner.user_name}
                        </a>
                        <small className="d-block text-muted text-truncate">
                          @{props.owner.user_id}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto text-end">
                    <span className="me-2 badge bg-success text-light">許可</span>
                  </div>
                  <div className="col-4">
                    <div className="row g-0">
                      <div className="col text-end">{props.owner.reward_first}%</div>
                      <div className="col text-end">{props.owner.reward_second}%</div>
                    </div>
                  </div>
                </div>
                <small className="d-block mb-2 fw-bold">メンバー</small>
                <ul className="list-group list-group-flush">
                  {/* <li className="list-group-item px-0 pt-0">
                    <div className="row g-1">
                      <div className="col overflow-hidden">
                        <div
                          className="media media-sm"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title=""
                        >
                          <div className="media-object">
                            <div className="icon icon-pill">
                              <img
                                src="/dist/tmty/example/users/9-icon.webp"
                                className="icon-item"
                                width="100%"
                                height="auto"
                                alt="Vertical Person"
                              />
                            </div>
                          </div>
                          <div className="media-body text-truncate">
                            <a
                              className="d-block small text-truncate"
                              href="index.html"
                              target="_blank"
                            >
                              Vertical Person
                            </a>
                            <small className="d-block text-muted text-truncate">@v_pearson</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end">
                        <span className="me-2 badge bg-success text-light">許可</span>
                      </div>
                      <div className="col-4">
                        <div className="row g-0">
                          <div className="col text-end">30%</div>
                          <div className="col text-end">50%</div>
                        </div>
                      </div>
                    </div>
                  </li> */}
                  {props.members.map((m, idx) => {
                    return (
                      <>
                        <li className="list-group-item px-0">
                          <div className="row g-1 mb-2">
                            <div className="col overflow-hidden">
                              <div
                                className="media media-sm"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title=""
                              >
                                <div className="media-object">
                                  <div className="icon icon-pill">
                                    <img
                                      src={m.user_icon}
                                      className="icon-item"
                                      width="100%"
                                      height="auto"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="media-body text-truncate">
                                  <a
                                    className="d-block small text-truncate"
                                    href="index.html"
                                    target="_blank"
                                  >
                                    {m.user_name}
                                  </a>
                                  <small className="d-block text-muted text-truncate">
                                    @{m.user_id}
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div className="col-auto text-end">
                              {m.auth ? (
                                <span className="me-2 badge bg-success text-light">許可</span>
                              ) : (
                                <span className="me-2 badge bg-light text-secondary">——</span>
                              )}
                            </div>
                            <div className="col-4">
                              <div className="row g-0">
                                <div className="col text-end">{m.reward_first}%</div>
                                <div className="col text-end">{m.reward_second}%</div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                    );
                  })}

                  {/* <li className="list-group-item px-0">
                    <div className="row g-1 mb-2">
                      <div className="col overflow-hidden">
                        <div
                          className="media media-sm"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Horizontal Person @h_pearson"
                        >
                          <div className="media-object">
                            <div className="icon icon-pill">
                              <img
                                src="/dist/tmty/example/users/8-icon.webp"
                                className="icon-item"
                                width="100%"
                                height="auto"
                                alt="Horizontal Person"
                              />
                            </div>
                          </div>
                          <div className="media-body text-truncate">
                            <a
                              className="d-block small text-truncate"
                              href="index.html"
                              target="_blank"
                            >
                              Horizontal Person
                            </a>
                            <small className="d-block text-muted text-truncate">@h_pearson</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end">
                        <span className="me-2 badge bg-light text-secondary">——</span>
                      </div>
                      <div className="col-4">
                        <div className="row g-0">
                          <div className="col text-end">30%</div>
                          <div className="col text-end">50%</div>
                        </div>
                      </div>
                    </div>
                  </li> */}
                  {}
                </ul>
              </div>
            </div>
            <div className="modal-footer">
              <span className="me-auto">これで作成しますか？</span>
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                変更
              </button>
              {}
              <a
                className="btn btn-primary"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  // showModal("modalSuccessfully");

                  dispatch_post_collabos(
                    {
                      category_id: props.category_id,
                      collection_icon: props.collection_icon,
                      collection_cover: props.collection_cover,
                      collection_nmid: props.collection_nmid,
                      collection_creator_reward: props.collection_creator_reward,
                      name: props.collection_name,
                      collection_description: props.collection_description,
                      link_twitter: props.link_twitter,
                      link_facebook: props.link_facebook,
                      link_discord: props.link_discord,
                      link_instagram: props.link_instagram,
                      link_other: props.link_other,

                      collection_user_icon: props.collection_user_icon,
                      collection_user_name: props.collection_user_name,
                      collection_user_nmid: props.collection_user_nmid,

                      owner: props.owner,
                      members: props.members,

                      active_flg: true,
                    },
                    (data) => {
                      // set_props({
                      //   category_id: "",
                      //   collection_icon: "",
                      //   collection_cover: "",
                      //   collection_nmid: "",
                      //   collection_creator_reward: "",
                      //   collection_name: "",
                      //   collection_description: "",
                      //   link_twitter: "",
                      //   link_facebook: "",
                      //   link_discord: "",
                      //   link_instagram: "",
                      //   link_other: "",

                      //   search: props.search,
                      // });
                      // showModal("modalSuccessfully");
                      hideModal("processDialog");
                      // history.goBack();
                      history.push(`/collections/${data.collection_id}/items`);
                    },
                    () => {
                      // hideModal("processDialog");
                    }
                  );
                }}
              >
                確定
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="modal fade modal-alert py-5"
        id="processDialog"
        data-bs-keyboard="false"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content rounded-4 shadow">
            <div className="modal-body p-4 text-center">
              <h5 className="mb-2">{t("確認")}</h5>
              <p className="mb-0">{t("入力内容を保存してもいいですか？")}</p>
            </div>
            <div className="modal-footer flex-nowrap p-0">
              <button
                type="button"
                className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0"
                data-bs-dismiss="modal"
              >
                {t("キャンセル")}
              </button>
              <button
                type="button"
                className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"
                // data-bs-target="#modalSuccessfully"
                // data-bs-toggle="modal"
                onClick={(e) => {
                  e.preventDefault();
                  // showModal("modalSuccessfully");

                  dispatch_post_collections(
                    {
                      category_id: props.category_id,
                      collection_icon: props.collection_icon,
                      collection_cover: props.collection_cover,
                      collection_nmid: props.collection_nmid,
                      collection_creator_reward: props.collection_creator_reward,
                      name: props.collection_name,
                      collection_description: props.collection_description,
                      link_twitter: props.link_twitter,
                      link_facebook: props.link_facebook,
                      link_discord: props.link_discord,
                      link_instagram: props.link_instagram,
                      link_other: props.link_other,
                      active_flg: true,
                    },
                    () => {
                      set_props({
                        category_id: "",
                        collection_icon: "",
                        collection_cover: "",
                        collection_nmid: "",
                        collection_creator_reward: "",
                        collection_name: "",
                        collection_description: "",
                        link_twitter: "",
                        link_facebook: "",
                        link_discord: "",
                        link_instagram: "",
                        link_other: "",
                        search: props.search,
                      });
                      // showModal("modalSuccessfully");
                      hideModal("processDialog");
                      history.goBack();
                    },
                    () => {
                      // hideModal("processDialog");
                    }
                  );
                }}
              >
                <strong>{t("はい")}</strong>
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
