import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import LiLanguage from "components/Li/LiLanguage";
import LiAccount from "components/Li/LiAccount";
import { getMessage } from "config/util";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";

import CustomImage from "./CustomImage";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import CustomDate from "components/CustomDate/CustomDate.js";
import CustomSwitch from "components/CustomSwitch/CustomSwitch.js";

import { hideModal, showModal } from "config/util";

export default function Contents(Collections) {
  const {
    propsCollections,
    set_propsCollections,
    dispatch_post_collections,
    loadingId,
  } = Collections;

  const props = propsCollections;
  const set_props = set_propsCollections;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const maps = useSelector((state) => state.reduce_get_maps);

  return (
    <>
      <div
        className="modal fade modal-alert py-5"
        id="processDialog"
        data-bs-keyboard="false"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content rounded-4 shadow">
            <div className="modal-body p-4 text-center">
              <h5 className="mb-2">{t("確認")}</h5>
              <p className="mb-0">{t("入力内容を保存してもいいですか？")}</p>
            </div>
            <div className="modal-footer flex-nowrap p-0">
              <button
                type="button"
                className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0"
                data-bs-dismiss="modal"
              >
                {t("キャンセル")}
              </button>
              <button
                type="button"
                className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"
                // data-bs-target="#modalSuccessfully"
                // data-bs-toggle="modal"
                onClick={(e) => {
                  e.preventDefault();
                  // showModal("modalSuccessfully");

                  dispatch_post_collections(
                    {
                      category_id: props.category_id,
                      collection_icon: props.collection_icon,
                      collection_cover: props.collection_cover,
                      collection_nmid: props.collection_nmid,
                      collection_creator_reward: props.collection_creator_reward,
                      name: props.collection_name,
                      collection_description: props.collection_description,
                      link_twitter: props.link_twitter,
                      link_facebook: props.link_facebook,
                      link_discord: props.link_discord,
                      link_instagram: props.link_instagram,
                      link_other: props.link_other,
                      active_flg: true,
                    },
                    () => {
                      set_props({
                        category_id: "",
                        collection_icon: "",
                        collection_cover: "",
                        collection_nmid: "",
                        collection_creator_reward: "",
                        collection_name: "",
                        collection_description: "",
                        link_twitter: "",
                        link_facebook: "",
                        link_discord: "",
                        link_instagram: "",
                        link_other: "",
                        search: props.search,
                      });
                      // showModal("modalSuccessfully");
                      hideModal("processDialog");
                      history.goBack()
                    },
                    () => {
                      // hideModal("processDialog");
                    }
                  );
                }}
              >
                <strong>{t("はい")}</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
