import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  zeroPadding,
  toNumber,
} from "config/util";
// import { view_item } from "../../stores/Items/view";

export const view_withdraw = (prop, maps, t, coin) => {
  const res = {
    ...prop,
  };
  if (!prop) return {};
  // if (!maps) return {};

  res.address = prop.address;
  res.label = prop.label;
  res.wallet_id = prop.wallet_id;

  return res;
};

// import { view_withdraw } from "../../stores/Collections/view";
// const wallet = useSelector((state) => state.reduce_get_activities.wallet);
// const v_wallet = view_withdraw(wallet);
