import { fork, take, put, join, select, takeLatest, takeEvery, call } from "redux-saga/effects";

import { post_authout } from "../Auth/actions";

import {
  api_get_collections,
  api_get_collections_id,
  api_post_collections,
  api_put_collections_id,
  api_delete_collections_id,
  api_put_collections_id_favorite,
  api_put_collections_id_join,
} from "./api";

import {
  get_collections,
  get_collections_data,
  get_collections_error,
  get_collections_id,
  get_collections_id_data,
  get_collections_id_error,
  post_collections,
  post_collections_data,
  post_collections_error,
  put_collections_id,
  put_collections_id_data,
  put_collections_id_error,
  delete_collections_id,
  delete_collections_id_data,
  delete_collections_id_error,
  put_collections_id_favorite,
  put_collections_id_favorite_data,
  put_collections_id_favorite_error,
  put_collections_id_join,
  put_collections_id_join_data,
  put_collections_id_join_error,
} from "./actions";

import { get_accounts } from "../Accounts/actions";

export function* run_get_collections({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_collections, payload.data, token);
  if (!error) {
    yield put(get_collections_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_collections_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_get_collections_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);
  const { data, error } = yield call(api_get_collections_id, payload.data, token);
  if (!error) {
    yield put(get_collections_id_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_collections_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_post_collections({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_post_collections, payload.data, token);
  if (!error) {
    yield put(post_collections_data({ data }));
    yield put(get_collections(payload));
  } else {
    yield put(post_collections_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_collections_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_collections_id, payload.data, token);
  if (!error) {
    yield put(put_collections_id_data({ data }));
    yield put(get_collections_id(payload));
  } else {
    yield put(put_collections_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_delete_collections_id({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_delete_collections_id, payload.data, token);
  if (!error) {
    yield put(delete_collections_id_data({ data }));
    yield put(get_collections(payload));
    yield put(get_accounts({ data }));
  } else {
    yield put(delete_collections_id_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_collections_id_favorite({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_collections_id_favorite, payload.data, token);
  if (!error) {
    yield put(put_collections_id_favorite_data({ data }));
    yield put(get_collections_id(payload));
  } else {
    yield put(put_collections_id_favorite_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_collections_id_join({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_collections_id_join, payload.data, token);
  if (!error) {
    yield put(put_collections_id_join_data({ data }));
    yield put(get_collections_id(payload));
    yield put(get_accounts({ data }));
  } else {
    yield put(put_collections_id_join_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* flow_get_collections() {
  yield takeLatest(get_collections, run_get_collections);
}
export function* flow_get_collections_id() {
  yield takeLatest(get_collections_id, run_get_collections_id);
}
export function* flow_post_collections() {
  yield takeLatest(post_collections, run_post_collections);
}
export function* flow_put_collections_id() {
  yield takeLatest(put_collections_id, run_put_collections_id);
}
export function* flow_delete_collections_id() {
  yield takeLatest(delete_collections_id, run_delete_collections_id);
}
export function* flow_put_collections_id_favorite() {
  yield takeLatest(put_collections_id_favorite, run_put_collections_id_favorite);
}
export function* flow_put_collections_id_join() {
  yield takeLatest(put_collections_id_join, run_put_collections_id_join);
}

export default function* rootSaga() {
  yield fork(flow_get_collections);
  yield fork(flow_get_collections_id);
  yield fork(flow_post_collections);
  yield fork(flow_put_collections_id);
  yield fork(flow_delete_collections_id);
  yield fork(flow_put_collections_id_favorite);
  yield fork(flow_put_collections_id_join);
}
