import { createAction, createReducer } from 'redux-act';

export const get_withdraws = createAction("GET_WITHDRAWS");
export const get_withdraws_data = createAction("GET_WITHDRAWS_DATA");
export const get_withdraws_error = createAction("GET_WITHDRAWS_ERROR");
export const get_withdraws_id = createAction("GET_WITHDRAWS_ID");
export const get_withdraws_id_data = createAction("GET_WITHDRAWS_ID_DATA");
export const get_withdraws_id_error = createAction("GET_WITHDRAWS_ID_ERROR");
export const post_withdraws = createAction("POST_WITHDRAWS");
export const post_withdraws_data = createAction("POST_WITHDRAWS_DATA");
export const post_withdraws_error = createAction("POST_WITHDRAWS_ERROR");
export const put_withdraws_id = createAction("PUT_WITHDRAWS_ID");
export const put_withdraws_id_data = createAction("PUT_WITHDRAWS_ID_DATA");
export const put_withdraws_id_error = createAction("PUT_WITHDRAWS_ID_ERROR");
export const delete_withdraws_id = createAction("DELETE_WITHDRAWS_ID");
export const delete_withdraws_id_data = createAction("DELETE_WITHDRAWS_ID_DATA");
export const delete_withdraws_id_error = createAction("DELETE_WITHDRAWS_ID_ERROR");

export const put_withdraws_id_favorite = createAction("PUT_WITHDRAWS_ID_FAVORITE");
export const put_withdraws_id_favorite_data = createAction("PUT_WITHDRAWS_ID_FAVORITE_DATA");
export const put_withdraws_id_favorite_error = createAction("PUT_WITHDRAWS_ID_FAVORITE_ERROR");


