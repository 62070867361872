import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import moment from "moment/moment";

import LiLanguage from "components/Li/LiLanguage";
import LiAccount from "components/Li/LiAccount";
import { getMessage } from "config/util";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";
import { view_item } from "../../stores/Items/view";
import CustomDate from "components/CustomDate/CustomDate.js";
import CustomDateTime from "components/CustomDateTime/CustomDateTime.js";
import { hideModal, showModal } from "config/util";
import _sidebar from "./_sidebar";

export default function Contents({ Items }) {
  const { propsItems, set_propsItems, dispatch_put_items_id, loadingId } = Items;

  const props = propsItems;
  const set_props = set_propsItems;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();
  const { item_id } = useParams();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const maps = useSelector((state) => state.reduce_get_maps);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const item = useSelector((state) => state.reduce_get_items.item);
  const v_item = view_item(item, maps, t);

  const coin_ids = maps.coins;

  // const coin_ids = { 1: "Bitcoin", 2: "Ethereum" };
  const sale_types = { 1: t("固定価格"), 2: t("オークション") };

  const itemsell = () => {
    return (
      <>
        <h3 className="h5">{t("出品形式")}</h3>
        <div className="row g-2 mb-3">
          <div className="col-12">
            <div className="form-check-reverse form-switch mb-3">
              <label className="form-check-label" htmlFor="invalidCheck2">
                {t("販売する")}
              </label>
              {/* <input
                  className="form-check-input"
                  type="checkbox"
                          id="tradingOnly"
                          onChange={() => {
                            set_props({
                              ...props,
                              sale_flg: !props.sale_flg,
                            });
                          }}
                          defaultChecked={props.sale_flg}
                        /> */}
              <input
                className="form-check-input"
                type="checkbox"
                id="invalidCheck2"
                required
                checked={props.sale_flg}
                onChange={(e) => {
                  const val = e.target.checked;
                  set_props({
                    ...props,
                    sale_flg: val,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-floating">
              <select
                onChange={(e) => {
                  const val = e.target.value;
                  set_props({
                    ...props,
                    sale_type: val,
                  });
                }}
                className="form-select"
                id="collectionCategory"
                aria-label="\u30AB\u30C6\u30B4\u30EA"
                value={props.sale_type}
              >
                {Object.keys(sale_types).map((k, idx) => {
                  return <option value={k}>{sale_types[k]}</option>;
                })}
              </select>
              <label htmlFor="collectionCategory">{t("出品形式")}</label>
            </div>
          </div>

          <div className="col-5">
            <div className="form-floating">
              <select
                onChange={(e) => {
                  const val = e.target.value;
                  set_props({
                    ...props,
                    coin: val,
                  });
                }}
                className="form-select"
                id="collectionCategory"
                aria-label=""
                value={props.coin}
              >
                {Object.keys(coin_ids).map((k, idx) => {
                  return <option value={k}>{coin_ids[k]}</option>;
                })}
              </select>
              <label htmlFor="collectionCategory">{t("出品通貨")}</label>
            </div>
          </div>
          <div className="col">
            <div className="form-floating">
              <input
                type="number"
                className="form-control"
                id="itemName"
                placeholder="\u51FA\u54C1\u4FA1\u683C"
                onChange={(e) => {
                  const val = e.target.value;
                  set_props({
                    ...props,
                    price: val,
                  });
                }}
                value={props.price}
              />
              <label htmlFor="itemName">{t("出品価格")}</label>
            </div>
          </div>
          {/* <div className="form-check-reverse form-switch mb-4">
                    <label className="form-check-label" htmlFor="tradingTrust">
                      信用取引を許可
                      <span
                        className="ms-1"
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        title=""
                      >
                        <i className="fa-solid fa-circle-question" />
                      </span>
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="tradingTrust"
                      onChange={(e) => {
                        const val = e.target.value;
                        set_props({
                          ...props,
                          iscredit: val,
                        });
                      }}
                      value={props.iscredit}
                    />
                  </div> */}
          <h3 className="h5">{t("出品期間")}</h3>
          <div className="btn-group btn-group-sm mb-1">
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                let opened_at = moment();
                if (props.opened_at) opened_at = moment(props.opened_at);
                const closed_at = opened_at.clone().add(24, "hour");
                set_props({
                  ...props,
                  opened_at: opened_at.format(),
                  closed_at: closed_at.format(),
                });
              }}
            >
              {t("24時間")}
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                let opened_at = moment();
                if (props.opened_at) opened_at = moment(props.opened_at);
                const closed_at = opened_at.clone().add(7, "day");
                set_props({
                  ...props,
                  opened_at: opened_at.format(),
                  closed_at: closed_at.format(),
                });
              }}
            >
              {t("7日間")}
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                let opened_at = moment();
                if (props.opened_at) opened_at = moment(props.opened_at);
                const closed_at = opened_at.clone().add(30, "day");
                set_props({
                  ...props,
                  opened_at: opened_at.format(),
                  closed_at: closed_at.format(),
                });
              }}
            >
              {t("30日間")}
            </button>
          </div>
          <div className="input-daterange hstack gap-2" id="datepicker">
            <div className="form-floating w-100">
              {/* <input
                        type="text"
                        className="form-control"
                        id="itemDateStart"
                        name="start"
                        placeholder="\u3044\u3064\u304B\u3089"
                      />
                      <label htmlFor="itemDateStart">いつから</label> */}

              <CustomDateTime
                readOnly={false}
                labelText={t("いつから")}
                type="text"
                autoComplete="off"
                value={props.opened_at}
                set_value={(val) => {
                  set_props({
                    ...props,
                    opened_at: val,
                  });
                }}
              />
            </div>
            <div className="form-floating w-100">
              <CustomDateTime
                readOnly={false}
                labelText={t("いつまで")}
                type="text"
                autoComplete="off"
                value={props.closed_at}
                set_value={(val) => {
                  set_props({
                    ...props,
                    closed_at: val,
                  });
                }}
              />
              {/* <input
                        type="text"
                        className="form-control"
                        id="itemDateEnd"
                        name="end"
                        placeholder="\u3044\u3064\u307E\u3067"
                      />
                      <label htmlFor="itemDateEnd">いつまで</label> */}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <Header />
        <div className="container container-main">
          {_sidebar(Items)}
          <main className="main">
            <div action="created-item-detail.html" entype="multpart/form-data">
              <div className="main-header pt-3 pb-2 ps-lg-3">
                <h2 className="h4 m-0 text-truncate">{t("アイテムを出品")}</h2>
                <div className="btn-group">
                  <a
                    className="btn btn-outline-secondary text-dark rounded-pill px-3 me-2 text-nowrap"
                    href="javascript:history.back()"
                  >
                    <i className="fa-solid fa-xmark" />
                    <span className="d-none d-sm-inline-block"> {t("キャンセル")}</span>
                  </a>
                  <button
                    type="button"
                    name="confirm"
                    className="btn btn-primary rounded-pill px-3 text-nowrap"
                    role="button"
                    data-bs-toggle="modal"
                    data-bs-target="#processDialog"
                  >
                    {t("保存")}
                  </button>
                </div>
              </div>
              <div className="accordion mb-3 d-lg-none" id="newAccordion">
                <div className="accordion-item">
                  <h3 className="accordion-header" id="newCollection-heading">
                    <button
                      className="accordion-button collapsed fw-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#newCollection"
                      aria-expanded="false"
                      aria-controls="newCollection"
                    >
                      {t("アイテムプレビュー")}
                    </button>
                  </h3>
                  <div
                    id="newCollection"
                    className="accordion-collapse collapse"
                    aria-labelledby="newCollection-heading"
                  >
                    <div className="accordion-bod bg-light">
                      <div className="p-3 text-center">
                        <div
                          className="card product product-item mx-auto text-start"
                          style={{
                            maxWidth: "320px",
                          }}
                        >
                          <div className="product-body">
                            <div className="product-media object-item">
                              <img
                                src={v_item.item_icon_uri}
                                width="100%"
                                height="100%"
                                className="card-img img-fluid"
                                alt=""
                              />
                            </div>
                            <h3 className="product-title">{v_item.item_name}</h3>
                            {/* <div className="d-md-flex">
                              <div className="hstack gap-2">
                                <span data-bs-toggle="tooltip" data-bs-placement="top" title="">
                                  <img src={v_item.item_icon_uri} width={18} height={18} alt="" />
                                </span>
                                <span
                                  className="text-secondary"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title=""
                                >
                                  <i className="fa-solid fa-handshake-angle fa-fw" />
                                </span>
                              </div>
                              <div className="ms-auto">
                                <div className="d-flex flex-column align-items-center align-items-md-end" />
                              </div>
                            </div> */}
                          </div>
                          <div className="product-footer">
                            <div className="collection-icon">
                              <img src={v_item.collection_icon_uri} width={22} height={22} alt="" />
                            </div>
                            <span className="product-author text-truncate">
                              {v_item.collection_name}
                            </span>
                          </div>
                        </div>
                        {}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-2 g-3 ps-lg-3">
                <div className="col">
                  {/* <ul className="list-group list-group-flush mb-4 d-lg-none">
                    <li className="list-group-item ps-0">
                      <h3 className="h5 m-0">
                        手数料{" "}
                        <a className="btn btn-link btn-sm ms-2" href>
                          手数料について
                          <i className="fa-solid fa-arrow-up-right-from-square ms-1" />
                        </a>
                      </h3>
                    </li>
                    <li className="list-group-item d-flex ps-0">
                      サービス手数料
                      <span className="ms-auto">2.5%</span>
                    </li>
                    <li className="list-group-item d-flex ps-0">
                      クリエイター報酬
                      <span className="ms-auto">2.5%</span>
                    </li>
                  </ul> */}
                  {!loadingId && itemsell()}
                </div>
                {/* <div className="col">
                  <h3 className="h5 mb-3">専用出品</h3>
                  <div className="form-check-reverse form-switch mb-3">
                    <label className="form-check-label" htmlFor="tradingOnly">
                      専用出品を有効にする
                      <span
                        className="ms-1"
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        title=""
                      >
                        <i className="fa-solid fa-circle-question" />
                      </span>
                    </label>
                    <input className="form-check-input" type="checkbox" id="tradingOnly" />
                  </div>
                  <div className="form-floating">
                    <input
                      type="tel"
                      className="form-control"
                      id="onlySell"
                      placeholder="\u8CFC\u5165\u8005\u3092\u691C\u7D22..."
                    />
                    <label htmlFor="onlySell">購入者を検索...</label>
                  </div>
                </div> */}
                {/* <div className="col">
                  <h3 className="h5 mb-3">専用出品</h3>
                  <div className="form-check-reverse form-switch mb-3">
                    <label className="form-check-label" htmlFor="tradingOnly">
                      販売する
                    </label>
                    <input className="form-check-input" type="checkbox" id="tradingOnly" />
                  </div>
                </div> */}
              </div>
              <div
                className="modal fade modal-alert py-5"
                id="processDialog"
                data-bs-keyboard="false"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content rounded-4 shadow">
                    <div className="modal-body p-4 text-center">
                      <h5 className="mb-2">{t("確認")}</h5>
                      <p className="mb-0">{t("入力内容を保存してもいいですか？")}</p>
                    </div>
                    <div className="modal-footer flex-nowrap p-0">
                      <button
                        type="button"
                        className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0"
                        data-bs-dismiss="modal"
                      >
                        {t("キャンセル")}
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"
                        // data-bs-target="#modalSuccessfully"
                        // data-bs-toggle="modal"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch_put_items_id(
                            {
                              // ...props,
                              search: props.search,

                              item_detail_id: props.item_detail_id,
                              id: props.item_id,
                              sale_type: props.sale_type,
                              sale_flg: props.sale_flg,
                              coin: props.coin,
                              price: props.price,
                              iscredit: props.iscredit,
                              opened_at: props.opened_at,
                              closed_at: props.closed_at,
                            },
                            () => {
                              hideModal("processDialog");
                              history.push(`/items/${item_id}`);
                              // showModal("modalSuccessfully");
                            }
                          );
                        }}
                      >
                        <strong>{t("はい")}</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="modalSuccessfully"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-6 shadow">
                  <div className="modal-body p-md-5">
                    <h2 className="fw-bold mb-0 text-center">{t("出品を開始しました")}</h2>
                    <small className="d-block text-center mt-2 text-muted">
                      {t("※出品期間を設定している場合はそれに準じます")}
                    </small>
                    <div className="row mt-4 mb-3">
                      <div className="col-4 mx-auto">
                        <div htmlFor="collectionLogo" className="ratio ratio-1x1 object-item">
                          <img src="/dist/tmty/example/collections/2-1-item-16.webp" alt />
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 text-center">
                      <h5>{t("アイテムをシェアしよう")}</h5>
                      <aside className="mb-3" role="link">
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-twitter text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-instagram text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-discord text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-linkedin-in text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fa-brands fa-telegram fa-fw" />
                        </a>
                      </aside>
                    </div>
                    <a className="btn btn-lg rounded-pill btn-primary mt-2 w-100" href="#">
                      {t("アイテム詳細へ")}
                    </a>
                    <a className="btn btn-lg rounded-pill btn-outline-primary mt-2 w-100" href="#">
                      {t("コレクション詳細へ")}
                    </a>
                    <a className="btn btn-lg rounded-pill btn-outline-primary mt-2 w-100" href="#">
                      {t("アイテムを作成")}
                    </a>
                    <a
                      className="btn btn-lg rounded-pill btn-link mt-2 w-100"
                      href="#"
                      target="_blank"
                    >
                      <i className="fa-solid fa-arrow-up-right-from-square" />
                      <span className="ms-1">{t("プレビュー")}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
