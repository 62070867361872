import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { hideModal, showModal } from "config/util";
export const HeaderCollabo = (Users) => {
  const { propsUsers, set_propsUsers, dispatch_gets, user_id } = Users;
  return <></>;
};

export const RowCollabo = (Users) => {
  const { propsUsers, v_user, history, t, dispatch_users_list } = Users;

  return (
    <>
      <div className="media px-3 position-relative">
        <div className="media-object">
          <div className="object-item user-icon">
            <img src={v_user.user_icon_uri} width="100%" height="auto" alt="" />
          </div>
        </div>
        <div className="media-body">
          <div className="media-action">
            <h3 className="media-title">
              <a className="stretched-link" href="../user.html" target="_blank">
                {v_user.user_name}
              </a>
              <span>@{v_user.user_nmid}</span>
            </h3>
            <div
              className="flex-shrink-0 ms-3"
              style={{
                zIndex: 2,
              }}
            >
              <button
                type="button"
                className="btn btn-outline-dark rounded-pill px-3 active"
                role="button"
                data-bs-toggle="button"
                autoComplete="off"
                onClick={(e) => {
                  // e.preventDefault();
                  e.stopPropagation();

                  // alert(v_user.user_id);
                  dispatch_users_list({
                    user_uuid: v_user.user_id,
                    user_icon: v_user.user_icon_uri,
                    user_name: v_user.user_name,
                    user_nmid: v_user.user_nmid,
                  });
                }}
              >
                追加
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// <a
// href="#"
// onClick={(e) => {
//   e.preventDefault();
//   history.push(`/users/${v_user.user_id}/items`);
//   hideModal("userFollow");
// }}
// className="media"
// >
// <div className="media-object">
//   <div className="object-item user-icon">
//     <img src={v_user.user_icon_uri} width="100%" height="auto" alt="" />
//   </div>
// </div>
// <div className="media-body">
//   <div className="media-action">
//     <h3 className="media-title">
//       {v_user.user_name}
//       <span>@{v_user.user_nmid}</span>
//     </h3>
//     <div className="flex-shrink-0">
//       {!v_user.myself && (
//         <button
//           type="button"
//           className={`btn btn-outline-dark rounded-pill px-3 ${
//             v_user.user_favorited_my ? "active" : ""
//           }`}
//           role="button"
//           data-bs-toggle="button"
//           autoComplete="off"
//           onClick={(e) => {
//             // e.preventDefault();
//             e.stopPropagation();

//             dispatch_put_users_id(
//               {
//                 search: propsUsers.search,
//                 id: v_user.user_id,
//               },
//               () => {},
//               () => {}
//             );
//           }}
//         >
//           {v_user.user_favorited_my ? t("フォロー中") : t("フォロー")}
//         </button>
//       )}
//     </div>
//   </div>
// </div>
// </a>
