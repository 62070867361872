import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { view_item } from "../../stores/Items/view";
import { view_collection } from "../../stores/Collections/view";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function Contents(Item) {
  const { dispatch_delete_items_id } = Item;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const maps = useSelector((state) => state.reduce_get_maps);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const item = useSelector((state) => state.reduce_get_items.item);
  const v_item = view_item(item, maps, t);

  return (
    <>
      <div data-bs-toggle="tooltip" data-bs-placement="top" title="">
        <button
          type="button"
          // className="btn btn-white rounded-pill px-3 me-2"
          className="btn btn-white rounded-pill px-3"
          id="userReact"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onClick={() => {}}
        >
          <i className="fa-solid fa-ellipsis" />
          {/* <i className="fa-solid fa-link fa-fw" /> */}
        </button>

        <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="userShere">
          {/* <li>
            <button type="button" className="dropdown-item">
              <i className="fa-solid fa-heart-crack fa-fw me-3" />
              {t("ウォッチリストを解除")}
            </button>
          </li> */}
          {/* <li>
            <hr className="dropdown-divider" />
          </li> */}
          <li>
            <CopyToClipboard
              text={`https://www.tmty.io/items/${v_item.item_id}`}
              onCopy={() => {
                alert("アイテムのリンクをコピーしました。");
              }}
            >
              <button type="button" className="dropdown-item">
                <i className="fa-solid fa-link fa-fw me-3" />
                {t("リンクをコピー")}
              </button>
            </CopyToClipboard>
          </li>

          {/* {v_item.出品する && (
            <>
              <a
                className="btn btn-outline-primary rounded-pill px-sm-3 text-nowrap ms-2"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/items/${v_item.item_id}/send`);
                }}
              >
                <i className="fa-solid fa-right-left" />
                <span className="ms-1 d-sm-none">{t("転送")}</span>
                <span className="ms-1 d-none d-sm-inline-block">{t("転送する")}</span>
              </a>
            </>
          )} */}
          {v_item.出品する && (
            <li>
              <button
                type="button"
                className="dropdown-item"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/items/${v_item.item_id}/send`);
                }}
              >
                <i className="fa-solid fa-right-left fa-fw me-3" />
                {/* <i className="fa-solid fa-clipboard-check fa-fw me-3" /> */}
                {t("転送する")}
              </button>
            </li>
          )}

          {/* <li>
            <button type="button" className="dropdown-item">
              <i className="fa-solid fa-clipboard-check fa-fw me-3" />
              {t("コレクションIDをコピー")}
            </button>
          </li>
          <li>
            <button type="button" className="dropdown-item">
              <i className="fa-solid fa-clipboard-check fa-fw me-3" />
              {t("ユーザIDをコピー")}
            </button>
          </li> */}
          {v_item.出品する && !v_item.nft_token_id && (
            <>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button
                  type="button"
                  className="dropdown-item text-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    // history.push(`/items/${v_item.item_id}/send`);
                    dispatch_delete_items_id(
                      {
                        item_id: v_item.item_id,
                        item_detail_id: v_item.item_detail_id,
                        // search: propsItemBids.search,
                      },
                      () => {
                        e.preventDefault();
                        // history.push(`/collections/${v_item.collection_id}/items`);
                        history.goBack();
                      },
                      () => {}
                    );
                  }}
                >
                  <i className="fa-solid fa-trash-can fa-fw me-3" />
                  {t("削除する")}
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
}

//  {/* <button type="button" className="dropdown-item text-danger">
//             <i className="fa-solid fa-font-awesome fa-fw me-3" />
//             {t("問題を報告")}
//           </button> */}
