import axios from "../axios";
import env from "../env";

export const api_get_withdraws = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`withdraws`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_get_withdraws_id = (data, token) => {
  const options = {
    method: "get",
    url: `${env.REACT_APP_API_URL}${`withdraws/${data.id}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: data,
  };
  return axios(options);
};

export const api_post_withdraws = (data, token) => {

  const options = {
    method: "post",
    url: `${env.REACT_APP_API_URL}${`withdraws`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
    formData: true
  };
  return axios(options);
};

export const api_put_withdraws_id = (data, token) => {

  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`withdraws/${data.id}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
    formData: true
  };
  return axios(options);
};

export const api_delete_withdraws_id = (data, token) => {
  const options = {
    method: "delete",
    url: `${env.REACT_APP_API_URL}${`withdraws/${data.id}`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(options);
};


export const api_put_withdraws_id_favorite = (data, token) => {

  const options = {
    method: "put",
    url: `${env.REACT_APP_API_URL}${`withdraws/${data.id}/favorite`}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
    formData: true
  };
  return axios(options);
};

