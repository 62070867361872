import { createAction, createReducer } from 'redux-act';

export const get_nfts = createAction("GET_NFTS");
export const get_nfts_data = createAction("GET_NFTS_DATA");
export const get_nfts_error = createAction("GET_NFTS_ERROR");
export const get_nfts_id = createAction("GET_NFTS_ID");
export const get_nfts_id_data = createAction("GET_NFTS_ID_DATA");
export const get_nfts_id_error = createAction("GET_NFTS_ID_ERROR");
export const post_nfts = createAction("POST_NFTS");
export const post_nfts_data = createAction("POST_NFTS_DATA");
export const post_nfts_error = createAction("POST_NFTS_ERROR");
export const put_nfts_id = createAction("PUT_NFTS_ID");
export const put_nfts_id_data = createAction("PUT_NFTS_ID_DATA");
export const put_nfts_id_error = createAction("PUT_NFTS_ID_ERROR");
export const delete_nfts_id = createAction("DELETE_NFTS_ID");
export const delete_nfts_id_data = createAction("DELETE_NFTS_ID_DATA");
export const delete_nfts_id_error = createAction("DELETE_NFTS_ID_ERROR");
