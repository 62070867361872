import React from "react";
import { initReactI18next, useTranslation } from "react-i18next";

function ImageUpload({ image_path, set_image_path, mime_type, title, id }) {
  const [t, i18n] = useTranslation();
  let type = null;

  switch (mime_type) {
    case "image/jpeg":
    case "image/png":
    case "image/svg+xml":
    case "image/gif":
    case "image/webp":
      type = "photo";
      break;
    case "audio/mpeg":
      type = "music";
      break;
    case "video/mp4":
      type = "video";
      break;
    default:
      break;
  }
  const fileInput = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        set_image_path(reader.result, file);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleClick = () => {
    fileInput.current.click();
  };

  switch (type) {
    case "photo":
      return (
        <div
          className="form-file border rounded-2 mb-3"
          style={{
            minHeight: "190px",
          }}
        >
          <div className="mb-2 p-2">
            <span className="text-muted">{title}</span>
          </div>
          <div className="row justify-content-center my-auto mb-3">
            <div className="col-4">
              <label htmlFor={id} className="ratio ratio-1x1 icon icon-square">
                <img
                  src={image_path}
                  className="icon-item"
                  alt
                  style={{ height: "100%", width: "100%" }}
                />
              </label>
              <input type="file" id={id} onChange={handleImageChange} ref={fileInput} />
            </div>
          </div>
          <div className="alert alert-secondary mb-0" role="alert">
            <dl className="row g-1 mb-0 small">
              <dt className="col-3 mb-0">{t("対応メディア")}</dt>
              {/* <dd className="col-9 mb-0">JPG, PNG, GIF, SVG, MP4, WEBM, MP3</dd> */}
              <dd className="col-9 mb-0">JPG, PNG, GIF, SVG, WEBM</dd>
              <dt className="col-3 mb-0">{t("最大容量")}</dt>
              <dd className="col-9 mb-0">100 MB</dd>
            </dl>
          </div>
        </div>
      );

    case "video":
      return (
        <div
          className="form-file border rounded-2 mb-3"
          style={{
            minHeight: "190px",
          }}
        >
          <div className="mb-2 p-2">
            <span className="text-muted">{title}</span>
          </div>
          <div className="row justify-content-center my-auto mb-3">
            <div className="col-4">
              <label htmlFor={id} className="ratio ratio-1x1 icon icon-square">
                <video
                  // readOnly={readOnly}
                  onClickCapture={(e) => {
                    e.preventDefault();
                  }}
                  // autoplay=""
                  // playsinline=""
                  // loop=""
                  // muted=""
                  // style={{ maxWidth: "100%" }}
                  style={{ width: "100%" }}
                  className="img-fluid"
                  controls
                  controlslist="nodownload"
                  onContextMenu={() => {
                    return false;
                  }}
                  loop
                  // preload="none"
                  // poster={thumbnail_path}
                  src={image_path}
                  onClick={handleClick}
                ></video>
              </label>
              <input type="file" id={id} onChange={handleImageChange} ref={fileInput} />
            </div>
          </div>
          <div className="alert alert-secondary mb-0" role="alert">
            <dl className="row g-1 mb-0 small">
              <dt className="col-3 mb-0">{t("対応メディア")}</dt>
              {/* <dd className="col-9 mb-0">JPG, PNG, GIF, SVG, MP4, WEBM, MP3</dd> */}
              <dd className="col-9 mb-0">JPG, PNG, GIF, SVG, WEBM</dd>
              <dt className="col-3 mb-0">{t("最大容量")}</dt>
              <dd className="col-9 mb-0">100 MB</dd>
            </dl>
          </div>
        </div>
      );

    default:
      return (
        <>
          <div
            className="form-file border rounded-2 mb-3"
            style={{
              minHeight: "190px",
            }}
          >
            <div className="mb-2 p-2">
              <span className="text-muted">{t("アイテムのメディア")}</span>
            </div>
            <div className="mb-3 text-center">
              <label htmlFor={id} className="btn btn-link bg-light text-secondary py-4 px-5 m-0">
                <i className="fa-solid fa-file-arrow-up fa-2x" />
              </label>
              <input type="file" id={id} required onChange={handleImageChange} ref={fileInput} />
            </div>
            <div className="alert alert-secondary mb-0" role="alert">
              <dl className="row g-1 mb-0 small">
                <dt className="col-3 mb-0">{t("対応メディア")}</dt>
                {/* <dd className="col-9 mb-0">JPG, PNG, GIF, SVG, MP4, WEBM, MP3</dd> */}
                <dd className="col-9 mb-0">JPG, PNG, GIF, SVG, WEBM</dd>
                <dt className="col-3 mb-0">{t("最大容量")}</dt>
                <dd className="col-9 mb-0">100 MB</dd>
              </dl>
            </div>
          </div>
        </>
      );
  }
}

export default ImageUpload;
