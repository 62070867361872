import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import { view_collection } from "../../stores/Collections/view";
import { view_user } from "../../stores/Users/view";

export default function Contents(Contact) {
  const { propsContact, set_propsContact, dispatch_post_contact } = Contact;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();
  const { user_id } = useParams();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_users = useSelector((state) => state.reduce_get_users);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const collection = useSelector((state) => state.reduce_get_items.collection);
  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection);

  const user = reduce_get_users.user;
  const v_user = view_user(user);

  return (
    <>
      <section className="py-5 bg-white">
        <div className="container py-sm-4">
          <div className="mb-5 mb-sm-3 pb-sm-1">
            <h3 className="h1 text-center">{t("スペシャリストに相談")}</h3>
            <div className="d-grid col-sm-10 col-lg-8 col-xl-6 mx-auto">
              <div className="position-relative m-4 mb-0">
                <div
                  className="progress"
                  style={{
                    height: "1px",
                  }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "100%",
                    }}
                    aria-valuenow={100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <span
                  className="position-absolute top-0 start-0 translate-middle rounded-pill btn btn-sm btn-primary"
                  style={{
                    cursor: "unset",
                  }}
                >
                  {t("入力")}
                </span>
                <span
                  className="position-absolute top-0 start-50 translate-middle rounded-pill btn btn-sm btn-primary"
                  style={{
                    cursor: "unset",
                  }}
                >
                  {t("確認")}
                </span>
                <span
                  className="position-absolute top-0 start-100 translate-middle rounded-pill btn btn-sm btn-primary"
                  style={{
                    cursor: "unset",
                  }}
                >
                  <i className="fa-solid fa-paper-plane" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container pb-5">
          <div className="d-grid col-sm-10 col-lg-8 col-xl-6 my-4 mx-auto text-center">
            <h4>{t("ご相談を受け付けました")}</h4>
            <p className="mb-2 mb-sm-0">
              {t("tomonity スペシャリストに")}
              <br className="d-sm-none" />
              {t("ご相談ありがとうございます。")}
            </p>
            <p>
              {t("ご相談内容の再確認のため")}
              <br className="d-sm-none" />
              {t("自動返信メールをお送りしました。")}
            </p>
            <div className="my-4 pb-3">
              <i className="fa-solid fa-envelope-open-text display-3" />
              <span className="d-block fw-bold mt-2 mb-3">{propsContact.mail_address}</span>
              {/* <span className="d-inline-block bg-brand-light rounded-2 p-2 px-sm-5">
                <small className="d-block fw-bold mb-1">ご相談番号</small>
                <span className="display-6 lh-1 fw-bolder text-dark">0123456</span>
              </span> */}
            </div>
            <div className="bg-light p-3 px-md-5 text-start">
              <p className="mb-2">
                {t("しばらく経っても受信されない場合は下記のような原因があります。")}
                <br />
                {t("再度お問い合わせいただく場合もございますのでご了承ください。")}
              </p>
              <ul className="mb-0">
                <li>{t("メールアドレスの入力間違い")}</li>
                <li>
                  {t(
                    "ご利用のメールサービスやセキュリティソフトにより迷惑メールと誤判断され、迷惑メールフォルダ・削除フォルダに隔離されている"
                  )}
                </li>
                <li>{t("メールサーバーの容量を超えている")}</li>
                <li>{t("ドメイン指定受信拒否などの制限をしている")}</li>
              </ul>
            </div>
          </div>
          <div className="text-center">
            <a
              className="btn btn-primary rounded-pill px-4 btn-lg"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/home`,
                });
              }}
            >
              <i className="fa-solid fa-house me-3" />
              tomonityに戻る
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
