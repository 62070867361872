import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  zeroPadding,
  toNumber,
} from "config/util";
// import { view_item } from "../../stores/Items/view";

export const view_wallets = (prop, maps, t, coin) => {
  const resWallets = {
    ...prop,
  };
  if (!prop) return {};
  if (!maps) return {};
  const coin_decimals = maps.coin_decimals;
  if (!coin_decimals) return {};

  const coins = maps.coins;

  const sum_usd = prop["SUM"]["USD"];
  const sum_jpy = prop["SUM"]["JPY"];

  if (coin) {
    resWallets.coin_fullname = coins[coin];
    resWallets.coin_balance = prop[coin].coin_balance;
    resWallets.usd_balance = prop[coin].usd_balance;

    resWallets.coin_balance_label = toNumber(resWallets.coin_balance, coin_decimals[coin]);
    resWallets.usd_balance_label = toNumber(resWallets.usd_balance, coin_decimals["USDT"]);

    resWallets.address = prop[coin].address;

    resWallets.coin_balance = prop[coin].coin_balance;
    resWallets.wallet_address = prop[coin].address;
    resWallets.wallet_id = prop[coin].wallet_id;
  }

  resWallets.sum_usd_label = toNumber(sum_usd, coin_decimals["USDT"]);
  resWallets.sum_jpy_label = toNumber(sum_jpy, coin_decimals["JPY"]);
  //  const item = prop.item;
  // if (item) {
  //   resWallets.item = view_item(item);
  // }

  return resWallets;
};

// import { view_wallets } from "../../stores/Collections/view";
// const wallet = useSelector((state) => state.reduce_get_activities.wallet);
// const v_wallet = view_wallets(wallet);
