import React, { FC, useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getSearch, setSearch } from "config/util";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";

import { post_contact } from "../../stores/Auth/actions";
import { ChainLink } from "components/ChainLink";

import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import socketIOClient from "socket.io-client";
import env from "../../stores/env";

let socket = null;

const App = ({ branch, children }) => {
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();

  const dispatch = useDispatch();

  const maps = useSelector((state) => state.reduce_get_maps);
  const datalist_collections = useSelector((state) => state.reduce_get_collections.collections);
  const { user_id } = useParams();

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    if(!message)return 
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const [propsContact, set_propsContact] = useState({
    type: 1,
    last_name: "",
    first_name: "",
    mail_address: "",
    mail_address2: "",
    country: "",
    device: "",
    os: "",
    browser: "",
    category: "",
    message: "",
    consent: false,
    search: {
      ...branch?.search,
    },
  });

  const dispatch_post_contact = (data, next, prev) => {
    dispatch(
      post_contact({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  useEffect(() => {}, []);

  const newchildren = React.cloneElement(children, {
    Contact: {
      propsContact,
      set_propsContact,
      dispatch_post_contact: dispatch_post_contact,
      branch,
    },
  });

  return (
    <>
      <Helmet>
        <title>tomonity</title>
      </Helmet>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      {newchildren}
    </>
  );
};

export default App;
