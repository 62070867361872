import { createAction, createReducer } from 'redux-act';

export const get_activities = createAction("GET_ACTIVITIES");
export const get_activities_data = createAction("GET_ACTIVITIES_DATA");
export const get_activities_error = createAction("GET_ACTIVITIES_ERROR");
export const get_activities_id = createAction("GET_ACTIVITIES_ID");
export const get_activities_id_data = createAction("GET_ACTIVITIES_ID_DATA");
export const get_activities_id_error = createAction("GET_ACTIVITIES_ID_ERROR");
export const post_activities = createAction("POST_ACTIVITIES");
export const post_activities_data = createAction("POST_ACTIVITIES_DATA");
export const post_activities_error = createAction("POST_ACTIVITIES_ERROR");
export const put_activities_id = createAction("PUT_ACTIVITIES_ID");
export const put_activities_id_data = createAction("PUT_ACTIVITIES_ID_DATA");
export const put_activities_id_error = createAction("PUT_ACTIVITIES_ID_ERROR");
export const delete_activities_id = createAction("DELETE_ACTIVITIES_ID");
export const delete_activities_id_data = createAction("DELETE_ACTIVITIES_ID_DATA");
export const delete_activities_id_error = createAction("DELETE_ACTIVITIES_ID_ERROR");
