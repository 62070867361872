import { createAction, createReducer } from 'redux-act';

export const get_transactions = createAction("GET_TRANSACTIONS");
export const get_transactions_data = createAction("GET_TRANSACTIONS_DATA");
export const get_transactions_error = createAction("GET_TRANSACTIONS_ERROR");
export const get_transactions_id = createAction("GET_TRANSACTIONS_ID");
export const get_transactions_id_data = createAction("GET_TRANSACTIONS_ID_DATA");
export const get_transactions_id_error = createAction("GET_TRANSACTIONS_ID_ERROR");
export const post_transactions = createAction("POST_TRANSACTIONS");
export const post_transactions_data = createAction("POST_TRANSACTIONS_DATA");
export const post_transactions_error = createAction("POST_TRANSACTIONS_ERROR");
export const put_transactions_id = createAction("PUT_TRANSACTIONS_ID");
export const put_transactions_id_data = createAction("PUT_TRANSACTIONS_ID_DATA");
export const put_transactions_id_error = createAction("PUT_TRANSACTIONS_ID_ERROR");
export const delete_transactions_id = createAction("DELETE_TRANSACTIONS_ID");
export const delete_transactions_id_data = createAction("DELETE_TRANSACTIONS_ID_DATA");
export const delete_transactions_id_error = createAction("DELETE_TRANSACTIONS_ID_ERROR");
