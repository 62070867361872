import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import CustomDate from "components/CustomDate/CustomDate.js";
import { hideModal, showModal } from "config/util";
import Decimal from "decimal.js";

// import { view_collection } from "../../stores/Collections/view";
import { view_item } from "../../stores/Items/view";
import moment from "moment/moment";

const _modal_item_bid = (ItemBids) => {
  const {
    propsItemBids,
    set_propsItemBids,
    dispatch_post_items_id_bids,
    set_get_items_id,
    dispatch_activities,
    maps,
    t,
    v_item,
  } = ItemBids;

  const pri = v_item.item_sale_price ?? 0;
  const per5 = new Decimal(pri).times(1.05).toFixed();

  const [price, set_price] = React.useState("");

  // useEffect(() => {
  //   set_propsItemBids({
  //     ...propsItemBids,
  //     price: per5,
  //   });
  // }, [v_item.item_sale_price]);

  return (
    <>
      <div
        className="modal fade"
        id="itemBidModal"
        tabIndex={-1}
        aria-labelledby="itemBidModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="itemBidModalLabel">
                {t("入札する")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <dl className="row g-2">
                {/* <dt className="col-sm-4">コイン</dt>
                <dd className="col-sm-8">
                  <select
                    className="form-select"
                    id="collectionCategory"
                    aria-label="\u30AB\u30C6\u30B4\u30EA"
                    value={propsItemBids.coin}
                    onChange={(e) => {
                      const val = e.target.value;
                      set_propsItemBids({
                        ...propsItemBids,
                        coin: val,
                      });
                    }}
                  >
                    {Object.keys(maps.coins).map((k, idx) => {
                      return <option value={k}>{maps.coins[k]}</option>;
                    })}
                  </select>
                </dd> */}

                <dt className="col-sm-4">{t("金額")}</dt>
                <dd className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) => {
                      const val = e.target.value;
                      set_price(val);
                      // set_propsItemBids({
                      //   ...propsItemBids,
                      //   price: val,
                      // });
                    }}
                    value={price}
                  />
                </dd>
                <dt className="col-sm-4">{t("最低入札額")}</dt>
                <dd className="col-sm-8">
                  <sub className="me-1">{v_item.item_sale_coin_tag}</sub>

                  {per5}
                  {/* <CustomDate
                    readOnly={false}
                    labelText={t("いつまで")}
                    type="text"
                    autoComplete="off"
                    value={propsItemBids.closed_at}
                    set_value={(val) => {
                      set_propsItemBids({
                        ...propsItemBids,
                        closed_at: val,
                      });
                    }}
                  /> */}
                </dd>
              </dl>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                {t("キャンセル")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  // hideModal("itemBidModal");
                  e.preventDefault();
                  dispatch_post_items_id_bids(
                    {
                      item_id: v_item.item_id,
                      item_detail_id: v_item.item_detail_id,
                      // coin: propsItemBids.coin,
                      price: price,
                      // closed_at: moment(propsItemBids.closed_at).format(),
                      search: propsItemBids.search,
                      // timezone: moment().format("Z"),
                    },
                    () => {
                      hideModal("itemBidModal");
                      set_get_items_id();
                      // dispatch_activities();
                    },
                    () => {}
                  );
                }}
              >
                {t("入札")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default _modal_item_bid;
