import { combineReducers } from "redux";
import { createAction, createReducer } from "redux-act";

import {
  get_top_collections,
  get_top_collections_data,
  get_top_collections_error,
  // get_top_collections_id,
  // get_top_collections_id_data,
  // get_top_collections_id_error,
  get_top_items,
  get_top_items_data,
  get_top_items_error,
  get_top_buyers,
  get_top_buyers_data,
  get_top_buyers_error,
  get_top_sellers,
  get_top_sellers_data,
  get_top_sellers_error,
  get_top_collabos,
  get_top_collabos_data,
  get_top_collabos_error,
  put_top_items_id_favorite,
  put_top_items_id_favorite_data,
  put_top_items_id_favorite_error,
  put_top_collections_id_favorite,
  put_top_collections_id_favorite_data,
  put_top_collections_id_favorite_error,
} from "./actions";

// const forupdate = (array, unit, id) => {
//   for (let index = 0; index < array.length; index++) {
//     const element = array[index];
//     if (element[id] === unit[id]) {
//       array[index] = unit;
//     }
//   }
//   return array;
// };

export const reduce_get_top_collections = createReducer(
  {
    [get_top_collections]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_top_collections_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });
      newState.isLoading = false;
      return newState;
    },
    [get_top_collections_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    // [get_top_collections_id]: (state, payload) => {
    //   const newState = Object.assign({}, state, {});
    //   newState.isLoadingId = true;
    //   return newState;
    // },
    // [get_top_collections_id_data]: (state, payload) => {
    //   const newState = Object.assign({}, state, { ...payload.data });

    //   const collection = payload.data.collection;
    //   const collections = state.collections;

    //   if (collections) newState.collections = forupdate(collections, collection, "collection_id");
    //   newState.isLoadingId = false;
    //   return newState;
    // },
    // [get_top_collections_id_error]: (state, payload) => {
    //   const newState = Object.assign({}, state, {});
    //   newState.isLoadingId = false;
    //   return newState;
    // },
  },
  {
    isLoading: false,
    collections: [],
    count: 0,
  }
);

export const reduce_get_top_items = createReducer(
  {
    [get_top_items]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_top_items_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_top_items_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
    items: [],
    count: 0,
  }
);

export const reduce_get_top_sellers = createReducer(
  {
    [get_top_sellers]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_top_sellers_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_top_sellers_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
    sellers: [],
    count: 0,
  }
);

export const reduce_get_top_buyers = createReducer(
  {
    [get_top_buyers]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_top_buyers_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_top_buyers_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
    buyers: [],
    count: 0,
  }
);


export const reduce_get_top_collabos = createReducer(
  {
    [get_top_collabos]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_top_collabos_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_top_collabos_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
    collabos: [],
    count: 0,
  }
);

export const reduce_put_top_items_id_favorite = createReducer(
  {
    [put_top_items_id_favorite]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_top_items_id_favorite_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_top_items_id_favorite_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_top_collections_id_favorite = createReducer(
  {
    [put_top_collections_id_favorite]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_top_collections_id_favorite_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_top_collections_id_favorite_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export default {
  reduce_get_top_collections,
  reduce_get_top_items,
  reduce_get_top_sellers,
  reduce_get_top_buyers,
  reduce_get_top_collabos,
};
