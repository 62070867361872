import { createAction, createReducer } from 'redux-act';

export const get_collections = createAction("GET_COLLECTIONS");
export const get_collections_data = createAction("GET_COLLECTIONS_DATA");
export const get_collections_error = createAction("GET_COLLECTIONS_ERROR");
export const get_collections_id = createAction("GET_COLLECTIONS_ID");
export const get_collections_id_data = createAction("GET_COLLECTIONS_ID_DATA");
export const get_collections_id_error = createAction("GET_COLLECTIONS_ID_ERROR");
export const post_collections = createAction("POST_COLLECTIONS");
export const post_collections_data = createAction("POST_COLLECTIONS_DATA");
export const post_collections_error = createAction("POST_COLLECTIONS_ERROR");
export const put_collections_id = createAction("PUT_COLLECTIONS_ID");
export const put_collections_id_data = createAction("PUT_COLLECTIONS_ID_DATA");
export const put_collections_id_error = createAction("PUT_COLLECTIONS_ID_ERROR");
export const delete_collections_id = createAction("DELETE_COLLECTIONS_ID");
export const delete_collections_id_data = createAction("DELETE_COLLECTIONS_ID_DATA");
export const delete_collections_id_error = createAction("DELETE_COLLECTIONS_ID_ERROR");

export const put_collections_id_favorite = createAction("PUT_COLLECTIONS_ID_FAVORITE");
export const put_collections_id_favorite_data = createAction("PUT_COLLECTIONS_ID_FAVORITE_DATA");
export const put_collections_id_favorite_error = createAction("PUT_COLLECTIONS_ID_FAVORITE_ERROR");

export const put_collections_id_join = createAction("PUT_COLLECTIONS_ID_JOIN");
export const put_collections_id_join_data = createAction("PUT_COLLECTIONS_ID_JOIN_DATA");
export const put_collections_id_join_error = createAction("PUT_COLLECTIONS_ID_JOIN_ERROR");



