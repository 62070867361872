import Header from "../../views_components/Component/Header";
import _sidebar from "./_sidebar";
import { view_collection } from "../../stores/Collections/view";
import ItemsPager from "../../views_components/PagerItems/ItemsPager";
import ItemsList from "../../views_components/PagerItems/ItemsList";
import Collections from "@material-ui/icons/Collections";
import _mainheader from "./_mainheader";
import Footer from "../../views_components/Component/Footer";

import ActivitiesPager from "../../views_components/PagerActivities/ActivitiesPager";
import ActivitiesList from "../../views_components/PagerActivities/ActivitiesList";

export default function Contents({ Page, Items, Activities }) {
  const { type, dispatch_type, v_collection } = Page;

  return (
    <>
      {_mainheader(Page, Items)}
      {type == "items" && (
        <>
          <ItemsPager Items={Items} />
          <div className="ms-md-3 mt-0">
            <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-xxl-4 g-2 g-sm-3">
              <ItemsList Items={Items} />
            </div>
          </div>
          <ItemsPager Items={Items} isFooter={true} />
        </>
      )}
      {type == "activities" && (
        <>
          <ActivitiesPager Activities={Activities} />
          <div className="ms-md-3 mt-0">
            <div className="activitys">
              <ActivitiesList Activities={Activities} />
            </div>
          </div>
          <ActivitiesPager Activities={Activities} isFooter={true} />
        </>
      )}
    </>
  );
}
