import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";
import CustomPhone from "components/CustomPhone/CustomPhone.js";
import CustomImage from "./CustomImage";
import CustomDate from "components/CustomDate/CustomDate.js";
import CustomCircle from "components/CustomCircle/CustomCircle.js";

import { hideModal, showModal } from "config/util";
import DeleteForever from "@material-ui/icons/DeleteForever";
import FlipMove from "react-flip-move";
import { Col, Row } from "reactstrap";
import MultiImageUpload from "./MultiImageUpload";
import { toMomentString } from "config/util";

export default function Contents({ Account }) {
  const { propsAccount, set_propsAccount, dispatch_put_accounts, dispatch_type, branch } = Account;
  const method = branch.method;

  const props = propsAccount;
  const set_props = set_propsAccount;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();
  const [images, set_images] = React.useState([]);

  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts.user);
  const reduce_get_accounts_kyc = useSelector((state) => state.reduce_get_accounts_kyc.kyc);
  const user_icon_uri = reduce_get_accounts.user_icon_uri;
  const user_icon_mime = reduce_get_accounts.user_icon_mime;
  const name01 = reduce_get_accounts.name01;
  const name02 = reduce_get_accounts.name02;
  const postal_code = reduce_get_accounts.postal_code;
  const address01 = reduce_get_accounts.address01;
  const address02 = reduce_get_accounts.address02;

  const loading = useSelector((state) => state.reduce_put_accounts.isLoading);

  const kyc = reduce_get_accounts_kyc;
  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const maps = useSelector((state) => state.reduce_get_maps);

  const renderPreview = () => {
    if (!kyc) {
      return <></>;
    }

    if (!Object.keys(kyc).length) {
      return <></>;
    }

    return (
      <div className={"fileUploader "}>
        <div className="fileContainer">
          <div className="uploadPicturesWrapper">
            <FlipMove
              enterAnimation="fade"
              leaveAnimation="fade"
              // style={{ ...styles }}
            >
              <Row>
                {Object.entries(kyc)
                  .map(([key, value]) => ({
                    key,
                    value,
                  }))
                  .map((image, idx) => {
                    return (
                      <Col xs={4} sm={4} md={4} key={idx}>
                        <div className="uploadPictureContainer">
                          <img src={image.value} className="uploadPicture" alt="preview" />
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </FlipMove>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <Header />
        <div className="container container-main">
          <main className="main">
            <div action="created-collection-detail.html" entype="multpart/form-data">
              <div className="main-header pt-3 pb-2">
                <h2 className="h4 m-0 text-truncate">{t("KYCの申請")}</h2>
                <div className="btn-group">
                  <a
                    className="btn btn-outline-secondary text-dark rounded-pill px-3 me-2 text-nowrap"
                    href="javascript:history.back()"
                  >
                    <i className="fa-solid fa-xmark" />
                    <span className="d-none d-sm-inline-block">{" "}{t("キャンセル")}</span>
                  </a>
                  <button
                    type="button"
                    name="confirm"
                    className="btn btn-primary rounded-pill px-3 text-nowrap"
                    role="button"
                    // data-bs-toggle="modal"
                    // data-bs-target="#processDialog"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch_put_accounts(
                        {
                          name01: props.name01,
                          name02: props.name02,
                          postal_code: props.postal_code,
                          address01: props.address01,
                          address02: props.address02,
                          birthday: toMomentString(props.birthday),
                          images,
                          type: 2,
                        },
                        () => {
                          dispatch_type()
                        },
                        () => {}
                      );
                    }}
                  >
                    <CustomCircle title={t("申請")} loading={loading} size={16} />
                  </button>
                </div>
              </div>
              <div className="row g-3 p-xl-3">
                <div className="col-md-6 col-xl-8">
                  <div className="row row-cols-1 row-cols-xl-2 g-3">
                    <div className="col">
                      <h3 className="h5">{t("個人情報")}</h3>
                      <div className="mb-4">
                        <div className="form-floating mb-2">
                          <input
                            type="text"
                            className="form-control"
                            id="collectionId"
                            placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3ID"
                            inputMode="email"
                            onChange={(e) => {
                              const val = e.target.value;
                              set_props({
                                ...props,
                                name01: val,
                              });
                            }}
                            value={props.name01}
                          />
                          <label htmlFor="collectionId">{t("姓")}</label>
                        </div>
                        <div className="form-floating mb-2">
                          <input
                            type="text"
                            className="form-control"
                            id="collectionId"
                            placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3ID"
                            inputMode="email"
                            onChange={(e) => {
                              const val = e.target.value;
                              set_props({
                                ...props,
                                name02: val,
                              });
                            }}
                            value={props.name02}
                          />
                          <label htmlFor="collectionId">{t("名")}</label>
                        </div>
                        <div className="form-floating mb-2">
                          <CustomDate
                            labelText={t("生年月日")}
                            value={props.birthday}
                            set_value={(val) => {
                              set_props({
                                ...props,
                                birthday: val,
                              });
                            }}
                          />
                        </div>
                        <div className="form-floating mb-2">
                          <input
                            type="text"
                            className="form-control"
                            id="collectionId"
                            placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3ID"
                            inputMode="email"
                            onChange={(e) => {
                              const val = e.target.value;
                              set_props({
                                ...props,
                                postal_code: val,
                              });
                            }}
                            value={props.postal_code}
                          />
                          <label htmlFor="collectionId">{t("郵便番号")}</label>
                        </div>
                        <div className="form-floating mb-2">
                          <input
                            type="text"
                            className="form-control"
                            id="collectionId"
                            placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3ID"
                            inputMode="email"
                            onChange={(e) => {
                              const val = e.target.value;
                              set_props({
                                ...props,
                                address01: val,
                              });
                            }}
                            value={props.address01}
                          />
                          <label htmlFor="collectionId">{t("住所１")}</label>
                        </div>
                        <div className="form-floating mb-2">
                          <input
                            type="text"
                            className="form-control"
                            id="collectionId"
                            placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3ID"
                            inputMode="email"
                            onChange={(e) => {
                              const val = e.target.value;
                              set_props({
                                ...props,
                                address02: val,
                              });
                            }}
                            value={props.address02}
                          />
                          <label htmlFor="collectionId">{t("住所２")}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col mb-4">
                      <h3 className="h5">{t("証明画像")}</h3>
                      <div className="mb-4">
                        <div className="form-floating mb-2">
                          {renderPreview()}
                          <MultiImageUpload images={images} set_images={set_images} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
