import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { view_item } from "../../stores/Items/view";

export default function Contents(Item, Activities) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const maps = useSelector((state) => state.reduce_get_maps);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const item = useSelector((state) => state.reduce_get_items.item);
  const v_item = view_item(item, maps, t);

  const link_twitter = v_item.collection_link_twitter;
  const link_facebook = v_item.collection_link_facebook;
  const link_instagram = v_item.collection_link_instagram;
  const link_discord = v_item.collection_link_discord;
  const link_linkedin = v_item.collection_link_linkedin;

  return (
    <>
      <div className="accordion mb-3" id="itemPanels">
        <div className="accordion-item">
          <h3 className="accordion-header" id="aboutCollection-heading">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#aboutCollection"
              aria-expanded="false"
              aria-controls="aboutCollection"
            >
              {t("コレクションについて")}
            </button>
          </h3>
          <div
            id="aboutCollection"
            className="accordion-collapse collapse"
            aria-labelledby="aboutCollection-heading"
          >
            <div className="accordion-body">
              <div className="position-relative">
                <div className="cover ratio ratio-1x3 mb-2">
                  <img
                    src={v_item.collection_cover_uri}
                    className="cover-item img-fluid"
                    width="100%"
                    // height="100%"
                    alt=""
                  />
                </div>
                <div className="media mb-2 overflow-hidden">
                  <div className="media-object">
                    <div className="object-item icon icon-square">
                      {/* <div className="verified"> */}
                      <div className="">
                        <img
                          src={v_item.collection_icon_uri}
                          className="icon-item"
                          width="100%"
                          height="auto"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="media-body overflow-hidden">
                    <h3 className="media-title">
                      {/* <span className="verified-text"> */}
                      <span className="">
                        <a
                          className="stretched-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/collections/${v_item.collection_id}/items`);
                          }}
                        >
                          {v_item.collection_name}
                        </a>
                      </span>
                      <span>@{v_item.collection_id}</span>
                    </h3>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <span className="d-block">
                  <a
                    className="btn btn-white-link btn-sm ps-0 mb-1"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`${v_item.category_icon_link}`);
                    }}
                  >
                    {v_item.category_icon_tag}
                    {t(v_item.category_name)}
                  </a>
                </span>

                {/* <button
                  type="button"
                  className="btn btn-light btn-sm ps-0"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                >
                  <i
                    className="fa-solid fa-clipboard-check fa-fw me-2"
                    title=""
                  />
                  0c000654321
                </button> */}
              </div>
              <p>
                {v_item.collection_description_br}
                {/* <a href="collections.html?%23#%E3%83%87%E3%82%A3%E3%82%BA%E3%83%8B%E3%83%BC">
                  #ディズニー
                </a>{" "}
                <a href="collections.html?%23%E3%83%87%E3%82%A3%E3%82%BA%E3%83%8B%E3%83%BC%E3%83%97%E3%83%AA%E3%83%B3%E3%82%BB%E3%82%B9">
                  #ディズニープリンセス
                </a> */}
              </p>
              <aside className="btn-group" role="link">
                {link_twitter && (
                  <a
                    className="btn btn-link btn-sm rounded-pill me-2"
                    href
                    target
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`${link_twitter}`, "_blank", "noreferrer");
                    }}
                  >
                    <i className="fab fa-twitter fa-fw" />
                  </a>
                )}
                {link_facebook && (
                  <a
                    className="btn btn-link btn-sm rounded-pill me-2"
                    href
                    target
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`${link_facebook}`, "_blank", "noreferrer");
                    }}
                  >
                    <i className="fab fa-facebook-f fa-fw" />
                  </a>
                )}
                {link_instagram && (
                  <a
                    className="btn btn-link btn-sm rounded-pill me-2"
                    href
                    target
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`${link_instagram}`, "_blank", "noreferrer");
                    }}
                  >
                    <i className="fab fa-instagram fa-fw" />
                  </a>
                )}
                {link_discord && (
                  <a
                    className="btn btn-link btn-sm rounded-pill me-2"
                    href
                    target
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`${link_discord}`, "_blank", "noreferrer");
                    }}
                  >
                    <i className="fab fa-discord fa-fw" />
                  </a>
                )}

                {/* {v_item.collection_link_twitter && (
                  <a className="btn btn-white-link btn-sm rounded-pill" href target>
                    <i className="fa-fw fa-brands fa-twitter" />
                  </a>
                )}
                {v_item.collection_link_facebook && (
                  <a className="btn btn-white-link btn-sm rounded-pill" href target>
                    <i className="fa-fw fa-brands fa-facebook-f" />
                  </a>
                )}
                {v_item.collection_link_instagram && (
                  <a className="btn btn-white-link btn-sm rounded-pill" href target>
                    <i className="fa-fw fa-brands fa-instagram" />
                  </a>
                )}
                {v_item.collection_link_discord && (
                  <a className="btn btn-white-link btn-sm rounded-pill" href target>
                    <i className="fa-fw fa-brands fa-discord" />
                  </a>
                )}
                {v_item.collection_link_linkedin && (
                  <a className="btn btn-white-link btn-sm rounded-pill" href target>
                    <i className="fa-fw fa-brands fa-linkedin-in" />
                  </a>
                )} */}
              </aside>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
