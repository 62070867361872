import { createAction } from "redux-act";


export const post_variable = createAction("post_variable");

export const post_authin = createAction("POST_AUTHIN");
export const post_authin_data = createAction("POST_AUTHIN_DATA");
export const post_authin_error = createAction("POST_AUTHIN_ERROR");
export const post_authout = createAction("POST_AUTHOUT");
export const post_authouthome = createAction("POST_AUTHOUTHOME");
export const post_auth_account = createAction("POST_AUTH_ACCOUNT");

export const post_authin_code = createAction("POST_AUTHIN_CODE");
export const post_authin_code_data = createAction("POST_AUTHIN_CODE_DATA");
export const post_authin_code_error = createAction("POST_AUTHIN_CODE_ERROR");

export const post_register = createAction("POST_REGISTER");
export const post_register_data = createAction("POST_REGISTER_DATA");
export const post_register_error = createAction("POST_REGISTER_ERROR");

export const post_register_code = createAction("POST_REGISTER_CODE");
export const post_register_code_data = createAction("POST_REGISTER_CODE_DATA");
export const post_register_code_error = createAction(
  "POST_REGISTER_CODE_ERROR"
);

export const post_register_resend = createAction("POST_REGISTER_RESEND");
export const post_register_resend_data = createAction("POST_REGISTER_RESEND_DATA");
export const post_register_resend_error = createAction(
  "POST_REGISTER_RESEND_ERROR"
);

export const post_password = createAction("POST_PASSWORD");
export const post_password_data = createAction("POST_PASSWORD_DATA");
export const post_password_error = createAction("POST_PASSWORD_ERROR");
export const post_password_code = createAction("POST_PASSWORD_CODE");
export const post_password_code_data = createAction("POST_PASSWORD_CODE_DATA");
export const post_password_code_error = createAction(
  "POST_PASSWORD_CODE_ERROR"
);
export const post_password_reset = createAction("POST_PASSWORD_RESET");
export const post_password_reset_data = createAction(
  "POST_PASSWORD_RESET_DATA"
);
export const post_password_reset_error = createAction(
  "POST_PASSWORD_RESET_ERROR"
);

export const get_maps = createAction("GET_MAPS");
export const get_maps_data = createAction("GET_MAPS_DATA");
export const get_maps_error = createAction("GET_MAPS_ERROR");

export const post_contact = createAction("POST_CONTACT");
export const post_contact_data = createAction("POST_CONTACT_DATA");
export const post_contact_error = createAction("POST_CONTACT_ERROR");

