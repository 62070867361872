import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import CustomDate from "components/CustomDate/CustomDate.js";
import { hideModal, showModal } from "config/util";

// import { view_collection } from "../../stores/Collections/view";
import { view_item } from "../../stores/Items/view";
import moment from "moment/moment";

const _modal_item_send = (Users, Items) => {
  const { propsItems, set_propsItems, dispatch_put_items_id, loadingId } = Items;
  const { v_user, history, t, dispatch_post_send, item_send } = Users;

  return (
    <>
      <div
        className="modal fade"
        id="itemSendModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-6 shadow">
            <div className="modal-body">
              <h2 className="fw-bold mb-0 text-center">{t("アイテムを転送しますか？")}</h2>
              <div className="row mt-4 mb-3">
                <div className="col-4 mx-auto">
                  <div htmlFor="collectionLogo" className="ratio ratio-1x1 object-item">
                    <img src={propsItems.item_icon_uri} />
                  </div>
                </div>
              </div>
              <div className="card card-body text-start overflow-hidden position-relative p-3">
                <div className="media">
                  <div className="media-object">
                    {/* <div className="icon icon-pill verified"> */}
                    <div className="icon icon-pill">
                      <img
                        src={item_send.user_icon_uri}
                        className="icon-item"
                        width="100%"
                        height="auto"
                        alt="tomonity official"
                      />
                    </div>
                  </div>
                  <div className="media-body text-truncate overflow-hidden">
                    <h3 className="media-title mb-0">
                      <a
                        className="stretched-link text-dark"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/users/${item_send.user_id}/items`);
                        }}
                      >
                        {/* <div className="d-flex verified-text"> */}
                        <span className="text-truncate">{item_send.user_name}</span>
                        {/* </div> */}
                      </a>
                    </h3>
                    <span className="d-block text-muted text-truncate">@{item_send.user_nmid}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                {t("キャンセル")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  // hideModal("itemOrderModal");
                  e.preventDefault();
                  dispatch_post_send(
                    item_send,
                    () => {
                      hideModal("itemSendModal");
                      history.goBack();
                    },
                    () => {}
                  );
                }}
              >
                {t("転送")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default _modal_item_send;
