import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";

import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";

import { view_item_bid } from "../../stores/ItemBids/view";

export const HeaderItem = (ItemBids) => {
  const t = ItemBids.t;

  return (
    <>
      <dl className="row g-0 g-sm-2 g-md-0 border-bottom border-dark border-2 py-3 pb-md-2 mb-1">
        <dt className="col">
          <small className="d-block">{t("入札者")}</small>
        </dt>
        <dt className="col-4 col-sm-3 col-md-4 pe-sm-3 pe-md-0 text-end">
          <small className="d-block"> </small>
        </dt>
        <dt className="col-12 col-sm-6 col-md-12 order-sm-first order-md-last">
          <dl className="row g-0 g-sm-2 g-md-0 align-items-sm-center align-items-md-start">
            <dt className="col-5 col-sm-6 pe-sm-3 pe-md-0 text-sm-end text-md-start">
              <small className="d-block">{t("入札価格")}</small>
            </dt>
            <dt className="col text-end text-sm-start text-md-end">
              <small className="d-block">{t("入札日時")}</small>
            </dt>
          </dl>
        </dt>
      </dl>
    </>
  );
};

export const RowItem = (ItemBids) => {
  const { v_item_bid, history, t } = ItemBids;
  const { propsItemBids, set_propsItemBids, dispatch_gets, user_id } = ItemBids;

  // created_at: "2022-05-31T12:52:37.000Z"
  // item_auction_bid_id: 1
  // item_auction_id: 93
  // price: "0.2"
  // user_id: 86

  const coin_icon_tag =
    v_item_bid.item_sale_coin == "BTC" ? (
      <i className="icomoon-brand-btc btc-color" />
    ) : v_item_bid.item_sale_coin == "ETH" ? (
      <i className="icomoon-brand-eth eth-color" />
    ) : v_item_bid.item_sale_coin == "IOST" ? (
      <i className="icomoon-brand-iost iost-color" />
    ) : v_item_bid.item_sale_coin == "USDT" ? (
      <i className="icomoon-brand-usdt usdt-color" />
    ) : (
      <></>
    );

  return (
    <>
      <dl className="row g-1 g-sm-2 py-3 pt-md-2 mb-1 align-items-sm-center align-items-lg-start align-items-xl-center border-bottom">
        <dd className="col text-truncate overflow-hidden">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: `/users/${v_item_bid.user_id}`,
              });
            }}
          >
            {/* <div className="d-flex verified-text"> */}
            <span className="text-truncate">{v_item_bid.user_name}</span>
            {/* </div> */}
          </a>
        </dd>
        <dd className="col-4 col-sm-3 col-md-4 pe-sm-3 pe-md-0 text-end">
          {/* <div className="btn-group btn-group-sm text-nowrap">
            <button
              className="btn btn-sm btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#bidAllowModal"
              onClick={(e) => {
                e.preventDefault();
                set_propsItemBids({
                  ...propsItemBids,
                  item_id: v_item_bid.item_id,
                  item_detail_id: v_item_bid.item_detail_id,
                  item_bid_id: v_item_bid.item_bid_id,
                  type: "allow",
                });
              }}
            >
              許可
            </button>
            <button
              className="btn btn-sm btn-outline-danger"
              data-bs-toggle="modal"
              data-bs-target="#bidAllowModal"
              onClick={(e) => {
                e.preventDefault();
                set_propsItemBids({
                  ...propsItemBids,
                  item_id: v_item_bid.item_id,
                  item_detail_id: v_item_bid.item_detail_id,
                  type: "decline",
                });
              }}
            >
              拒否
            </button>
          </div> */}
        </dd>
        <dd className="col-12 col-sm-6 col-md-12 order-sm-first order-md-last">
          <dl className="row g-0 g-sm-2 g-md-0 align-items-sm-center align-items-md-start mt-2 mt-sm-0 mt-lg-2">
            <dd className="col-5 col-sm-6 pe-sm-3 pe-md-0 text-sm-end text-md-start">
              <span
                className="fw-bold"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={v_item_bid.item_sale_coin}
              >
                <sub className="me-1">{coin_icon_tag}</sub>
                {v_item_bid.item_sale_price}
              </span>
              {/* <span className="fw-bold" data-bs-toggle="tooltip" data-bs-placement="top" title="">
                {v_item_bid.item_sale_price}
              </span> */}
              <small className="d-block ps-2 ps-sm-0 ps-md-2">
                <sub>$</sub>
                {v_item_bid.item_sale_price_usd_label}
              </small>
            </dd>
            <dd className="col text-end text-sm-start text-md-end">
              <small> {v_item_bid.created_at_countdown_label}</small>
            </dd>
          </dl>
        </dd>
      </dl>
    </>
  );
};
