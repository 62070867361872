import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";

import { view_activity } from "../../stores/Activities/view";
import { HeaderUser, RowUser } from "./_user";
import { HeaderItem, RowItem } from "./_item";
import { HeaderWallet, RowWallet } from "./_wallet";

export default function Dashboard({ Activities }) {
  const [t, i18n] = useTranslation();
  const history = useHistory();

  Activities.history = history;
  Activities.t = t;

  const maps = useSelector((state) => state.reduce_get_maps);

  // const datalist = useSelector((state) => state.reduce_get_activities.activities);

  const datalist = useSelector((state) => state.reduce_get_activities.activities) ?? [];
  const loading = useSelector((state) => state.reduce_get_activities.isLoading);
  const loadingId = useSelector((state) => state.reduce_get_activities.isLoadingId);

  if (loading) return <></>;

  return (
    <>
      {Activities.list == "user" && (
        <>
          {HeaderUser(Activities)}
          {datalist.map((prop, idx) => {
            const v_activity = view_activity(prop, maps, t);
            Activities.v_activity = v_activity;
            return <>{RowUser(Activities)}</>;
          })}
        </>
      )}
      {Activities.list == "item" && (
        <>
          {HeaderItem(Activities)}
          {datalist.map((prop, idx) => {
            const v_activity = view_activity(prop, maps, t);
            Activities.v_activity = v_activity;
            return <>{RowItem(Activities)}</>;
          })}
        </>
      )}
      {Activities.list == "wallet" && (
        <>
          {HeaderWallet(Activities)}
          {datalist.map((prop, idx) => {
            const v_activity = view_activity(prop, maps, t);
            Activities.v_activity = v_activity;
            return <>{RowWallet(Activities)}</>;
          })}
        </>
      )}
    </>
  );
}
