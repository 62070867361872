import { combineReducers } from "redux";
import { createAction, createReducer } from "redux-act";

import {
  get_collabos,
  get_collabos_data,
  get_collabos_error,
  get_collabos_id,
  get_collabos_id_data,
  get_collabos_id_error,
  post_collabos,
  post_collabos_data,
  post_collabos_error,
  put_collabos_id,
  put_collabos_id_data,
  put_collabos_id_error,
  delete_collabos_id,
  delete_collabos_id_data,
  delete_collabos_id_error,
  put_collabos_id_favorite,
  put_collabos_id_favorite_data,
  put_collabos_id_favorite_error,
} from "./actions";

const forupdate = (array, unit, id) => {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element[id] === unit[id]) {
      array[index] = unit;
    }
  }
  return array;
};

export const reduce_get_collabos = createReducer(
  {
    [get_collabos]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [get_collabos_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [get_collabos_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
    [get_collabos_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoadingId = true;
      return newState;
    },
    [get_collabos_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      const collabo = payload.data.collabo;
      const collabos = state.collabos;

      if (collabos) newState.collabos = forupdate(collabos, collabo, "collabo_id");
      newState.isLoadingId = false;
      return newState;
    },
    [get_collabos_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoadingId = false;
      return newState;
    },
  },
  {
    isLoading: false,
    isLoadingId: false,
    collabo: {},
    collabos: [],
    count: 0,
  }
);

export const reduce_post_collabos = createReducer(
  {
    [post_collabos]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [post_collabos_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [post_collabos_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_collabos_id = createReducer(
  {
    [put_collabos_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_collabos_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_collabos_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_delete_collabos_id = createReducer(
  {
    [delete_collabos_id]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [delete_collabos_id_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [delete_collabos_id_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export const reduce_put_collabos_id_favorite = createReducer(
  {
    [put_collabos_id_favorite]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = true;
      return newState;
    },
    [put_collabos_id_favorite_data]: (state, payload) => {
      const newState = Object.assign({}, state, { ...payload.data });

      newState.isLoading = false;
      return newState;
    },
    [put_collabos_id_favorite_error]: (state, payload) => {
      const newState = Object.assign({}, state, {});
      newState.isLoading = false;
      return newState;
    },
  },
  {
    isLoading: false,
  }
);

export default {
  reduce_get_collabos,
  reduce_post_collabos,
  reduce_put_collabos_id,
  reduce_delete_collabos_id,
};
