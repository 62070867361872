import React, { FC, useEffect, useRef, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import { initReactI18next, useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomLoading from "components/CustomLoading/CustomLoading.js";
import { DisplayContext } from "../../Navigator/Router_material";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { getMessage, toMomentString, toggleAry } from "config/util";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

import { getSearch, setSearch } from "config/util";
import MainFixed from "../../components/CustomLoadingScreen/MainFixed";

export default function Dashboard(Items) {
  const { set_get_items, propsItems, set_propsItems, dispatch_gets, type, t } = Items;

  const sale_types = propsItems.search.sale_types ?? [];

  const set_gets_sale_type = (val) => {
    const search = {
      ...propsItems.search,
      sale_types: toggleAry(sale_types, val),
      // type:[val],
      // page: 1,
    };
    // dispatch_gets(search, 1);

    set_propsItems({
      search: { ...search },
    });
  };

  return (
    <>
      <div
        action
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="searchFilter"
        aria-labelledby="searchFilterLabel"
      >
        <div className="offcanvas-header">
          <h5 id="searchFilterLabel">{t("検索条件を絞り込む")}</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          <div className="mb-3">
            <label htmlFor="filter-status" className="form-label">
              {t("出品形式")}
            </label>
            <div className="row row-cols-2 g-1">
              <div className="d-grid">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="status-1"
                  autoComplete="off"
                  checked={sale_types.includes(1)}
                  onClick={() => {
                    set_gets_sale_type(1);
                  }}
                />
                <label className="btn btn-outline-secondary" htmlFor="status-1">
                  {t("出品中")}
                </label>
              </div>
              <div className="d-grid">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="status-2"
                  autoComplete="off"
                  checked={sale_types.includes(2)}
                  onClick={() => {
                    set_gets_sale_type(2);
                  }}
                />
                <label className="btn btn-outline-secondary" htmlFor="status-2">
                  {t("オークション中")}
                </label>
              </div>
              {/* <div className="d-grid">
                <input type="checkbox" className="btn-check" id="status-3" autoComplete="off" />
                <label className="btn btn-outline-secondary" htmlFor="status-3">
                  {t("新着")}
                </label>
              </div>
              <div className="d-grid">
                <input type="checkbox" className="btn-check" id="status-4" autoComplete="off" />
                <label className="btn btn-outline-secondary" htmlFor="status-4">
                  {t("オファーあり")}
                </label>
              </div> */}
            </div>
          </div>
          {/* <div className="mb-3">
            <label htmlFor="filter-price" className="form-label">
              {t("価格と通貨")}
            </label>
            <div className="row row-cols-1 row-cols-sm-2 g-1">
              <div className="col">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={0}
                    aria-label="Username"
                  />
                  <span className="input-group-text">〜</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={1000}
                    aria-label="Server"
                  />
                </div>
              </div>
              <div className="col mb-2 mb-sm-0">
                <select
                  className="selectpicker form-control"
                  id="filter-price"
                  data-style="btn-white border"
                  aria-label="\u901A\u8CA8\u306E\u7A2E\u985E"
                  title=""
                >
                  <option>United States Dollar (USD)</option>
                  <option>Ether (ETH)</option>
                </select>
              </div>
            </div>
          </div> */}

          {/* <div className="mb-3">
            <label htmlFor="filter-category" className="form-label">
              カテゴリ
            </label>
            <select
              className="selectpicker form-control"
              id="filter-category"
              multiple
              data-style="btn-white border"
              data-live-search="true"
              data-header=" \u30AB\u30C6\u30B4\u30EA"
              aria-label="\u30AB\u30C6\u30B4\u30EA"
              title=""
            >
              <option>絵画</option>
              <option>音楽</option>
              <option>ドメイン</option>
              <option>仮想世界</option>
              <option>トレーディングカード</option>
              <option>ユーティリティ</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="filter-chain" className="form-label">
              ブロックチェーン
            </label>
            <select
              className="selectpicker form-control"
              id="filter-chain"
              multiple
              data-style="btn-white border"
              data-header=" \u30D6\u30ED\u30C3\u30AF\u30C1\u30A7\u30FC\u30F3"
              aria-label="\u30D6\u30ED\u30C3\u30AF\u30C1\u30A7\u30FC\u30F3"
              title=""
            >
              <option>Ethereum</option>
              <option>Polygon</option>
              <option>Klaytn</option>
            </select>
          </div> */}
        </div>
        <div className="offcanvas-footer border-top p-3 pb-sm-5">
          <div className="d-flex">
            <button
              type="reset"
              className="btn btn-white"
              onClick={() => {
                const search = {
                  ...propsItems.search,
                  sale_types: [],
                };
                set_propsItems({
                  search: { ...search },
                });
              }}
            >
              {t("条件を解除")}
            </button>
            <button
              type="button"
              className="btn btn-primary ms-auto"
              onClick={() => {
                const searchFilter = document.getElementById("searchFilter");
                const modal = window.bootstrap.Offcanvas.getInstance(searchFilter);
                if (modal) {
                  modal.hide();
                }

                dispatch_gets(propsItems.search, 1);

              }}
            >
              {t("実行")}
            </button>
          </div>
        </div>
      </div>
      <div
        action="collections.html"
        className="modal fade"
        id="searchField"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="searchFieldLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              {}
              <button className="btn" type="button" data-bs-dismiss="modal" aria-label="Close">
                <i className="fa-solid fa-arrow-left fa-fw" />
              </button>
              <input
                type="search"
                id="searchFieldInput"
                className="form-control"
                placeholder="\u30A2\u30A4\u30C6\u30E0\u3001\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3\u3001\u30E6\u30FC\u30B6\u3092\u691C\u7D22"
                aria-label="Search"
                autoFocus
              />
              {}
            </div>
            <div className="modal-body p-0 bg-light">
              {}
              <h6 className="px-3 mt-4 mb-2">{t("保存した検索条件")}</h6>
              <div className="list-group">
                <a href="#" className="list-group-item list-group-item-action d-flex p-3">
                  ラプンツェル
                  <small className="badge bg-dark text-white ms-1">コレクション</small>
                  <i className="fa-solid fa-angle-right ms-auto" />
                </a>
                <a href="#" className="list-group-item list-group-item-action d-flex p-3">
                  プリンセス
                  <small className="badge bg-dark text-white ms-1">コレクション</small>
                  <i className="fa-solid fa-angle-right ms-auto" />
                </a>
              </div>
              <h6 className="px-3 mt-3 mb-2">{t("直近の検索履歴")}</h6>
              <div className="list-group">
                <a href="#" className="list-group-item list-group-item-action d-flex p-3">
                  ディズニー
                  <small className="badge bg-dark text-white ms-1">コレクション</small>
                  <i className="fa-solid fa-angle-right ms-auto" />
                </a>
                <a href="#" className="list-group-item list-group-item-action d-flex p-3">
                  ディズニー
                  <small className="badge bg-dark text-white ms-1">ユーザ</small>
                  <i className="fa-solid fa-angle-right ms-auto" />
                </a>
                <a href="#" className="list-group-item list-group-item-action d-flex p-3">
                  プリンセス
                  <i className="fa-solid fa-angle-right ms-auto" />
                </a>
                <a href="#" className="list-group-item list-group-item-action d-flex p-3">
                  ラプンツェル
                  <small className="badge bg-dark text-white ms-1">コレクション</small>
                  <i className="fa-solid fa-angle-right ms-auto" />
                </a>
                <a href="#" className="list-group-item list-group-item-action d-flex p-3">
                  スーパーブラシ
                  <i className="fa-solid fa-angle-right ms-auto" />
                </a>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              {}
              <button type="submit" className="btn btn-primary rounded-pill px-4" role="button">
                検索
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
