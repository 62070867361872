import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { view_item } from "../../stores/Items/view";
import {
  LightgalleryProvider,
  LightgalleryItem,
  withLightgallery,
  useLightgallery,
} from "react-lightgallery";

export default function Contents(Item, Activities) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const maps = useSelector((state) => state.reduce_get_maps);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const item = useSelector((state) => state.reduce_get_items.item);
  const v_item = view_item(item, maps, t);

  const GROUP1 = [
    [
      "https://images.unsplash.com/photo-1592549585866-486f41343aaf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
      "https://images.unsplash.com/photo-1592549585866-486f41343aaf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
    ],
    [
      "https://images.unsplash.com/photo-1594614271360-0ed9a570ae15?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
      "https://images.unsplash.com/photo-1594614271360-0ed9a570ae15?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
    ],
  ];

  const PhotoItem = ({ image, thumb, group }) => (
    <div style={{ maxWidth: "250px", width: "200px", padding: "5px" }}>
      <LightgalleryItem group={group} src={image} thumb={thumb}>
        <img src={image} style={{ width: "100%" }} />
      </LightgalleryItem>
    </div>
  );
  //   <div
  //   style={{
  //     display: "flex",
  //     alignItems: "center",
  //     justifyContent: "center",
  //   }}
  // >
  //   <div style={{ maxWidth: "250px", width: "200px", padding: "5px" }}>
  //     <LightgalleryItem
  //       group={"group1"}
  //       src={v_item.item_icon_uri}
  //       thumb={v_item.item_icon_uri}
  //     >
  //       <img src={v_item.item_icon_uri} style={{ width: "100%" }} />
  //     </LightgalleryItem>
  //   </div>
  // </div>
  // </LightgalleryProvider>
  return (
    <>
      <LightgalleryProvider>
        <LightgalleryItem group={"group1"} src={v_item.item_icon_uri} thumb={v_item.item_icon_uri}>
          <div className="object-item bg-transparency mb-2 order-md-1">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/items/${v_item.item_id}`);
              }}
            >
              <img
                src={v_item.item_icon_uri}
                width="100%"
                className="rounded-3 img-fluid"
                alt=""
                style={{
                  objectFit: "contain",
                }}
              />
            </a>
          </div>
        </LightgalleryItem>
      </LightgalleryProvider>

      <div className="list-group list-group-flush rounded-0 mb-3">
        <div className="list-group-item p-1 d-flex flex-nowrap">
          <a
            href="javascript:void(0);"
            className="btn btn-white px-0"
            role="button"
            data-bs-toggle="modal"
            data-bs-target="#userFollow"
          >
            <span className="fw-bold">{v_item.item_favorited_count}</span>
            <span className="ms-1 text-gray">{t("お気に入り")}</span>
          </a>
        </div>
      </div>
    </>
  );
}
