import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import { QRCodeSVG } from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { hideModal, showModal } from "config/util";
import { view_wallets } from "../../stores/Wallets/view";

import _modal_withdrawals from "./_modal_withdrawals";
import _modal_walletsCreate from "./_modal_walletsCreate";

export default function Contents() {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const { coin } = useParams();

  const [active_tab, set_active_tab] = useState("預入");

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_wallets = useSelector((state) => state.reduce_get_wallets);
  const reduce_get_withdraws = useSelector((state) => state.reduce_get_withdraws);

  const loading_get_wallets = useSelector((state) => state.reduce_get_wallets.isLoading);

  const maps = useSelector((state) => state.reduce_get_maps);

  const coin_decimals = maps.coin_decimals;
  const coins = maps.coins;

  useEffect(() => {}, []);
  const coin_fullname = coins[coin];

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const wallets = reduce_get_wallets?.wallets;
  const v_wallets = view_wallets(wallets, maps, t, coin);

  const withdraws = reduce_get_withdraws?.withdraws;
  // const v_withdraws = view_withdraws(withdraws,  maps,t, coin);

  const mindeposit = {
    BTC: 0.0002,
    ETH: 0.0025,
    IOST: 0,
    USDT: 0,
  };
  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  if (!v_wallets.wallet_address)
    return (
      <>
        <div
          className={`tab-pane fade pt-2 m-md-3 show ${active_tab === "預入" && "active"}`}
          id="deposits"
          role="tabpanel"
          aria-labelledby="deposits-tab"
        >
          <p className="lead">
            {t("ウォレット作成中です")}
            <br />
            {t("作成完了までしばらくお待ちください。")}
          </p>
        </div>
      </>
    );

  return (
    <>
      <div
        className={`tab-pane fade pt-2 m-md-3 show ${active_tab === "預入" && "active"}`}
        id="deposits"
        role="tabpanel"
        aria-labelledby="deposits-tab"
      >
        <p className="lead">
          {t("お客さまの")} {coin} {t("預入用アドレスです。")}
          <br />
          {t("アドレスをコピーするか、2次元バーコードをスキャンしてご利用ください。")}
        </p>
        <div className="card bg-light text-center mb-3">
          <h3 className="card-header h5">{t("2次元バーコード")}</h3>
          <div className="card-body">
            <div
              className="bg-white mx-auto my-5 p-3"
              style={{
                maxWidth: "200px",
              }}
            >
              <QRCodeSVG value={v_wallets.wallet_address} size={168} includeMargin={false} />
            </div>
            {/* {!loading_get_wallets && ( */}
            <div
              className="mx-auto input-group mb-3"
              style={{
                maxWidth: "300px",
              }}
            >
              <input
                type="text"
                className="form-control"
                placeholder=""
                aria-label=""
                aria-describedby="walletAddressCopy"
                disabled
                value={v_wallets.wallet_address}
              />
              <CopyToClipboard
                text={v_wallets.wallet_address}
                onCopy={() => {
                  alert(t("ウォレットアドレスをコピーしました。"));
                }}
              >
                <button className="btn btn-outline-secondary" type="button" id="walletAddressCopy">
                  <i className="fa-solid fa-copy me-2 text-muted" />
                  {t("コピー")}
                </button>
              </CopyToClipboard>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
      
    </>
  );
}
